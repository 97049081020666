import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';
import { DialogService } from 'src/app/core/dialog/dialog.service';

import { AppMessages } from 'src/app/core/constants/app.constant';

import { ConfirmModal, ButtonTheme } from 'src/app/core/dialog/confirm-dialog';
import { MatDialogConfig } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  /*********************Properties*********************/
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private dialog: DialogService
  ) {}
  /*********************Constructor*********************/

  /*********************Utility Methods*********************/

  loadScript(url: string) {
    const script = document.createElement('script');
    const head = document.head;

    script.async = true;
    script.src = url;
    head.insertBefore(script, head.firstChild);
  }

//   getDefaultLogMessage(): string {
//     // const userDetails = this.userService.getDetails();
//     // const deviceInfo = this.getUserAgentType();
//     // let logMessage = '[App: WBD] [Device: ' + deviceInfo;

//     // if (userDetails && userDetails.enterpriseId) {
//     //   logMessage += ' [Enterprise Id: ' + userDetails.enterpriseId + ']';
//     //   // logMessage += ' [Role: ' + this.getUserRoleName(userDetails);
//     // }

//     return logMessage + '] ';
//   }

  confirmCancel(
    title: string = AppMessages.Confirm.Cancel.Title,
    message: string = AppMessages.Confirm.Cancel.Message
  ) {
    return this.confirm(title, message, {
      panelClass: ['confirm-dialog', 'confirm-cancel'],
    });
  }

  confirmOk(title, error, okFlag) {
    return this.confirm(title, error.faultMessage, {
      panelClass: ['confirm-dialog', 'confirm-cancel'],
    },
    ButtonTheme.Secondary,
    okFlag
    );
  }

  confirmSave(
    title: string = AppMessages.Confirm.Save.Title,
    message: string = AppMessages.Confirm.Save.Message
  ) {
    return this.confirm(title, message, {
      panelClass: ['confirm-dialog', 'confirm-save'],
    });
  }

  confirmCancelOrder(
    title: string = AppMessages.Confirm.CancelOrder.Title,
    message: string = AppMessages.Confirm.CancelOrder.Message
  ) {
    return this.confirm(title, message, {
      panelClass: ['confirm-dialog', 'confirm-save'],
    });
  }

  confirmDelete(
    title: string = AppMessages.Confirm.Delete.Title,
    message: string = AppMessages.Confirm.Delete.Message
  ) {
    return this.confirm(title, message, {
      panelClass: ['confirm-dialog', 'confirm-delete'],
    });
  }


  /*********************Utility Methods*********************/

  /*********************Private Methods*********************/

  private confirm(
    title: string,
    message: string,
    config?: MatDialogConfig,
    yesTheme: ButtonTheme = ButtonTheme.Secondary,
    okFlag?:boolean
  ) {
    const modal = new ConfirmModal();
    modal.title = title;
    modal.message = message;
    modal.okFlag = okFlag;
    modal.action.yes.theme = yesTheme;
    modal.action.no.theme =
      yesTheme === ButtonTheme.Secondary
        ? ButtonTheme.Primary
        : ButtonTheme.Secondary;

    return this.dialog
      .confirm(modal, config)
      .afterClosed()
      .pipe(map((res) => (res ? true : false)));
  }


  /*********************Private Methods*********************/
}
