// import * as _ from 'lodash';

import { CacheResolverService } from '../cache/cache-resolver.service';

import { StaticBase } from './static-base';
import { TypeRefs } from './static';
import { StaticConstants } from './static.constant';

export class TypeRef extends StaticBase {
  /*********************Properties*********************/
  private selectorPropertyName = 'typeCode';
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private selectorName: string,
    private primaryKey: string,
    private storageCache: CacheResolverService
  ) {
    super(selectorName, primaryKey, storageCache);
  }
  /*********************Constructor*********************/

  /*********************Methods*********************/

  /**
   * Get typeref object by code
   */
  get(typeCode: number): TypeRefs {
    return this.getItem(this.selectorPropertyName, typeCode);
  }

  /**
   * Gets typeref object collection by group code
   */
  // byGroup(
  //   groupCode: number,
  //   sortBy: string = StaticConstants.Properties.TypeDesc
  // ): Array<TypeRefs> {
  //   const itemData = this.getData();
  //   let typeRefs: Array<any> = [];

  //   if (itemData && itemData.length > 0) {
  //     typeRefs = _.filter(itemData, (item) => item.typeGroupCode === groupCode);
  //     typeRefs = _.sortBy(typeRefs, (item) => item[sortBy]);
  //   }

  //   return typeRefs;
  // }

  /*********************Methods*********************/
}
