Array.prototype.flatten = function(this: Array<any>, result = []) {
  this.forEach((value) => {
    if (Array.isArray(value)) {
      value.flatten(result);
    } else {
      result.push(value);
    }
  });

  return result;
};

// Array.prototype.move = function(oldIndex: number, newIndex: number) {
//   while (oldIndex < 0) {
//     oldIndex += this.length;
//   }

//   while (newIndex < 0) {
//     newIndex += this.length;
//   }

//   if (newIndex >= this.length) {
//     let k = newIndex - this.length;
//     while (k-- + 1) {
//       this.push(undefined);
//     }
//   }

//   this.splice(newIndex, 0, this.splice(oldIndex, 1)[0]);
// };

// Array.prototype.toTotal = function(
//   propertyName: string,
//   restrictZero: boolean = false
// ) {
//   const total = this.reduce(
//     (t, item) =>
//       t + (item[propertyName] ? item[propertyName].toString().toNumber() : 0),
//     0
//   );

//   return restrictZero ? total || null : total;
// };

// Array.prototype.toTotalFloat = function(
//   propertyName: string,
//   restrictZero: boolean = false,
//   fractionDigits: number = 2
// ) {
//   const total = this.reduce(
//     (t, item) =>
//       t +
//       (item[propertyName]
//         ? item[propertyName].toString().toNumber(true, fractionDigits)
//         : 0),
//     0
//   );

//   return restrictZero ? total || null : total;
// };
