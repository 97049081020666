import { NgModule } from '@angular/core';

import { SanitizePipe } from '../pipes/sanitize.pipe';
import { FormatNamePipe } from '../pipes/format-name.pipe';

@NgModule({
  declarations: [SanitizePipe, FormatNamePipe],
  exports: [SanitizePipe, FormatNamePipe],
})
export class PipeModule {
 
}
