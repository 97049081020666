import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

import * as _moment from 'moment';
import 'moment-timezone';
// tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment } from 'moment';
import { ActivatedRoute } from '@angular/router';

import { ModalDialogOrderStatusComponent } from './modal-dialog-order-status/modal-dialog-order-status.component';

import { OrderStatusService } from './order-status.service';
import { AppService } from 'src/app/app.service';
import { UserService } from 'src/app/core/services/user.service';

import { ComplianceOrderData, searchOrderCriteria } from './order';
import { DateFormat } from 'src/app/core/constants/app.constant';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ModalDialogCancelOrderComponent } from './modal-dialog-cancel-order/modal-dialog-cancel-order.component';
import { CommonControls } from 'src/app/shared/common-control/common-controls';
import { PaginationService } from 'src/app/shared/utils/pagination.service';

const moment = _moment;

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent implements OnInit, OnDestroy {
  /*********************Properties*********************/
  @ViewChild('accNoSearchInput', { static: true }) accNoSearchInput: any;
  @ViewChild('ncpdpSearchInput', { static: true }) ncpdpSearchInput: any;
  permissionsData: any;
  fPermissionNum: any;
  permissionAssignment: any = {
    cancelOrder: false
  };
  clearSearchFlag: boolean = false;
  report: any;
  disableSearchFlag: boolean = true;
  cancelFlag: boolean = false;
  minDate: Date;
  searchObj: searchOrderCriteria = {
    "fromDate": null,
    "toDate": null,
    "accountNo": null,
    //"patientName": null,
    "orderId": null,
    "groupId": null,
    "orderStatus": null,
    "assignedAccounts": null,
    "selectedHeader": null,
    "ascStatus": null,
    "itemsPerPage": null,
    "rowOffset": null
  };

  dictionary = 
    [
      {column: "sapAccountNumber", dbCol: "sap_acc_num"},
      {column: "pharmacyName", dbCol: "acc_name_1"},
      {column: "ncpdp", dbCol: "ncpdp"},
      {column: "orderNumber", dbCol: "fo_num"},
      {column: "groupId", dbCol: "navix_grp_id"},
      {column: "rxNeedByDate", dbCol: "rx_need_by_date"},
      {column: "manifestId", dbCol: "mnfst_id"},
      {column: "patientName", dbCol: "patient_name"},
      {column: "recvDateTime", dbCol: "row_add_stp"},
      {column: "displayStatus", dbCol: "displayStatus"},
      {column: "statusUpdateDateTime", dbCol: "fo_status_stp"},
      {column: "displayReason", dbCol: "displayReason"},
      {column: "shipToPatient", dbCol: "ship_to_patient"}
    ];
  searchForm: FormGroup;
  showNCPDP = false;
  searchOptions: any = [
    { label: 'Account', value: 'accountNo', checked: true },
    { label: 'NCPDP', value: 'ncpdp', checked: false }
  ]
  selectedItems = null;
  //order status dropdown list
  orderStatus: any = [];
  convertOrderStat: any = [];
  displayedColumns: string[] = ["sapAccountNumber", "pharmacyName", "ncpdp", "orderNumber", "groupId", "rxNeedByDate", "manifestId", "patientName","shipToPatient", "recvDateTime", "displayStatus", "statusUpdateDateTime", "displayReason", "actions"];
  cancellableStatus: string[] = ["ACTION REQUIRED: APPROVE & SUBMIT", "ACTION REQUIRED: MISSING FIELDS", "HELD", "ACCEPTED", "READY FOR FULFILLMENT", "PENDING LICENSE CHECK", "RBC-ACCEPTED"];
  dataSource: MatTableDataSource<ComplianceOrderData>;
  noDataFlag: boolean = true;
  foStatCdes: any = [];
  searchStatCdes: any = [];

  private onDestroy = new Subject<void>();
  private subscription = {
    accDetails: null,
    userFlag: null,
    roleNum: null,
  };
  accountDetail: any;

  totalLength: number = 0;
  pageSize: number = 5;
  pageIndex: number = 0;
  pageSizeOptions = [5, 10, 25, 100, 200];
  showPageSizeOptions: boolean = true;
  rowOffset: number = 0;
  ascStatus: string = "asc";
  colSort: string = "fo_num";

  complianceOrderData: ComplianceOrderData[];
  complianceOrderStatusDataDetails: any;

  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private subscription2 = {
    activeRoute: null
  };

  allAccountsFlag: boolean = false;
  roleNum: number;

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private appService: AppService,
    private orderStatusService: OrderStatusService,
    private activatedroute: ActivatedRoute,
    private userService: UserService,
    private paginationService: PaginationService,
  ) { }
  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit(): void {
    this.subscription.roleNum = this.userService.roleNum$.subscribe(
      roleNum => {
        this.roleNum = roleNum ? roleNum : null;
      }
    );

    this.subscription.accDetails = this.appService.accDetails$.subscribe(
      accDetail => {
        this.accountDetail = accDetail;
        this.disableSearchFlag = accDetail ? false : true;
      }
    );

    this.subscription.userFlag = this.userService.userFlag$.subscribe(
      userFlag => {
        this.allAccountsFlag = userFlag ? userFlag : false;
      }
    );

    this.subscription2.activeRoute = this.activatedroute.data.subscribe(data => {
      this.fPermissionNum = data.permissionNum;
      this.permissionsData = this.userService.getUserPermissions();
      this.permissionAssignment.cancelOrder = this.roleNum == 5 ? true : false;
    });
    this.foStatCdes = this.userService.getFoStatCdeData();
    this.getOrderStatusList();
    this.init();

    //Form Initialization
    this.searchForm = this.formBuilder.group({
      //'patientName': [null],
      'orderId': [null],
      'statusName': [null],
      'fromDate': [null],
      'toDate': [null]
    });

    this.searchForm.get("fromDate").valueChanges.subscribe(dateVal => {
      this.minDate = dateVal;
    });

    this.searchForm.get("statusName").valueChanges.subscribe(status => {
      this.selectedItems = status ? (status.length == 0 ? null : status) : null;
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.subscription.accDetails.unsubscribe();
    this.subscription.userFlag.unsubscribe();
  }
  /*********************Lifecyle Methods*********************/

  /*********************Component Methods*********************/
  handlePageEvent(e: PageEvent) {
    this.rowOffset = this.paginationService.handlePageEvent(e) ? this.paginationService.handlePageEvent(e) : this.rowOffset;
    this.pageSize = e.pageSize ? e.pageSize : this.pageSize;
    this.ascStatus = this.sort.direction != "" ? this.sort.direction : this.ascStatus;
    this.onSearchClick(false, "");
  }

  onCopyPaste(e: ClipboardEvent) {//ClipboardEvent to capture cut copy paste
    let val = {
      value: null
    }
    val.value = e.clipboardData.getData('text/plain');
    this.onSearchFilter(val);
  }

  getOrderStatusList() {
    Array.from(this.foStatCdes).forEach((number,index) => {
      if(this.foStatCdes[index].aplcHdrStat && !this.orderStatus.includes(this.foStatCdes[index].dispStat)) {
        this.orderStatus.push(this.foStatCdes[index].dispStat);
      }
    });
  }

  onSearchFilter(val) {
    if (val && val.value) {
      this.disableSearchFlag = false
    }
    else {
      this.disableSearchFlag = true;
    }
  }

  onStatSelection(selectedItems: any){
    this.convertOrderStat = [];
    if(selectedItems){
      Array.from(this.foStatCdes).forEach((number,index) => {
        Array.from(selectedItems).forEach((n,i) => {
          if(selectedItems[i].toUpperCase() == this.foStatCdes[index].dispStat.toUpperCase() && !this.convertOrderStat.includes(this.foStatCdes[index].stat)) {
            this.convertOrderStat.push(this.foStatCdes[index].stat.toLowerCase());
          }
        })
      });
    }
  }

  private onSortClick(col){
    this.dictionary.forEach((num, i) => {
      if(col==num.column){
        this.colSort = num.dbCol;
      }
    })  
    return this.colSort
  }

  onSearchClick(search: boolean, action: string) {
    this.paginator.pageIndex = search ? 0 : this.paginator.pageIndex;
    this.onStatSelection(this.selectedItems);
    this.searchObjFunction(search, action);
    this.orderStatusService.getOrderStatusData(this.searchObj).subscribe((response: any) => {
      if(action == "clear" || response.hasError){
        this.complianceOrderData = [];
        this.totalLength = 0;
      } else {
        this.complianceOrderData = response.data.complianceOrders;
        this.totalLength = response.data.numberOfRows;
      }
      this.complianceOrderData.forEach((number, index) => {
          if(this.complianceOrderData[index].recvDateTime){
            this.complianceOrderData[index]["recvDateTimeFormat"] = moment(CommonControls.convertGMTtoLocalTZ(this.complianceOrderData[index].recvDateTime)).format(DateFormat.MMDDYYYYHHmmA);
          }
        if (!this.complianceOrderData[index].status) {
          this.complianceOrderData[index].cancelFlag = false;
        }
        else if (this.cancellableStatus.includes(this.complianceOrderData[index].status.toUpperCase())) {
          this.complianceOrderData[index].cancelFlag = true;
        }
        else {
          this.complianceOrderData[index].cancelFlag = false;
        }
      });
      this.refreshGrid(this.complianceOrderData);
    });
  }

  searchObjFunction(search: boolean, action: string) {
    if(action == "clear") {
      this.searchObj.ascStatus = "asc";
      this.searchObj.selectedHeader = this.onSortClick("fo_num");
    } else {
      this.searchObj = {
        "fromDate": this.searchForm.value.fromDate ? moment(this.searchForm.value.fromDate).format(DateFormat.YYYYMMDD) : null,
        "toDate": this.searchForm.value.toDate ? moment(this.searchForm.value.toDate).format(DateFormat.YYYYMMDD) : null,
        "accountNo": this.accountDetail.accountNumber ? this.accountDetail.accountNumber.toString() : null,
        "orderId": this.searchForm.value.orderId ? this.searchForm.value.orderId : null,
        "groupId": this.searchForm.value.groupId ? this.searchForm.value.groupId.toString() : null,
        "orderStatus": this.selectedItems != null ? this.convertOrderStat : null,
        "assignedAccounts": this.accountDetail.assignedAccounts ? this.accountDetail.assignedAccounts : null,
        "selectedHeader": this.onSortClick(this.sort.active),
        "ascStatus": this.ascStatus,
        "itemsPerPage": this.pageSize,
        "rowOffset": search ? 0 : this.rowOffset
      };
    }
  }

  onClearSearchResults() {
    this.searchForm.reset();
    this.gridReset();
    this.paginator.pageIndex = 0;
    this.disableSearchFlag = !this.accountDetail;
    this.onSearchClick(false, "clear");
  }

  onCellClicked(orderStatusRow: any) {
    let orderNum = orderStatusRow ? orderStatusRow.orderNumber : null;
    this.complianceOrderStatusDataDetails = orderStatusRow;
    this.orderStatusService.getOrderStatusDetailData(orderNum).subscribe((response: any) => {
      this.complianceOrderStatusDataDetails.orderDetails = !response.hasError ? response.data.orderDetails : [];
      this.openDialog(this.complianceOrderStatusDataDetails, false);
    });
  }

  onCancelClicked(orderNum: any) {
    this.openDialog(orderNum, true);
  }
  /*********************Component Methods*********************/

  /*********************Dialog Methods*********************/
  public openDialog(ComplianceOrderStatusDataDetails: any, cancelOrder: boolean) {
    this.report = cancelOrder ? ModalDialogCancelOrderComponent : ModalDialogOrderStatusComponent;
    const dialogRef = this.dialog.open(this.report, {
      data: ComplianceOrderStatusDataDetails,
      width: cancelOrder ? '45%' : '100%',
      autoFocus: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result == "cancel") {
        this.onSearchClick(false,"");
      }
    });
  }
  /*********************Dialog Methods*********************/

  /*********************Init/Reset Methods*********************/
  private init() {
    this.noDataFlag = true;
    this.totalLength= 0;
    this.pageSize = 5;
    this.pageIndex = 0;
    this.pageSizeOptions = [5, 10, 25, 100, 200];
    this.showPageSizeOptions = true;
    this.rowOffset = 0;
    this.ascStatus = "asc";
    this.colSort = "fo_num";
  }

  private gridReset() {
    this.complianceOrderData = [];
    this.refreshGrid(this.complianceOrderData);
  }

  private refreshGrid(obj) {
    console.log(obj);
    this.dataSource = new MatTableDataSource(obj);
    this.dataSource.sort = this.sort;
    if (obj && obj.length > 0) {
      this.noDataFlag = false;
    }
    else {
      this.noDataFlag = true;
    }
  }

  /*********************Init/Reset Methods*********************/

}
