import { Component } from '@angular/core';

import { Subject } from 'rxjs';

import { ConfirmModal, ConfirmActions } from './confirm-dialog';
import { ModalReasons } from 'src/app/core/constants/app.constant';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'wbd-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  readonly actions = ConfirmActions;
  confirm: ConfirmModal;
  onClose: Subject<string> = new Subject<string>();
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {}
  /*********************Constructor*********************/

  /*********************Component Methods*********************/

  onCloseClick() {
    this.closeDialog(ModalReasons.No, false);
  }

  onYesClick() {
    this.closeDialog(ModalReasons.Yes, true);
  }

  onNoClick() {
    this.closeDialog(ModalReasons.No, false);
  }

  onCancelClick() {
    this.closeDialog(ModalReasons.Cancel, false);
  }

  /*********************Component Methods*********************/

  /*********************Private Methods*********************/

  private closeDialog(reason: ModalReasons, result: boolean) {
    this.onClose.next(reason);
    this.dialogRef.close(result);
  }

  /*********************Private Methods*********************/
}
