import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { ToastrModule } from 'ngx-toastr';

import { ToastService } from '../utils/toast.service';
import { RouteUtilService } from '../utils/route-util.service';
import { PipeModule } from './pipe.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    PipeModule   
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    // NgxUiLoaderModule,
    PipeModule
  ],
  providers: [
    ToastService,
    RouteUtilService,
  ],
})
export class SharedModule {}
