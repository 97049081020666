/* istanbul ignore file */

import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError, TimeoutError } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';
import { HttpError } from '../../shared/models/app';
import {
    AppMessages,
    ErrorNotificationType,
    HttpStatusCode,
    ErrorConfig,
    ErrorMessages
} from '../constants/app.constant';

import { UtilityService } from 'src/app/shared/utils/utility.service';
import { ToastService } from 'src/app/shared/utils/toast.service';
import { AuthService } from '../services/auth.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    /***************Properties***************/
    logoutUser: boolean;

    private handledSessionExpiration = false;
    /***************Properties***************/

    /***************Constructor***************/
    constructor(
        private utility: UtilityService,
        private authService: AuthService,
        private toastr: ToastService,
    ) { }
    /***************Constructor***************/
    /* istanbul ignore next */
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap((event: HttpEvent<any>) => { }),
            catchError(error => {
                if (this.handledSessionExpiration) {
                    const message = error.error.faultInfos[0] ? error.error.faultInfos[0].faultMessage : ErrorMessages.UI408;
                    this.toastr.error(message);
                    this.authService.signOut(true);
                    return of(error);
                }

                if (error instanceof TimeoutError) {
                    const message = this.getMessageDesc(ErrorConfig.TimeoutId);
                    this.toastr.error(message);
                }

                if (error instanceof HttpErrorResponse && error.error) {
                    let unauthRequest = false;

                    if (this.isSessionExpired(error.error)) {
                        this.toastr.warn(ErrorMessages.UI408);
                        this.authService.signOut(true);
                    } else {
                        switch (error.status) {
                            case 0:
                            case 401:
                                unauthRequest = true;
                                this.toastr.warn('Your session has timed out.');
                                this.authService.signOut(true);
                                break;
                            case 412:
                                error = this.resoslveServerError(error);
                                this.showErrorMessage(error);
                                break;
                            default:
                                if (this.handleServerException(error.error)) {
                                    error = this.resoslveServerError(error);
                                    this.showErrorMessage(error);
                                }
                                break;
                        }

                    }

                    if (unauthRequest) {
                        this.handledSessionExpiration = true;
                        // this.authService.signOut();
                        // this.modalService.dismissAll();
                    }
                }

                return throwError(error);
            })
        );
    }

    /******************Private Methods******************/
    /* istanbul ignore next */
    private isSessionExpired(error: HttpError): boolean {
        return error.logoutUser && !this.handledSessionExpiration;
    }
    /* istanbul ignore next */
    private handleServerException(error) {
        return (
            this.handleSystemException(error) || this.handleBusinessException(error)
        );
    }
    /* istanbul ignore next */
    private handleSystemException(error) {
        let handle = false;
        // const bypassServices = [];

        if (!error.businessFault && !error.logoutUser) {
            handle =
                !error.messageDisplay ||
                error.messageDisplay === ErrorNotificationType.Toast;
        }

        // if (
        //     error.url &&
        //     bypassServices.find(url => {
        //         return error.url.indexOf(url) > -1;
        //     })
        // ) {
        //     handle = false;
        // }

        return handle;
    }
    /* istanbul ignore next */
    private handleBusinessException(error) {
        return (
            error.businessFault &&
            error.messageDisplay === ErrorNotificationType.Toast
        );
    }
    /* istanbul ignore next */
    private resoslveServerError(error) {
        if (error.error && error.error.useServerMessage && error.error.faultInfos) {
            error.message = error.error.faultInfos[0].faultMessage;
        } else {
            const errorId = error.error ? error.error.spanId : null;
            const messageId =
                error.error && error.error.faultInfos
                    ? error.error.faultInfos[0].faultCode
                    : null;

            error.message =
                error.error && error.error.businessFault
                    ? this.getMessageDesc(messageId)
                    : this.getSystemExceptionMessage(messageId, error.status);

            error.message = this.setErrorId(error.message, errorId);
        }

        return error;
    }
    /* istanbul ignore next */
    private getSystemExceptionMessage(messageId: string, status: number) {
        if (!messageId) {
            switch (status) {
                case HttpStatusCode.BadRequest:
                    messageId = ErrorConfig.BadRequestId;
                    break;

                case HttpStatusCode.NotFound:
                    messageId = ErrorConfig.NotFoundId;
                    break;

                case HttpStatusCode.PreconditionRequired:
                    messageId = ErrorConfig.PreconditionRequiredId;
                    break;

                default:
                    messageId = ErrorConfig.SysErrId;
                    break;
            }
        }

        return this.getMessageDesc(messageId);
    }
    /* istanbul ignore next */
    private getMessageDesc(
        messageId: string,
        defaultMessage: string = AppMessages.GenericErrorMessage
    ): string {
        let message = null;

        const ref = ErrorMessages[messageId];
        message = ref || defaultMessage;

        return message;
    }
    /* istanbul ignore next */
    private setErrorId(message: string, errorId: any) {
        if (errorId) {
            return message + ' [Error Code: ' + errorId + ']';
        }

        return message;
    }
    /* istanbul ignore next */
    private showErrorMessage(error) {
        switch (error.error.messageDisplay) {
            case ErrorNotificationType.Modal:
                const err = error.error.faultInfos[0];
                this.utility.confirmOk('Error', err, true);
                break;

            case ErrorNotificationType.Toast:
                this.toastr.error(error.message);
                break;

            default:
                this.toastr.error(error.message);
                break;
        }
    }

    /*********************Private Methods*********************/
}

