import { Component, OnInit } from '@angular/core';

import { PrintService } from './print.service';

@Component({
  selector: 'app-print',
  templateUrl: './print.component.html',
  styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {
  /*********************Properties*********************/
  printData: any
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private printService: PrintService
  ) { }
  /*********************Constructor*********************/
  /*********************Lifecyle Methods*********************/
  ngOnInit(): void {
    this.printData = this.printService.printData;
    this.onPrintLoad(this.printData);
  }
  /*********************Lifecyle Methods*********************/
  /*********************Component Methods*********************/
onPrintLoad(data:any){
  Promise.all([data])
  .then(() => this.printService.onDataReady(data)).catch((err) =>{
    console.log(err)
  });
}

  /*********************Component Methods*********************/
}
