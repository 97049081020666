import { MatDialogConfig } from "@angular/material/dialog";

/***************Enum Constants***************/

export enum ConfirmActions {
  YesNo = 1,
  YesNoCancel = 2,
  Ok = 3,
  No = 4,
}

export enum ModalActions {
  Yes = 'Yes',
  No = 'No',
  Ok = 'Ok',
  Cancel = 'Cancel',
  ConfirmSubmit = 'Yes, Submit',
  ConfirmCancel = 'No, Stay',
}

export enum ButtonTheme {
  Primary = 'primary',
  Secondary = 'secondary',
  Default = 'secondary',
}

/***************Enum Constants***************/

export class ConfirmModal {
  title = 'Confirm';
  message: string;
  actions: ConfirmActions = ConfirmActions.YesNo;
  config?: MatDialogConfig;
  okFlag?: boolean;
  action = {
    yes: { text: ModalActions.Yes, theme: ButtonTheme.Primary },
    no: { text: ModalActions.No, theme: ButtonTheme.Secondary },
    ok: { text: ModalActions.Ok, theme: ButtonTheme.Secondary },
    cancel: { text: ModalActions.Cancel, theme: ButtonTheme.Default },
  };

  constructor() {}
}
