export enum RoutePrefix {
  Login = 'login',
  Dashboard = 'dashboard',
  HeldQueue = 'heldQueue',
  Inventory = 'inventory',
  Formulary = 'formulary',
  OrderStatus = 'orderStatus',
  Report = 'report',
  Pms = 'pms',
  Print = 'print',
  Logout = 'logout',
  UserManagement = 'userManagement',
  CustomerAccountManagement = 'customerAccountManagement',
}

const RouteNames = {
  Error: 'error',
  Callback: 'auth-code/callback',
  Logout: 'logout',
  SessionExpired: 'session-expired',
  SessionTimeout: 'session/time-out',
  UnAuthorized: 'access-denied',
  Dashboard: 'dashboard'
};

export enum MasterRoutes {
  Okta = '',
  Module = '',
  Dashboard = 'dashboard',
  Inventory = 'inventory',
  Formulary = 'formulary',
  Report = 'report',
  Pms = 'pms',
}

export enum PermissionsList {
  Dashboard = 0,
  ViewInventory = 1,
  UpdateInventory = 2,
  ViewFormulary = 3,
  UpdateFormulary = 4,
  OrderStatus = 6,
  Report = 5,
  Pms = 7,
  Logout = 8,
  UserManagement = 9,
  CustomerAccountManagement = 10,
};

export const DashboardRoutes = {
  Dashboard: {
    Name: RouteNames.Dashboard,
    Url: MasterRoutes.Module + '/' + RouteNames.Dashboard,
  },
};


export const ModuleRoutes = {
  Dashboard: {
    Name: RoutePrefix.Dashboard,
    Url: MasterRoutes.Module + '/' + RoutePrefix.Dashboard
  },
  HeldQueue: {
    Name: RoutePrefix.HeldQueue,
    Url: MasterRoutes.Module + '/' + RoutePrefix.HeldQueue
  },
  Inventory: {
    Name: RoutePrefix.Inventory,
    Url: MasterRoutes.Module + '/' + RoutePrefix.Inventory
  },
  Formulary: {
    Name: RoutePrefix.Formulary,
    Url: MasterRoutes.Module + '/' + RoutePrefix.Formulary
  },
  OrderStatus: {
    Name: RoutePrefix.OrderStatus,
    Url: MasterRoutes.Module + '/' + RoutePrefix.OrderStatus
  },
  Report: {
    Name: RoutePrefix.Report,
    Url: MasterRoutes.Module + '/' + RoutePrefix.Report
  },
  Pms: {
    Name: RoutePrefix.Pms,
    Url: MasterRoutes.Module + '/' + RoutePrefix.Pms
  },
  UserManagement: {
    Name: RoutePrefix.UserManagement,
    Url: MasterRoutes.Module + '/' + RoutePrefix.UserManagement
  },
  CustomerAccountManagement: {
    Name: RoutePrefix.CustomerAccountManagement,
    Url: MasterRoutes.Module + '/' + RoutePrefix.CustomerAccountManagement
  }
}

export const OktaRoutes = {
  Login: {
    Name: RoutePrefix.Login,
    Url: MasterRoutes.Okta + '/' + RoutePrefix.Login
  },
  Callback: {
    Name: RouteNames.Callback,
    Url: MasterRoutes.Okta + '/' + RouteNames.Callback,
  },
  Logout: {
    Name: RouteNames.Logout,
    Url: MasterRoutes.Okta + '/' + RouteNames.Logout,
  },
  SessionExpired: {
    Name: RouteNames.SessionExpired,
    Url: MasterRoutes.Okta + '/' + RouteNames.SessionExpired,
  },
  SessionTimeout: {
    Name: RouteNames.SessionTimeout,
    Url: MasterRoutes.Okta + '/' + RouteNames.SessionTimeout,
  },
  UnAuthorized: {
    Name: RouteNames.UnAuthorized,
    Url: MasterRoutes.Okta + '/' + RouteNames.UnAuthorized,
  },
};


export const ErrorRoutes = {
  Error: {
    Name: RouteNames.Error,
    Url: RouteNames.Error,
  },
};



