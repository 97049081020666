<h2 mat-dialog-title> {{dialogTitle}}
    <mat-icon class="dialog-icon" (click)="onCloseDialog()">close</mat-icon>
</h2>
<mat-dialog-content>
    <form name="saveParLevel" [formGroup]="saveParLevel" novalidate>
      <ul>
        <li>
          <strong>Inventory Account:</strong> {{invRowData.accNumAndName}}
        </li>
        <li>
          <strong>Account ID:</strong> {{invRowData.accountId}}
        </li>
        <li>
          <strong>Description:</strong> {{invRowData.description}}
        </li>
        <li>
          <strong>NDC:</strong> {{invRowData.ndc}}
        </li>
        <li>
          <strong>CIN:</strong> {{invRowData.cin}}
        </li>
        <li>
          <strong>Source Item:</strong> {{invRowData.srcItemNum}}
        </li>
        <li>
          <strong>Pack Size:</strong> {{invRowData.packSize}}
        </li>
        <li>
          <strong>Pack Qty:</strong> {{invRowData.packQuantity}}
        </li>
        <li class="parlevel">
          <strong>Custom Par Level Qty:</strong> 
          <mat-form-field class="parlevelText">
            <input matInput name="parLevel" max="9999999" formControlName="parLevel" (keyup)="parLevelValidation(saveParLevel.controls.parLevel.value)" required>
          </mat-form-field>
          <mat-error *ngIf="errorFlag" id="errors">
            Par Level Qty must be numeric, greater than or equal to 0 and less than or equal to 9999999
        </mat-error>
        </li>
      </ul>
      <mat-dialog-actions align="end">
        <button mat-button mat-stroked-button color="primary" (click)="onCloseDialog()">Cancel</button>
        <button mat-button cdkFocusInitial mat-flat-button color="primary"
          class="button primary" (click)="onConfirmChanges()" [disabled]="errorFlag">Confirm</button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>