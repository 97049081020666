<div class="wrapper">
<div class="container-fluid p-0">
    <div class="d-flex flex-row">
        <div class="d-flex flex-column nav-container">
            <app-navigation class="navigation shadow flex-shrink-0"></app-navigation>
        </div>
        <div class="d-flex flex-column main-body">
            <app-header></app-header>
            <div class="main-container">
                <div>
                    <router-outlet></router-outlet>

                </div>
            </div>
        </div>
    </div>
</div>

</div>
<!-- Print preview -->
<app-print *ngIf="printService.isPrinting" class="print-view"></app-print>
<!-- Print preview -->