<h2 mat-dialog-title>
    <div>{{ dialogTitle }}</div>
    <mat-icon class="dialog-icon" (click)="onCloseDialog()">close</mat-icon>
</h2>
<mat-dialog-content>
    <form name="addReplenOrderItemForm" [formGroup]="addReplenOrderItemForm" novalidate>
        <div class="row">
            <div class="col-12 block">
                <div class="wrapper">
                    <ul class="non-editable">
                        <li class="col1">
                            <div class="form-wrap">
                                <mat-form-field appearance="fill">
                                    <mat-label>Source Item Number</mat-label>
                                    <input matInput type="text" formControlName="sourceItemNumber" [(ngModel)]="sourceItemNumber" (keyup)="srcItemNumberValidation()">  
                                </mat-form-field>
                                <button mat-icon-button [disabled]="searchIconDisable" (click)="searchItem()" color="primary">
                                    <mat-icon class="grid-icon"><em class="fas fa-search"></em></mat-icon>
                                </button>
                                <mat-error *ngIf="srcItemNumErrorFlag">
                                   {{ srcItemNumErrorMssg }}
                                </mat-error>
                            </div>
                        </li>
                        <li class="col1">
                            <div class="form-wrap">
                                <mat-form-field appearance="fill">
                                    <mat-label>NDC</mat-label>
                                    <input matInput type="text" formControlName="ndcText" [(ngModel)]="ndcText">
                                </mat-form-field>
                            </div>
                        </li>

                        <li class="col1">
                            <div class="form-wrap">
                                <mat-form-field appearance="fill">
                                    <mat-label>Description</mat-label>
                                    <input matInput type="text" formControlName="description" [(ngModel)]="description">
                                </mat-form-field>
                            </div>
                        </li>
                        <li class="col1">
                            <div class="form-wrap">
                                <mat-form-field appearance="fill">
                                    <mat-label>Qty</mat-label>
                                    <input matInput type="text" formControlName="qty" [(ngModel)]="qty" (keyup)="qtyValidation()">
                                </mat-form-field>
                                <mat-error *ngIf="qtyErrorFlag">
                                    {{ qtyErrorMssg }}
                                 </mat-error>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </form>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button cdkFocusInitial mat-flat-button color="primary"class="button primary"  
            (click)="onSave()" [disabled]="saveButtonDisable">Save</button> 
    <button mat-button mat-stroked-button color="primary" (click)="onCloseDialog()">Close</button>
</mat-dialog-actions>