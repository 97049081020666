const CircularJSON = require('circular-json');

Object.clone = function(obj: any): any {
  return CircularJSON.parse(CircularJSON.stringify(obj));
};

Object.parse = function(obj): any {
  return CircularJSON.parse(obj);
};

Object.stringify = function(obj): any {
  return CircularJSON.stringify(obj);
};

Object.mapProperties = function(dest: any, src: any) {
  for (const property in src) {
    if (src.hasOwnProperty(property) && dest[property] !== undefined) {
      dest[property] = src[property];
    }
  }
};
