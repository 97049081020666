import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'src/app/shared/utils/toast.service';
import { ReleaseDeleteDetail } from '../held-queue';
import { HeldQueueService } from '../held-queue.service';

@Component({
  selector: 'app-mat-dialog-held-queue',
  templateUrl: './mat-dialog-held-queue.component.html',
  styleUrls: ['./mat-dialog-held-queue.component.scss']
})
export class MatDialogHeldQueueComponent implements OnInit {

  isDeleteDialog: boolean = false;
  releaseDeleteForm: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<MatDialogHeldQueueComponent>,
    private heldQueueService: HeldQueueService,
    private toastr: ToastService,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.isDeleteDialog = this.data.action === 'Delete' ? true : false;

    this.releaseDeleteForm = this.formBuilder.group({
      'reason': [null, [Validators.required]],
    });

  }

  releaseDeleteOrder(releaseDeleteOrder: ReleaseDeleteDetail) {
    this.heldQueueService.releaseDeleteOrder(releaseDeleteOrder).subscribe((response: any) => {
      if (this.isDeleteDialog) {
        this.deleteOrder(response);
      }
      else {
        this.releaseOrder(response);
      }
    });
  }

  onConfirmRelease() {
    this.releaseDeleteOrder(this.data.releaseDeleteOrder);
  }

  onConfirmDelete() {
    this.data.releaseDeleteOrder.reason = this.releaseDeleteForm.controls.reason ? this.releaseDeleteForm.controls.reason.value : '';
    this.releaseDeleteOrder(this.data.releaseDeleteOrder);
  }

  onCloseDialog() {
    this.dialogRef.close('cancel');
  }

  onCloseDialogDelRel() {
    this.dialogRef.close('deleterelease');
  }

  private releaseOrder(response: any) {
    if (!response.hasError) {
      console.log(response);
      this.toastr.success('Success Order Group ID: ' + this.data.releaseOrderDetails.groupID + ' was released.');
      this.onCloseDialogDelRel();
    }
    else {
      this.toastr.error('Error Order Group ID: ' + this.data.releaseOrderDetails.groupID + ' was denied by receiver. Please contact support.');

      this.toastr.warn('Order Group ID: ' + this.data.releaseOrderDetails.groupID + ' encountered a problem submitting. System will automatically retry.  Please wait 15 mins and check status.');
    }
  }

  private deleteOrder(response: any) {
    if (!response.hasError) {
      console.log(response);
      this.toastr.success('Successfully deleted order line.');
      this.onCloseDialogDelRel();
    }
    else {
      this.toastr.error('Error deleting order line.');
    }
  }

}
