import { Injectable } from '@angular/core';
import { CacheService } from '@ngx-cache/core';

import { CacheDuration } from '../constants/app.constant';
import { EncDecService } from 'src/app/core/utils/enc-dec.service';

@Injectable({
  providedIn: 'root'
})
export class CacheResolverService extends EncDecService {
  /*********************Properties*********************/
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(private readonly cache: CacheService) {
    super();
  }
  /*********************Constructor*********************/

  /*********************Service Methods*********************/
  /**
   * Gets the value from cache if the key is provided.
   */
  get(key: string): any {
    let value;

    try {
      value = this.cache.get(key);
      value = JSON.parse(this.decrypt(value));
    } catch (error) {}

    return value || undefined;
  }

  /**
   * Sets the value with key in the cache.
   */
  set(key: string, value: any, expiry?: number): boolean {
    expiry = expiry || CacheDuration.Default;
    value = this.encrypt(JSON.stringify(value));
    return this.cache.set(key, value, 0, {
      expiry:
        expiry >= Number.MAX_VALUE
          ? Number.MAX_VALUE
          : Date.now() + expiry * 1000,
      TTL: expiry
    });
  }

  /**
   * Checks if a key exists in cache
   */
  has(key: string): boolean {
    // Cache service 'has' operation doesn't check cache expiration time.
    // So to avoid issue in future, check actual value by reading from cache and
    // if value exist then return true otherwise false.
    const value = this.get(key);
    return value ? true : false;
  }

  /**
   * Deletes the value from cache if the key is provided.
   */
  remove(key: string) {
    this.cache.remove(key);
  }

  /**
   * Clears all the key/value from cache.
   */
  clear(): void {
    this.cache.clear();
  }

  /*********************Service Methods*********************/
}
