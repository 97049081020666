import { Injectable } from '@angular/core';
import { IPrintData } from './../../models/app';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
 /*********************Properties*********************/
 isPrinting = false;
 accountNum: string;
 printData:IPrintData = {title:'', data:''};
/*********************Properties*********************/

/*********************Constructor*********************/
constructor() {
  
}
/*********************Constructor*********************/

/*********************Service Methods*********************/
printDocument(documentName: string, data:any, accNum) {
  this.isPrinting = true;
  this.accountNum = accNum;
  this.printData.title = documentName;
  this.printData.data = data;
  // this.router.navigate(['print'], {relativeTo: this.activatedRoute});
}

onDataReady(data:any) {
  // setTimeout(() => {
    this.accountNum = this.printData.title == 'Formulary by Customer'  ? '_' + this.accountNum : ''
    window.document.title = data.title+' Report' + this.accountNum;
    //window.document.title = data.title+' Report';
    window.print();
    this.isPrinting = false;
    // this.router.navigate(['report'], {relativeTo: this.activatedRoute});
  // });
}

/*********************Service Methods*********************/
}
