import { Directive } from '@angular/core';
import { Validators, FormGroup } from '@angular/forms';
import * as moment from 'moment';
// import moment from 'moment';
import { DateFormat } from 'src/app/core/constants/app.constant';
import { BillingExceptions } from 'src/app/modules/customer-account-management/customer-account-management';
import { CommonControls } from '../common-control/common-controls';

@Directive({
  selector: '[appValidation]'
})
export class ValidationDirective extends Validators {
  /*********************Properties*********************/
  
  /*********************Properties*********************/

  /*********************Component Methods*********************/
  static pastDateValidator(formGroupValues: FormGroup) {
    const startDate = formGroupValues.get('EffectiveStartDate').value;
    const endDate = formGroupValues.get('EffectiveEndDate').value;
    if (new Date(startDate) > new Date(endDate)) {
      formGroupValues.controls.EffectiveEndDate.setErrors({ PastDateErr: true });
    }
    else {
      formGroupValues.controls.EffectiveEndDate.setErrors(null);
    }
  }

  static billingDateValidator(formGroupValues: FormGroup) {
    const startDate = formGroupValues.get('eff_start_date').value;
    const endDate = formGroupValues.get('eff_end_date').value;
    const extCost = formGroupValues.get("ext_cust_cost").value;
    const billingData: BillingExceptions[] = formGroupValues.get("billing_exceptions").value;
    const exceptionNum = formGroupValues.get("exception_num").value;
    const previousCost = formGroupValues.get("previous_cost").value;
    const cinNum = formGroupValues.get("cin_number").value;
    
    formGroupValues.controls.eff_start_date.setErrors(null);
    formGroupValues.controls.eff_end_date.setErrors(null);
    formGroupValues.controls.ext_cust_cost.setErrors(null);

    // If Start and End Date aren't empty
    if (startDate != null && endDate != null) {
      // Validate that Start Date is before End Date
      if (new Date(startDate) > new Date(endDate)) {
        formGroupValues.controls.eff_start_date.setErrors({PastDateErr: true});
        formGroupValues.controls.eff_end_date.setErrors({PastDateErr: true});
      }
      // If both exist and SD is after ED, Validate Start and End date combination is unique
      else if (startDate && endDate && billingData) {
        let enteredStart = new Date(moment(startDate).format(DateFormat.YYYYMMDD));
        let enteredEnd = new Date(moment(endDate).format(DateFormat.YYYYMMDD));
        // Check billing exceptions
        for (let i = 0; i < billingData.length; i++) {
          let billingMin = new Date(billingData[i].startDate);
          let billingMax = new Date(billingData[i].endDate);
          let billingCin = billingData[i].cin;

          // If the start or end date exist within the period of time an existing exception set error  
          if(billingData[i].exceptionNumber != exceptionNum && checkOverlap(enteredStart, enteredEnd, billingMin, billingMax) && cinNum == billingCin) {
            formGroupValues.controls.eff_start_date.setErrors({NotUnique: true});
            formGroupValues.controls.eff_end_date.setErrors({NotUnique: true});
            break;
          }
        }
      }
    } else {
      if (startDate == null) {
        formGroupValues.controls.eff_start_date.setErrors({RequiredErr: true});
      }
      if (endDate == null) {
        formGroupValues.controls.eff_end_date.setErrors({RequiredErr: true});
      }
    }

    if (extCost && extCost != ".") {
      let regex = new RegExp(/^[0-9]*(\.[0-9]{0,2})?$/);
      // Validate that the input passes the regex
      if(!regex.test(extCost)) {
        formGroupValues.controls.ext_cust_cost.setValue(previousCost);
      } else if (+extCost <= 0) {
        // If the cost is less than or 0 throw error
        formGroupValues.controls.ext_cust_cost.setErrors({EmptyErr: true});
      } else if (extCost != previousCost) {
        // If it passes set the previous cost to current value
        formGroupValues.controls.previous_cost.setValue(extCost);
      }
    } else {
      formGroupValues.controls.ext_cust_cost.setErrors({EmptyErr: true});
    }
  }

  static customerSearchValidation(formGroupValues: FormGroup) {
    const accVal = formGroupValues.get('accountSearchText').value;
    // const DCVal = formGroupValues.get('DCSearchText').value;
   
    if(accVal=='' || CommonControls.numericOnly(accVal)){
      formGroupValues.controls.accountSearchText.setErrors(null);
    }
    else{
      formGroupValues.controls.accountSearchText.setErrors({ InvalidSearch: true });
    }

    // if(DCVal=='' || CommonControls.numericOnly(DCVal)){
    //   formGroupValues.controls.DCSearchText.setErrors(null);
    // }
    // else{
    //   formGroupValues.controls.DCSearchText.setErrors({ InvalidSearch: true });
    // }
  }

  /*********************Component Methods*********************/
}

function checkOverlap(enterStart: Date, enterEnd: Date, billingMin: Date, billingMax: Date): boolean {
  if (enterStart <= billingMin && billingMin <= enterEnd) return true;
  if (enterStart <= billingMax && billingMax <= enterEnd) return true;
  if (billingMin <= enterStart && enterEnd <= billingMax) return true;
  return false;
}

