import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    CanActivateChild,
} from '@angular/router';
import { Observable } from 'rxjs';
import { RouteUtilService } from 'src/app/shared/utils/route-util.service';
import { OktaRoutes } from '../constants/routes.constant';

@Injectable({
    providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate, CanActivateChild {
    /*********************Constructor*********************/
    constructor(
        private routeUtils: RouteUtilService
    ) { }
    /*********************Constructor*********************/

    /*********************Implementation Methods*********************/

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        // if (this.appService.isUnderMaintenance()) {
        //     this.routeUtils.navigate(ModuleRoutes.Maintenance.Url);
        //     return false;
        // }
        return this.isAuthorized();
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | boolean
        | UrlTree
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree> {
        return this.isAuthorized();
    }

    /*********************Implementation Methods*********************/

    /*********************Private Methods*********************/

    private isAuthorized() {
        // const permission = this.userService.getUserPermissions();
        const permission = ["Authorize"];
        const isValid = permission && permission.length > 0;

        if (!isValid) {
            this.routeUtils.navigate(OktaRoutes.UnAuthorized.Url);
        }

        return isValid;
    }
}
