import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/modules/shared.module';
import { DateTimeAdapter, OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { TokenInterceptor } from './core/interceptors/token.interceptor';

import { AppComponent } from './app.component';
import { NavigationComponent } from './common/navigation/navigation.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';

import { AppIdleTimeoutDirective } from './shared/directives/app-idle-timeout.directive';
import { ValidationDirective } from './shared/directives/validations.directive';
import { InventoryComponent } from './modules/inventory/inventory.component';
import { ReportsComponent } from './modules/reports/reports.component';
import { MainComponent } from './common/main/main.component';
import { PrintComponent } from './shared/utils/print/print.component';
import { OrderStatusComponent } from './modules/order-status/order-status.component';
import { PmsComponent } from './modules/pms/pms.component';
import { UserManagementComponent } from './modules/user-management/user-management.component';
import { CustomerAccountManagementComponent } from './modules/customer-account-management/customer-account-management.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { AccountSelectComponent } from './shared/utils/account-select/account-select.component';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './common/header/header.component';
import { FormularyComponent } from './modules/formulary/formulary.component';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MaterialModule } from './shared/modules/material.module';
import { MomentDateTimeAdapter } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { NgxUiLoaderConfig, NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { RbcComponent } from './modules/rbc/rbc.component';
import { HeldQueueComponent } from './modules/held-queue/held-queue.component';
// import { NgxMatMomentModule, NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';

const MY_MOMENT_FORMATS = {
  fullPickerInput: 'MM/DD/YYYY HH:mm A', /* <---- Here i've rewrited the format */
  datePickerInput: 'MM/DD/YYYY',
  timePickerInput: 'HH:mm',
  monthYearLabel: 'MM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MM YYYY',
};

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  "bgsColor": "red",
  "bgsOpacity": 0.5,
  "bgsPosition": "center-center",
  "bgsSize": 60,
  "bgsType": "ball-spin-clockwise",
  "blur": 5,
  "delay": 0,
  "fastFadeOut": true,
  "fgsColor": "red",
  "fgsPosition": "center-center",
  "fgsSize": 60,
  "fgsType": "ball-spin-clockwise",
  "gap": 24,
  "logoPosition": "center-center",
  "logoSize": 120,
  "logoUrl": "",
  "masterLoaderId": "master",
  "overlayBorderRadius": "0",
  "overlayColor": "rgba(40, 40, 40, 0.8)",
  "pbColor": "red",
  "pbDirection": "ltr",
  "pbThickness": 3,
  "hasProgressBar": true,
  "text": "",
  "textColor": "#FFFFFF",
  "textPosition": "center-center",
  "maxTime": -1,
  "minTime": 300
};

@NgModule({
  declarations: [
    AppIdleTimeoutDirective,
    AppComponent,
    HeaderComponent,
    NavigationComponent,
    FormularyComponent,
    DashboardComponent,
    ValidationDirective,
    InventoryComponent,
    ReportsComponent,
    MainComponent,
    PrintComponent,
    OrderStatusComponent,
    PmsComponent,
    UserManagementComponent,
    CustomerAccountManagementComponent,
    AccountSelectComponent,
    RbcComponent,
    HeldQueueComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    ToastrModule.forRoot(),
    RouterModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxMatSelectSearchModule,
    MaterialModule,
    MatCardModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    MatSelectModule,
    MatTabsModule,
    MatIconModule,
    NgxMatSelectSearchModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig), 
    NgxUiLoaderHttpModule.forRoot({ showForeground: false, delay: 1000, maxTime: 10000})
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS },
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA // Tells Angular we will have custom tags in our templates
  ]
})
export class AppModule { }
