import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Subject, BehaviorSubject } from 'rxjs';

import { BaseService } from './base.service';
import { CacheResolverService } from '../cache/cache-resolver.service';
import { CacheConstant } from '../cache/cache.constant';
import { StaticDataListDetails, UserRolePermissionDetails, UserDetail } from 'src/app/shared/models/user';



@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  /*********************Properties*********************/
  userProfileLoaded$: Subject<boolean> = new Subject<boolean>();

  private userDetails = new BehaviorSubject<any>(null);
  userDetails$ = this.userDetails.asObservable();

  private userFlag = new BehaviorSubject<any>(null);
  userFlag$ = this.userFlag.asObservable();

  public roleNum = new BehaviorSubject<any>(null);
  roleNum$ = this.roleNum.asObservable();

  private userPermissions = new BehaviorSubject<any>(null);
  userPermissions$ = this.userPermissions.asObservable();

  private userEmail = new BehaviorSubject<any>(null);
  userEmail$ = this.userEmail.asObservable();

  private medTypeData = new BehaviorSubject<any>(null);
  medTypeData$ = this.medTypeData.asObservable();

  private foStatCdes = new BehaviorSubject<any>(null);
  foStatCdes$ = this.medTypeData.asObservable();

  staticDataListDetails: StaticDataListDetails[] = [];
  versionList: any;
  interfaceVersion: any;

  userRolePermissionsDetails: UserRolePermissionDetails = {
    userRolePermission: null,
    userInfo: null,
    rolePermissionsListObj: null
  };

  userDetail: any;
  rolePermissionsList: any;
  defaultPermisions:any = [{
    activeFlag: true,
    appTypeCode: -1,
    oktaRoleName: "Inventory Coordinator",
    permission: [{ permissionNum: 1, permissionName: "View Store Inventory" }],
    roleDesc: "Inventory Coordinator",
    roleName: "Inventory Coordinator",
    roleNum: 1
  }];

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    protected override http: HttpClient,
    private readonly cacheService: CacheResolverService
  ) {
    super(http);
  }
  /*********************Constructor*********************/

  /*********************Utility Methods*********************/
  setUserDetails(userDetails: UserDetail) {
    this.userDetails.next(userDetails);
  }

  setUserPermissions(userPermissions: any) {
    this.userPermissions.next(userPermissions);
  }

  setUserFlag(userData: boolean) {
    this.userFlag.next(userData);
  }

  setUserRole(userData: number) {
    this.roleNum.next(userData);
  }

  onProfileLoaded(userDetails) {
    this.cacheService.set(CacheConstant.UserAuth, userDetails);
    this.userDetails.next(userDetails);
  }

  /*********************Utility Methods*********************/

  /*********************Service Methods*********************/

  getDetails(): any {
    let user = this.cacheService.get(CacheConstant.UserAuth);
    this.userDetail = user;
    return this.userDetail;
  }

  getRolePermissionsDetails(): any {
    let rolePermissions = this.cacheService.get(CacheConstant.StaticData.Name);
    this.rolePermissionsList = rolePermissions.rolePermissionList;
    return this.rolePermissionsList;
  }

  /* istanbul ignore next */
  getUserPermissions(): any {
    let index = this.rolePermissionsList.findIndex(i => i.roleNum === this.roleNum.value);
    this.userPermissions =  this.rolePermissionsList[index].permission;
    return this.userPermissions;
  }

  getUserEmail(): any {
    let userEmail = this.userRolePermissionsDetails.userInfo.email;
    return userEmail;
  }

  setRolePermissionDetails(): any {
    this.getDetails();
    this.getRolePermissionsDetails();
      const userData = this.userDetail.userinfo ? JSON.parse(this.userDetail.userinfo) : null;
      const userInfo = userData;
      const claims = this.userDetail.user_claims ? JSON.parse(this.userDetail.user_claims) : null;
      const userRolePermissions = claims;
      const rolePermissionsList = this.rolePermissionsList?this.rolePermissionsList:this.defaultPermisions;

      this.userRolePermissionsDetails = {
        userRolePermission: userRolePermissions,
        userInfo: userInfo,
        rolePermissionsListObj: rolePermissionsList

      }

      let loggedinUserDetails:any = {
        userDetails: this.userRolePermissionsDetails.userInfo,
        rolePermissions:[]
      };
      if(this.userRolePermissionsDetails.userRolePermission.roleNameSet.length > 0){
        loggedinUserDetails.rolePermissions = this.userRolePermissionsDetails.rolePermissionsListObj.filter((data: { roleName: string; }) => {
          return data.roleName.toLowerCase() == this.userRolePermissionsDetails.userRolePermission.roleNameSet[0].toLowerCase();
        });
      }
      else {
        loggedinUserDetails = {
          userDetails: 'other',
          rolePermissions:[{
            permission:[],
            roleName: "other"
          }]
        }
      }
      
      return loggedinUserDetails;
    // return this.userRolePermissionsDetails;
  }

  getMedTypeDetails(): any {
    let medTypes = this.cacheService.get(CacheConstant.StaticData.Name);
    this.medTypeData = medTypes.medTypeList;
    return this.medTypeData;
  }

  getFoStatCdeData(): any {
    let foStatCdeData = this.cacheService.get(CacheConstant.StaticData.Name);
    this.foStatCdes = foStatCdeData.foStatCdeList;
    return this.foStatCdes;
  }

  getStaticDataDetails(): any {
    let staticData = this.cacheService.get(CacheConstant.StaticData.Name);
    this.staticDataListDetails = staticData.staticDataList;
    return this.staticDataListDetails;
  }
  
  /*********************Service Methods*********************/

  /*********************Private Methods*********************/

  /*********************Private Methods*********************/
}
