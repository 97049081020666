import { Component, OnInit } from '@angular/core';
import {OktaAuth} from '@okta/okta-auth-js';

import { RouteUtilService } from 'src/app/shared/utils/route-util.service';

import { menuOption } from 'src/app/core/constants/nav-constant';
import { CommonControls } from 'src/app/shared/common-control/common-controls';

import { AuthService } from 'src/app/core/services/auth.service';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  /*********************Properties*********************/
  menuOption = menuOption;
  menuOptionDisplay: any;
  topMenuOption: any;
  bottomMenuOption: any;
  onAppSelected = false;
  rolePermissionsDetails: any;
  loggedinUserDetails: any;
  roleAssignFlag: boolean;
  Url: any;

  private oktaAuth: OktaAuth = null;
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    // private router: Router,
    private routeUtils: RouteUtilService,
    // private oktaPkceAuth: OktaPkceAuthService,
    private authService: AuthService,
    private userService: UserService
    // private appService: AppService,
  ) {
  }
  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit() {
    this.menuOptionDisplay = this.menuOption;
    this.init();
    setTimeout(() => {
      this.loggedinUserDetails = this.userService.setRolePermissionDetails();
      if (this.loggedinUserDetails.rolePermissions[0].permission.length > 0) {
        this.menuOptionDisplay = this.setMenuPermissions(this.loggedinUserDetails, this.menuOption);
      }
      else {
        //When no roles are assigned
        // this.menuOptionDisplay = [];
        // this.menuOptionDisplay.push(this.menuOption[this.menuOption.length-1]);
        this.menuOptionDisplay = this.menuOption;
      }
      this.init();
    }, 100);
  }

  /*********************Lifecyle Methods*********************/

  /*********************Component Methods*********************/
  onMainMenuClicked(currentIndex) {
    this.Url = this.menuOptionDisplay[currentIndex].url;
    if (this.Url) {
      this.Url === '/logout' ? this.signOut('Logout') : this.routeUtils.navigate(this.Url);
      this.menuOptionDisplay = CommonControls.highlightMenu(this.menuOptionDisplay, currentIndex);
    }
    else {
      // CommonControls.highlightMenu(this.menuOptionDisplay, currentIndex);
    }
  }

  setMenuPermissions(loggedinUserDetailsObj, menuOptionsObj) {
    let menusList = [];
    menuOptionsObj.forEach(data => {
      loggedinUserDetailsObj.rolePermissions[0].userDetails = loggedinUserDetailsObj.userDetails;
      data.config.permissionsList.push(loggedinUserDetailsObj.rolePermissions[0]);
      loggedinUserDetailsObj.rolePermissions[0].permission.forEach(element => {
        if (data.config.permissionNum.includes(element.permissionNum) || data.config.permissionNum.length == 0) {
          menusList.push(data);
        }
      });
    });
    menusList.sort((x, y) => (x.menuIndex > y.menuIndex ? 1 : -1));
    return [...new Set(menusList)];
  }

  /*********************Component Methods*********************/
  /*********************Private/Init Methods*********************/
  private init() {
    this.topMenuOption = this.menuOptionDisplay.filter(
      option => option.config.displayBottom === false);
    this.bottomMenuOption = this.menuOptionDisplay.filter(
      option => option.config.displayBottom === true);
    this.menuOptionDisplay = CommonControls.highlightMenu(this.menuOptionDisplay, 0);
  }

  private signOut(error: string) {
    this.authService.signOut();
  }
  /*********************Private/Init Methods*********************/


}


