<h2 mat-dialog-title> {{dialogTitle}}
  <mat-icon class="dialog-icon" (click)="onCloseDialog()">close</mat-icon>
</h2>
<mat-dialog-content class="orderStatus-dialog inventory-dialog">
  <ul>
    <li>
      <strong>NavixRx Order No:</strong>{{orderStatusRowData.orderNumber}}
    </li>
    <!-- <li>
      <strong>Type:</strong>{{orderStatusRowData.foType}}
    </li> -->
    <li>
      <strong>Status:</strong>
      <!-- RBC changes -->
      <span *ngIf="orderStatusRowData.status !== 'rbc-accepted'">
        {{orderStatusRowData.displayStatus}}
    </span>
    <span *ngIf="orderStatusRowData.status === 'rbc-accepted'">
        accepted
    </span>    
    </li>
    <li>
      <strong>Shipment No:</strong>{{orderStatusRowData.shipPackNum}}
    </li>
    <li>
      <strong># Boxes:</strong>{{orderStatusRowData.cfxTotalBox}}
    </li>
    <li>
      <strong>Carrier:</strong>{{orderStatusRowData.shipCarrier}}
    </li>
    <li>
      <strong>Status Update Date &amp; Time:</strong>{{orderStatusRowData.statusUpdateDateTime | date:'MM-dd-yyyy HH:mm a'}}
    </li>
    <li>
      <strong>Tracking No:</strong><a href="https://www.fedex.com/fedextrack/?trknbr={{orderStatusRowData.shipTrkNum}}" target="_blank">{{orderStatusRowData.shipTrkNum}}</a>
    </li>
  </ul>


  <!-- Order Status Data -->
  <div class="mat-elevation-z8 dialog-table">
    <table mat-table [dataSource]="dataSource" aria-describedby="Order Status data view" matSort>

      <ng-container matColumnDef="rxNum">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Rx Number</th>
        <td mat-cell *matCellDef="let row">
          {{row.rxNum}} </td>
      </ng-container>

      <ng-container matColumnDef="foType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Type</th>
        <td mat-cell *matCellDef="let row">
          {{row.foType}} </td>
      </ng-container>

      <ng-container matColumnDef="origNdc">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">NDC</th>
        <td mat-cell *matCellDef="let row">
          {{row.origNdc}} </td>
      </ng-container>

      <ng-container matColumnDef="tradeName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Trade Name</th>
        <td mat-cell *matCellDef="let row">
          {{row.tradeName}} </td>
      </ng-container>

      <ng-container matColumnDef="strength">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Strength</th>
        <td mat-cell *matCellDef="let row">
          {{row.strength}} </td>
      </ng-container>

      <ng-container matColumnDef="phaQuantityDispensed">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Dispense Qty</th>
        <td mat-cell *matCellDef="let row">
          {{row.phaQuantityDispensed}} </td>
      </ng-container>

      <ng-container matColumnDef="phaDaysSupply">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Days Supply</th>
        <td mat-cell *matCellDef="let row">
          {{row.phaDaysSupply}} </td>
      </ng-container>

      <ng-container matColumnDef="dspnsSrtDte">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Dispense Start Date</th>
        <td mat-cell *matCellDef="let row">
            {{row.dspnsSrtDte | date:'MM-dd-yyyy'}} </td>
    </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Status</th>
        <td mat-cell *matCellDef="let row">
          {{row.displayStatus}} </td>
      </ng-container>

      <ng-container matColumnDef="errorMsg">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Error Msg</th>
        <td mat-cell *matCellDef="let row">
          {{row.displayReason}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <!-- Row shown when there is no matching data. -->
    <div *ngIf="noDataFlag" class="no-records">
      No records found
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 200]"></mat-paginator>
  </div>
  <!-- Order Status Data -->
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-stroked-button color="primary" (click)="onCloseDialog()">Close</button>
</mat-dialog-actions>