import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ClientApi } from 'src/app/core/constants/api.constant';
import { BaseService } from 'src/app/core/services/base.service';
import { ReleaseDeleteDetail } from './held-queue';

@Injectable({
  providedIn: 'root'
})
export class HeldQueueService extends BaseService {
  /*********************Properties*********************/

  /*********************Properties*********************/

 /*********************Constructor*********************/
  constructor(protected http: HttpClient) {
    super(http);
    // this.apiConfig.order = 'http://localhost:7285/api/v1/';
   }
/*********************Constructor*********************/

/*********************Service Methods*********************/
//Get Held Queue header details service
getHeldQueueHeaderData(searchCriteria: any) {
  const url = this.serviceUrl(this.apiConfig.order, ClientApi.getHeldQueueHeaderData);
  
  return super.post(url,searchCriteria).pipe(
    map((response: any) => {
      // if (!response.hasError) {
      return response;
      // }
    })
  )
}

//Get Held Queue item details service
getHeldQueueGroupDetails(groupId: string) {
  const url = this.serviceUrl(this.apiConfig.order, ClientApi.getHeldQueueGroupDetails);
  
  return super.fetch(url+"?groupId="+groupId).pipe(
    map((response: any) => {
      // if (!response.hasError) {
      return response;
      // }
    })
  )
}

//Delete or release Held Queue item details service
releaseDeleteOrder(releaseDeleteOrder:ReleaseDeleteDetail) {
  const url = this.serviceUrl(this.apiConfig.order, ClientApi.releaseDeleteOrder);
  
  return super.post(url,releaseDeleteOrder).pipe(
    map((response: any) => {
      // if (!response.hasError) {
      return response;
      // }
    })
  )
}

/*********************Service Methods*********************/
  }