<mat-card>
    <mat-card-header>
        <mat-card-title>Order Import</mat-card-title>
        <div class="refresh" *ngIf="!isAccountSelected">
            <div class="timestamp">
                Data as of {{current_timestamp}}</div>
            <button class="refresh-button" (click)="refreshData()">
                <span class="material-icons">
                    refresh
                </span>
                Refresh
            </button>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="top-cont">
            <app-account-select></app-account-select>
            <ng-container hidden="permissionAssignment.importControl" *ngIf="!isAccountSelected">
                <div class="upload-CSV">
                    <input type="file" #fileImportInput name="csvFileUpload" id="csvFileUpload"
                        (change)="fileChangeListener($event)" accept=".csv" />
                    <i class="fa fa-info-circle info-icon" aria-hidden="true"
                        matTooltip="Only CSV file is allowed to upload" matTooltipClass="tool-tip-class"
                        aria-label="Button that shows a tooltip"></i>
                    <button mat-flat-button color="primary" class="button primary" [disabled]="disableSearchFlag"
                        (click)="onImportClick()">Import</button>
                </div>
                <div class="show-status alert" [ngClass]="'alert-danger'"
                    *ngIf="showStatusFlag && (uploadStatus.status === 'rejected' || uploadStatus.status === 'Unable To Parse')">
                    {{uploadStatus.message}}<strong>{{uploadStatus.fileName}}</strong>
                </div>
                <div class="show-status alert" [ngClass]="'alert-success'"
                    *ngIf="showStatusFlag && uploadStatus.status !== 'rejected' && uploadStatus.status !== 'Unable To Parse'">
                    File <strong>{{uploadStatus.fileName}}</strong> {{uploadStatus.message}}
                </div>
            </ng-container>
        </div>
        <!-- PMS Data -->
        <div class="mat-elevation-z8 pms-table">
            <table mat-table [dataSource]="dataSource" aria-describedby="PMS View" multiTemplateDataRows matSort>
                <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef scope="col"> </th>
                    <td mat-cell *matCellDef="let row">
                        <div style="display:flex" *ngIf="row.showToggleIcon">
                            <mat-icon class="material-icons-outlined" *ngIf="!row.toggleItem" title="expand"
                                (click)="toggleRow(row)">add_circle_outline</mat-icon>
                            <mat-icon class="material-icons-outlined" *ngIf="row.toggleItem" title="collapse"
                                (click)="toggleRow(row)">remove_circle_outline</mat-icon>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="sapAccountNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Account Number</th>
                    <td mat-cell *matCellDef="let pmsDataRow">
                        {{sapAccountNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="pharmacyName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Pharmacy Name</th>
                    <td mat-cell *matCellDef="let pmsDataRow">
                        {{pharmacyName}} </td>
                </ng-container> 
                
                <ng-container matColumnDef="fileName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">File Name</th>
                    <td mat-cell *matCellDef="let pmsDataRow">
                        {{pmsDataRow.fileName}} </td>
                </ng-container>

                <ng-container matColumnDef="dateImported">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Date Imported</th>
                    <td mat-cell *matCellDef="let pmsDataRow">
                        {{pmsDataRow.dateImported | date:'MM/dd/yyyy HH:mm a'}} </td>
                </ng-container>

                <ng-container matColumnDef="importedMethod">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Import Method</th>
                    <td mat-cell *matCellDef="let pmsDataRow">
                        {{pmsDataRow.importedMethod}} </td>
                </ng-container>

                <ng-container matColumnDef="importedBy">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Imported By User</th>
                    <td mat-cell *matCellDef="let pmsDataRow">
                        {{pmsDataRow.importedBy}} </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">File Import Status</th>
                    <td mat-cell *matCellDef="let pmsDataRow" matTooltip="Please check file format"
                        [matTooltipDisabled]="pmsDataRow.status!='Error Processing'" matTooltipClass="table-tooltip"
                        class="text-capitalize">
                        {{pmsDataRow.status}} </td>
                </ng-container>

                <!-- expanded cols -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                        <div class="example-element-detail" *ngIf="element"
                            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="inner-table mat-elevation-z8" *ngIf="expandedElement">
                                <table #innerTables mat-table #innerSort="matSort" aria-describedby="PMS Inner Grid"
                                    [dataSource]="element.orderDetails" matSort>
                                    <ng-container matColumnDef="foNumber">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Order No
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.foNumber}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="patientName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Patient Name
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.patientName}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="submitDate">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Date
                                            Submitted </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.submitDate | date:'MM/dd/yyyy HH:mm a'}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="orderStatus">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Patient Order
                                            Status </th>
                                        <td mat-cell *matCellDef="let element"
                                            matTooltip="Please include Mandatory Rx data"
                                            [matTooltipDisabled]="element.orderStatus!='reject'"
                                            matTooltipClass="table-tooltip">
                                            <span
                                                [ngClass]="{'text-warning': element.orderStatus == 'approve & submit', 'text-danger' : element.orderStatus == 'missing fields'}"
                                                class="text-capitalize" *ngIf="element.orderStatus !== 'rbc-accepted'">
                                                {{element.orderStatus}}
                                            </span>
                                            <!-- RBC changes -->
                                            <span
                                                [ngClass]="{'text-warning': element.orderStatus == 'approve & submit', 'text-danger' : element.orderStatus == 'missing fields'}"
                                                class="text-capitalize" *ngIf="element.orderStatus === 'rbc-accepted'">
                                                Accepted
                                            </span>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="actions">
                                        <th mat-header-cell *matHeaderCellDef scope="col"> </th>
                                        <td mat-cell *matCellDef="let element">
                                            <div style="display:flex"
                                                *ngIf="element.orderStatus === 'Action Required: Missing Fields' || (element.orderStatus === 'Action Required: Approve & Submit' && (this.permissionsData.userRolePermission[0].roleName == 'Pharmacist' || this.permissionsData.userRolePermission[0].roleName == 'IT support'))"
                                                [hidden]="permissionAssignment.editBtn">
                                                <mat-icon class="grid-icon" title="edit"
                                                    (click)="onEditClick(element.foNumber, element.importedBy)">
                                                    edit</mat-icon>
                                            </div>
                                        </td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;"></tr>
                                </table>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <!-- expanded cols -->

                <!-- <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;" [class.example-element-row]="element"
                    [class.example-expanded-row]="expandedElement === element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

            </table>
            <!-- Row shown when there is no matching data. -->
            <!-- <div *ngIf="noDataFlag" class="no-records">
                No records found
            </div> -->
            <div *ngIf="noDataFlag && !isAccountSelected" class="no-records">
                No orders have been imported for this account!
            </div>
            <div *ngIf="isAccountSelected" class="no-records">
                Please select an account!
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 200]"></mat-paginator>
        </div>
        <!-- PMS Data -->
    </mat-card-content>
    <!-- <mat-card-actions>
        <button mat-raised-button color="accent">Reset</button>
        <button mat-raised-button color="primary" (click)="onEditClick()">Edit</button>
    </mat-card-actions> -->
</mat-card>