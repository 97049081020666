<h2 mat-dialog-title>{{title}}
    <mat-icon class="dialog-icon" (click)="onCloseDialog()">close</mat-icon>
  </h2>
<mat-dialog-content>
    <!-- Assign Role dropdown  -->
    <div *ngIf="showHideRole; else showAllAccounts" class="field-wrap">
        <form name="roleForm" [formGroup]="roleForm" novalidate>
            <label class="label-txt">Assign Role: <span [ngStyle]="{'color':displayError ? 'red' : 'black' }">*</span></label>
            <mat-form-field class="assign-role">
                <mat-select [(ngModel)]="selectedRole" formControlName="roleSelection" #selectRole required (selectionChange)="onRoleChange($event.value)">
                    <mat-option *ngFor="let role of roleData" [value]="role.id">
                        {{role.title}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-error *ngIf="displayError">
                Select Assign Role
            </mat-error>
        </form>
    </div>
    <!-- Assign Role dropdown -->
    <!-- Search Filter -->
		<form name="customerSearchForm" [formGroup]="customerSearchForm" novalidate>
            <!-- Assign All Accounts Checkbox -->
            <ng-template #showAllAccounts>
                <mat-checkbox formControlName="allAccountsCheckbox" (change)="onChangeCheckbox($event.checked)">All Accounts
                </mat-checkbox>
            </ng-template>
            <!-- Assign All Accounts Checkbox -->
			<div class="search-bar user-mangmt-search">
				<div class="mat-form-search-cont">
					<mat-form-field>
						<input matInput type="text" placeholder="Enter your search..." name="keyword"
                        formControlName="keyword" (paste)="onCopyPaste($event)" 
                        (keyup)="onSearchFilter(customerSearchForm.controls.keyword)"
								#searchInput required />
                                <a mat-button [hidden]="!clearSearchFlag" matSuffix mat-icon-button
                            aria-label="x" (click)="onClearSearch()">
                            <mat-icon class="close-icon">close</mat-icon>
                        </a>
							<mat-hint align="start">Please enter search criteria. For more information check <i
								class="fa fa-info-circle info-icon" aria-hidden="true"></i> icon. </mat-hint>
					</mat-form-field>
					<i class="fa fa-info-circle info-icon" aria-hidden="true" matTooltip="Note: &#13;
                    Search by Customer Account Number or Name" matTooltipClass="tool-tip-class"
							aria-label="Button that shows a tooltip"></i>
					<button mat-flat-button color="primary" class="button primary" (click)="onCustomerSearchClick()" [disabled]="disableSearchFlag || customerSearchForm.get('keyword').hasError('InvalidSearch')">
						Search
					</button>
				</div>
				<div class="search-bar-options">
                    <button mat-stroked-button color="primary" class="clear-filter condensed-btn" (click)="onClearSearchResults()">
                        Clear search results
                    </button>
                </div>
			</div>
		</form>
	    <!-- Search Filter -->

    <!-- grid-box -->
    <div class="grid-box-container" [class.disabled]="showAllFlag">
        <div class="grid-box">
            <p class="grid-header">Available Customer(s)</p>
            <div class="table-container" [class.disabled]="config.allAccount">
                <table aria-describedby="Customer Accounts" class="table-wrap" mat-table matSort #availableSort="matSort"
                    [dataSource]="table.dataSource.available">
                    <ng-container matColumnDef="sapAccountNumber">
                        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
                           Account Number
                        </th>
                        <td mat-cell *matCellDef="let account">
                            <span *ngIf="account.sapAccountNumber === null || account.sapAccountNumber === ''">{{ account.legacyAccountNumber}}</span> 
                            <span *ngIf="account.sapAccountNumber != null || account.sapAccountNumber != ''">{{ account.sapAccountNumber}}</span> 
                            
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="accountName1">
                        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let account">
                            {{ account.accountName1 }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="address1">
                        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
                            Address
                        </th>
                        <td mat-cell *matCellDef="let account">
                            {{ account.address1 }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="table.columns; sticky: true"></tr>
                    <tr class="main-row " (click)="selectAccount(account,i)" [class.row-is-clicked]="i==highlightCustomerRow" mat-row *matRowDef="let account; let i=index; columns: table.columns"></tr>
                </table>
                <!-- Row shown when there is no matching data. -->
                <div *ngIf="noCustDataFlag" class="no-records">
                    No records found
                </div>
            </div>
        </div>

        <div class="grid-button-box">
            <button type="button" mat-flat-button color="primary" class="button primary" 
                (click)="assignAllAccounts(true)" [disabled]="showAllFlag">
                <em class="fas fa-caret-right"></em><em class="fas fa-caret-right"></em>
            </button>

            <button type="button" mat-flat-button color="primary" class="button primary" 
                (click)="assignAllAccounts()" [disabled]="showAllFlag">
                <em class="fas fa-caret-right"></em>
            </button>

            <button type="button" mat-button mat-stroked-button color="primary" 
                (click)="removeAllAccounts()" [disabled]="showAllFlag">
                <em class="fas fa-caret-left"></em>
            </button>

            <button type="button" mat-button mat-stroked-button color="primary" 
                (click)="removeAllAccounts(true)" [disabled]="showAllFlag">
                <em class="fas fa-caret-left"></em><em class="fas fa-caret-left"></em>
            </button>
        </div>

        <div class="grid-box">
            <p class="grid-header">Assigned Customer(s)</p>

            <div class="table-container">
                <table aria-describedby="Assigned Customer" class="table-wrap" mat-table matSort #assignedSort="matSort"
                    [dataSource]="table.dataSource.assigned">
                    <ng-container matColumnDef="sapAccountNumber">
                        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
                           Account Number
                        </th>
                        <td mat-cell *matCellDef="let accountAssign">
                            <span *ngIf="accountAssign.sapAccountNumber === null || accountAssign.sapAccountNumber === ''">{{ accountAssign.legacyAccountNumber}}</span> 
                            <span *ngIf="accountAssign.sapAccountNumber != null || accountAssign.sapAccountNumber != ''">{{ accountAssign.sapAccountNumber}}</span> 
                            
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="accountName1">
                        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
                            Name
                        </th>
                        <td mat-cell *matCellDef="let accountAssign">
                            {{ accountAssign.accountName1 }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="address1">
                        <th scope="col" mat-header-cell *matHeaderCellDef mat-sort-header>
                            Address
                        </th>
                        <td mat-cell *matCellDef="let accountAssign">
                            {{ accountAssign.address1 }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="table.columns; sticky: true"></tr>
                    <tr class="main-row" (click)="selectAssignAccount(accountAssign,i)" [class.row-is-clicked]="i==highlightAssignedRow" mat-row *matRowDef="let accountAssign; let i=index; columns: table.columns"
                        ></tr>
                </table>
                <!-- Row shown when there is no matching data. -->
                <div *ngIf="noAssignDataFlag" class="no-records">
                    No records found
                </div>
            </div>
        </div>
    </div>
    <!-- grid-box -->
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-stroked-button color="primary" (click)="onCloseDialog()">Cancel</button>
    <button mat-button cdkFocusInitial mat-flat-button color="primary"
      class="button primary"  (click)="onSave()">Save</button>  
</mat-dialog-actions>