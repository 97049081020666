import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { UserManagementService } from '../user-management.service';

import { SearchCriteria } from 'src/app/shared/models/header';
import { SaveAssignedCustomerCriteria, SearchAssignedCustomerCriteria, SearchCustomerCriteria, UserData } from '../user-management';
import { ToastService } from 'src/app/shared/utils/toast.service';
import { RoleTypes } from 'src/app/core/constants/app.constant';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-modal-dialog-user-management',
  templateUrl: './modal-dialog-user-management.component.html',
  styleUrls: ['./modal-dialog-user-management.component.scss']
})
export class ModalDialogUserManagementComponent implements OnInit {

  /*********************Properties*********************/
  title: string = 'Assign Customer';
  roleData: any[] = RoleTypes;
  customerSearchForm: FormGroup;
  clearSearchFlag: boolean = false;
  disableSearchFlag: boolean = true;
  noCustDataFlag: boolean = false;
  noAssignDataFlag: boolean = false;
  showAllFlag: boolean = false;
  searchObj: SearchCriteria = {
    "keyword": null
  };
  searchCustomerObj: SearchCustomerCriteria = {
    "keyword": null,
    "filterUserNum": null
  };
  searchAssignedCustomerObj: SearchAssignedCustomerCriteria = {
    "userNum": null
  }
  saveAssignedCustomerCriteria: SaveAssignedCustomerCriteria = {
    "userNum": null,
    "customerAccountIds": [],
    "roleNums": [],
    "showAllCustomer": false
  };
  customersDataArr: any[] = [];
  customersData: any[] = [];
  customersAssigned: any[] = [];
  selectedRole: number = 0;
  showHideRole: boolean = false;
  displayError: boolean = false;
  updatedShowAllFlag: boolean = false;
  userObj: UserData;
  addModifiedCustomers: any;
  removeModifiedCustomers: any;
  highlightCustomerRow: any;
  highlightAssignedRow: any;
  userNumber: number;
  roleForm: FormGroup;
  readonly config = {
    search: { text: null },
    allAccount: false,
  };

  table = {
    config: { available: { warning: false, message: null } },
    columns: ['sapAccountNumber', 'accountName1', 'address1'],
    dataSource: {
      available: new MatTableDataSource(this.customersData),
      assigned: new MatTableDataSource(this.customersAssigned),
    },
  };

  @ViewChild('availableSort', { static: true }) availableSort: MatSort;
  @ViewChild('assignedSort', { static: true }) assignedSort: MatSort;

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    public dialogRef: MatDialogRef<ModalDialogUserManagementComponent>,
    private formBuilder: FormBuilder,
    private userManagementService: UserManagementService,
    private toastr: ToastService,
    @Inject(MAT_DIALOG_DATA)
    public data: { userRow: UserData, updateRole(userNum: number, roleNum: number): any },
  ) { }
  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit(): void {
    //Form Initialization
    this.customerSearchForm = this.formBuilder.group({
      'keyword': [null, [Validators.required]],
      'allAccountsCheckbox': [null]
    });
    this.roleForm = this.formBuilder.group({
      'roleSelection': [null]
    });

    this.userObj = this.data.userRow;
    this.init();
  }

  ngAfterViewInit() {
    this.table.dataSource.available.sort = this.availableSort;
    this.table.dataSource.assigned.sort = this.assignedSort;
  }
  /*********************Lifecyle Methods*********************/

  /*********************Helper Methods*********************/
  removeAssigned(array1: any, array2: any) {
    return array1.filter(function (cv) {
      return !array2.find(function (e) {
        return e.accountId == cv.accountId;
      });
    });
  }
  /*********************Helper Methods*********************/
  /*********************Component Methods*********************/

  onCopyPaste(e: ClipboardEvent) {//ClipboardEvent to capture cut copy paste
    let val = {
      value: null
    }
    val.value = e.clipboardData.getData('text/plain');
    this.onSearchFilter(val);
  }

  onSearchFilter(val) {
    this.clearSearchFlag = true;
    // this.customerSearchForm.controls.keyword.setErrors(null);
    if (val.value && val.value.length > 1) {
      this.resetSearchObj();
      this.searchCustomerObj.keyword = val.value;
    }
    else {//default condition
      this.disableSearchFlag = false;
      this.customerSearchForm.controls.keyword.setErrors({ InvalidSearch: true });
    }

    //search button validation
    if (val && val.value && val.value.length > 1) {
      this.disableSearchFlag = false;
    }
    else {
      this.disableSearchFlag = true;
    }
  }

  //Close modal dialog
  onCloseDialog() {
    this.dialogRef.close();
  }

  //Get all available customers with search filter
  onCustomerSearchClick() {
    this.getCustomers(this.userObj);
  }

  //clear all the filters added
  onClearSearch() {
    this.customerSearchForm.get('keyword').setValue('');
    this.clearSearchFlag = false;
    this.disableSearchFlag = true;
  }

  //reset the filter serach results & get all the available customers
  onClearSearchResults() {
    this.resetSearchObj();
    this.onClearSearch();
    this.getCustomers(this.userObj);
  }

  //Get all the customers for specific user
  getCustomers(user: UserData) {
    if (this.searchCustomerObj) {
      this.userNumber = user.userNum;
      this.searchCustomerObj.filterUserNum = "0" //get all accounts on search
      this.userManagementService.searchCustomerAccounts(this.searchCustomerObj).subscribe((res: any) => {
        this.customersData = !res.hasError ? (res.data.customers ? res.data.customers : []) : [];
        this.customersData = this.removeAssigned(this.customersData, this.customersAssigned);
        this.refreshAllCustGrid(this.customersData);
      });
    }
  }

  //Get all the assigned customers for that user
  getAllAssignedCustomers(user: UserData) {
    this.searchAssignedCustomerObj.userNum = user.userNum;
    this.userManagementService.searchAssignedCustomerAccounts(this.searchAssignedCustomerObj).subscribe((response: any) => {
      this.customersAssigned = !response.hasError ? (response.data.userAccountsMappings ? response.data.userAccountsMappings[0].customerAccountsList : []) : [];
      this.refreshAssignedCustGrid(this.customersAssigned);
    });
  }

  //Highlight the selected available account  row
  selectAccount(row: any, index: any) {
    this.highlightCustomerRow = index;
    this.addModifiedCustomers = row;
  }

  //Highlight the selected assigned account row
  selectAssignAccount(row: any, index: any) {
    this.highlightAssignedRow = index;
    this.removeModifiedCustomers = row;
  }

  //move the accounts(all/selected) from available grid to assigned grid
  assignAllAccounts(allAccounts?: any) {
    if (allAccounts) {
      this.customersData.forEach(customerValue => {
        this.customersAssigned.push(customerValue);
      });
      this.customersData = [];
    }
    else if (this.addModifiedCustomers) {
      this.customersAssigned.push(this.addModifiedCustomers);
      let index = this.customersData.findIndex(d => d === this.addModifiedCustomers); //find index in your array
      this.customersData.splice(index, 1);//remove element from array     
    }
    // this.customersAssigned = this.checkForDuplicate(this.customersAssigned);
    this.refreshAllCustGrid(this.customersData);
    this.refreshAssignedCustGrid(this.customersAssigned);
  }

  //move the accounts(all/selected) from assigned grid to available grid
  removeAllAccounts(allAccounts?: any) {
    if (allAccounts) {
      this.customersAssigned.forEach(customerValue => {
        this.customersData.push(customerValue);
      })
      this.customersAssigned = [];
    }
    else if (this.removeModifiedCustomers) {
      this.customersData.push(this.removeModifiedCustomers);
      let index = this.customersAssigned.findIndex(d => d === this.removeModifiedCustomers); //find index in your array
      this.customersAssigned.splice(index, 1)
    }
    this.refreshAllCustGrid(this.customersData);
    this.refreshAssignedCustGrid(this.customersAssigned);
  }

  //Save added/removed accounts
  onSave() {
    // If the user is an internal user or the dropdown has been used to select a role 
    // for an external user, proceed
    if (!this.showHideRole || this.selectedRole != 0) {
      if (this.showHideRole) {
        this.setRole();
      } else {
        this.setAllCustomerFlag();
      }
      this.saveCriteria();
    } else {
      this.displayError = true;
    }
  }

  onRoleChange(roleIdVal: any) {
    this.selectedRole = roleIdVal;
    if (roleIdVal == 0) {
      this.displayError = true;
    } else {
      this.displayError = false;
    }
  }

  isUserExternal(user: UserData) {
    if (!user.email.toLowerCase().includes("@cardinalhealth.com") && !user.email.toLowerCase().includes("@sonexushealth.com")) {
      let roleNum = user.userRoleXrefList[0].roleNum;
      this.roleData.forEach(role => {
        if (role.id === roleNum) {
          this.selectedRole = roleNum;
        }
      })

      if (this.selectedRole == 0) {
        this.displayError = true;
      }
      return true;
    }
    return false;
  }

  onChangeCheckbox(val: any) {
    this.updatedShowAllFlag = val != this.showAllFlag ? true : false;
    this.showAllFlag = val;
    if(this.showAllFlag){
      this.assignAllAccounts(true);
    } else {
      this.removeAllAccounts(true);
    }
  }

  private checkAllAccountsFlag(user: any) {
    if (user.userRoleXrefList && !this.isUserExternal(user)) {
      this.searchObj.keyword = user.email;
      this.userManagementService.searchUserData(this.searchObj).subscribe((response: any) => {
        if (!response.hasError) {
          this.showAllFlag = response.data.users ? response.data.users[0].userRoleXrefList[0].showAllCustomer : false;
          this.customerSearchForm.get('allAccountsCheckbox').setValue(this.showAllFlag);
          this.onChangeCheckbox(this.showAllFlag);
        }
      });  
    }
  }


  /*********************Component Methods*********************/

  /*********************Private Methods*********************/
  private init() {
    this.resetSearchObj();
    this.getCustomers(this.userObj);
    this.getAllAssignedCustomers(this.userObj);
    this.showHideRole = this.isUserExternal(this.userObj);
    this.checkAllAccountsFlag(this.userObj);
  }

  private refreshAllCustGrid(obj: any) {
    this.table.dataSource.available = new MatTableDataSource(obj);
    this.table.dataSource.available.sort = this.availableSort;
    if (obj && obj.length > 0) {
      this.noCustDataFlag = false;
    }
    else {
      this.noCustDataFlag = true;
    }
    this.highlightAssignedRow = null;
    this.removeModifiedCustomers = null;
  }

  private refreshAssignedCustGrid(obj: any) {
    this.table.dataSource.assigned = new MatTableDataSource(obj);
    this.table.dataSource.assigned.sort = this.assignedSort;
    if (obj && obj.length > 0) {
      this.noAssignDataFlag = false;
    }
    else {
      this.noAssignDataFlag = true;
    }
    this.highlightCustomerRow = null;
    this.addModifiedCustomers = null;
  }

  private resetSearchObj() {
    this.searchCustomerObj = {
      "keyword": null,
      "filterUserNum": null
    };
  }

  private setRole() {
    this.saveAssignedCustomerCriteria.roleNums = [this.selectedRole];
    if (this.data.updateRole) {
      this.data.updateRole(this.userNumber, this.selectedRole);
    }
  }

  private setAllCustomerFlag() {
    this.saveAssignedCustomerCriteria.showAllCustomer = this.showAllFlag;
  }

  private saveCriteria() {
    this.saveAssignedCustomerCriteria.userNum = this.userNumber;
    if(this.updatedShowAllFlag){
      this.saveAssignedCustomerCriteria.customerAccountIds = [];
    }
    else {
      this.customersAssigned.forEach(customerValue => {
        this.saveAssignedCustomerCriteria.customerAccountIds.push(customerValue.accountId);
      });
    }
    this.userManagementService.saveAssignedCustomerAccounts(this.saveAssignedCustomerCriteria).subscribe((response: any) => {
      if (!response.hasError) {
        this.toastr.success('User data updated successfully.');
        this.updatedShowAllFlag = false;
        this.dialogRef.close('save');
      }
    });
  }
  /*********************Private Methods*********************/
}