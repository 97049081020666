import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';
import { CacheResolverService } from '../../core/cache/cache-resolver.service';

import { HttpHeader, CacheDuration } from '../../core/constants/app.constant';
import { CacheConstant } from 'src/app/core/cache/cache.constant';

import { OktaAuth, TokenExpiration } from '../models/app';
import { Environment } from '../models/environment';
import jwtDecode from 'jwt-decode';
import * as moment from 'moment';
import { Duration } from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TokenService extends BaseService {
  /*********************Properties*********************/
  apigee = {
    token: null,
    sessionValidationTime: 2 * 60 * 1000,
    sessionInterval: null,
    tokenExpired$: new BehaviorSubject<boolean>(false),
    tokenExpiring$: new BehaviorSubject<TokenExpiration>({
      expiring: false,
      time: null,
    }),
  };
  /*********************Properties*********************/
  /*********************Constructor*********************/
  constructor(http: HttpClient, private readonly cache: CacheResolverService) {
    super(http);
  }
  /*********************Constructor*********************/

  /*********************Utility Methods*********************/

  init() {
    this.verifyApigeeTokenExpiration();
  }

  get(): OktaAuth {
    return this.getAuthToken();
  }


  set(
    token: string,
    tokenType: string = 'Bearer',
    expiresAt: Date = null
  ): void {

    //helper method created to add hours
    Date.prototype.addHours = function (h) {
      this.setTime(this.getTime() + (h * 60 * 60 * 1000));
      return this;
    }

    expiresAt = expiresAt || new Date().addHours(2);

    const oktaAuth = this.getAuthToken();
    oktaAuth.accessToken = token;
    oktaAuth.tokenType = tokenType;
    oktaAuth.expiresAt = expiresAt;

    this.cache.set(CacheConstant.OktaAuth, oktaAuth, CacheDuration.TwoHour);
  }

  getQueryToken() {
    const urlParams = new URLSearchParams(window.location.search);
    const queryParamToken = urlParams.get(HttpHeader.Token);
    return queryParamToken;
  }

  exist() {
    const queryParamToken = this.getQueryToken();
    const oktaAuthCache = this.get();
    const token = queryParamToken || oktaAuthCache.accessToken;

    if (queryParamToken) {
      this.set(token);
    }

    return token;
  }

  /*********************Utility Methods*********************/

  /*********************Service Methods*********************/

  validate(accessToken: string, idToken: string) {
    const options = {
      headers: {
        Authorization: `Bearer ${idToken}, Bearer ${accessToken}`,
      },
    };

    return this
      .post(
        Environment.getInstance().appConfig.apigeeTokenEndpoint,
        null,
        options
      )
      .pipe(
        map((response: any) => {
          if (!response.hasError) {
            this.set(response.data.access_token, response.data.token_type);
          }

          return response;
        })
      );
  }

  /*********************Service Methods*********************/

  /*********************Private Methods*********************/
  /* istanbul ignore next */
  private verifyApigeeTokenExpiration() {
    this.apigee.token = this.cache.get(CacheConstant.OktaAuth) as OktaAuth;
    const expiryTime = this.getExpiryTime(this.apigee.token.accessToken);
    if (expiryTime.asSeconds() <= 0) {
      this.clearSessionInterval(this.apigee.sessionInterval);
      this.apigee.tokenExpired$.next(true);
      return;
    }

    this.clearSessionInterval(this.apigee.sessionInterval);

    if (expiryTime.asMilliseconds() <= this.apigee.sessionValidationTime) {
      const tokenExpiration: TokenExpiration = {
        expiring: true,
        time: expiryTime,
      };

      this.apigee.tokenExpiring$.next(tokenExpiration);
      this.startApigeeSessionInterval(20000);
    } else {
      const timeout = expiryTime.asMilliseconds() - this.apigee.sessionValidationTime;
      this.startApigeeSessionInterval(timeout);
    }
  }

  /* istanbul ignore next */
  private getExpiryTime(token: string): Duration {
    let expiryTime: Duration = moment.duration();

    if (token) {
      const userToken = jwtDecode<any>(token);
      const tokenExpiry = moment(userToken.exp * 1000);
      const today = moment();

      expiryTime = moment.duration(tokenExpiry.diff(today));
    }

    return expiryTime;
  }

  /* istanbul ignore next */
  private startApigeeSessionInterval(timeOut: number) {
    this.apigee.sessionInterval = setInterval(
      () => this.verifyApigeeTokenExpiration(),
      timeOut
    );
  }

  /* istanbul ignore next */
  private clearSessionInterval(interval) {
    clearInterval(interval);
  }

  private getAuthToken(): OktaAuth {
    let oktaAuth = this.cache.get(CacheConstant.OktaAuth) as OktaAuth;

    if (!oktaAuth) {
      oktaAuth = {
        accessToken: null,
        tokenType: null,
        expiresAt: null,
      };
    }

    return oktaAuth;
  }

  /*********************Private Methods*********************/
}
