<div class="logo">
    <img class="logo-img" src="../../../assets/images/cardinal-logo.png" alt="Cardinal" title="Home" />
</div>
<div class="wrapper">
    <h2>GLN Lookup</h2>
    <div class="body">
        <form [formGroup]="rbcForm">
            <mat-form-field>
                <input matInput placeholder="Account Number" name="sap" formControlName="sap" minlength="10" maxlength="10" required>
                <mat-hint align="start">Please enter 10 digit Account number.</mat-hint>
                <mat-error *ngIf="rbcForm.controls.sap.status == 'INVALID'">Incorrect Account Number.</mat-error>
            </mat-form-field>

            <mat-form-field>
                <input matInput placeholder="Zip code" name="zip" formControlName="zip" minlength="5" required>
                <mat-hint align="start">Zip code must be atleast 5 digit.</mat-hint>
                <mat-error *ngIf="rbcForm.controls.zip.status == 'INVALID'">Incorrect Zip code.</mat-error>
            </mat-form-field>
            <br />
            <button mat-flat-button color="primary" class="button primary" (click)="onSubmitForm()" [disabled]="rbcForm.status == 'INVALID'">Submit</button>
        </form>
    <h3>Global Location Number (GLN): <strong>{{GLN}}</strong></h3>
    <h3>Customer Name: <strong>{{custName}}</strong></h3>

    </div>
</div>