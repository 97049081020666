import { Pipe, PipeTransform } from '@angular/core';
import { Regex } from 'src/app/core/constants/app.constant';

@Pipe({
  name: 'formatName'
})
export class FormatNamePipe implements PipeTransform {

  transform(value: string): string {
    let strVal = value == value.toUpperCase() ? value : value.replace(Regex.addSpaceBeforeCapLetter, ' $1').trim();
    
    if(strVal && strVal.search(Regex.checkForSpace) == -1 && strVal.length < 4){
      return strVal.toUpperCase();
    }
    else {
      strVal = strVal=='ncpdp'?'NCPDP':strVal;
      return strVal;
    }
  }

}
