import { Component, OnInit, ViewChild, ViewChildren, QueryList, ChangeDetectorRef, ElementRef } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
// import { MatDialog, MatPaginator, MatSort, MatTable, MatTableDataSource } from '@angular/material';
import * as _moment from 'moment';
import 'moment-timezone';
// tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment } from 'moment';
import { ActivatedRoute } from '@angular/router';

import { ModalDialogPmsComponent } from './modal-dialog-pms/modal-dialog-pms.component';
import { CommonControls } from 'src/app/shared/common-control/common-controls';

import { PmsService } from './pms.service';

import { OrderDetails, PmsData, SearchCriteria } from './pms';
import { Subject } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { DateFormat } from 'src/app/core/constants/app.constant';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';

const moment = _moment;

@Component({
  selector: 'app-pms',
  templateUrl: './pms.component.html',
  styleUrls: ['./pms.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class PmsComponent implements OnInit {
  /*********************Properties*********************/
  panelOpenState = true;
  disableSearchFlag: boolean = true;
  file: any;
  uploadFile: any;
  files: any;
  encFile: any = { csvFile: null };
  showStatusFlag: boolean = false;
  uploadStatus: any = {
    fileName: null,
    message: null,
    status: null
  };
  searchObj: SearchCriteria = {
    "userId": null,
    "foNumber": null,
    "accountNum": null,
    "accountName": null
  };
  current_timestamp = moment().format(DateFormat.MMDDYYYYHHmmssA);

  displayedColumns = ['expand', 'sapAccountNumber', 'pharmacyName', 'fileName', 'dateImported', 'importedMethod', 'importedBy', 'status'];
  dataSource: MatTableDataSource<PmsData>;
  noDataFlag: boolean = true;
  innerDisplayedColumns = ['foNumber', 'patientName', 'submitDate', 'orderStatus', 'actions'];
  expandedElement: PmsData | null;
  tempObjArr: any[] = [];
  pmsData: PmsData[] = [];
  tempPmsDataObj: any = [];
  sapAccountNumber: string;
  pharmacyName: string;
  userDetails: any;

  @ViewChild('fileImportInput') fileImportInput: ElementRef;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChildren('innerSort', { read: MatSort }) innerSort: QueryList<MatSort>;
  @ViewChildren('innerTables') innerTables: QueryList<MatTable<OrderDetails>>;

  permissionsData: any;
  permissionAssignment: any = {
    importControl: false,
    editIcon: false,
    cancelBtn: false,
    submitBtn: false,
    submitReviewBtn: false
  };

  private onDestroy = new Subject<void>();
  private subscription = {
    activeRoute: null,
    accDetails: null
  };
  accountDetail: any;
  isAccountSelected: boolean = false;

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    public dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private pmsService: PmsService,
    private appService: AppService,
    private activatedroute: ActivatedRoute
  ) { }
  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit(): void {
    this.subscription.activeRoute = this.activatedroute.data.subscribe(data => {
      this.permissionsData = data as any[];
    });

    this.subscription.accDetails = this.appService.accDetails$.subscribe(
      accDetail => {
        this.accountDetail = accDetail;
        this.isAccountSelected = accDetail ? accDetail.accountNumber == "All" : true; // set isAccountSelected flag value to hide or show import control
        this.sapAccountNumber = accDetail ? accDetail.sapAccountNumber : "";
        this.pharmacyName = accDetail ? accDetail.accountName1 : "";
        this.getUserDetails();
        this.getPMSData();
      }
    );
    this.init();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.subscription.accDetails.unsubscribe();
  }
  /*********************Lifecyle Methods*********************/

  /*********************Helper Methods*********************/
  toggleRow(element: PmsData) {
    // element.orderDetails && (element.orderDetails as MatTableDataSource<OrderDetails>).data.length ? (this.expandedElement = this.expandedElement === element ? null : element) : null;
    if (element.orderDetails && (element.orderDetails as MatTableDataSource<OrderDetails>).data.length) {
      element.orderDetails.data.forEach(elm => {
        elm.orderStatus = elm.orderStatus.toLowerCase() === 'pending license check'.toLowerCase() ? 'Processing' : elm.orderStatus
      });
      if (this.expandedElement === element) {
        this.expandedElement = null;
      }
      else {
        this.expandedElement = element;
      }
    } else {
      this.expandedElement = null
    }

    this.cd.detectChanges();

    this.innerTables.forEach((table, index) =>
      (table.dataSource as MatTableDataSource<OrderDetails>).sort = this.innerSort.toArray()[index]);

    //expand icon setting
    this.pmsData.forEach(el => {
      if (this.expandedElement === element) {
        el.toggleItem = false;
        element.toggleItem = true;
      }
      else {
        el.toggleItem = false;
      }
    });
  }

  fileChangeListener($event: any): void {
    this.files = $event.srcElement.files;
    //search button validation
    if ($event.srcElement.files.length > 0) {
      this.disableSearchFlag = false;
    }
    else {
      this.disableSearchFlag = true;
    }
  }
  /*********************Helper Methods*********************/

  /*********************Component Methods*********************/
  onEditClick(foNum: any, importedBy: any) {
    let pmsDataDetails: any = {
      data: null,
      rolePermissionData: null,
      permissionAssignment: null,
      accountDetail: this.accountDetail
    };
    this.pmsService.getOrderDetails(foNum).subscribe((response: any) => {//foNum=3447
      if (!response.hasError) {
        pmsDataDetails.data = response.data;
        pmsDataDetails.data.importedBy = importedBy;
        pmsDataDetails.rolePermissionData = this.permissionsData;
        pmsDataDetails.permissionAssignment = this.permissionAssignment;
      }
      this.openDialog(pmsDataDetails);
    });
  }

  onImportClick() {
    this.file = this.files[0];
    this.uploadFile = new FormData();
    this.uploadFile.append('file', new Blob([this.file], { type: 'multipart/form-data' }), this.file.name);
    this.uploadCSVFile(this.uploadFile, this.accountDetail.accountNumber);
  }

  getPMSData() {
    this.current_timestamp = moment().format(DateFormat.MMDDYYYYHHmmssA);
    this.pmsService.getPMSData(this.searchObj).subscribe((response: any) => {
      this.tempObjArr = [];
      if (!response.hasError && response.data.fileStatus.length > 0) {        
        this.tempObjArr = CommonControls.dateSorting(response.data.fileStatus);
        this.createPMSObj(this.tempObjArr);
        if (this.pmsData[0].showToggleIcon) {
          this.toggleRow(this.pmsData[0]);
        }//Default first row expanded onload
      }
      else {
        this.createPMSObj(this.tempObjArr);
      }
    });
  }

  uploadCSVFile(file: FormData, accountNumber: any) {
    this.pmsService.uploadCSVFile(file, accountNumber).subscribe((response: any) => {
      if (!response.hasError) {
        this.showStatusFlag = true;
        this.uploadStatus.fileName = response.data.file.fileName;
        this.uploadStatus.status = response.data.file.fileStatus;
        this.uploadStatus.message = (response.data.file.fileStatus == 'rejected' || response.data.file.fileStatus == 'Unable To Parse') ? 'An error occurred processing file ' : 'imported successfully';
      }
      setTimeout(() => {
        this.showStatusFlag = false;
      }, 10000);
      this.getPMSData();
      this.resetImportControl(); //Reset File Import Control
    });
  }

  refreshData() {
    this.getPMSData();
  }

  /*********************Component Methods*********************/

  /*********************Dialog Methods*********************/
  public openDialog(pmsDataDetails: any) {
    const dialogRef = this.dialog.open(ModalDialogPmsComponent, {
      data: pmsDataDetails ? pmsDataDetails : null,
      autoFocus: false,
      disableClose: true,
      panelClass: 'fullScreenDialog'
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result !== 'close') this.init();
    });
  }
  /*********************Dialog Methods*********************/

  /*********************Init/Reset Methods*********************/
  private init() {
    this.getUserDetails();
    this.getPMSData();
    this.assignPermissions();
  }

  private assignPermissions() {
    switch (this.permissionsData.userRolePermission[0].roleName) {
      case 'Pharmacist':
        this.setPermissionsByRole(false, false, false, false, true);
        break;
      case 'Pharmacy Tech':
        this.setPermissionsByRole(false, true, false, true, false);
        break;
      case 'IT support':
        this.setPermissionsByRole(false, false, false, false, false);
        break;
      default:
        this.setPermissionsByRole(true, true, true, true, true);
    }
  }

  private setPermissionsByRole(importControlFlag: boolean, cancelBtnFlag: boolean, editBtnFlag: boolean, submitBtnFlag: boolean, submitReviewBtnFlag: boolean) {
    this.permissionAssignment.importControl = importControlFlag;
    this.permissionAssignment.cancelBtn = cancelBtnFlag;
    this.permissionAssignment.editBtn = editBtnFlag;
    this.permissionAssignment.submitBtn = submitBtnFlag;
    this.permissionAssignment.submitReviewBtn = submitReviewBtnFlag;
  }

  private createPMSObj(pmsObj: any[]) {
    this.tempPmsDataObj = [];
    this.pmsData = [];
    pmsObj.forEach(data => {
      this.tempPmsDataObj.push({
        sapAccountNumber: data.sapAccountNumber,
        pharmacyName: data.pharmacyName,
        fileName: data.fileName,
        importedMethod: data.importType,
        dateImported: data.importDate,
        importedBy: data.uploadedBy,
        status: data.fileStatus,
        toggleItem: false,
        showToggleIcon: true,
        orderDetails: data.pmsOrders
      })
    });

    this.tempPmsDataObj.forEach((data: PmsData) => {
      if (data.orderDetails && Array.isArray(data.orderDetails) && data.orderDetails.length) {
        data.toggleItem = false;
        data.showToggleIcon = true;
        data.orderDetails.forEach((orderDetail: OrderDetails) => {
          orderDetail.importedBy = data.importedBy;
        });
        this.pmsData = [...this.pmsData, { ...data, orderDetails: new MatTableDataSource(data.orderDetails) }];
      } else {
        data.toggleItem = false;
        data.showToggleIcon = false;
        this.pmsData = [...this.pmsData, data];
      }
    });
    this.dataSource = new MatTableDataSource(this.pmsData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.pmsData && this.pmsData.length > 0) {
      this.noDataFlag = false;
    }
    else {
      this.noDataFlag = true;
    }
  }

  private resetImportControl() {
    this.fileImportInput.nativeElement.value = null; //Reset Import Control
    this.disableSearchFlag = true;
  }

  private getUserDetails() {
    // this.searchObj.userId = this.permissionsData.userRolePermission[0].userDetails.preferred_username;
    this.searchObj.userId = null;
    this.searchObj.accountNum = this.accountDetail ? this.accountDetail.accountNumber : null;
    this.searchObj.accountName = this.accountDetail ? this.accountDetail.accountName1 : null;
  }
  /*********************Init/Reset Methods*********************/
}


