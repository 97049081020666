import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';

import { ClientApi, ExportApi } from 'src/app/core/constants/api.constant';
import { IResponse } from 'src/app/shared/models/app';
import { saveReplenOrderDetails } from './inventory';

@Injectable({
  providedIn: 'root'
})
export class InventoryService extends BaseService {
  /*********************Properties*********************/

  /*********************Properties*********************/

 /*********************Constructor*********************/
  constructor(protected http: HttpClient) {
    super(http);
    // this.apiConfig.order = 'http://localhost:7285/api/v1/';
   }
/*********************Constructor*********************/

/*********************Service Methods*********************/

// Inventory search service
searchFacilityInventoryData(searchCriteria){
  const url = this.serviceUrl(this.apiConfig.order, ClientApi.SearchFacilityInventoryData);
  return super.post(url, searchCriteria).pipe(
    map((response: any) => {
      // if (!response.hasError) {
        return response;
      // }
    })
  )
}

searchCustomerInventoryData(searchCriteria){
  const url = this.serviceUrl(this.apiConfig.order, ClientApi.SearchCustomerInventoryData);
  return super.post(url, searchCriteria).pipe(
    map((response: any) => {
      // if (!response.hasError) {
        return response;
      // }
    })
  )
}

searchUserData(searchCriteria){
  const url = this.serviceUrl(this.apiConfig.user, ClientApi.SearchUserData);
  return super.post(url, searchCriteria).pipe(
    map((response: any) => {
      // if (!response.hasError) {
      return response;
      // }
    })
  )
}

searchAssignedCustomerData(userNum) {
  const url = this.serviceUrl(this.apiConfig.user, ClientApi.SearchAssignedCustomerAccounts, userNum);
  return this.http.get(url).pipe(
    map((response: any) => {
      // if (!response.hasError) {
      return response;
      // }
    })
  )
}

saveParLevel(searchCriteria){
  const url = this.serviceUrl(this.apiConfig.order, ClientApi.saveParLevel);
  return super.post(url, searchCriteria).pipe(
    map((response: any) => {
      // if (!response.hasError) {
        return response;
      // }
    })
  )
}

searchReplenishmentOrderData(searchCriteria){
  const url = this.serviceUrl(this.apiConfig.order, ClientApi.getReplenishmentOrders);
  return super.post(url, searchCriteria).pipe(
    map((response: any) => {
      // if (!response.hasError) {
        return response;
      // }
    })
  )
}

downloadReplenOrderInvoice(searchCriteria){
  const url = this.serviceUrl(this.apiConfig.order, ClientApi.getReplenOrderInvoice);
  return super.post(url, searchCriteria,{ responseType: 'blob' }).pipe(
    map((response: any) => {
      //if(!response.hasError) {
        return response;
      //}
    })
  )
}

// Inventory Edit/Remove service
editInventoryData(saveObj){
  const url = this.serviceUrl(this.apiConfig.order, ClientApi.EditInventoryData);
  return super.post(url, saveObj).pipe(
    map((response: any) => {
      // if (!response.hasError) {
        return response;
      // }
    })
  )
}

searchReplenOrderDetails(searchCriteria) {
  const url = this.serviceUrl(this.apiConfig.order, ClientApi.getReplenOrderDetails);
  return super.post(url, searchCriteria).pipe(
    map((response: any) => {
      // if (!response.hasError) {
        return response;
      // }
    })
  )
}

saveReplenOrderDetails(saveReplenOrderDetails: saveReplenOrderDetails){
  const url=this.serviceUrl(this.apiConfig.order,ClientApi.saveReplenOrderDetails);
    return super.post(url, saveReplenOrderDetails).pipe(
      map((response: any) => {
        // if (!response.hasError) {
        return response;
        // }
      })
    )
}

 // Export data to xls service
 export(reportName: any, reportRequest: any) {
  let url = '';
  switch (reportName) {
    case 'NavixRX Balance on Hand': {
      url = this.serviceUrl(
        this.apiConfig.order,
        ExportApi.ExportInvFacilityData
      );
      break;
    }
    case 'Inventory On Hand': {
      url = this.serviceUrl(
        this.apiConfig.order,
        ExportApi.ExportInvByCustomerData
      );
      break;
    }
    default: {
      //do nothing
      break;
    }
  }
  return super.post(url, reportRequest, { responseType: "blob" }).pipe(
    map((response: IResponse) => {
      // if (!response.hasError) {
      return response;
      // }
    })
  );
}


searchReplItem(searchCriteria) {
  const url = this.serviceUrl(this.apiConfig.mdm, ClientApi.getReplenOrderItem);
  return super.post(url, searchCriteria).pipe(
    map((response: any) => {
      // if (!response.hasError) {
        return response;
      // }
    })
  )
}

saveReplItem(saveReplItem){
  const url=this.serviceUrl(this.apiConfig.order,ClientApi.saveReplenishmentItem);
    return super.post(url, saveReplItem).pipe(
      map((response: any) => {
        // if (!response.hasError) {
        return response;
        // }
      })
    )
}

/*********************Service Methods*********************/
}
