import { Injectable } from '@angular/core';
import { ConfirmModal } from './confirm-dialog';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { Observable } from 'rxjs';
import { MatDialogConfig, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  /*********************Properties*********************/
  private readonly dialogConfig: MatDialogConfig = {
    autoFocus: true,
    disableClose: false,
    hasBackdrop: false,
    panelClass: '',
  };
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(private dialog: MatDialog) {}
  /*********************Constructor*********************/

  /*********************Utility Methods*********************/

  confirm(confirm: ConfirmModal, config?: MatDialogConfig) {
    const dialogConfig = this.getDialogOptions(config, this.getConfirmConfig());
    const dialogRef: MatDialogRef<ConfirmDialogComponent> = this.openModal(
      ConfirmDialogComponent,
      dialogConfig
    ) as MatDialogRef<ConfirmDialogComponent>;

    dialogRef.componentInstance.confirm = confirm;

    return dialogRef;
  }

  open(component: any, config: MatDialogConfig = this.dialogConfig) {
    const dialogRef = this.openModal(component, config);

    return {
      dialogRef,
      result: new Observable<string>(this.getDialogSubscriber(dialogRef)),
    };
  }

  closeAll() {
    this.dialog.closeAll();
  }

  /*********************Utility Methods*********************/

  /*********************Private Methods*********************/

  private openModal(component: any, options?: MatDialogConfig) {
    const modalOptions = this.getDialogOptions(options);
    return this.dialog.open(component, modalOptions);
  }

  private getDialogSubscriber(modelRef: MatDialogRef<any>) {
    return (observer) => {
      const subscription = modelRef
        .afterClosed()
        .subscribe((reason: string) => {
          observer.next(reason);
          observer.complete();
        });

      return {
        unsubscribe() {
          subscription.unsubscribe();
        },
      };
    };
  }

  private getConfirmConfig(): MatDialogConfig {
    const config = new MatDialogConfig();
    config.disableClose = true;
    config.autoFocus = false;
    config.panelClass = 'confirm-dialog';
    return config;
  }

  private getDialogOptions(
    config: MatDialogConfig,
    defaultConfig: MatDialogConfig = this.dialogConfig
  ) {
    return Object.assign(defaultConfig, config);
  }

  /*********************Private Methods*********************/
}
