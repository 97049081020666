import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from './core/services/auth.service';
import { OktaPkceAuthService } from './core/services/okta-pkce-auth.service';
import { RouteUtilService } from './shared/utils/route-util.service';
import { ModuleRoutes, OktaRoutes } from './core/constants/routes.constant';
import { UserService } from './core/services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  /*********************Properties*********************/
  authenticated = false;
  title = 'wbd-ui';
  private subscription = {
    userProfile: null,
    signOut: null,
  };
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private authService: AuthService,
    private oktapkceAuth: OktaPkceAuthService,
    private routeUtils: RouteUtilService,
    private userService: UserService,
    private router: Router,
  ) { }
  /*********************Constructor*********************/

  /*********************Implementation Methods*********************/
  ngOnInit() {
    this.subscription.userProfile = this.userService.userProfileLoaded$.subscribe(
      (response) => this.onUserProfileLoaded()
    );

    this.subscription.signOut = this.authService.signedOut$.subscribe(
      (response) => this.onUserSignedOut(response)
    );

    this.init();
  }

  ngOnDestroy() {
    this.subscription.userProfile.unsubscribe();
    this.subscription.signOut.unsubscribe();
  }

  /*********************Implementation Methods*********************/
  userSignout(): void {
    this.authenticated = false;
    this.authService.signOut();
  }
  /*********************Implementation Methods*********************/

  /*********************Private Methods*********************/
  /* istanbul ignore next */
  private init() {
    // This will be checked when user is already logged in and refresh the page.
    this.authService.isAuthenticated().then((authResponse) => {
      if (window.location.search.substring(1).startsWith('iss=')) { // for stage & Prod internal login user auth
        this.validateInternalAuthentication(authResponse);
      }

      if (authResponse.authenticated.all) {
        this.onUserProfileLoaded();
      } else if (
        window.location.pathname === '/' &&
        !this.oktapkceAuth.isAuthCallback()
      ) {
        this.oktapkceAuth.signOut();
      }
    });
  }

  private validateInternalAuthentication(authResponse) {
    !authResponse.authenticated.app
      ? this.router.navigateByUrl(OktaRoutes.Callback.Url)
      : this.onUserProfileLoaded();
  }

  /* istanbul ignore next */
  private onUserProfileLoaded() {
    this.authenticated = false;
    const userDetails = this.userService.getDetails();
   
    if (userDetails) {
      this.routeUtils.navigate(ModuleRoutes.Dashboard.Url);
      this.authenticated = true;
      this.userService.setUserDetails(userDetails);
    } else {
      this.oktapkceAuth.signOut();
      this.authenticated = false;
    }
  }

  private onUserSignedOut(response: any) {
    if (!response.hasError) {
      this.authenticated = false;
      this.routeUtils.navigate(response.redirect.url);
    }
  }

}



