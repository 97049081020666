<div class="row dashboard">
    <h2 class="col-lg-12 col-md-12 col-sm-12">
        <!-- [ngClass]="{ 'fade-in': !roleAssignedFlag}" -->
        NavixRx Order Management
    </h2>

    <!-- <div class="col-lg-12 col-md-12 col-sm-12 dashboard-NoRoleAssigned" *ngIf="!roleAssignedFlag">
        <h1 class="fade-in">
            <span class="material-icons">
                person_off
            </span>
            <br />
            The User has No Access!
        </h1>
    </div> -->

    <div class="col-lg-9 col-md-9 col-sm-9 dashboard-menus">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-3 option-padding {{option.name}}"
                *ngFor="let option of dashboardMenuOptionDisplay; let currentIndex = index" id="{{option.id}}" #item>
                <mat-card (click)="onMaindashboardMenuClicked(currentIndex, item.id)" >
                    <mat-card-header>
                        <mat-card-title>{{option.title}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <i aria-hidden="true" class="fa {{option.src}} icon"></i>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

</div>
