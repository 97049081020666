import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// import { MatDialogRef, MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';

import { InventoryAdjustmentData, updateInventoryData } from '../inventory';

@Component({
  selector: 'app-modal-dialog-inventory',
  templateUrl: './modal-dialog-inventory.component.html',
  styleUrls: ['./modal-dialog-inventory.component.scss']
})
export class ModalDialogInventoryComponent implements OnInit {

  /*********************Properties*********************/
  dialogTitle: string;
  addEditInventoryForm: FormGroup;
  inventoryRowData: any;
  saveObj: updateInventoryData;
  totalOnHand: number = 0;

  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<InventoryAdjustmentData>;
  noDataFlag: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    // private dialog: MatDialogRef<ModalDialogInventoryComponent>,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ModalDialogInventoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }
  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit(): void {
    this.inventoryRowData = this.data;
    this.displayedColumns = ['inventoryAccountNumber', 'inventoryAccountName', 'inventoryOnHand', 'inventoryOnOrder', 'quantityReserved'];
    (this.inventoryRowData.inventoryAdjustmentList || []).forEach((data,index) => {
      this.inventoryRowData.inventoryAdjustmentList[index].accountNumber = this.inventoryRowData.inventoryAdjustmentList[index].sapAccountNumber == "" ? this.inventoryRowData.inventoryAdjustmentList[index].accountNo : this.inventoryRowData.inventoryAdjustmentList[index].sapAccountNumber;
    });
    if (this.inventoryRowData && this.inventoryRowData.tabName == 'facilityInventory') {
      this.dialogTitle = 'View Inventory';
      this.inventoryRowData.inventoryAdjustmentList.length > 0 ? this.noDataFlag = false : this.noDataFlag = true;
      this.dataSource = new MatTableDataSource(this.inventoryRowData.inventoryAdjustmentList);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      
    //Delete after flag added from server side
    if (this.inventoryRowData.inventoryAdjustmentList && this.inventoryRowData.inventoryAdjustmentList.length > 0) {
      this.inventoryRowData.inventoryAdjustmentList.forEach((value) => {
        value.houseAccFlag = false;
        this.totalOnHand += value.inventoryOnHand;
      });
      this.inventoryRowData.inventoryAdjustmentList[0].houseAccFlag = true;
    }
    //Delete after flag added from server side

    }
    else {
      //this.displayedColumns = ['accountNumber', 'accountName', 'inventoryOnHand', 'inventoryOnOrder', 'quantityReserved'];
      this.dialogTitle = 'Customer Facility - Inventory on Hand';
      this.inventoryRowData.length > 0 ? this.noDataFlag = false : this.noDataFlag = true;
      this.dataSource = new MatTableDataSource(this.inventoryRowData.inventoryAdjustmentList);
    }

    this.addEditInventoryForm = this.formBuilder.group({
      'newOnHandValue': [null],
      'adjustValue': [null]
    });
  }
  /*********************Lifecyle Methods*********************/
  /*********************helper Methods*********************/
  
  
  /*********************helper Methods*********************/
  /*********************Component Methods*********************/

  onCloseDialog() {
    this.dialogRef.close();
  }
  /*********************Component Methods*********************/


  /*********************Private Methods*********************/

  /*********************Private Methods*********************/
}
