import * as _moment from 'moment';
import 'moment-timezone';
import { DateFormat, Regex } from 'src/app/core/constants/app.constant';

const moment = _moment;

export class CommonControls {
    static highlightMenu(menus, currentIndex) {
        menus.forEach((menu) => {
            menu.config.highlight = false;
        });
        menus[currentIndex].config.highlight = !menus[currentIndex].config.highlight;
        return menus;
    }

    static highlightMenuFromDash(menus: any, id: string) {
      let index;
      menus.forEach((menu) => {
          menu.config.highlight = false;
          if(menu.id == id) {
            index = menu.menuIndex;
            // DEBUG: console.log(index);
          }
      });
      menus[index].config.highlight = !menus[index].config.highlight;
      return menus;
    }

    static numericOnly(val) {
        return Regex.NumericOnly.test(val);
    }

    static numericOnlyFractional(val) {
        return Regex.NumericOnlyFractional.test(val);
    }

    static alphaAndNumeric(val) {
        return Regex.AlphaAndNumeric.test(val);
    }

    static aplhaNumericOnly(val) {
        return Regex.AlphaNumericOnly.test(val);
    }

    static alphaOnly(val) {
        return Regex.AlphaOnly.test(val);
    }

    static alphaNumericAndSpecial(val) {
        return Regex.AlphaNumericAndSpecial.test(val);
    }

    static convertGMTtoLocalTZ(date: any) {
        let d = new Date(date + ' UTC');
        const timezone = moment.tz.guess();
        let dateObj = moment.tz(d, timezone).format();
        let convertedDate = new Date(dateObj);
        return convertedDate;
    }

    static numbersWithHyphen(value) {
        const strVal = value.toString();
        const a = strVal.replace(Regex.NumbersWithHyphen, "$1-$2-$3");
        return a;
    }

    static currentTimeZone() {
        const timeZone = moment.tz.guess();
        const timeZoneOffset = new Date().getTimezoneOffset();
        const timeZoneName = moment.tz.zone(timeZone).abbr(timeZoneOffset);
        return timeZoneName;
    }

    static revertNDC(val) { // Helper method to change NDC number
        const strVal = val.replace(Regex.removehHyphen, "");
        return parseInt(strVal);
    }

    static checkTodaysDate(date) {
        let today = moment().format(DateFormat.MMDDYYYY);
        let selectedDate = moment(date).format(DateFormat.MMDDYYYY);
        if (selectedDate == today) {
            return true;
        } else {
            return false;
        }
    }

    static dateSorting(arrObj: any) {
        arrObj.sort((val1: any, val2: any) => {
            return <any>moment(val2.importDate) - <any>moment(val1.importDate)
        })
        return arrObj;
    }
}
