import { Component } from '@angular/core';

import { AppMessages } from '../../constants/app.constant';

@Component({
  selector: 'wbd-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
})
export class ErrorComponent {
  /*********************Properties*********************/
  readonly config = {
    message: AppMessages.GenericErrorMessage,
  };
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor() {}
  /*********************Constructor*********************/
}
