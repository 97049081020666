import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  CanActivateChild
} from '@angular/router';

import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {
  
  /*********************Constructor*********************/
  constructor(
    // private authService: AuthService,
    // private routeUtils: RouteUtilService,
    // private userService: UserService,
    // private appService: AppService
  ) { }
  /*********************Constructor*********************/

  /*********************Implementation Methods*********************/

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean | UrlTree {
    // if (this.appService.isUnderMaintenance()) {
    //   this.routeUtils.navigate(ModuleRoutes.Maintenance.Url);
    //   return false;
    // }
    return this.isAuthenticated();
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    return this.isAuthenticated();
  }

  /*********************Implementation Methods*********************/

  /*********************Private Methods*********************/

  private isAuthenticated() {
    // return this.authService
    //   .isAuthenticated()
    //   .then(authResponse => {
    //     if (!authResponse.authenticated.all) {
    //       // this.modal.dismissAll();
    //       this.routeUtils.redirectToLogin();
    //     }

    //     return Promise.resolve(authResponse.authenticated.all);
    //   })
    //   .catch(error => {
    //     console.error('Error while verifying user authentication:' + error);
    //     return Promise.reject(false);
    //   });
    return true;
  }

  

  /*********************Private Methods*********************/
}
