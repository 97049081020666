import { Component, OnInit } from '@angular/core';

import { RouteUtilService } from 'src/app/shared/utils/route-util.service';

import { dashboardMenuOption, menuOption } from 'src/app/core/constants/nav-constant';

import { CommonControls } from 'src/app/shared/common-control/common-controls';
import { UserService } from 'src/app/core/services/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  /*********************Properties*********************/
  menuOption = menuOption;
  menuOptionDisplay;
  dashboardMenuOption = dashboardMenuOption;
  dashboardMenuOptionDisplay: any;
  Url: any;
  Id: any;

  rolePermissionsDetails: any;
  loggedinUserDetails: any;
  roleAssignedFlag: boolean;

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private routeUtils: RouteUtilService,
    private userService: UserService
    // private appService: AppService,
  ) {
  }
  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit() {
    this.dashboardMenuOptionDisplay = this.dashboardMenuOption;

    setTimeout(() => {
      this.loggedinUserDetails = this.userService.setRolePermissionDetails();
      if (this.loggedinUserDetails.rolePermissions[0].permission.length > 0) {
        this.dashboardMenuOptionDisplay = this.setMenuPermissions(this.loggedinUserDetails, this.dashboardMenuOption);
      }
      else {
        this.dashboardMenuOptionDisplay = this.dashboardMenuOption;
      }
    }, 50);

  }
  /*********************Lifecyle Methods*********************/

  /*********************Component Methods*********************/
  onMaindashboardMenuClicked(currentIndex: number, id: string) {
    // setTimeout(() => {
    //   console.log(this.routeUtils.activeUrl);
    // }, 500);

    this.Url = this.dashboardMenuOptionDisplay[currentIndex].url;
    this.menuOptionDisplay = this.menuOption;
    if (this.Url) {
      this.routeUtils.navigate(this.Url);
      this.menuOptionDisplay = CommonControls.highlightMenuFromDash(this.menuOptionDisplay, id);
    }
    else {
      // this.routeUtils.navigate('/logout');
    }
  }

  setMenuPermissions(loggedinUserDetails, menuOptions) {
    let menus = [];
    if (loggedinUserDetails.rolePermissions) {
      this.roleAssignedFlag = true;
      menuOptions.forEach(data => {
        loggedinUserDetails.rolePermissions[0].userEmail = loggedinUserDetails.userDetails.email;
        data.config.permissionsList.push(loggedinUserDetails.rolePermissions[0]);
        loggedinUserDetails.rolePermissions[0].permission.forEach(element => {
          if (data.config.permissionNum.includes(element.permissionNum) || data.config.permissionNum.length == 0) {
            menus.push(data);
          }
        });
      });
      menus.sort((x, y) => (x.menuIndex > y.menuIndex ? 1 : -1));
      return [...new Set(menus)];
    }
    else {
      this.roleAssignedFlag = false;
      return menus;
    }

  }
  /*********************Component Methods*********************/
}
