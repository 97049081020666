import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { saveReplenOrderDetails, replenishmentDetailList } from '../../inventory';
import { InventoryService } from '../../inventory.service';
import { ToastService } from 'src/app/shared/utils/toast.service';

@Component({
  selector: 'app-modal-dialog-confirm-replen',
  templateUrl: './modal-dialog-confirm-replen.component.html',
  styleUrls: ['./modal-dialog-confirm-replen.component.scss']
})
export class ModalDialogConfirmReplenComponent implements OnInit {

  /*********************Properties*********************/
  dialogTitle: string;
  accountNum: string;
  accName: string;
  replenOrderDetails: saveReplenOrderDetails;
  replenDetailList: replenishmentDetailList;
  /*********************Properties*********************/
  /*********************Component Methods*********************/
  constructor(
    public dialogRef: MatDialogRef<ModalDialogConfirmReplenComponent>,
    private inventoryService: InventoryService,
    private toastr: ToastService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }

  ngOnInit(): void {
    this.dialogTitle = "Confirm Replenishment Order";
    this.accountNum = this.data.accountNumber;
    this.accName = this.data.accName;
    this.replenOrderDetails = {
      "rplHdrId": this.data.rplnshOrderHdrId,
      "poNum": this.data.poNumber,
      "action": null,
      "replenishmentDetailList": []
    };
  }

  saveReplenOrderDetails(){
    this.replenOrderDetails = {
      "rplHdrId": this.data.rplnshOrderHdrId,
      "poNum": this.data.poNumber.value,
      "action": this.data.action,
      "replenishmentDetailList": []
    };
    this.data.updatedIndices.forEach((value:any ,index: number) => {
      this.replenDetailList = {
        "cin": null,
        "ndc": null,
        "srcItemNum": null,
        "description": null,
        "orderQty": null
      };
      this.replenDetailList.cin = this.data[value].cin;
      this.replenDetailList.ndc = this.data[value].ndc;
      this.replenDetailList.srcItemNum = this.data[value].srcItemNum;
      this.replenDetailList.description = this.data[value].itemDescription;
      this.replenDetailList.orderQty = this.data[value].orderQty.toNumber();
      this.replenOrderDetails.replenishmentDetailList.push(this.replenDetailList);
    });
    this.inventoryService.saveReplenOrderDetails(this.replenOrderDetails).subscribe((response: any) => {
      if (response && !response.hasError) {
        this.dialogRef.close('save');
        this.toastr.success("Order successfully approved");
      }
    });
  }
  /*********************Component Methods*********************/
  /*********************Dialog Methods*********************/
  onCloseDialog() {
    this.dialogRef.close('cancel');
  }
  /*********************Dialog Methods*********************/
}
