import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IReport, SearchCriteria } from './report';

import { UserService } from 'src/app/core/services/user.service';
import { ReportsService } from './reports.service';
import { AppService } from 'src/app/app.service';
import { Subject } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { PrintService } from 'src/app/shared/utils/print/print.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  /*********************Properties*********************/
  reports: IReport[] = this.reportsService.reportsList;
  breakpoint: number = 5; //based on window size assign breakpoint value;
  resonCodeList: any = [];
  reportNameList: any;
  report: any;
  reportNameDetails: any[] = [];
  reportFilterSearchForm: FormGroup;
  reportData: [];
  tableHeaders: Array<string>;
  dialogTitle: string;
  disableSearchFlag: boolean = true;
  noDataFlag: boolean = false;
  allAccountsFlag: boolean = false;
  accountNum: string = '';
  enableFlag: boolean = false;
  searchCriteria: SearchCriteria = {
    accountNumber: null,
    assignedAccounts: null,
    cin: null,
    ndc: null,
    searchFlag: true,
    searchText: null
  }
  accountSelectVal: any;

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('printSection') printSection: ElementRef;

  private onDestroy = new Subject<void>();
  private subscription = {
    accDetails: null,
    assignedAccountList: null,
  };
  assignedAccountList: any;

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private reportsService: ReportsService,
    private appService: AppService,
    public printService: PrintService,
    private userService: UserService,
    private formBuilder: FormBuilder,
  ) {
  }
  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit() {
    this.subscription.assignedAccountList = this.appService.assignedAccountList$.subscribe(
      assignedAccountList => {
        this.assignedAccountList = assignedAccountList;
      }
    );

    this.reportNameDetails = this.getReportNameOptions();

    //Form Initialization
    this.reportFilterSearchForm = this.formBuilder.group({
      'reportType': [null,[Validators.required]],
      'accountSelect': [null,[Validators.required]]
    });

    this.reportFilterSearchForm.get("reportType").valueChanges.subscribe(val => {
      this.reportFilterSearchForm.get("accountSelect").reset();
      this.disableSearchFlag = val === 'Current Formulary'? false: true;
    });

    this.reportFilterSearchForm.get("accountSelect").valueChanges.subscribe(val => {
      this.accountSelectVal = val;
      this.disableSearchFlag = false;
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.subscription.assignedAccountList.unsubscribe();
  }
  /*********************Lifecyle Methods*********************/
  /*********************helper Methods*********************/

  public setTableProperties() {
    //check and set headers based on report name
    this.tableHeaders = this.reports[0].tableHeaders;
  }

  public setReportData(data: any) {
    this.setTableProperties();
    this.reportData = data ? data : [];
    this.dataSource = new MatTableDataSource(this.reportData);
  }

  /*********************helper Methods*********************/
  /*********************Component Methods*********************/
  onClearSearchResults() {
    this.resetSearchObj();
    this.reportFilterSearchForm.reset();
    this.gridReset();
    this.enableFlag = false;
    this.disableSearchFlag = true;
    this.noDataFlag = false;
  }

  onExportReport(reportType: string) {
    this.exportReportDetails(reportType, this.searchCriteria);
  }

  onPrintReport() {
    let accountNumber: any;
    if (this.report == 'Formulary by Customer') {
      accountNumber = this.allAccountsFlag ? 'All Accounts' : this.accountSelectVal.sapAccountNumber;
    } else {
      accountNumber = '';
    }
    this.printService
      .printDocument(this.report, this.printSection.nativeElement.innerHTML, accountNumber);
  }

  onReportClick() {
    this.report = this.reportFilterSearchForm.controls['reportType'].value;
    if (this.report == 'Current Formulary') {
      this.searchCriteria = {
        accountNumber: null,
        assignedAccounts: null,
        cin: null,
        ndc: null,
        searchFlag: true,
        searchText: null
      }
      this.getFormularyData();
    }
    else if (this.report == 'Formulary by Customer') {
      this.allAccountsFlag = this.accountSelectVal.accNumAndName ? this.accountSelectVal.accNumAndName === 'All Accounts' : false;

      this.searchCriteria = {
        accountNumber: this.accountSelectVal.sapAccountNumber ? this.accountSelectVal.sapAccountNumber.trim() : 'All',
        assignedAccounts: this.allAccountsFlag ? this.assignedAccountList[0].assignedAccounts : null,
        cin: null,
        ndc: null,
        searchFlag: true,
        searchText: null
      }
      this.accountNum = this.allAccountsFlag ? 'All Accounts' : '_' + this.accountSelectVal.sapAccountNumber;
      this.getFormularyData();
    }
    else {
      this.noDataFlag = true;
    }
  }

  getFormularyData() {
    this.reportsService.searchFormularyData(this.searchCriteria).subscribe((response: any) => {
      if (response && !response.hasError) {
        this.setReportData(response.data.formulary);
        this.refreshGrid(response.data.formulary);
        this.enableFlag = true;
      }
      else {
        this.noDataFlag = true;
        this.enableFlag = false;
      }
    });
  }

  getReportNameOptions() {
    this.reportNameList = this.userService.getStaticDataDetails();
    Object.keys(this.reportNameList).forEach((index) => {
      if (this.reportNameList[index].staticDataCategory == "Report_Name") {
        this.reportNameDetails.push(this.reportNameList[index]);
      }
    });
    return this.reportNameDetails;
  }


  /*********************Component Methods*********************/

  /*********************Init/Reset Methods*********************/
  private resetSearchObj() {
    this.searchCriteria = {
      accountNumber: null,
      assignedAccounts: null,
      cin: null,
      ndc: null,
      searchFlag: true,
      searchText: null
    };
  }

  private gridReset() {
    this.reportData = [];
    this.tableHeaders = [];
    this.refreshGrid(this.reportData);
  }

  private refreshGrid(obj:any) {
    this.dataSource = new MatTableDataSource(obj);
    if (obj && obj.length > 0) {
      this.noDataFlag = false;
    }
    else {
      this.noDataFlag = true;
    }
  }
  /*********************Init/Reset Methods*********************/
  /*********************Private Methods*********************/
  private exportReportDetails(reportType: string, searchCriteria: SearchCriteria) {
    this.reportsService.export(reportType, searchCriteria).subscribe((response) => {
      if (response && !response.hasError) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        var file = document.createElement("a");
        this.accountNum = reportType == 'Formulary by Customer' ? '_' + this.accountNum : '';
        file.download =
          // "WBD_" +
          reportType + ' Report' + this.accountNum +
        // "_" +
        // new Date() +
        ".xlsx";
        file.href = url;
        file.click();
      }
    });
  }
  /*********************Private Methods*********************/

}
