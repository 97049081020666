import { trigger, state, style, transition, animate } from '@angular/animations';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { HeldQueueData, HeldQueueDetailsData, ReleaseDeleteDetail, ReleaseOrderDetail, searchDataCriteria } from './held-queue';


import * as _moment from 'moment';
import 'moment-timezone';
import { distinctUntilChanged, Subject } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { DateFormat } from 'src/app/core/constants/app.constant';
import { HeldQueueService } from './held-queue.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { SelectionModel } from '@angular/cdk/collections';
import { MatDialog } from '@angular/material/dialog';
import { MatDialogHeldQueueComponent } from './mat-dialog-held-queue/mat-dialog-held-queue.component';
import { CommonControls } from 'src/app/shared/common-control/common-controls';
const moment = _moment;

@Component({
  selector: 'app-held-queue',
  templateUrl: './held-queue.component.html',
  styleUrls: ['./held-queue.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class HeldQueueComponent implements OnInit, OnDestroy {
  /*********************Properties*********************/

  displayedColumns: string[] = ["expand", "accNum", "accName", "batchId", "groupId", "addDate", "displayReason", "shipToPatient", "groupLineTotal", "groupHeaderTotal", "statusWeight"];
  searchForm: FormGroup;
  disableSearchFlag: boolean = true;
  searchObj: searchDataCriteria = {
    "startDate": null,
    "endDate": null,
    "shipToAccId": null,
    "batchId": null
  };
  minDate: any;
  hideFlag: boolean = true;
  dataSource: MatTableDataSource<HeldQueueData>;
  heldQueueData: any[] = [];
  noDataFlag: boolean = true;
  expandedElement: any | null;

  @ViewChild('heldSort', { read: MatSort }) heldSort: MatSort;
  @ViewChild('heldPaginator', { read: MatPaginator }) heldPaginator: MatPaginator;

  @ViewChild('heldDetailSort', { static: false }) heldDetailSort: MatSort;
  @ViewChild('heldDetailPaginator', { static: false }) heldDetailPaginator: MatPaginator;
  
  innerDisplayedColumns: string[] = ["select", "foNum", "rxNum", "shipLocation", "patientName", "ndc", "srcItemNum", "cin", "description", "rxQtyDspns", "status", "detailReason"];
  dataSourceGroupDetail: MatTableDataSource<any>;
  selection = new SelectionModel<Element>(true, []);
  groupDetails: Array<HeldQueueDetailsData> = [];
  disableReleaseBtnFlag: boolean = true;
  noDetailsDataFlag: boolean = true;
  releaseDeleteOrderObj: ReleaseDeleteDetail = {
    "foDetailLineNumber": [],
    "rxNum": [],
    "action": 'Disp_Released',
    "reason": 'Order Released',    
  };
  releaseOrderDetails: ReleaseOrderDetail = {
    "groupID": null,
    "numofGrpOrders": null,
    "numofLinesGrpOrders": null
  }

  private onDestroy = new Subject<void>();
  private subscription = {
    accDetails: null,
    userFlag: null
  };
  accountDetail: any;

  constructor(
    private formBuilder: FormBuilder,
    private appService: AppService,
    private heldQueueService: HeldQueueService,
    private cd: ChangeDetectorRef,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.init();

    this.subscription.accDetails = this.appService.accDetails$.subscribe(
      accDetail => {
        this.accountDetail = accDetail;
        this.disableSearchFlag = accDetail ? false : true;
      }
    );
  }


  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.subscription.accDetails.unsubscribe();
  }


  public sortColumnsByValues(data: Array<HeldQueueData>) {
    if (data.length) {
      data.sort((a, b) => a.addDate.localeCompare(b.addDate));
      return data;
    }
    else {
      return [];
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  public isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSourceGroupDetail.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  public masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSourceGroupDetail.data.forEach(row => this.selection.select(row));
  }

  public toggleRow(element: HeldQueueData, state: string) {
    //Reset checkbox on load
    this.selection.clear();

    if (this.expandedElement === element) {
      this.expandedElement = null;
    }
    else {
      this.expandedElement = element;
    }

    if (state === 'expand') {
      this.getGroupDetails(element.groupId);
    }
    else {
      this.dataSourceGroupDetail = null;
    }

    //expand icon setting
    this.heldQueueData.forEach(el => {
      if (this.expandedElement === element) {
        el.toggleItem = false;
        element.toggleItem = true;
      }
      else {
        el.toggleItem = false;
      }
    });
  }

  onSearchClick() {
    let endDate = new Date(this.searchForm.value.toDate);
    let endDateAdd = endDate.setDate(endDate.getDate() + 1);
    const timezone = moment.tz.guess();
    let edateObj = moment.parseZone(timezone).utcOffset() > 0 ? moment.tz(endDateAdd, timezone).format() : moment.tz(endDate, timezone).format()
    let sdateObj = moment.tz(new Date(this.searchForm.value.fromDate), timezone).format();
    let sconvertedDate = new Date(sdateObj);
    let econvertedDate = new Date(edateObj);
    this.searchObj = {
      "startDate": this.searchForm.value.fromDate ? sconvertedDate : moment("0001-01-01").format(DateFormat.YYYYMMDD),
      "endDate": this.searchForm.value.toDate ? econvertedDate : moment("9999-12-31").format(DateFormat.YYYYMMDD),
      "shipToAccId": this.accountDetail.accountNumber == 'All' ? this.accountDetail.assignedAccIds : [this.accountDetail.accountId],
      "batchId": this.searchForm.value.manifestId ? this.searchForm.value.manifestId : ""
    }
    this.getHeaderData(this.searchObj);
  }

  onClearSearchResults() {
    this.resetSearchObj();
    this.initForm();
    this.gridReset();
  }

  getHeaderData(searchObj: searchDataCriteria) {
    this.heldQueueService.getHeldQueueHeaderData(searchObj).subscribe((response: any) => {
      if (!response.hasError) {
        this.heldQueueData = this.sortColumnsByValues(response.data);
        this.heldQueueData.forEach((data) => {
          if(data.addDate){
            data.addDateFormat = moment(CommonControls.convertGMTtoLocalTZ(data.addDate)).format(DateFormat.MMDDYYYYHHmmA);
          }
        })
        this.refreshGrid(this.heldQueueData);
      }
    });
  }

  getGroupDetails(groupId: string) {
    this.heldQueueService.getHeldQueueGroupDetails(groupId).subscribe((response: any) => {
      if (!response.hasError) {
        this.groupDetails = response.data;
        this.disableReleaseBtnFlag = !this.groupDetails.every(item => item.purchBfr === true);
        this.groupDetails.forEach((detLine) => {
          detLine.ndcTxt = CommonControls.numbersWithHyphen(detLine.ndc);
        })
        this.refreshInnerGrid(this.groupDetails);
      }
    });
  }

  /* istanbul ignore next */
  onDeleteClick(selectedItems: SelectionModel<any>, action:string) {
    let foDetailNumList: Array<any> = [];
    let RxNumList: Array<string> = [];
    selectedItems.selected.forEach((item) => {
      RxNumList.push(item.rxNum);
      foDetailNumList.push(item.lineNum);
    });
    this.releaseDeleteOrderObj = {
      "foDetailLineNumber": foDetailNumList,
      "rxNum": RxNumList,
      "action": 'Disp_Deleted',
      "reason": 'Order Deleted'
    }
    this.openDialog(this.releaseDeleteOrderObj, action);
  }

  onReleaseClick(rowDetails: HeldQueueData, action:string) {
    let foDetailNumList: Array<any> = [];
    this.groupDetails.forEach((item) => {
      foDetailNumList.push(item.lineNum);
    });
    this.releaseDeleteOrderObj = {
      "foDetailLineNumber": foDetailNumList,
      "action": 'Disp_Released',
      "reason": 'Order Released'
    }
    this.releaseOrderDetails = {
      "groupID": rowDetails.groupId,
      "numofGrpOrders": rowDetails.groupHeaderTotal,
      "numofLinesGrpOrders":  rowDetails.groupLineTotal
    }
    this.openDialog(this.releaseDeleteOrderObj, action, this.releaseOrderDetails);
  }

  public openDialog(releaseDeleteOrder: ReleaseDeleteDetail,  action: string, releaseOrderDetails?: ReleaseOrderDetail) {
    const dialogRef = this.dialog.open(MatDialogHeldQueueComponent, {
      data: {releaseDeleteOrder: releaseDeleteOrder, releaseOrderDetails:releaseOrderDetails, action:action},
      width: '40%',
      autoFocus: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result == "deleterelease"){
        this.getHeaderData(this.searchObj);
      }
    });
  }

  private init() {
    this.initForm();
  }

  private initForm() {
    //Form Initialization
    this.searchForm = this.formBuilder.group({
      'manifestId': [null],
      'statusName': [null],
      'fromDate': [null],
      'toDate': [null]
    });

    this.searchForm.get("fromDate").valueChanges.subscribe(dateVal => {
      this.minDate = dateVal;
    });  
  }

  private gridReset() {
    this.heldQueueData = [];
    this.refreshGrid(this.heldQueueData);
  }

  private refreshGrid(obj: any) {
    this.dataSource = new MatTableDataSource(obj);
    this.dataSource.paginator = this.heldPaginator;
    this.dataSource.sort = this.heldSort;

    if (obj && obj.length > 0) {
      this.noDataFlag = false;
    }
    else {
      this.noDataFlag = true;
    }
  }

  private refreshInnerGrid(obj: Array<HeldQueueDetailsData>) {
    this.dataSourceGroupDetail = new MatTableDataSource(obj);
    this.cd.detectChanges();
    this.dataSourceGroupDetail.sort = this.heldDetailSort;
    this.dataSourceGroupDetail.paginator = this.heldDetailPaginator;

    if (obj && obj.length > 0) {
      this.noDetailsDataFlag = false;
    }
    else {
      this.noDetailsDataFlag = true;
    }
  }

  private resetSearchObj() {
    this.searchForm.reset();
    this.searchObj = {
      "startDate": null,
      "endDate": null,
      "shipToAccId": null,
      "batchId": null
    };
  }
  /*********************Init/Reset Methods*********************/

}