import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as _moment from 'moment';
import 'moment-timezone';
// tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment } from 'moment';
import { ActivatedRoute } from '@angular/router';

import { ModalDialogFormularyComponent } from './modal-dialog-formulary/modal-dialog-formulary.component';
import { CommonControls } from 'src/app/shared/common-control/common-controls';

import { FormularyService } from './formulary.service';
import { FormularyData, searchFormularyCriteria, searchAccountCriteria } from './formulary';
import { DateFormat } from 'src/app/core/constants/app.constant';
import { UserService } from 'src/app/core/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

const moment = _moment;

@Component({
  selector: 'app-formulary',
  templateUrl: './formulary.component.html',
  styleUrls: ['./formulary.component.scss'],
  providers: []
})

export class FormularyComponent implements OnInit {
  /*********************Properties*********************/
  @ViewChild('searchInput', { static: true }) searchInput: any;
  clearSearchFlag: boolean = false;
  disableSearchFlag: boolean = true;
  searchObj: searchFormularyCriteria = {
    "cin": null,
    "ndc": null,
    "searchText": null,
    "searchFlag": false
  };
  searchForm: FormGroup;
  formularyOnlyCheck: boolean = false;
  includeFormularyFilterFlag: boolean = false;

  displayedColumns: string[] = ['ndc', 'cin', 'srcItemNum', 'description', 'tradeName', 'genericName', 'supplierName', 'supplierId', 'rxIndicatorString', 'genIndicatorString','formName', 'gcn', 'baseUom', 'medTypeString', 'startDate', 'endDate', 'modifiedBy', 'modifiedDate', 'actions'];
  dataSource: MatTableDataSource<FormularyData>;
  noDataFlag: boolean = true;

  medTypeData: any;
  permissionsData: any;
  fPermissionNum: any;
  formularyData: FormularyData[];
  formularyDataTempObj: FormularyData[];
  inventoryAccountData: any;
  permissionAssignment: any = {
    viewFormulary: false,
    editFormulary: false
  };

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  private subscription = { activeRoute: null };

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private formularyService: FormularyService,
    private activatedroute: ActivatedRoute,
    private userService: UserService,
    // private appComponent: AppComponent,
  ) { }
  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit(): void {
    this.subscription.activeRoute = this.activatedroute.data.subscribe(data => {
      this.fPermissionNum = data.permissionNum;
      this.permissionsData = this.userService.getUserPermissions();
    });
    this.permissionsData.forEach((number,index) => {
      if (this.permissionsData[index].permissionNum == this.fPermissionNum[0]) {
        /* istanbul ignore else */
        if (this.permissionsData[index].permissionNam = "View Formulary") {
          this.permissionAssignment.viewFormulary = true;
        }
      }
      else if (this.permissionsData[index].permissionNum == this.fPermissionNum[1]) {
        /* istanbul ignore else */
        if (this.permissionsData[index].permissionNam = "Update Formulary") {
          this.permissionAssignment.viewFormulary = true;
          this.permissionAssignment.editFormulary = true;
        }
      }
   });

    this.medTypeData = this.userService.getMedTypeDetails();
  
    this.init();

    //Form Initialization
    this.searchForm = this.formBuilder.group({
      'searchText': [null, [Validators.required]],
      'formularyFilterCheckbox': ''
    });

  }
  /*********************Lifecyle Methods*********************/

  /*********************Helper Methods*********************/

  public changeDataMethod(data) { // Helper method to change NDC number
    data.forEach((value) => {
      // value.ndc = CommonControls.numbersWithHyphen(value.ndc);
      if (value.formularyFlag) {
        const sDate = value.startDate + ' ' + value.startTime;
        const eDate = value.endDate + ' ' + value.endTime;
        value.startDate = moment(CommonControls.convertGMTtoLocalTZ(sDate)).format(DateFormat.MMDDYYYY);
        value.startTime = moment(CommonControls.convertGMTtoLocalTZ(sDate)).format(DateFormat.HHmmss);
        value.endDate = moment(CommonControls.convertGMTtoLocalTZ(eDate)).format(DateFormat.MMDDYYYY);
        value.endTime = moment(CommonControls.convertGMTtoLocalTZ(eDate)).format(DateFormat.HHmmss);
      }
      if (value.rxIndicator === true) {
        value.rxIndicatorString = 'RX';
      }
      else {
        value.rxIndicatorString = 'OTC';
      }
      if (value.genIndicator === true) {
        value.genIndicatorString = 'Generic';
      }
      else {
        value.genIndicatorString = 'Brand';
      }

      // Change med type number to string type
      if (this.medTypeData){
        this.medTypeData.forEach((number,index) => {
          if (value.medType == this.medTypeData[index].medTypeCode) {
            value.medTypeString = this.medTypeData[index].medType;
          }
        });
      }
    });
    return data;
  }

  /*********************Helper Methods*********************/

  /*********************Component Methods*********************/
  onCopyPaste(e: ClipboardEvent) {//ClipboardEvent to capture cut copy paste
    let val = {
      value: null
    }
    val.value = e.clipboardData.getData('text/plain');
    this.onSearchFilter(val);
  }
  
  onSearchFilter(val) {
    this.clearSearchFlag = true;
    this.searchForm.controls.searchText.setErrors(null);
    if (val.value && val.value.length === 11 && CommonControls.numericOnly(val.value)) {//enter & search product using NDC code ( exactly 11 digits numeric)
      this.resetSearchObj();
      this.searchObj.ndc = val.value;
      this.searchObj.searchFlag = this.formularyOnlyCheck;
    }
    else if (val.value && CommonControls.numericOnly(val.value)) {//enter & search product using CIN code (numeric)
      this.resetSearchObj();
      this.searchObj.cin = parseInt(val.value);
      this.searchObj.searchText = val.value;
      this.searchObj.searchFlag = this.formularyOnlyCheck;
    }
    else if (val.value && val.value.length > 2 && CommonControls.aplhaNumericOnly(val.value)) {//enter & search product using Generic/Brand Name (starts with 3 char- alpha)
      this.resetSearchObj();
      this.searchObj.searchText = val.value;
      this.searchObj.searchFlag = this.formularyOnlyCheck;
    }
    else {//default condition
      // this.resetSearchObj();
      // this.searchObj.searchFlag = this.formularyOnlyCheck;
      this.disableSearchFlag = false;
      this.searchForm.controls.searchText.setErrors({ InvalidSearch: true });
    }

    //when search input is empty hide clear icon
    if (val && val.value == '') {
      this.clearSearchFlag = false;
      this.includeFormularyFilterFlag = true;
    }
    else {
      this.includeFormularyFilterFlag = false;
    }

    //search button validation
    if (val && val.value && val.value.length > 2) {
      this.disableSearchFlag = false;
    }
    else {
      this.disableSearchFlag = true;
    }
  }

  onSearchClick() {
    if (this.searchObj) {
      this.formularyService.searchFormularyData(this.searchObj).subscribe((response: any) => {
        this.formularyData = !response.hasError ? this.changeDataMethod(response.data.formulary) : [];
        this.refreshGrid(this.formularyData);
      });
    }
  }

  onClearSearch() {
    // this.resetSearchObj();
    this.searchForm.get('searchText').setValue('');
    this.clearSearchFlag = false;
    this.disableSearchFlag = true;
    this.includeFormularyFilterFlag = true;
  }

  onClearSearchResults() {
    this.resetSearchObj();
    this.searchForm.get('formularyFilterCheckbox').setValue('');
    this.formularyFilter(false);
    this.searchInput.nativeElement.focus();
    this.onClearSearch();
    this.gridReset();
  }

  formularyFilter(filterValue: boolean) {
    this.formularyOnlyCheck = filterValue;
    if (this.includeFormularyFilterFlag == true) {
      this.resetSearchObj();
      this.searchObj.searchFlag = filterValue;
      this.onSearchClick();
    }
    else {
      this.searchObj.searchFlag = filterValue;
      this.onSearchClick();
    }
  }

  onCellClicked(formularyRow) {
    // if (formularyRow) {
    let value: searchAccountCriteria = {
      "ndc": null, "cin": formularyRow.cin, "searchText": null, "inventory": true
    }
    this.formularyService.searchInventoryData(value).subscribe((response: any) => {
      this.inventoryAccountData = !response.hasError ? response.data : [];
      this.inventoryAccountData.ndcText = formularyRow.ndcText;
      this.inventoryAccountData.cin = formularyRow.cin;
      this.openDialog(this.inventoryAccountData, false, 'AccountInfo');
    });
    // }
  }
  /*********************Component Methods*********************/

  /*********************Dialog Methods*********************/
  public openDialog(formularyRow: any, deleteFlag: boolean, typeOfData: string) {
    formularyRow.delete = deleteFlag;
    formularyRow.typeOfData = typeOfData;
    (formularyRow.inventoryAdjustmentList || []).forEach((data,index) => {
      formularyRow.inventoryAdjustmentList[index].sapAccountNumber = formularyRow.inventoryAdjustmentList[index].sapAccountNumber == "" ? formularyRow.inventoryAdjustmentList[index].accountNo : formularyRow.inventoryAdjustmentList[index].sapAccountNumber;
    });
    let width = typeOfData == 'FormularyInfo' ? '45%' : '70%';
    const dialogRef = this.dialog.open(ModalDialogFormularyComponent, {
      data: formularyRow,
      width: width,
      autoFocus: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result == 'save') {
        setTimeout(() => {
          this.formularyService.searchFormularyData(this.searchObj).subscribe((response: any) => {
            this.formularyDataTempObj = this.changeDataMethod(response.data.formulary);
            this.refreshGrid(this.formularyDataTempObj);
          });
        }, 500);
      }
    });
  }
  /*********************Dialog Methods*********************/

  /*********************Init/Reset Methods*********************/
  private gridReset() {
    this.formularyData = [];
    this.refreshGrid(this.formularyData);
  }

  private init() {
    this.searchInput.nativeElement.focus(); // onload set focus in search input
    this.includeFormularyFilterFlag = true;
  }

  private refreshGrid(obj) {
    this.dataSource = new MatTableDataSource(obj);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (obj && obj.length > 0) {
      this.noDataFlag = false;
    }
    else {
      this.noDataFlag = true;
    }
  }

  private resetSearchObj() {
    this.searchObj = {
      "cin": null,
      "ndc": null,
      "searchText": null,
      "searchFlag": false
    };
  }

/*********************Init/Reset Methods*********************/

}





