import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';

@Injectable({
  providedIn: 'root'
})
export class PaginationService {
  /*********************Properties*********************/
  pageSize: number = 5;
  pageIndex: number = 0;
  rowOffset: number = 0;

  /*********************Properties*********************/

  handlePageEvent(e: PageEvent) {
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex;
    this.rowOffset = this.pageIndex * this.pageSize;
    return this.rowOffset;
  }
  
}
