import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { PmsService } from '../pms.service';
import { UtilityService } from 'src/app/shared/utils/utility.service';
import { ToastService } from 'src/app/shared/utils/toast.service';
import { CustomerAccountManagementService } from '../../customer-account-management/customer-account-management.service';

import { DateFormat } from 'src/app/core/constants/app.constant';
import { ConfigSettings } from '../../customer-account-management/customer-account-management';
import { SearchAcctConfigCriteria } from '../pms';

import * as _moment from 'moment';
import 'moment-timezone';
import { CommonControls } from 'src/app/shared/common-control/common-controls';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
const moment = _moment;

@Component({
  selector: 'app-modal-dialog-pms',
  templateUrl: './modal-dialog-pms.component.html',
  styleUrls: ['./modal-dialog-pms.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ModalDialogPmsComponent implements OnInit, AfterViewInit {
  /*********************Properties*********************/
  panelOpenState = true;
  pmsForm: FormGroup;
  pmsOrderDetails: any;
  permissionsData: any;
  doseDayMandatoryFlag: boolean;
  tempData: any;
  isAddressMandatory: boolean = true;
  tdateNeedBy: any;
  accountId: any;
  adminSDateFlag: boolean;
  doseOptions: any = [
    { id: 1, value: 'TAB', name: 'TAB' },
    { id: 2, value: 'CAP', name: 'CAP' }
  ];
  sla: any;
  acctId: any;
  searchAcctConfigObj: SearchAcctConfigCriteria = {
    "accountId":null
  }
  configSettings: ConfigSettings = {
    enableBilling: null,
    customParLevel: null,
    shipDirToPtnt: null,
    sla: null,
    autoApproved: null,
    allowEditsInNavix: null,
    interfaceVersion: null,
    hoaPrintType: null
  }

  doseOptionSelected: any;

  compliancePkgOptions: any = [
    { id: 1, value: true, name: "YES" },
    { id: 2, value: false, name: "NO" }
  ];
  compliancePkgOptionSelected: any;

  doseDataMap: any = {
    dayOfWeek: [
      { name: 'MON', checked: false, disabled: false },
      { name: 'TUE', checked: false, disabled: false },
      { name: 'WED', checked: false, disabled: false },
      { name: 'THU', checked: false, disabled: false },
      { name: 'FRI', checked: false, disabled: false },
      { name: 'SAT', checked: false, disabled: false },
      { name: 'SUN', checked: false, disabled: false },
    ]
  };

  foDetailMock: any = {
    administrationTime:null,
    asNeeded: "NO",
    coreflexNumber: null,
    dosageId: null,
    dayOfWeek: this.doseDataMap.dayOfWeek,
    dosageQty: null,
    dosageSig: null,
    dosageUnit: null,
    isAssociated: true,
    rxNumber: null,
  }

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private dialogRef: MatDialogRef<ModalDialogPmsComponent>,
    private pmsService: PmsService,
    private customerAccountManagementService: CustomerAccountManagementService,
    private utility: UtilityService,
    private toastr: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit(): void {
    let orderDetails = this.data ? this.data.data : null;   
    this.permissionsData = this.data ? this.data : null;
    this.accountId = this.permissionsData ? this.permissionsData.accountDetail.accountId : null;
    this.getAccountConfigDetails(this.accountId);
    orderDetails.importedDate = moment(CommonControls.convertGMTtoLocalTZ(orderDetails.importedDate)).format(DateFormat.MMDDYYYY);
    this.pmsOrderDetails = this.filterPrescriptions(orderDetails); 
    this.formInit(this.pmsOrderDetails);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
    this.pmsOrderDetails.prescription.forEach((prescription: any, prescIndex: number) => {
      //Extra validations on admin date, date needed by 
      this.compareDates(this.pmsForm.get('admin_start_date' + prescIndex).value, this.pmsForm.get('date_needed_by' + prescIndex).value, prescIndex);
      this.compareNeedByDates(this.pmsForm.get('date_needed_by' + prescIndex).value, this.tdateNeedBy, prescIndex);
      this.pmsForm.get('admin_start_date' + prescIndex).valueChanges.subscribe(dateVal => {
        this.compareDates(dateVal, this.pmsForm.get('date_needed_by' + prescIndex).value, prescIndex);
      });
      this.pmsForm.get('date_needed_by' + prescIndex).valueChanges.subscribe(dateVal => {
        this.compareDates(this.pmsForm.get('admin_start_date' + prescIndex).value, dateVal, prescIndex);
      });
      this.pmsForm.get('date_needed_by' + prescIndex).valueChanges.subscribe(dateVal => {
        this.compareNeedByDates(dateVal, this.tdateNeedBy, prescIndex);
      });
    });
  });
  }

  /*********************Lifecyle Methods*********************/

  /*********************Helper Methods*********************/
  getAccountConfigDetails(acctId: any) {
    this.searchAcctConfigObj.accountId = acctId;
    this.customerAccountManagementService.getAccountConfigDetails(this.searchAcctConfigObj).subscribe((res: any) => {
        this.configSettings = !res.hasError ? (res.data? res.data :[]) : [];
        this.sla = this.configSettings.sla;
        this.tdateNeedBy = moment(new Date(new Date().setHours(0,0,0,0))).add(this.sla, 'days');
    });
  }

  filterPrescriptions(orderObj: any) {
    orderObj.prescription.forEach(prescription => {
      prescription.enableForNonCompliancePkg = prescription.compliancePackage;
      prescription.foDosageDetails.forEach(dose => {
        //Set time of dose
        let time = dose.administrationTime ? dose.administrationTime.toString().split(":") : null;//set default time
        dose.administrationTime = time ? moment().set({ "hour": parseInt(time[0]), "minute": parseInt(time[1]) }) : null;

        //Set dose schedule in days
        // dose.dayOfWeek = dose.dayOfWeek ? dose.dayOfWeek : 'MON|TUE|WED|THU|FRI|SAT|SUN';
        if (dose.dayOfWeek) {
          dose.dayOfWeek = dose.dayOfWeek.toString().split("|");
          dose.dayOfWeek = dose.dayOfWeek.map((str, index) => ({ name: str, checked: true, disabled: false }));
          let missingDays = this.doseDataMap.dayOfWeek.filter(o1 => !dose.dayOfWeek.some(o2 => o1.name === o2.name));
          dose.dayOfWeek = [...dose.dayOfWeek, ...missingDays];
        }
        else {
          dose.dayOfWeek = this.doseDataMap.dayOfWeek;
        }


        //Sort days by name
        const map = {
          'MON': 1,
          'TUE': 2,
          'WED': 3,
          'THU': 4,
          'FRI': 5,
          'SAT': 6,
          'SUN': 7
        };
        dose.dayOfWeek.sort((a, b) => {
          return map[a.name] - map[b.name];
        });
      });
    });
    return orderObj;
  }

  formatDataToSave(orderObj: any) {
    let formattedObj = Object.clone(orderObj);//copy objecy to avoid bugs
    //Set patient Address
    formattedObj.patient.patientAddress = {
      address1: this.pmsForm.controls['patient_address'].value,
      address2: this.pmsForm.controls['patient_address2'].value,
      city: this.pmsForm.controls['patient_city'].value,
      state: this.pmsForm.controls['patient_state'].value,
      zipCode: this.pmsForm.controls['patient_zip'].value
    }
    formattedObj.prescription.forEach((prescription: any, prescIndex: number) => {
      //Set prescription details
      prescription.rxAdministrationStartDate = prescription.enableForNonCompliancePkg ? moment(this.pmsForm.controls['admin_start_date' + prescIndex].value).format(DateFormat.YYYYMMDD): null;
      prescription.rxNeedByDate = moment(this.pmsForm.controls['date_needed_by' + prescIndex].value).format(DateFormat.YYYYMMDD);
      prescription.compliancePackage = this.pmsForm.controls['compliance_pkg_type' + prescIndex].value;

      //Set dose details
      prescription.foDosageDetails.forEach((dose, doseIndex) => {
        if(prescription.enableForNonCompliancePkg === true) {
          dose.administrationTime = moment(this.pmsForm.controls['admin_time' + prescIndex + doseIndex].value).format(DateFormat.HHmm);
          dose.dosageQty = this.pmsForm.controls['quantity' + prescIndex + doseIndex].value;
          dose.dosageUnit = this.pmsForm.controls['uom' + prescIndex + doseIndex].value;
          //Set dose schedule in days
          if (dose.dayOfWeek) {
            let days = dose.dayOfWeek.filter(d => d.checked).map(d => d.name);
            let daysString = days.join('|');
            dose.dayOfWeek = daysString.toString();
          }
          else {
            dose.dayOfWeek = '';
          }
        }
        else {
          dose.administrationTime = null;
          dose.dosageQty = null;
          dose.dosageUnit = null;
          //Set dose schedule in days
            dose.dayOfWeek = '';
        }        
      });
    });
    return formattedObj;
  }

  iterateSetData(prescIndex: number) {
    //Set patient Address
    this.pmsOrderDetails.patient.patientAddress['address1'] = this.pmsForm.controls['patient_address'].value;
    this.pmsOrderDetails.patient.patientAddress['address2'] = this.pmsForm.controls['patient_address2'].value;
    this.pmsOrderDetails.patient.patientAddress['city'] = this.pmsForm.controls['patient_city'].value;
    this.pmsOrderDetails.patient.patientAddress['state'] = this.pmsForm.controls['patient_state'].value;
    this.pmsOrderDetails.patient.patientAddress['zipCode'] = this.pmsForm.controls['patient_zip'].value;

    //Set prescription details
    this.pmsOrderDetails.prescription.forEach((prescription: any, prescIndex: number) => {
      prescription.rxAdministrationStartDate = moment(this.pmsForm.controls['admin_start_date' + prescIndex].value).format(DateFormat.YYYYMMDD);
      prescription.rxNeedByDate = moment(this.pmsForm.controls['date_needed_by' + prescIndex].value).format(DateFormat.YYYYMMDD);
      prescription.compliancePackage = this.pmsForm.controls['compliance_pkg_type' + prescIndex].value;

      prescription.foDosageDetails.forEach((dose: any, doseIndex: number) => {
        dose.selectAllDays = this.pmsForm.controls['allDays' + doseIndex + prescIndex].value;
        dose.administrationTime = this.pmsForm.controls['admin_time' + prescIndex + doseIndex].value;
        dose.dosageQty = this.pmsForm.controls['quantity' + prescIndex + doseIndex].value;
        dose.dosageUnit = this.pmsForm.controls['uom' + prescIndex + doseIndex].value;
        dose.dayOfWeek.forEach((day: any) => {
          day.checked = this.pmsForm.controls['days' + day.name + doseIndex + prescIndex].value;
        });
      });
    });
    // this.pmsOrderDetails.prescription[prescIndex].rxAdministrationStartDate = moment(this.pmsForm.controls['admin_start_date' + prescIndex].value).format(DateFormat.YYYYMMDD);
    // this.pmsOrderDetails.prescription[prescIndex].rxNeedByDate = moment(this.pmsForm.controls['date_needed_by' + prescIndex].value).format(DateFormat.YYYYMMDD);
    // this.pmsOrderDetails.prescription[prescIndex].compliancePackage = this.pmsForm.controls['compliance_pkg_type' + prescIndex].value;

    // this.pmsOrderDetails.prescription[prescIndex].foDosageDetails.forEach((dose: any, doseIndex: number) => {
    //   dose.selectAllDays = this.pmsForm.controls['allDays' + doseIndex + prescIndex].value;
    //   dose.administrationTime = moment(this.pmsForm.controls['admin_time' + prescIndex + doseIndex].value);
    //   dose.dosageQty = this.pmsForm.controls['quantity' + prescIndex + doseIndex].value;
    //   dose.dosageUnit = this.pmsForm.controls['uom' + prescIndex + doseIndex].value;
    //   dose.dayOfWeek.forEach((day: any) => {
    //     day.checked = this.pmsForm.controls['days' + day.name + doseIndex + prescIndex].value;
    //   });
    // });
  }

  checkHasError(doseDetailsArr: any) {
    let flag: boolean;
    let allDoseArr: any = [];
    doseDetailsArr.prescription.forEach(prescription => {
      prescription.foDosageDetails.forEach(dose => {
        allDoseArr.push(dose);
      });
      flag = allDoseArr.some(val => val.hasError === true);
    });
    return !flag;
  }

  resetDoseControls(prescIndex: number) {
    this.pmsOrderDetails.prescription[prescIndex].foDosageDetails.forEach((dose: any, doseIndex: number) => {
      this.pmsForm.controls['allDays' + doseIndex + prescIndex].reset();
      this.pmsForm.controls['admin_time' + prescIndex + doseIndex].reset();
      this.pmsForm.controls['quantity' + prescIndex + doseIndex].reset();
      this.pmsForm.controls['uom' + prescIndex + doseIndex].reset();
      dose.dayOfWeek.forEach((day: any) => {
        this.pmsForm.controls['days' + day.name + doseIndex + prescIndex].reset();
      });
    });
  }

  setDaysState(prescription: any, prescIndex: number) {
    prescription.foDosageDetails.forEach((dose: any, doseIndex: number) => {
      dose.dayOfWeek.forEach((day: any) => {
        dose.selectAllDays || prescription.enableForNonCompliancePkg === false ? this.pmsForm.controls['days' + day.name + doseIndex + prescIndex].disable() : this.pmsForm.controls['days' + day.name + doseIndex + prescIndex].enable();
      });
    });
  }

  compareDates(date1: any, date2: any, prescIndex: number) {
    if (moment(date1) >= moment(date2)) {
      this.pmsForm.get('admin_start_date' + prescIndex).setErrors(null);
    }
    else {
      this.pmsForm.get('admin_start_date' + prescIndex).setErrors({ PastDateErr: true });
    }
  }

  compareNeedByDates(date1: any, date2: any, prescIndex: number) {
    if (moment(date1) >= moment(date2)) {
      this.pmsForm.get('date_needed_by' + prescIndex).setErrors(null);
    }
    else {
      this.pmsForm.get('date_needed_by' + prescIndex).setErrors({ PastDateErr: true });
    }
  }

  onValidateCompliancePkgValue(value: any, prescIndex: number) {
    this.pmsOrderDetails.prescription[prescIndex].compliancePackage = value;
    this.pmsOrderDetails.prescription[prescIndex].enableForNonCompliancePkg = value;
    this.iterateSetData(prescIndex);

    if (value === false) {
      setTimeout(() => {
        this.pmsForm.controls['admin_start_date' + prescIndex].disable();
        this.pmsForm.controls['admin_start_date' + prescIndex].setErrors(null);
        this.pmsOrderDetails.prescription[prescIndex].foDosageDetails.forEach((dose: any, doseIndex: number) => {
          this.pmsForm.controls['allDays' + doseIndex + prescIndex].disable();
          this.pmsForm.controls['admin_time' + prescIndex + doseIndex].disable();
          this.pmsForm.controls['quantity' + prescIndex + doseIndex].disable();
          this.pmsForm.controls['uom' + prescIndex + doseIndex].disable();

          this.pmsForm.controls['allDays' + doseIndex + prescIndex].setErrors(null);
          this.pmsForm.controls['admin_time' + prescIndex + doseIndex].setErrors(null);
          this.pmsForm.controls['quantity' + prescIndex + doseIndex].setErrors(null);
          this.pmsForm.controls['uom' + prescIndex + doseIndex].setErrors(null);
          dose.dayOfWeek.forEach((day: any) => {
            this.pmsForm.controls['days' + day.name + doseIndex + prescIndex].disable();
            this.pmsForm.controls['days' + day.name + doseIndex + prescIndex].setErrors(null);
            dose.hasError = false;
          });
        });
      }, 100);
    }
    else {
      setTimeout(() => {
        this.pmsForm.get('admin_start_date' + prescIndex).enable();
        this.pmsOrderDetails.prescription[prescIndex].foDosageDetails.forEach((dose: any, doseIndex: number) => {
          this.pmsForm.controls['allDays' + doseIndex + prescIndex].enable();
          this.pmsForm.controls['admin_time' + prescIndex + doseIndex].enable();
          this.pmsForm.controls['quantity' + prescIndex + doseIndex].enable();
          this.pmsForm.controls['uom' + prescIndex + doseIndex].enable();
          
          this.pmsForm.controls['admin_time' + prescIndex + doseIndex].setValidators(Validators.required);
          this.pmsForm.controls['admin_time' + prescIndex + doseIndex].updateValueAndValidity();
          this.pmsForm.controls['quantity' + prescIndex + doseIndex].setValidators(Validators.required);
          this.pmsForm.controls['quantity' + prescIndex + doseIndex].updateValueAndValidity();
          this.pmsForm.controls['uom' + prescIndex + doseIndex].setValidators(Validators.required);
          this.pmsForm.controls['uom' + prescIndex + doseIndex].updateValueAndValidity();

          dose.dayOfWeek.forEach((day: any) => {
            this.pmsForm.controls['days' + day.name + doseIndex + prescIndex].enable();
            dose.hasError = !dose.dayOfWeek.some(val => val.checked === true);
          });
        });
        //If all days are pre selected then enable daily checkbox an disable day checkbox
        this.setDaysState(this.pmsOrderDetails.prescription[prescIndex], prescIndex);
      }, 100);
    }
  }
  /*********************Helper Methods*********************/

  /*********************Component Methods*********************/
  onCompliancePkgTypeChange(value: any, prescIndex: number) {
    this.onValidateCompliancePkgValue(value, prescIndex);
  }

  onUpdateAllDays(selDose: any, prescIndex: number, doseIndex: number, isChecked: boolean) {
    let changedItem;
    let currPresc = this.pmsOrderDetails.prescription[prescIndex];
    changedItem = currPresc.foDosageDetails.filter((data, i) => {
      return i === doseIndex
    })[0];
    selDose.selectAllDays = isChecked;
    let itemDay = Object.clone(changedItem.dayOfWeek);
    itemDay.forEach(rowDay => {
      rowDay.checked = selDose.selectAllDays;
      rowDay.disabled = selDose.selectAllDays;
      this.pmsForm.controls['days' + rowDay.name + doseIndex + prescIndex].setValue(rowDay.checked);
      selDose.selectAllDays ? this.pmsForm.controls['days' + rowDay.name + doseIndex + prescIndex].disable() : this.pmsForm.controls['days' + rowDay.name + doseIndex + prescIndex].enable();
    });
    changedItem.dayOfWeek = itemDay;
    currPresc.foDosageDetails[doseIndex] = changedItem;
    this.pmsOrderDetails.prescription[prescIndex] = currPresc;

    selDose.hasError = !selDose.dayOfWeek.some(val => val.checked === true);
  }

  onUpdateDay(selDose: any, day: any, prescIndex: number, doseIndex: number, isChecked: boolean) {
    let changedDoseItem;
    let currPrescItem = this.pmsOrderDetails.prescription[prescIndex];
    changedDoseItem = currPrescItem.foDosageDetails.filter((data, i) => {
      return i === doseIndex
    })[0];
    let itemDay = Object.clone(changedDoseItem.dayOfWeek);
    itemDay.forEach((doseDay, rowIndex) => {
      if (doseDay.name === day.name) {
        doseDay.checked = isChecked;
        doseDay.disabled = isChecked;
        this.pmsForm.controls['days' + doseDay.name + doseIndex + prescIndex].setValue(doseDay.checked);
      }
    });
    changedDoseItem.dayOfWeek = itemDay;
    currPrescItem.foDosageDetails[doseIndex] = changedDoseItem;
    this.pmsOrderDetails.prescription[prescIndex] = currPrescItem;

    changedDoseItem.hasError = !changedDoseItem.dayOfWeek.some(val => val.checked === true);
  }

  onAddDose(prescIndex: number) {
    this.iterateSetData(prescIndex);
    this.resetDoseControls(prescIndex);
    let prescription = Object.clone(this.pmsOrderDetails.prescription[prescIndex]);
    let foDetail = [];
    this.foDetailMock.coreflexNumber = prescription.foDosageDetails[0].coreflexNumber; //To maintain Coreflex and Rx numbers
    this.foDetailMock.rxNumber = prescription.foDosageDetails[0].rxNumber;
    this.foDetailMock.dosageSig = prescription.sig;
    foDetail.push(Object.clone(this.foDetailMock));
    this.pmsOrderDetails.prescription[prescIndex].foDosageDetails = [...prescription.foDosageDetails, ...foDetail];
    this.formInit(this.pmsOrderDetails);
    this.pmsForm.markAsDirty();
  }

  onRemoveDose(prescIndex: number, doseIndx: number) {
    //Code refactoring required:- Refactor code using splice and slice method
    this.iterateSetData(prescIndex);
    let temp = Object.clone(this.pmsOrderDetails.prescription[prescIndex].foDosageDetails);
    let tempArr = [];
    temp.forEach((d, i) => {
      if (i != doseIndx) {
        tempArr.push(d);
      }
    });
    this.pmsOrderDetails.prescription[prescIndex].foDosageDetails = tempArr;
    this.formInit(this.pmsOrderDetails);
    this.pmsForm.markAsDirty();
  }

  onSave() {
    this.saveSubmitData(this.pmsOrderDetails, this.pmsOrderDetails.orderStatus, this.pmsOrderDetails.orderNo, "N", this.permissionsData.rolePermissionData.userRolePermission[0].userDetails.preferred_username, this.permissionsData.rolePermissionData.userRolePermission[0].roleName, this.pmsForm.status, 'save');
  }

  onSubmit() {
    this.saveSubmitData(this.pmsOrderDetails, this.pmsOrderDetails.orderStatus, this.pmsOrderDetails.orderNo, "Y", this.permissionsData.rolePermissionData.userRolePermission[0].userDetails.preferred_username, this.permissionsData.rolePermissionData.userRolePermission[0].roleName, this.pmsForm.status, 'submit');
  }

  onSubmitReview() {
    this.saveSubmitData(this.pmsOrderDetails, this.pmsOrderDetails.orderStatus, this.pmsOrderDetails.orderNo, "Y", this.permissionsData.rolePermissionData.userRolePermission[0].userDetails.preferred_username, this.permissionsData.rolePermissionData.userRolePermission[0].roleName, this.pmsForm.status, 'submitReview');
  }

  onCancelOrder() {
    this.utility.confirmCancelOrder().subscribe((result: any) => {
      if (result) {
        let cancelDataObj = {
          "orderStatus": "Cancel",
          "orderNo": this.pmsOrderDetails.orderNo
        }
        this.pmsService.cancelOrderDetails(cancelDataObj).subscribe((response: any) => {
          if (!response.hasError) {
            this.toastr.success('Order is cancelled successfully.');
          }
        });
        this.pmsForm.markAsPristine();
        this.dialogRef.close('cancel');
      }
    });
  }

  onCloseDialog() {
    if (!this.pmsForm.pristine) {
      this.utility.confirmCancel().subscribe((result: any) => {
        if (result) {
          this.dialogRef.close('close');
        }
      });
    }
    else {
      this.dialogRef.close('close');
    }
  }

  /*********************Component Methods*********************/

  /*********************Dialog Methods*********************/

  /*********************Dialog Methods*********************/

  /*********************Init/Reset Methods*********************/
  private formInit(pmsOrderDetailsData: any) {
    //Form Initialization
    let group = {};
    this.isAddressMandatory = pmsOrderDetailsData.shipDirectToPatient; // true = Ship Direct to Patient & false = ship to pharmacy
    if (this.isAddressMandatory) {
      group['patient_address'] = new FormControl(pmsOrderDetailsData.patient.patientAddress.address1, [Validators.required]);
      group['patient_address2'] = new FormControl(pmsOrderDetailsData.patient.patientAddress.address2); // non mandatory field 
      group['patient_city'] = new FormControl(pmsOrderDetailsData.patient.patientAddress.city, [Validators.required]);
      group['patient_state'] = new FormControl(pmsOrderDetailsData.patient.patientAddress.state, [Validators.required]);
      group['patient_zip'] = new FormControl(pmsOrderDetailsData.patient.patientAddress.zipCode, [Validators.required]);
    }
    else {
      group['patient_address'] = new FormControl(pmsOrderDetailsData.patient.patientAddress.address1);
      group['patient_address2'] = new FormControl(pmsOrderDetailsData.patient.patientAddress.address2); // non mandatory field 
      group['patient_city'] = new FormControl(pmsOrderDetailsData.patient.patientAddress.city);
      group['patient_state'] = new FormControl(pmsOrderDetailsData.patient.patientAddress.state);
      group['patient_zip'] = new FormControl(pmsOrderDetailsData.patient.patientAddress.zipCode);
    }

    pmsOrderDetailsData.prescription.forEach((prescription: any, prescIndex: number) => {
      if (prescription.enableForNonCompliancePkg === false) {
        group['admin_start_date' + prescIndex] = new FormControl({ value: prescription.rxAdministrationStartDate, disabled: true });
        group['date_needed_by' + prescIndex] = new FormControl(prescription.rxNeedByDate, [Validators.required]);

        group['compliance_pkg_type' + prescIndex] = new FormControl(prescription.compliancePackage, [Validators.required]);
        group['compliance_pkg_type' + prescIndex].setValue(prescription.compliancePackage, [Validators.required]); //Set default values to dropdown
        prescription.foDosageDetails.forEach((dose: any, doseIndex: number) => {
          dose.selectAllDays = dose.dayOfWeek.every(val => val.checked === true);
          //select all days intitialize
          group['allDays' + doseIndex + prescIndex] = new FormControl({ value: dose.selectAllDays, disabled: true });

          group['admin_time' + prescIndex + doseIndex] = new FormControl({ value: dose.administrationTime, disabled: true });
          group['quantity' + prescIndex + doseIndex] = new FormControl({ value: dose.dosageQty, disabled: true });
          group['uom' + prescIndex + doseIndex] = new FormControl({ value: dose.dosageUnit, disabled: true });
          group['uom' + prescIndex + doseIndex].setValue(dose.dosageUnit);
          dose.dayOfWeek.forEach((day: any) => {
            group['days' + day.name + doseIndex + prescIndex] = new FormControl({ value: day.checked, disabled: true });
          });
          dose.hasError = false;
        });
      }
      else {
        group['admin_start_date' + prescIndex] = new FormControl(prescription.rxAdministrationStartDate, [Validators.required]);
        group['date_needed_by' + prescIndex] = new FormControl(prescription.rxNeedByDate, [Validators.required]);

        group['compliance_pkg_type' + prescIndex] = new FormControl(prescription.compliancePackage, [Validators.required]);
        group['compliance_pkg_type' + prescIndex].setValue(prescription.compliancePackage, [Validators.required]); //Set default values to dropdown
        prescription.foDosageDetails.forEach((dose: any, doseIndex: number) => {
          dose.selectAllDays = dose.dayOfWeek.every(val => val.checked === true);
          //select all days intitialize
          group['allDays' + doseIndex + prescIndex] = new FormControl(dose.selectAllDays, [Validators.required]);

          group['admin_time' + prescIndex + doseIndex] = new FormControl(dose.administrationTime, [Validators.required]);
          group['quantity' + prescIndex + doseIndex] = new FormControl(dose.dosageQty, [Validators.required]);
          group['uom' + prescIndex + doseIndex] = new FormControl(dose.dosageUnit);
          group['uom' + prescIndex + doseIndex].setValue(dose.dosageUnit);

          dose.dayOfWeek.forEach((day: any) => {
            group['days' + day.name + doseIndex + prescIndex] = new FormControl(day.checked, [Validators.required]);
          });
          dose.hasError = !dose.dayOfWeek.some(val => val.checked === true);
        });
      }
    });
    this.pmsForm = new FormGroup(group);
    Object.keys(this.pmsForm.controls).forEach(key => {
      this.pmsForm.controls[key].value ? this.pmsForm.controls[key].markAsUntouched() : this.pmsForm.controls[key].markAsTouched();
    });

    //If all days are pre selected & compliance pkg value is Yes then enable daily checkbox and disable day checkbox
    pmsOrderDetailsData.prescription.forEach((prescription: any, prescIndex: number) => {
      this.setDaysState(prescription, prescIndex);
    });
  }

  private onlySaveData(details: any, status: string, orderNo: number, validation: string, userEmail: string, userRole: string, formValidationStatus: string, typeOfFunction: string) {
      let tempSaveObj = details;
      let saveDataObj = this.formatDataToSave(tempSaveObj);
      saveDataObj.orderStatus = status;
      saveDataObj.saveStatus = typeOfFunction;
      saveDataObj.orderNo = orderNo;
      saveDataObj.needValidation = validation;
      saveDataObj.userEmail = userEmail;
      saveDataObj.userRole = userRole;
      // this.pmsService.saveOrderDetails(saveDataObj).subscribe((response: any) => {
      //   if (!response.hasError) {
      //         this.toastr.success('Order saved successfully.');             
      //   }
      // });
      this.dialogRef.close('save');    
      console.log(saveDataObj);
  }

  private saveSubmitData(details: any, status: string, orderNo: number, validation: string, userEmail: string, userRole: string, formValidationStatus: string, typeOfFunction: string) {
    this.doseDayMandatoryFlag = this.checkHasError(details);
    if (formValidationStatus === 'VALID' && this.doseDayMandatoryFlag) {
      let tempSaveObj = details;
      let saveDataObj = this.formatDataToSave(tempSaveObj);
      saveDataObj.orderStatus = status;
      saveDataObj.saveStatus = typeOfFunction;
      saveDataObj.orderNo = orderNo;
      saveDataObj.needValidation = validation;
      saveDataObj.userEmail = userEmail;
      saveDataObj.userRole = userRole;
      this.pmsService.saveOrderDetails(saveDataObj).subscribe((response: any) => {
        if (!response.hasError) {
          switch (typeOfFunction) {
            case 'save':
              this.toastr.success('Order saved successfully.')
              break;
            case 'submit':
              this.toastr.success('Order submitted successfully.')
              break;
            case 'submitReview':
              this.toastr.success('Order Submitted for Review successfully.')
              break;
            default:
              break;
          }
        }
      });
      this.dialogRef.close('save');
    }
    else {
      this.utility.confirmOk('Warning', { faultMessage: 'Mandatory fields are either invalid or missing' }, true);
    }
  }
  /*********************Init/Reset Methods*********************/

}
