<mat-card>
    <mat-card-header>
        <mat-card-title>Formulary</mat-card-title>
    </mat-card-header>
    <mat-card-content>

        <!-- Search Filter -->
        <form name="searchForm" [formGroup]="searchForm" novalidate>
            <div class="search-bar">
                <div class="mat-form-search-cont">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Enter your search..." name="searchText"
                            formControlName="searchText" (paste)="onCopyPaste($event)" (keyup)="onSearchFilter(searchForm.controls.searchText)"
                            #searchInput required />
                        <a mat-button [hidden]="!clearSearchFlag" matSuffix mat-icon-button aria-label="x"
                            (click)="onClearSearch()">
                            <mat-icon class="close-icon">close</mat-icon>
                    </a>
                        <mat-hint align="start">Please enter search criteria. For more information check <i
                            class="fa fa-info-circle info-icon" aria-hidden="true"></i> icon. </mat-hint>
                        <mat-error *ngIf="searchForm.get('searchText').hasError('InvalidSearch')">
                            Invalid Entry. Please refer to the selection criteria <i class="fa fa-info-circle info-icon" aria-hidden="true" style="font-size: 16px"></i>.
                        </mat-error>
                    </mat-form-field>
                    <i class="fa fa-info-circle info-icon" aria-hidden="true" matTooltip="Note: &#13;
                    NDC (exactly 11 digits numeric),&#13;
                    CIN (numeric only), &#13;
                    Source Item Code (numeric or alphanumeric), &#13;
                    Generic Name, or Trade Name (starts with 3 char- alphanumeric)" matTooltipClass="tool-tip-class"
                        aria-label="Button that shows a tooltip"></i>
                    <button mat-flat-button color="primary" class="button primary" (click)="onSearchClick()"
                        [disabled]="disableSearchFlag || searchForm.get('searchText').hasError('InvalidSearch')">
                        Search
                    </button>
                </div>
                <div class="search-bar-options">
                    <mat-checkbox formControlName="formularyFilterCheckbox" value="1"
                        (change)="formularyFilter($event.checked)">
                        Products on Formulary Only
                    </mat-checkbox>
                    <button mat-stroked-button color="primary" class="clear-filter condensed-btn"
                        (click)="onClearSearchResults()">
                        Clear search results
                    </button>

                </div>
            </div>
        </form>
        <!-- Search Filter -->

        <!-- Formulary Data -->
        <div class="mat-elevation-z8 scroll" [hidden]="!permissionAssignment.viewFormulary">
            <table mat-table [dataSource]="dataSource" aria-describedby="formulary view" matSort>

                <ng-container matColumnDef="ndc">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> NDC</th>
                    <td mat-cell *matCellDef="let row" class="clickable no-wrap" (click)="onCellClicked(row)">
                        {{row.ndcText}} </td>
                </ng-container>

                <ng-container matColumnDef="cin">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">CIN</th>
                    <td mat-cell *matCellDef="let row" class="clickable" (click)="onCellClicked(row)">
                        {{row.cin}} </td>
                </ng-container>

                <ng-container matColumnDef="srcItemNum">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Source Item Code</th>
                    <td mat-cell *matCellDef="let row" class="clickable" (click)="onCellClicked(row)">
                        {{row.srcItemNum}} </td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Description</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.description}} </td>
                </ng-container>

                <ng-container matColumnDef="tradeName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Trade Name</th>
                    <td mat-cell *matCellDef="let row">
                        <span>{{row.tradeName}}</span> </td>
                </ng-container>

                <ng-container matColumnDef="genericName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Generic Name</th>
                    <td mat-cell *matCellDef="let row" class="clickable" (click)="onCellClicked(row)">
                       <span>{{row.genericName}}</span></td>
                </ng-container>

                <ng-container matColumnDef="supplierName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Supplier Name</th>
                    <td mat-cell *matCellDef="let row">
                       <span>{{row.supplierName}}</span></td>
                </ng-container>

                <ng-container matColumnDef="supplierId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Supplier ID</th>
                    <td mat-cell *matCellDef="let row">
                       {{row.supplierId}}</td>
                </ng-container>

                <ng-container matColumnDef="rxIndicatorString">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">RX / OTC</th>
                    <td mat-cell *matCellDef="let row">
                       {{row.rxIndicatorString}}</td>
                </ng-container>

                <ng-container matColumnDef="genIndicatorString">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Brand / Generic</th>
                    <td mat-cell *matCellDef="let row">
                       {{row.genIndicatorString}}</td>
                </ng-container>

                <ng-container matColumnDef="formName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Form</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.form}} </td>
                </ng-container>

                <ng-container matColumnDef="gcn">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">GCN</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.gcn}} </td>
                </ng-container>

                <ng-container matColumnDef="baseUom">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">UOM</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.baseUom}} </td>
                </ng-container>

                <ng-container matColumnDef="medTypeString">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="no-wrap">Med Type</th>
                    <td mat-cell *matCellDef="let row">
                       <span>{{row.medTypeString}}</span></td>
                </ng-container>

                <ng-container matColumnDef="startDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="no-wrap">Eff Start Date</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.startDate | date:'MM-dd-yyyy'}} </td>
                </ng-container>

                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" class="no-wrap">Eff End Date</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.endDate | date:'MM-dd-yyyy'}} </td>
                </ng-container>

                <ng-container matColumnDef="modifiedBy">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Modified By</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.modifiedBy}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="modifiedDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Modified Date</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.modifiedDate | date:'MM-dd-yyyy HH:mm a'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef scope="col"> </th>
                    <td mat-cell *matCellDef="let row">
                        <div style="display:flex"  [hidden]="!permissionAssignment.editFormulary">
                            <mat-icon class="grid-icon" title="edit" (click)="openDialog(row, false, 'FormularyInfo')">edit</mat-icon>
                            <mat-icon class="grid-icon" title="Remove" *ngIf="row.formularyFlag"
                                (click)="openDialog(row, true, 'FormularyInfo')">delete</mat-icon>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'formulary-row': row.formularyFlag === true}"></tr>

            </table>
            <!-- Row shown when there is no matching data. -->
            <div *ngIf="noDataFlag" class="no-records">
                No records found
            </div>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 200]"></mat-paginator>
        </div>
        <!-- Formulary Data -->

    </mat-card-content>
</mat-card>