export class StaticConstants {
  static readonly Properties = {
    TypeCode: 'typeCode',
    TypeDesc: 'typeDesc',
  };
}

export class AppPreferenceConstant {
  static readonly SEND_UI_CONSOLE_LOGS = 'SEND_UI_CONSOLE_LOGS';
  static readonly AppTimeOut = 'IDLE_TIMEOUT_ADMIN_PORTAL';
}

export class TypeGroupConstant {
  static readonly RelationshipType = 5;
  static readonly AccountType = 6;
  static readonly ContactType = 7;
  static readonly AccountRegionType = 7;
}

export class TypeRefConstant {
  static readonly UserType = {
    Internal: 300,
    External: 301,
  };

  static readonly RelationshipType = {
    Vendor: 500,
    Customer: 501,
    Seller: 502,
    VendorAgreement: 503,
    VendorContract: 504,
    VendorProgram: 505,
    GPOCustomer: 506,
  };

  static readonly AccountType = {
    System: 600,
    Facility: 601,
  };

  static readonly GPOUserType = {
    CHPC: 100,
    GPO: 101,
  };
}
