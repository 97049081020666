<header id="header">
  <div class="row">
    <div class="col">
      <div class="flex-container">
        <h1 id="cardinal-logo" class="logo">
          <img class="logo-img" src="../../../assets/images/cardinal-logo.png" alt="NavixRX" title="Home" />
        </h1>
      </div>
    </div>
    
    <div class="col text-right">
      <h1 id="whirlybird-logo" class="logo">
        <span>
          Hello <a>{{userDetails.name}}</a>
          <strong>Welcome to NavixRx Order Management!</strong>
        </span>
      </h1>
    </div>

  </div>
</header>