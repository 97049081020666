import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from 'src/app/core/services/base.service';
// import { UtilityService } from './utility.service';

import { LogLevel } from '../../core/constants/app.constant';
import { Environment } from '../models/environment';

@Injectable({
  providedIn: 'root',
})
export class LoggerService extends BaseService {
  /*********************Properties*********************/
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(protected override http: HttpClient) {
    super(http);
  }
  /*********************Constructor*********************/

  /*********************Service Methods*********************/

  trace(msg: any, additional: any[] = []) {
    this.logMessage(LogLevel.TRACE, msg, additional);
  }

  debug(msg: any, additional: any[] = []) {
    this.logMessage(LogLevel.DEBUG, msg, additional);
  }

  info(msg: any, additional: any[] = []) {
    this.logMessage(LogLevel.INFO, msg, additional);
  }

  log(msg: any, additional: any[] = []) {
    this.logMessage(LogLevel.LOG, msg, additional, true);
  }

  warn(msg: any, additional: any[] = []) {
    this.logMessage(LogLevel.WARN, msg, additional, true);
  }

  error(msg: any, additional: any[] = []) {
    this.logMessage(LogLevel.ERROR, msg, additional, true);
  }

  /*********************Service Methods*********************/

  /*********************Private Methods*********************/

  private logMessage(
    level: LogLevel,
    message: any,
    additional: any[] = [],
    log: boolean = false
  ) {
    switch (level) {
      case LogLevel.TRACE:
        console.trace(message, additional);
        break;

      case LogLevel.DEBUG:
        console.debug(message, additional);
        break;

      case LogLevel.INFO:
        console.info(message, additional);
        break;

      case LogLevel.WARN:
        console.warn(message, additional);
        break;

      case LogLevel.ERROR:
        console.error(message, additional);
        break;

      case LogLevel.LOG:
      default:
        console.log(message, additional);
        break;
    }

    const logConsoleLogs = Environment.getInstance().appConfig.logger.serverLog;
    if (log && logConsoleLogs) {
      // this.logMessageToServer(level, message);
    }
  }

  // private logMessageToServer(level: LogLevel, message: string) {
  //   const logMessage = this.utility.getDefaultLogMessage() + message;

  //   const url = "https://wbd-config-dev.apps.cf.np.gc.cahcloud.net/api/log";
  //   const log: Log = {
  //     logType: level,
  //     logMessage,
  //   };

  //   super.post(url, log).subscribe();
  // }

  /*********************Private Methods*********************/
}
