export class OktaConstant {
  static readonly IdToken = 'id_token';
  static readonly AccessToken = 'access_token';
  static readonly TokenType = 'Bearer';

  static readonly AuthStatus = {
    Success: 'SUCCESS',
    PasswordExpired: 'PASSWORD_EXPIRED',
    UserLocked: 'LOCKED_OUT',
    AuthFailed: 'AUTH_FAILED'
  };

  static readonly Messages = {
    InvalidUserNamePassword: 'Invalid username or password. Please try again.',
    PasswordExpired: 'Password is expired and must be changed',
    UserLocked: 'Your account is locked. Please user Unlock account link to unlock your account',
    VerifyAuthentication: 'Verifying authentication',
    SigninIntoApp: 'Signing into NavixRX'
  };
}
