import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipeModule } from 'src/app/shared/modules/pipe.module';
import { MaterialModule } from 'src/app/shared/modules/material.module';

import { ConfirmDialogComponent } from './confirm-dialog.component';

@NgModule({
  declarations: [ConfirmDialogComponent],
  imports: [CommonModule, MaterialModule, PipeModule],
  entryComponents: [ConfirmDialogComponent]
})
export class DialogModule {}
