import { Injectable } from '@angular/core';
import { BaseService } from './core/services/base.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthValidate } from './shared/models/app';
import { OktaPkceAuthService } from './core/services/okta-pkce-auth.service';
import { TokenService } from './shared/utils/token.service';
import { AuthService } from './core/services/auth.service';
import { StaticService } from './core/static/static.service';
import { ClientApi } from 'src/app/core/constants/api.constant';
import { SearchCriteria } from 'src/app/shared/models/header';
import { SearchCustomerCriteria } from 'src/app/modules/user-management/user-management';

@Injectable({
  providedIn: 'root',
})
export class AppService extends BaseService {
  /*********************Properties*********************/
  pageHeader$: Subject<string> = new Subject<string>();
  backPageRoute$: Subject<string> = new Subject<string>();
  alertLoaded$: Subject<number> = new Subject<number>();

  private accDetails = new BehaviorSubject<any>(null);
  accDetails$ = this.accDetails.asObservable();

  private assignedAccountList = new BehaviorSubject<any>(null);
  assignedAccountList$ = this.assignedAccountList.asObservable();

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private authService: AuthService,
    private oktaPkceAuth: OktaPkceAuthService,
    private token: TokenService,
    private staticData: StaticService,
    protected http: HttpClient,
  ) {
    super(http);
  }
  /*********************Constructor*********************/

  /*********************Utility Methods*********************/

  init() {
    this.oktaPkceAuth.init();
  }

  initOnLoad(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.token.getQueryToken()) {
        resolve(true);
        return;
      }

      this.authService.isAuthenticated().then((response: AuthValidate) => {
        resolve(response.authenticated.all);
      }).catch((err) =>{
        console.log(err)
      });
    });
  }

  /*********************Utility Methods*********************/

  /*********************Service Methods*********************/

  load(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.token.init();
      // const userDetailRequest = this.userService.get();
      const staticDataRequest = this.staticData.load();

      forkJoin([
      //   userDetailRequest,
        staticDataRequest,
      ]).subscribe((response) => {
        this.oktaPkceAuth.auth.validate = false;
        this.authService.userAuthenticated();
        resolve(true);
      });
    });
  }

  getAccountSelectionDetails(userNum) {
    const url = this.serviceUrl(this.apiConfig.user, ClientApi.getUserAccounts, userNum);
    return this.http.get(url).pipe(
      map((response: any) => {
        // if (!response.hasError) {
        return response;
        // }
      })
    )
  }

  searchCustomerData(searchCriteria: SearchCustomerCriteria) {
    const url = this.serviceUrl(this.apiConfig.user, ClientApi.SearchCustomerAccounts);
    return super.post(url, searchCriteria).pipe(
      map((response: any) => {
        // if (!response.hasError) {
        return response;
        // }
      })
    )
  }

  searchUserData(searchCriteria: SearchCriteria) {
    const url = this.serviceUrl(this.apiConfig.user, ClientApi.SearchUserData);
    return super.post(url, searchCriteria).pipe(
      map((response: any) => {
        // if (!response.hasError) {
        return response;
        // }
      })
    )
  }

  setAccount(account: any) {
    this.accDetails.next(account)
  }

  setAssignedAccount(accountList: any) {
    this.assignedAccountList.next(accountList)
  }

  /*********************Service Methods*********************/

  /*********************Private  Methods*********************/
  // private getPreference(preferenceId) {
  //  let preference = localStorage.getItem(CacheConstant.PreferenceData.Name) as any;

  //   if (preference) {
  //     preference = JSON.parse(preference);
  //   }

  //   if (preference && preference.length > 0 && preferenceId) {
  //     preference = preference.find(
  //       (x) => x.preferenceId.trim() === preferenceId
  //     );
  //   }

  //   return preference || this.getDefaultApplicationPreference(preferenceId)
  // }

  // private getDefaultApplicationPreference(
  //   prefId: string = null
  // ): ApplicationPreferences {
  //   return {
  //     activeFlag: true,
  //     appTypeCode: 1,
  //     preferenceDesc: '',
  //     preferenceId: prefId,
  //     preferenceValue: '',
  //   };
  // }
  /*********************Private Methods*********************/
}
