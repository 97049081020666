<mat-card>
    <mat-card-header>
        <mat-card-title>Fulfillment Order Status</mat-card-title>
    </mat-card-header>
    <mat-card-content>

        <!-- Search Filter -->
        <form name="searchForm" [formGroup]="searchForm" novalidate>
            <div class="search-bar complianceOrderStatus-search-bar row">
                <div class="mandatory-fields col-s2">
                    <div class="bottom">
                        <label>Account:</label>
                    <app-account-select></app-account-select>
                    </div>                   
                </div>
                <div class="optional-fields col-s6">
                   <div class="left col-s1">
                        <label class="label-txt">Order ID:</label>
                        <!-- <label class="label-txt">Enter Patient Name:</label> -->
                        <mat-form-field>
                            <input matInput type="text" placeholder="Enter Order ID..." name="orderId"
                                formControlName="orderId" #orderIdSearchInput />
                            <!-- <input matInput type="text" placeholder="Enter search text..." name="patientName"
                                formControlName="patientName" #patientNameSearchInput /> -->
                        <!-- <a mat-button [hidden]="!clearSearchFlag" matSuffix mat-icon-button aria-label="x"
                                (click)="onClearSearch()">
                                <mat-icon class="close-icon">close</mat-icon>
                            </a> -->
                        </mat-form-field>
                    </div>
                    <div class="right col-s3">
                        <label class="label-txt">Select Order Received Date:</label>
                        <mat-form-field appearance="fill" style="margin-right:15px">
                            <mat-label>From</mat-label>
                            <input matInput [owlDateTime]="fdate" formControlName="fromDate" placeholder="MM/DD/YYYY">
                            <owl-date-time #fdate [pickerType]="'calendar'"></owl-date-time>
                            <span [owlDateTimeTrigger]="fdate" class="dateTimeIcon material-icons">
                                calendar_today
                            </span>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>To</mat-label>
                            <input matInput [min]="minDate" [owlDateTime]="tdate" formControlName="toDate"
                                placeholder="MM/DD/YYYY">
                            <owl-date-time #tdate [pickerType]="'calendar'"></owl-date-time>
                            <span [owlDateTimeTrigger]="tdate" class="dateTimeIcon material-icons">
                                calendar_today
                            </span>
                        </mat-form-field>
                    </div>
                    <div class="last col-s2">                   
                        <label class="label-txt">Order Status:</label> 
                        <mat-form-field>
                            <mat-label>Select Order Status</mat-label>
                            <mat-select formControlName="statusName" multiple>
                                <ng-container *ngFor="let status of orderStatus">
                                    <mat-option [value]="status | lowercase">{{status}}</mat-option>
                                </ng-container>                                 
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <!-- <div class="right col-s2 ">
                        <label class="label-txt">Group ID:</label>
                        <mat-form-field>
                            <input matInput type="text" placeholder="Enter Group ID..." name="groupId"
                                formControlName="groupId" #groupIdSearchInput />
                        </mat-form-field>
                    </div> -->
                </div>
                <div class="search-bar-options col-s4">
                    <button mat-flat-button color="primary" class="button primary" (click)="onSearchClick(true, '')"
                        [disabled]="disableSearchFlag"> Search
                    </button>
                    <button mat-stroked-button color="primary" class="clear-filter" (click)="onClearSearchResults()">
                        Clear search results
                    </button>

                </div>
            </div>
        </form>
        <!-- Search Filter -->

        <!-- Order Status Data -->
        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" aria-describedby="Order Status View" class="order-status-table"
                matSort>

                <ng-container matColumnDef="sapAccountNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" (click)="handlePageEvent($event)">Account Number</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.sapAccountNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="pharmacyName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" (click)="handlePageEvent($event)">Pharmacy Name</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.pharmacyName}} </td>
                </ng-container>

                <ng-container matColumnDef="ncpdp">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" (click)="handlePageEvent($event)">NCPDP</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.ncpdp}} </td>
                </ng-container>

                <ng-container matColumnDef="orderNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" (click)="handlePageEvent($event)">Order ID</th>
                    <td mat-cell *matCellDef="let row" class="clickable" (click)="onCellClicked(row)">
                        {{row.orderNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="groupId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" (click)="handlePageEvent($event)">Group ID</th>
                    <td mat-cell *matCellDef="let row" class="clickable" (click)="onCellClicked(row)">
                        {{row.navixGroupId}} </td>
                </ng-container>

                <ng-container matColumnDef="rxNeedByDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" (click)="handlePageEvent($event)">Need By Date</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.rxNeedByDate | date:'MM-dd-yyyy'}} </td>
                </ng-container>

                <ng-container matColumnDef="manifestId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" (click)="handlePageEvent($event)">Manifest ID</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.manifestId}} </td>
                </ng-container>

                <ng-container matColumnDef="patientName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" (click)="handlePageEvent($event)">Patient Name</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.patientName}} </td>
                </ng-container>

                <ng-container matColumnDef="shipToPatient">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" (click)="handlePageEvent($event)">Shipping Location</th>
                    <td mat-cell *matCellDef="let row">
                        <span *ngIf="row.shipToPatient">Patient</span>
                        <span *ngIf="!row.shipToPatient">Clinic</span>
                    </td>
                </ng-container>

                <!-- <ng-container matColumnDef="pharmacistName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Pharmacist Name</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.pharmacistName}} </td>
                </ng-container> -->

                <ng-container matColumnDef="recvDateTime">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" (click)="handlePageEvent($event)">Date Received</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.recvDateTimeFormat}} </td>
                </ng-container>

                <ng-container matColumnDef="displayStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" (click)="handlePageEvent($event)">Status</th>
                    <td mat-cell *matCellDef="let row">
                        <!-- RBC changes -->
                        <span *ngIf="row.status !== 'rbc-accepted'">
                            {{row.displayStatus}}
                        </span>
                        <span *ngIf="row.status === 'rbc-accepted'">
                            accepted
                        </span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="statusUpdateDateTime">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" (click)="handlePageEvent($event)">Status Updated
                    </th>
                    <td mat-cell *matCellDef="let row">
                        {{row.statusUpdateDateTime | date:'MM-dd-yyyy HH:mm a'}} </td>
                </ng-container>

                <ng-container matColumnDef="displayReason">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" (click)="handlePageEvent($event)">Error Msg</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.displayReason}} </td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef scope="col"> </th>
                    <td mat-cell *matCellDef="let row">
                        <div style="display:flex" [hidden]="true">
                            <mat-icon class="grid-icon" title="Cancel" *ngIf="row.cancelFlag"
                                (click)="onCancelClicked(row)">delete</mat-icon>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

            </table>
            <!-- Row shown when there is no matching data. -->
            <div *ngIf="noDataFlag" class="no-records">
                No records found
            </div>
            <!-- Row shown when there is no matching data. -->
            <mat-paginator #paginator (page)="handlePageEvent($event)" [length]="totalLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [pageIndex]="pageIndex">
            </mat-paginator>
        </div>
        <!-- Order Status Data -->

    </mat-card-content>
</mat-card>