import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';

import { BaseService } from './base.service';

import {
  OktaAuth,
  AuthValidate
} from 'src/app/shared/models/app';
import { OktaConstant } from '../constants/okta.constant';
import { OktaRoutes } from '../constants/routes.constant';
import { AppMessages } from '../constants/app.constant';
import { OktaPkceAuthService } from './okta-pkce-auth.service';
import { CacheResolverService } from '../cache/cache-resolver.service';
import { CacheConstant } from '../cache/cache.constant';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends BaseService {
  /*********************Properties*********************/
  // signingOut = false;

  authenticated$: Subject<boolean> = new Subject();
  signedOut$: Subject<any> = new Subject();

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    //private router: Router,
    protected override http: HttpClient,
    private oktaPkceAuth: OktaPkceAuthService,
    //private userService: UserService,
    private readonly cacheService: CacheResolverService
  ) {
    super(http);
    // this.userService.userProfileLoaded$.subscribe(() => {
    //   this.onUserProfileLoaded();
    // });
  }
  /*********************Constructor*********************/

  /*********************Utility Methods*********************/

  // init() {
  //   this.userSigningOut = false;
  // }

  userAuthenticated() {
    this.authenticated$.next(true);
  }

  isAuthenticated(): Promise<AuthValidate> {
    const oktaAuth = this.cacheService.get(CacheConstant.OktaAuth) as OktaAuth;
    const user = this.cacheService.get(CacheConstant.UserAuth);
    /* istanbul ignore next */
    const hasToken = oktaAuth && oktaAuth.accessToken ? true : false;
    /* istanbul ignore next */
    const hasUserDetails = user && user.userinfo ? true : false;
    const hasAuthDetails = hasToken && hasUserDetails;
    const response: AuthValidate = {
      authenticated: {
        app: hasAuthDetails,
        okta: false,
        all: false,
      },
    };

    if (hasAuthDetails) {
      response.authenticated.okta = response.authenticated.all = true;
    }
    return Promise.resolve(response);
  }

  clearSession() {
    this.clearSessionCache();
  }

  /*********************Utility Methods*********************/

  /*********************Service Methods*********************/

  // authenticate(authRequest: AuthRequest) {
  //   return this.oktaPkceAuth
  //     .authenticate(authRequest.userName, authRequest.password)
  //     .then(response => {
  //       const authResponse: AuthResponse = {
  //         statusText: response.status,
  //         error: null
  //       };

  //       if (response.status !== OktaConstant.AuthStatus.Success) {
  //         authResponse.error = this.resolveAuthError(response);
  //       }

  //       return authResponse;
  //     })
  //     .catch(error => {
  //       const authResponse: AuthResponse = {
  //         statusText: '',
  //         error: OktaConstant.Messages.InvalidUserNamePassword
  //       };

  //       return authResponse;
  //     });
  // }

  signOut(timeOut: boolean = false) {
    this.isAuthenticated().then(authResponse => {
      if (authResponse.authenticated.okta) {
        this.oktaPkceAuth.signOut();
      }
       this.signOutUser(timeOut);
    });
  }

  clearSessionCache() {
    this.cacheService.remove(CacheConstant.OktaAuth);
    this.cacheService.remove(CacheConstant.UserAuth);
  }

  /*********************Service Methods*********************/

  /*********************Private Methods*********************/

  private signOutUser(timeOut: boolean) {
    const redirectUrl =
      timeOut
        ? OktaRoutes.SessionExpired.Url
        : OktaRoutes.Logout.Url;
        
    this.oktaPkceAuth.auth.validate = false;
    this.clearSessionCache();

    this.signedOut$.next({
      hasError: false,
      redirect: { url: redirectUrl }
    });

  }
 
  private resolveAuthError(response: { status: any; }) {
    let errorMessage = '';

    switch (response.status) {
      case OktaConstant.AuthStatus.PasswordExpired:
        errorMessage = OktaConstant.Messages.PasswordExpired;
        break;

      case OktaConstant.AuthStatus.UserLocked:
        errorMessage = OktaConstant.Messages.UserLocked;
        break;

      case OktaConstant.AuthStatus.AuthFailed:
        errorMessage = OktaConstant.Messages.InvalidUserNamePassword;
        break;

      default:
        errorMessage = AppMessages.GenericErrorMessage;
        break;
    }

    return errorMessage;
  }

  /*********************Private Methods*********************/
}
