import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { ToastService } from 'src/app/shared/utils/toast.service';
import { UtilityService } from 'src/app/shared/utils/utility.service';
import { CustomerAccountManagementService } from '../customer-account-management.service';
import { UserService } from 'src/app/core/services/user.service';

import { UpdateAcctConfigSettings, ConfigSettings, SearchAcctConfigCriteria, BillingExceptions } from '../customer-account-management';
import { ModalDialogManageDataComponent } from './modal-dialog-manage-data/modal-dialog-manage-data.component';
import * as _moment from 'moment';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
const moment = _moment;

@Component({
  selector: 'app-modal-dialog-customer-account-management',
  templateUrl: './modal-dialog-customer-account-management.component.html',
  styleUrls: ['./modal-dialog-customer-account-management.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class ModalDialogCustomerAccountManagementComponent implements OnInit {
  /*********************Properties*********************/
  title: string = 'Customer Account Settings';
  accountName: string;
  sapAccountNumber: string;
  accountId: string;
  editConfigSettingsForm: FormGroup;
  updateAcctConfigSettings: UpdateAcctConfigSettings;
  searchAcctConfigObj: SearchAcctConfigCriteria = {
    "accountId": null
  }
  configSettings: ConfigSettings = {
    enableBilling: null,
    customParLevel: null,
    shipDirToPtnt: null,
    sla: null,
    autoApproved: null,
    allowEditsInNavix: null,
    interfaceVersion: null,
    hoaPrintType: null
  }
  labelTypeList: any = [
    { id: 1, value: 'Time Groups', name: 'Time Groups' },
    { id: 2, value: 'Hour Specific', name: 'Hour Specific' }
  ];
  
  dataSource: MatTableDataSource<BillingExceptions>;
  billingData: BillingExceptions[];
  today: string = moment().format('yyyy-MM-DD');
  noDataFlag: boolean = true;
  displayedColumns: string[] = ['cin', 'cinDescription', 'price', 'startDate', 'endDate', 'actions'];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  interfaceVersionDetails: any[] = [];
  interfaceVersionList: any;
  interfaceVersionString: any;
  interfaceVersionId: any;

  slaDetails: any[] = [];
  slaList: any;

  panelOpenState1 = false;
  panelOpenState2 = false;
  panelOpenState3 = false;
  panelOpenState4 = false;
  panelOpenState5 = false;
  panelOpenState6 = false;
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private utility: UtilityService,
    public dialogRef: MatDialogRef<ModalDialogCustomerAccountManagementComponent>,
    private customerAccountManagementService: CustomerAccountManagementService,
    private toastr: ToastService,
    private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }
  /*********************Constructor*********************/
  /*********************Lifecyle Methods*********************/
  ngOnInit(): void {
    this.init();
  }

  ngAfterViewInit() {
    this.editConfigSettingsForm.controls['sla'].setValue(this.configSettings.sla);
  }
  /*********************Lifecyle Methods*********************/

  /*********************Component Methods*********************/
  getAccountConfigDetails(customer: any) {
    this.accountName = customer.accountName1;
    this.sapAccountNumber = customer.sapAccountNumber;
    this.accountId = customer.accountId;
    let slaVal: string = '';
    this.searchAcctConfigObj.accountId = customer.accountId;
    this.customerAccountManagementService.getAccountConfigDetails(this.searchAcctConfigObj).subscribe((res: any) => {
        this.configSettings = !res.hasError ? (res.data? res.data :[]) : [];
        slaVal = this.configSettings.sla?this.configSettings.sla.toString():'';
        //bind config settings to form
        this.editConfigSettingsForm.controls['enableBilling'].setValue(this.configSettings.enableBilling);
        this.editConfigSettingsForm.controls['customParLevel'].setValue(this.configSettings.customParLevel);
        this.editConfigSettingsForm.controls['shipDirToPtnt'].setValue(this.configSettings.shipDirToPtnt);
        this.editConfigSettingsForm.controls['sla'].setValue(slaVal);
        this.editConfigSettingsForm.controls['autoApproved'].setValue(this.configSettings.autoApproved);        
        this.editConfigSettingsForm.controls['allowEditsInNavix'].setValue(this.configSettings.allowEditsInNavix);
        this.editConfigSettingsForm.controls['interfaceVersion'].setValue(this.getInterfaceVersionString(this.configSettings.interfaceVersion));
        this.editConfigSettingsForm.controls['hoaPrintType'].setValue(this.configSettings.hoaPrintType);
        //bind config settings to form
    });
    this.getBillingData();    
  }

  onSaveClick(buttonState: string) {
    //create pop up for when save or cancel button clicked
    if (buttonState == 'save') {
      this.utility.confirmSave().subscribe((result: any) => {
        if (result) {
          this.onSave('save');
        }
      });
    }
    else {
      this.onCloseDialog();
    }
  }

  onSave(userAction: string) {
    this.updateAcctConfigSettings = {
      accountId: this.searchAcctConfigObj.accountId,
      enableBilling: this.editConfigSettingsForm.value.enableBilling,
      customParLevel: this.editConfigSettingsForm.value.customParLevel,
      shipDirToPtnt: this.editConfigSettingsForm.value.shipDirToPtnt,
      sla: this.editConfigSettingsForm.value.sla,
      autoApproved: this.editConfigSettingsForm.value.autoApproved,
      allowEditsInNavix: this.editConfigSettingsForm.value.allowEditsInNavix,
      interfaceVersion: this.getInterfaceVersionId(this.editConfigSettingsForm.value.interfaceVersion),
      hoaPrintType: this.editConfigSettingsForm.value.hoaPrintType?this.editConfigSettingsForm.value.hoaPrintType:null
    }
    this.customerAccountManagementService.saveAccountConfigDetails(this.updateAcctConfigSettings).subscribe((response: any) => {
      if (response && response.hasError) {
        //test error
        this.toastr.error('Error occured while saving the data.');
      }
      else {
        this.toastr.success('Data updated successfully.');
        this.dialogRef.close('save');
      }
    });
  }

  onCloseDialog() {
    this.dialogRef.close();
  }

  checkValid() {
    if (this.editConfigSettingsForm.value.autoApproved != false || this.editConfigSettingsForm.value.allowEditsInNavix != false) {
      return false;
    } else {
      return true;
    }
  }

  getInterfaceVersionDetails() {
    this.interfaceVersionList = this.userService.getStaticDataDetails();
    Object.keys(this.interfaceVersionList).forEach((index) => {
      if (this.interfaceVersionList[index].staticDataCategory == "Interface_Version") {
        this.interfaceVersionDetails.push(this.interfaceVersionList[index]);
      }
    });
    return this.interfaceVersionDetails;
  }

  getInterfaceVersionString(currentVersion: number) {
    Object.keys(this.interfaceVersionDetails).forEach((index) => {
      if (this.interfaceVersionDetails[index].staticDataCode == currentVersion) {
        this.interfaceVersionString = this.interfaceVersionDetails[index].staticDataValue;
      }
    });
    return this.interfaceVersionString;
  }

  getInterfaceVersionId(currentVersion: number) {
    Object.keys(this.interfaceVersionDetails).forEach((index) => {
      if (this.interfaceVersionDetails[index].staticDataValue == currentVersion) {
        this.interfaceVersionId = this.interfaceVersionDetails[index].staticDataCode;
      }
    });
    return this.interfaceVersionId;
  }

  getSlaDetails() {
    this.slaList = this.userService.getStaticDataDetails();
    Object.keys(this.slaList).forEach((index) => {
      if (this.slaList[index].staticDataCategory == "Accepted_SLA") {
        this.slaDetails.push(this.slaList[index]);
      }
    });
    return this.slaDetails;
  }

  getBillingData() {
    this.customerAccountManagementService.getAccountBillingDetails(this.searchAcctConfigObj).subscribe((res: any) => {
      this.billingData = !res.hasError ? (res.data ? res.data.billingExceptions :[]): [];
      this.dataSource = new MatTableDataSource(this.billingData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      if (this.billingData && this.billingData.length > 0) {
        this.noDataFlag = false;
      }
      else {
        this.noDataFlag = true;
      }
    });
  }

  onDialogOpen(action: string, data?: any) {
    this.openDialog(action, data);
  }
  
/*********************Component Methods*********************/

  /*********************Dialog Methods*********************/
  public openDialog(action: string, data?: any) {
    let dialogData = {
      data: data ? data : null,
      exceptionData: this.billingData,
      action: action,
      accountId: this.accountId,
      accountName: this.accountName
    }
    const dialogRef = this.dialog.open(ModalDialogManageDataComponent, {
      data: dialogData,
      width: '70%',
      autoFocus: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result == 'get data') {
        this.getBillingData(); //reload billing data after data update
      }
    });
  }
/* istanbul ignore next */
  inProgAlert() {
    alert("Delete implementation is in progress");
  }

  /*********************Dialog Methods*********************/

/*********************Init/Reset Methods*********************/
private init() {    
   //form initialization
  this.editConfigSettingsForm = this.formBuilder.group({
    enableBilling:[null, [Validators.required]],
    customParLevel: [null, [Validators.required]],
    shipDirToPtnt:[null, [Validators.required]],
    sla:[null, [Validators.required]],
    autoApproved:[null, [Validators.required]],
    allowEditsInNavix:[null, [Validators.required]],
    interfaceVersion:[null, [Validators.required]],
    hoaPrintType:[null, [Validators.required]]
  });
  
  this.interfaceVersionDetails = this.getInterfaceVersionDetails();
  this.slaDetails = this.getSlaDetails();
  this.getAccountConfigDetails(this.data);
}

  /*********************Init/Reset Methods*********************/
}
