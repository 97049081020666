import { Component, OnInit, OnDestroy } from '@angular/core';
import { PrintService } from 'src/app/shared/utils/print/print.service';

import { AuthService } from '../../core/services/auth.service';
import { RouteUtilService } from '../../shared/utils/route-util.service';


@Component({
  selector: 'wbd-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy {
  /*********************Properties*********************/
  private subscription: any = {
    signOut: null
  };

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private authService: AuthService,
    private routeUtils: RouteUtilService,
    public printService: PrintService
  ) { }
  /*********************Constructor*********************/

  /*********************Implementation Methods*********************/

  ngOnInit() {
    this.subscription.signOut = this.authService.signedOut$.subscribe(
      response => this.onUserSignedOut(response)
    );
  }

  ngOnDestroy() {
    this.subscription.signOut.unsubscribe();
  }

  /*********************Implementation Methods*********************/

  /*********************Component Methods*********************/

  /*********************Component Methods*********************/

  /*********************Private Methods*********************/

  private onUserSignedOut(response: any) {
    // if (!response.hasError) {
    this.routeUtils.navigate(response.redirect.url);
    // }
  }

  /*********************Private Methods*********************/
}
