<h2 mat-dialog-title> {{dialogTitle}}
    <mat-icon class="dialog-icon" (click)="onCloseDialog()">close</mat-icon>
</h2>
<h3><strong>Inventory Account:</strong> {{accNumAndName}}</h3>
<h3><strong>Custom Par Level Qty:</strong><strong style="color: red;"> {{parLevel}}</strong></h3>
<h3><strong> Drug Name:</strong> {{description}}</h3>
<h3><strong>NDC:</strong> {{ndc}}</h3> 
<h3><strong>Source Item Num:</strong> {{srcItemNum}}</h3> 
<mat-dialog-actions align="end">
    <button mat-button mat-stroked-button color="primary" (click)="onCloseDialog()">Cancel</button>
    <button mat-button cdkFocusInitial mat-flat-button color="primary"
      class="button primary" (click)="onConfirm()">Save</button>
  </mat-dialog-actions>