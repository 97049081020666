<mat-card>
    <mat-card-header>
        <mat-card-title>Held Queue</mat-card-title>
    </mat-card-header>
    <mat-card-content>

        <!-- Search Filter -->
        <form name="searchForm" [formGroup]="searchForm" novalidate>
            <div class="search-bar held-queue-search-bar row">
                <div class="mandatory-fields col-s2">
                    <div class="bottom">
                        <label>Account:</label>
                        <app-account-select></app-account-select>
                    </div>
                </div>
                <div class="optional-fields col-s6">
                    <div class="left col-s1">
                        <label class="label-txt">Manifest ID:</label>
                        <mat-form-field>
                            <input matInput type="text" min="0" maxlength="60" placeholder="Enter Manifest ID..."
                                name="manifestId" formControlName="manifestId" #manifestIdSearchInput />
                        </mat-form-field>
                    </div>
                    <div class="right col-s3">
                        <label class="label-txt">Select Order Received Date:</label>
                        <mat-form-field appearance="fill" style="margin-right:15px" [owlDateTimeTrigger]="fdate">
                            <mat-label>From</mat-label>
                            <input matInput [owlDateTime]="fdate" formControlName="fromDate" placeholder="MM/DD/YYYY"
                                readonly>
                            <owl-date-time #fdate [pickerType]="'calendar'"></owl-date-time>
                            <span class="dateTimeIcon material-icons">
                                calendar_today
                            </span>
                        </mat-form-field>
                        <mat-form-field appearance="fill" [owlDateTimeTrigger]="tdate">
                            <mat-label>To</mat-label>
                            <input matInput [owlDateTime]="tdate" [min]="minDate" formControlName="toDate"
                                placeholder="MM/DD/YYYY" readonly>
                            <owl-date-time #tdate [pickerType]="'calendar'"></owl-date-time>
                            <span class="dateTimeIcon material-icons">
                                calendar_today
                            </span>
                        </mat-form-field>
                    </div>
                </div>
                <div class="search-bar-options col-s4">
                    <button mat-flat-button color="primary" class="button primary" (click)="onSearchClick()"
                        [disabled]="disableSearchFlag"> Search
                    </button>
                    <button mat-stroked-button color="primary" class="clear-filter" (click)="onClearSearchResults()">
                        Clear search results
                    </button>

                </div>
            </div>
        </form>
        <!-- Search Filter -->

        <!-- Held Queue Data -->
        <div class="mat-elevation-z8 hq-table">
            <table mat-table [dataSource]="dataSource" aria-describedby="Held Queue View" #heldSort
                class="held-queue-table" multiTemplateDataRows matSort matSortActive="statusWeight"
                matSortDirection='asc'>

                <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef scope="col"> </th>
                    <td mat-cell *matCellDef="let row">
                        <div style="display:flex">
                            <mat-icon class="material-icons-outlined" *ngIf="!row.toggleItem" title="expand"
                                (click)="toggleRow(row, 'expand')">add_circle_outline</mat-icon>
                            <mat-icon class="material-icons-outlined" *ngIf="row.toggleItem" title="collapse"
                                (click)="toggleRow(row, 'collapse')">remove_circle_outline</mat-icon>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="accNum">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Ship to Account</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.accNum}} </td>
                </ng-container>

                <ng-container matColumnDef="accName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Ship to Account Name</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.accName}} </td>
                </ng-container>

                <ng-container matColumnDef="batchId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Manifest ID</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.batchId}} </td>
                </ng-container>

                <ng-container matColumnDef="groupId">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Group ID</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.groupId}} </td>
                </ng-container>

                <ng-container matColumnDef="addDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Date Received</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.addDateFormat}} </td>
                </ng-container>

                <ng-container matColumnDef="displayReason">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Order Reason</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.displayReason}} </td>
                </ng-container>

                <ng-container matColumnDef="shipToPatient">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Shipping Location</th>
                    <td mat-cell *matCellDef="let row">
                        <span *ngIf="row.shipToPatient">Patient</span>
                        <span *ngIf="!row.shipToPatient">Clinic</span>
                    </td>
                </ng-container>

                <ng-container matColumnDef="groupLineTotal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Number of lines</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.groupLineTotal}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="groupHeaderTotal">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Number of Orders</th>
                    <td mat-cell *matCellDef="let row">
                        {{row.groupHeaderTotal}} </td>
                </ng-container>

                <ng-container matColumnDef="statusWeight">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" [hidden]="hideFlag"></th>
                    <td mat-cell *matCellDef="let row" [hidden]="hideFlag">
                        {{row.statusWeight}} </td>
                </ng-container>

                <!-- expanded cols -->
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                        <div class="example-element-detail"
                            [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="inner-table mat-elevation-z8" *ngIf="element == expandedElement">
                                <table #innerTables mat-table #heldDetailSort="matSort"
                                    aria-describedby="Held Queue Inner Grid" [dataSource]="dataSourceGroupDetail" matSort>
                                    <ng-container matColumnDef="select">
                                        <th mat-header-cell *matHeaderCellDef scope="col">
                                            <mat-checkbox (change)="$event ? masterToggle() : null"
                                                [checked]="selection.hasValue() && isAllSelected()"
                                                [indeterminate]="selection.hasValue() && !isAllSelected()"
                                                [disabled]="element.displayStatus !== 'Held'">
                                            </mat-checkbox>
                                        </th>
                                        <td mat-cell *matCellDef="let row">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="$event ? selection.toggle(row) : null"
                                                [checked]="selection.isSelected(row)"
                                                [disabled]="element.displayStatus !== 'Held'">
                                            </mat-checkbox>
                                        </td>
                                    </ng-container>
                                    <ng-container matColumnDef="foNum">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Order ID
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.foNum}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="rxNum">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Rx
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.rxNum}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="shipLocation">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Shipping
                                            Location
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.shipLocation}} </td>
                                    </ng-container>
                                    <ng-container matColumnDef="patientName">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Patient
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.patientName}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="ndc">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> NDC
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.ndcTxt}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="srcItemNum">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Source Item
                                            Number
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.srcItemNum}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="cin">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> CIN
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.cin}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="description">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Drug
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.description}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="rxQtyDspns">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Qty
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.rxQtyDspns }}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="status">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Line Item Status
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.displayStatus}}</td>
                                    </ng-container>
                                    <ng-container matColumnDef="detailReason">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Line Reason
                                        </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.displayReason}}</td>
                                    </ng-container>
                                    <tr mat-header-row *matHeaderRowDef="innerDisplayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: innerDisplayedColumns;" [ngClass]="{'highlight-row': row.displayStatus === 'Held'}"></tr>
                                </table>

                                <!-- Row shown when there is no matching data. -->
                                <div *ngIf="noDetailsDataFlag" class="no-records">
                                    No records found
                                </div>
                                <!-- Row shown when there is no matching data. -->
                                <mat-paginator #heldDetailPaginator [pageSizeOptions]="[100, 200, 500, 1000]">
                                </mat-paginator>
                                <div class="btns" *ngIf="!noDetailsDataFlag">
                                    <button mat-flat-button color="primary" class="button primary" *ngIf="element.displayStatus == 'Held'"
                                        (click)="onDeleteClick(selection, 'Delete')" [disabled]="!selection.hasValue()">Delete Selected
                                    </button>
                                    <button mat-flat-button color="primary" class="button primary" *ngIf="element.displayStatus !== 'Held'" [disabled]="element.displayStatus !== 'Held'">Delete Selected
                                    </button>
                                    <button mat-flat-button color="primary" class="button primary" (click)="onReleaseClick(element, 'Release')" *ngIf="element.displayStatus == 'Held'" [disabled]="disableReleaseBtnFlag || selection.hasValue()">Release Order
                                    </button>
                                    <button mat-flat-button color="primary" class="button primary" *ngIf="element.displayStatus !== 'Held'" [disabled]="true">Release Order
                                    </button>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <!-- expanded cols -->

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns;" [class.example-element-row]="element"
                    [class.example-expanded-row]="expandedElement === element">
                </tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']"  class="example-detail-row"></tr>

            </table>
            <!-- Row shown when there is no matching data. -->
            <div *ngIf="noDataFlag" class="no-records">
                No records found
            </div>
            <!-- Row shown when there is no matching data. -->
            <mat-paginator #heldPaginator [pageSizeOptions]="[5, 10, 25, 100, 200]"></mat-paginator>
        </div>
        <!-- Held Queue Data -->

    </mat-card-content>
</mat-card>