import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs/internal/Observable';
import { catchError } from 'rxjs/internal/operators/catchError';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/internal/operators/map';

import { HttpHeader } from '../constants/app.constant';
import { Environment } from 'src/app/shared/models/environment';


@Injectable({
  providedIn: 'root',
})
export class HttpService {
  /*********************Properties*********************/
  protected get options() {
    const headers = {};
    const apiKey = Environment.getInstance().appConfig.apiKey;
    /* tslint:disable:no-string-literal */
    // headers['Accept'] = 'application/json';
    // headers['Access-Control-Allow-Methods'] = '*';
    // headers['Allow'] = '*';
    // headers['Cache-Control'] = 'no-cache, no-store';
    // headers['Pragma'] = 'no-cache';
    // headers['Expires'] = 'Sat, 01 Jan 2000 00:00:00 GMT';
    // headers[HttpHeader.ContentType] = 'application/json';
    /* tslint:enable:no-string-literal */
    if (apiKey) {
      headers[HttpHeader.ApiKey] = apiKey;
    }
    return {
      headers
    };
  }

  private readonly httpMethod = {
    GET: 'get',
    POST: 'post',
    PUT: 'put',
    DELETE: 'delete',
    Patch: 'patch'
  };
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(protected http: HttpClient) { }
  /*********************Constructor*********************/

  /*********************Service Methods*********************/

  protected fetch<T>(url: string, options?: any | {}): Observable<T> {
    return this.invokeService<T>(this.httpMethod.GET, url, null, options);
  }

  protected post<T>(
    url: string,
    body: object,
    options?: any | {}
  ): Observable<T> {
    return this.invokeService<T>(this.httpMethod.POST, url, body, options);
  }

  protected put<T>(
    url: string,
    body: object,
    options?: any | {}
  ): Observable<T> {
    return this.invokeService<T>(this.httpMethod.PUT, url, body, options);
  }

  protected patch<T>(
    url: string,
    body?: object,
    options?: any | {}
  ): Observable<T> {
    return this.invokeService<T>(this.httpMethod.Patch, url, body, options);
  }

  protected delete<T>(
    url: string,
    body?: object,
    options?: any | {}
  ): Observable<T> {
    return this.invokeService<T>(this.httpMethod.DELETE, url, body, options);
  }

  /*********************Service Methods*********************/

  /*********************Private Methods*********************/

  private invokeService<T>(
    method: string,
    url: string,
    body?: object | null,
    options?: any | {}
  ): Observable<T> {
    let reqOptions = Object.assign(this.options);
    if (options && options.headers) {
      reqOptions.headers = Object.assign(reqOptions.headers, options.headers);
      delete options.headers;
    }

    reqOptions = Object.assign(reqOptions, options);

    if (body) {
      reqOptions.body = body;
    }

    return this.http.request<T>(method, url, reqOptions).pipe(
      map((response) => this.onHttpResponse(response)),
      catchError(this.onHttpError())
    );
  }

  private onHttpResponse(response: any) {
    const res: any = {
      data: response,
      error: null,
      hasError: false,
    };

    return res;
  }

  private onHttpError() {
    return (error: any): Observable<any> => {
      const res: any = {
        data: null,
        error,
        hasError: true,
      };

      return of(res);
    };
  }

  /*********************Private Methods*********************/
}
