import { ApiConfig, AppConfig as ApplicationConfig, OktaConfig } from './app';
import { LogLevel } from 'src/app/core/constants/app.constant';

export class Environment {
  /*********************Properties*********************/
  get appConfig(): ApplicationConfig {
    return this.applicationConfiguration;
  }

  set appConfig(config: ApplicationConfig) {
    this.applicationConfiguration = config;
  }

  get apiConfig(): ApiConfig {
    return this.apiConfiguration;
  }

  set apiConfig(config: ApiConfig) {
    this.apiConfiguration = config;
  }

  get oktaConfig(): OktaConfig {
    return this.oktaConfiguration;
  }

  set oktaConfig(config: OktaConfig) {
    this.oktaConfiguration = config;
  }

  private static instance: Environment;

  private applicationConfiguration: any = {
    apiKey: '',
    appDown: false,
    appLoaded: false,
    apiTimeOut: '60',
    internalLoginUrl: null,
    logger: {
      serverLog: false,
      serverLogLevel: LogLevel.ERROR,
      logLevel:
        LogLevel.LOG
      // environment.name === AppEnv.Production ? LogLevel.LOG : LogLevel.DEBUG
    },
    apigeeTokenEndpoint: '',
    baseApiUrl: '',
    production: false,
    appIdleTimeOut: null,
    wbdInternalUrl: '',
  };

  private apiConfiguration: ApiConfig = {
    config: '',
    mdm: '',
    order: '',
    wbd: '',
    auth: '',
    rbcGLNUrl: '',
    // mdm: '',
    // wbd: '',
    user: '',
    apigeeExternalAppBaseUrl: '',
    apigeeExternalAppRBCToken: '',
    rbcClientCredentialsUrl: ''
  };

  private oktaConfiguration: OktaConfig = {
    clientId: '',
    issuer: '',
    scope: '',
    logoutUri: '',
    redirectUri: ''
  };
  /*********************Properties*********************/

  /*********************Constructor*********************/
  private constructor() { }
  /*********************Constructor*********************/

  /*********************Methods*********************/

  static getInstance() {
    if (!Environment.instance) {
      Environment.instance = new Environment();
    }
    return Environment.instance;
  }


  /*********************Methods*********************/
}
