<mat-card>
	<mat-card-header>
		<mat-card-title>Customer Account Management</mat-card-title>
	</mat-card-header>
    <mat-card-content>
        <!-- Search Filter -->
        <form name="customerSearchForm" [formGroup]="customerSearchForm" novalidate>
            <div class="search-bar customer-acct-mangmt-search">
                <div class="mat-form-search-cont">
                    <mat-form-field>
                        <input matInput type="text" placeholder="Enter your search..." name="keyword"
                        formControlName="keyword" (paste)="onCopyPaste($event)" 
                        (keyup)="onSearchFilter(customerSearchForm.controls.keyword)"
                                #searchInput required />
                            <a mat-button [hidden]="!clearSearchFlag" matSuffix mat-icon-button
                            aria-label="x" (click)="onClearSearch()">
                            <mat-icon class="close-icon">close</mat-icon>
                        </a>
                        <mat-hint align="start">Please enter search criteria. For more information check <i
                            class="fa fa-info-circle info-icon" aria-hidden="true"></i> icon. </mat-hint>
                        <mat-error *ngIf="customerSearchForm.get('keyword').hasError('InvalidSearch')">
                            Invalid Entry. Please refer to the selection criteria <i
                            class="fa fa-info-circle info-icon" aria-hidden="true"
                            style="font-size: 16px"></i>
                        </mat-error>
                    </mat-form-field>
                    <i class="fa fa-info-circle info-icon" aria-hidden="true" matTooltip="Note: &#13;
                            Search by SAP or Legacy Account numbers or Customer Name" matTooltipClass="tool-tip-class"
                            aria-label="Button that shows a tooltip"></i>
                    <button mat-flat-button color="primary" class="button primary" (click)="onCustomerSearchClick()"
                    [disabled]="disableSearchFlag || customerSearchForm.get('keyword').hasError('InvalidSearch')">
                        Search
                    </button>
                </div>
                <div class="search-bar-options">
                    <button mat-stroked-button color="primary" class="clear-filter condensed-btn" 
                    (click)="onClearSearchResults()">
                        Clear search results
                    </button>
                </div>
            </div>
        </form>
        <!-- Search Filter -->

        <!-- Customer Account Management Data -->
        <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" aria-describedby="customer account management view" matSort>

                <ng-container matColumnDef="sapAccountNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> SAP Account </th>
                    <td mat-cell *matCellDef="let row"> {{row.sapAccountNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="legacyAccountNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Legacy Account </th>
                    <td mat-cell *matCellDef="let row"> {{row.legacyAccountNumber}} </td>
                </ng-container>

                <ng-container matColumnDef="accountName1">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Name </th>
                    <td mat-cell *matCellDef="let row"> {{row.accountName1}} </td>
                </ng-container>
                                            
                <ng-container matColumnDef="address1">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Address </th>
                    <td mat-cell *matCellDef="let row"> {{row.address1}} {{row.address2}} </td>
                </ng-container>

                <ng-container matColumnDef="city">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> City </th>
                    <td mat-cell *matCellDef="let row"> {{row.city}} </td>
                </ng-container>

                <ng-container matColumnDef="state">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> State </th>
                    <td mat-cell *matCellDef="let row"> {{row.state}} </td>
                </ng-container>

                <ng-container matColumnDef="customer_actions">
                    <th mat-header-cell *matHeaderCellDef scope="col"> </th>
                    <td mat-cell *matCellDef="let row">
                        <div class="customer-action">
                            <mat-icon class="grid-icon" title="edit" (click)="openDialog(row)">edit</mat-icon>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div *ngIf="noDataFlag" class="no-records">
				No records found
			</div>
            <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 200]"></mat-paginator>
        </div>
        <!-- Customer Account Management Data -->
        
    </mat-card-content>
</mat-card>
