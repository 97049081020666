<mat-card>
    <mat-card-header>
        <mat-card-title>Inventory</mat-card-title>
    </mat-card-header>
    <mat-card-content>

        <mat-tab-group dynamicHeight #tabGroup (selectedTabChange)="tabChanged($event)">
            <mat-tab label="Fulfillment Facility" *ngIf="permissionAssignment.viewFulfillmentFacility">
                <div class="example-small-box mat-elevation-z4">
                    <!-- Search Filter -->
                    <form name="facilitySearchForm" [formGroup]="facilitySearchForm" novalidate>
                        <div class="search-bar inventory-search">
                            <div class="mat-form-search-cont">
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Enter your search..." name="searchText"
                                        formControlName="searchText" (paste)="onCopyPaste($event)"
                                        (keyup)="onSearchFilter(facilitySearchForm.controls.searchText, 'facility')"
                                        #facilitySearchInput />
                                    <a mat-button [hidden]="!clearSearchFlag" matSuffix mat-icon-button aria-label="x"
                                        (click)="onClearSearch()">
                                        <mat-icon class="close-icon">close</mat-icon>
                                    </a>
                                    <mat-hint align="start">Please enter search criteria. For more information check <i
                                            class="fa fa-info-circle info-icon" aria-hidden="true"></i> icon.
                                    </mat-hint>
                                    <mat-error *ngIf="facilitySearchForm.get('searchText').hasError('InvalidSearch')">
                                        Invalid Entry. Please refer to the selection criteria <i
                                            class="fa fa-info-circle info-icon" aria-hidden="true"
                                            style="font-size: 16px"></i>
                                    </mat-error>
                                </mat-form-field>
                                <i class="fa fa-info-circle info-icon" aria-hidden="true" matTooltip="Note: &#13;
                                NDC (exactly 11 digits numeric),&#13;
                                CIN (numeric),&#13;
                                Source Item Code (numeric or alphanumeric), &#13;
                                Generic Name, or Trade Name (starts with 3 char- alphanumeric)" matTooltipClass="tool-tip-class" 
                                aria-label="Button that shows a tooltip"></i>
                                <button mat-flat-button color="primary" class="button primary"
                                    (click)="onFacilitySearchClick()"
                                    [disabled]="disableSearchFlag || facilitySearchForm.get('searchText').hasError('InvalidSearch')">
                                    Search
                                </button>
                            </div>

                            <div class="search-bar-options">
                                <button mat-stroked-button color="primary" class="clear-filter"
                                    (click)="onClearSearchResults()">
                                    Clear search results
                                </button>
                                <!-- <button mat-stroked-button color="primary" title="Download Excel"
                                    aria-label="Export to Excel" class="export-btn"
                                    (click)="onExportReport('NavixRX Balance on Hand')">
                                    <span style="font-size: 20px;" class="fas fa-download"></span>
                                </button> -->
                            </div>
                        </div>
                    </form>
                    <!-- Search Filter -->

                    <!-- Facility Inventory Data -->
                    <div class="mat-elevation-z8 inventory-facility-table">
                        <table mat-table [dataSource]="dataSource" aria-describedby="Facility Inventory view" #t1sort
                            matSort>

                            <ng-container matColumnDef="ndc">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">NDC</th>
                                <td mat-cell *matCellDef="let facInvRow" class="clickable" (click)="onEditInventory(facInvRow, 'facilityInventory')">
                                    {{facInvRow.ndcText}} </td>
                            </ng-container>

                            <ng-container matColumnDef="sourceItemCode">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Source Item Code</th>
                                <td mat-cell *matCellDef="let facInvRow" class="clickable no-wrap" (click)="onEditInventory(facInvRow, 'facilityInventory')">
                                    {{facInvRow.srcItemCde}} </td>
                            </ng-container>

                            <ng-container matColumnDef="cin">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">CIN</th>
                                <td mat-cell *matCellDef="let facInvRow" class="clickable" (click)="onEditInventory(facInvRow, 'facilityInventory')">
                                    {{facInvRow.cin}} </td>
                            </ng-container>

                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Description</th>
                                <td mat-cell *matCellDef="let facInvRow" class="clickable" (click)="onEditInventory(facInvRow, 'facilityInventory')">
                                    {{facInvRow.description}} </td>
                            </ng-container>

                            <ng-container matColumnDef="genericName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Generic Name</th>
                                <td mat-cell *matCellDef="let facInvRow" class="clickable" (click)="onEditInventory(facInvRow, 'facilityInventory')">
                                    {{facInvRow.genericName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="tradeName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Trade Name</th>
                                <td mat-cell *matCellDef="let facInvRow" class="clickable" (click)="onEditInventory(facInvRow, 'facilityInventory')">
                                    {{facInvRow.tradeName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="totalOnHand">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Virtual Inventory"
                                    matTooltipClass="table-tooltip" scope="col">Total On Hand</th>
                                <td mat-cell *matCellDef="let facInvRow">
                                    {{facInvRow.totalOnHand}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="packSize">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Pack Size</th>
                                <td mat-cell *matCellDef="let facInvRow">
                                    {{facInvRow.packSize}} </td>
                            </ng-container>

                            <ng-container matColumnDef="packQty">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Pack Qty</th>
                                <td mat-cell *matCellDef="let facInvRow">
                                    {{facInvRow.packQty}} </td>
                            </ng-container>

                            <ng-container matColumnDef="strength">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Strength</th>
                                <td mat-cell *matCellDef="let facInvRow">
                                    {{facInvRow.strength}} </td>
                            </ng-container>

                            <ng-container matColumnDef="uom">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">UOM</th>
                                <td mat-cell *matCellDef="let facInvRow">
                                    {{facInvRow.baseUom}} </td>
                            </ng-container>

                            <ng-container matColumnDef="itemType">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Item Type</th>
                                <td mat-cell *matCellDef="let facInvRow">
                                    {{facInvRow.itemType}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedFacilityColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedFacilityColumns;"></tr>

                        </table>
                        <!-- Row shown when there is no matching data. -->
                        <div *ngIf="noDataFlag" class="no-records">
                            No records found
                        </div>
                        <mat-paginator #t1paginator [pageSizeOptions]="[5, 10, 25, 100, 200]"></mat-paginator>
                    </div>
                    <!-- Facility Inventory Data -->
                </div>
            </mat-tab>
            <mat-tab label="Customer Account" *ngIf="permissionAssignment.viewCustomerInventory">
                <div class="example-large-box mat-elevation-z4">
                    <!-- Search Filter -->
                    <form name="customerAcctSearchForm" [formGroup]="customerAcctSearchForm" novalidate>
                        <div class="search-bar inventory-customer-search">
                            <div class="mat-form-search-cont">
                                <mat-form-field class="account-selection">
                                    <mat-label class="label-txt">Select Account</mat-label>  
                                    <mat-select formControlName="accountData" required>
                                      <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search..."></ngx-mat-select-search>
                                      </mat-option>
                                      <mat-option *ngFor="let accData of customerAccounts" [value]="accData">
                                        {{accData.accNumAndName}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                <mat-form-field>
                                    <input matInput type="text" placeholder="Enter your search..." name="searchText"
                                        formControlName="searchText" (paste)="onCopyPaste($event)"
                                        (keyup)="onSearchFilter(customerAcctSearchForm.controls.searchText, 'customer')"
                                        #customerSearchInput />
                                    <a mat-button [hidden]="!clearSearchFlag" matSuffix mat-icon-button aria-label="x"
                                        (click)="onClearSearch()">
                                        <mat-icon class="close-icon">close</mat-icon>
                                    </a>
                                    <mat-hint align="start">Please enter search criteria. For more information check <i
                                            class="fa fa-info-circle info-icon" aria-hidden="true"></i> icon.
                                    </mat-hint>
                                    <mat-error *ngIf="customerAcctSearchForm.get('searchText').hasError('InvalidSearch')">
                                        Invalid Entry. Please refer to the selection criteria <i
                                            class="fa fa-info-circle info-icon" aria-hidden="true"
                                            style="font-size: 16px"></i>
                                    </mat-error>
                                </mat-form-field>

                                <i class="fa fa-info-circle info-icon" aria-hidden="true" matTooltip="Note: &#13;
                                NDC (exactly 11 digits numeric),&#13;
                                CIN (numeric),&#13;
                                Source Item Code (numeric or alphanumeric), &#13;
                                Generic Name, or Trade Name (starts with 3 char- alphanumeric)" matTooltipClass="tool-tip-class"
                                aria-label="Button that shows a tooltip"></i>
                                <button mat-flat-button color="primary" class="button primary"
                                    (click)="onCustomerSearchClick()"
                                    [disabled]="customerAcctSearchForm.status === 'INVALID'">
                                    Search
                                </button>
                            </div>
                            <div class="search-bar-options">
                                <button mat-stroked-button color="primary" class="clear-filter"
                                    (click)="onClearSearchResults()">
                                    Clear search results
                                </button>
                                <!-- <button mat-stroked-button color="primary" title="Download Excel"
                                    aria-label="Export to Excel" class="export-btn"
                                    (click)="onExportReport('Inventory On Hand')">
                                    <span style="font-size: 20px;" class="fas fa-download">
                                    </span>
                                </button> -->
                            </div>
                        </div>
                    </form>

                    <div class="mat-elevation-z8">
                        <table mat-table [dataSource]="dataSource" aria-describedby="Customer Account view" #t2sort
                            matSort>

                            <ng-container matColumnDef="ndcText">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">NDC</th>
                                <td mat-cell *matCellDef="let invRow">
                                    {{invRow.ndcText}} </td>
                            </ng-container>

                            <ng-container matColumnDef="srcItemNum">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Source Item Code</th>
                                <td mat-cell *matCellDef="let invRow">
                                    {{invRow.srcItemNum}} </td>
                            </ng-container>
                            
                            <ng-container matColumnDef="cin">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">CIN</th>
                                <td mat-cell *matCellDef="let invRow">
                                    {{invRow.cin}} </td>
                            </ng-container>

                            <ng-container matColumnDef="description">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Description</th>
                                <td mat-cell *matCellDef="let invRow">
                                    {{invRow.description}} </td>
                            </ng-container>

                            <ng-container matColumnDef="genericName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Generic Name</th>
                                <td mat-cell *matCellDef="let invRow">
                                    {{invRow.genericName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="tradeName">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Trade Name</th>
                                <td mat-cell *matCellDef="let invRow">
                                    {{invRow.tradeName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="onHand">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Virtual Inventory"
                                    matTooltipClass="table-tooltip" scope="col">On Hand</th>
                                <td mat-cell *matCellDef="let invRow">
                                    {{invRow.onHand}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="onOrder">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    matTooltip="Quantity pending replenishment" matTooltipClass="table-tooltip"
                                    scope="col">On Order</th>
                                <td mat-cell *matCellDef="let invRow">
                                    {{invRow.onOrder}} </td>
                            </ng-container>

                            <ng-container matColumnDef="allocated">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                    matTooltip="Quantity allocated for fulfillment" matTooltipClass="table-tooltip"
                                    scope="col">Allocated</th>
                                <td mat-cell *matCellDef="let invRow">
                                    {{invRow.allocated}}
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="packSize">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Pack Size</th>
                                <td mat-cell *matCellDef="let invRow">
                                    {{invRow.packSize}} </td>
                            </ng-container>

                            <ng-container matColumnDef="packQuantity">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Pack Qty</th>
                                <td mat-cell *matCellDef="let invRow">
                                    {{invRow.packQuantity}} </td>
                            </ng-container>

                            <ng-container matColumnDef="strength">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Strength</th>
                                <td mat-cell *matCellDef="let invRow">
                                    {{invRow.strength}} </td>
                            </ng-container>

                            <ng-container matColumnDef="baseUom">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">UOM</th>
                                <td mat-cell *matCellDef="let invRow">
                                    {{invRow.baseUom}} </td>
                            </ng-container>

                            <ng-container matColumnDef="itemType">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Item Type</th>
                                <td mat-cell *matCellDef="let invRow">
                                    {{invRow.itemType}} </td>
                            </ng-container>

                            <ng-container matColumnDef="parLevel">
                                <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Par Level</th>
                                <td mat-cell *matCellDef="let invRow">
                                    {{invRow.parLevel}} </td>
                            </ng-container>

                            <ng-container matColumnDef="actions">
                                <th mat-header-cell *matHeaderCellDef scope="col"></th>
                                <td mat-cell *matCellDef="let invRow">
                                    <div style="display:flex" [hidden]="invRow.itemType != 4">
                                        <mat-icon class="grid-icon" title="edit" (click)="onCustomParLevel(invRow, 'customerAccount')">edit</mat-icon>
                                    </div>
                                </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedCustomerAccountColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedCustomerAccountColumns;"></tr>

                        </table>
                        <div *ngIf="noDataFlag" class="no-records">
                            No records found
                        </div>
                        <mat-paginator #t2paginator [pageSizeOptions]="[5, 10, 25, 100, 200]"></mat-paginator>
                    </div>
                    <!-- Inventory Customer Data -->
                </div>
            </mat-tab>
        <mat-tab label="Replenishment Orders" *ngIf="permissionAssignment.viewReplenishmentOrders">
            <div class="example-large-box mat-elevation-z4">
                <form name="replenSearchForm" [formGroup]="replenSearchForm" novalidate>
                <!-- Search Filter -->
                    <div class="search-bar replenishment-order-search">
                        <div class="mandatory-fields">
                            <label class="label-txt">Account:</label>
                            <mat-form-field>
                                <mat-label>Select Account</mat-label>  
                                <mat-select formControlName="accountData" required>
                                  <mat-option>
                                    <ngx-mat-select-search placeholderLabel="Search..."></ngx-mat-select-search>
                                  </mat-option>
                                  <mat-option *ngFor="let accData of customerAccountsWithAll" [value]="accData">
                                    {{accData.accNumAndName}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                            </div>
                        <div class="optional-fields">
                            <div class="left">
                                <label class="label-txt">Created Date Range:</label>
                                <mat-form-field appearance="fill">
                                    <mat-label>From</mat-label>
                                    <input matInput [owlDateTime]="fdate" formControlName="fromDate" placeholder="MM/DD/YYYY">
                                    <owl-date-time #fdate [pickerType]="'calendar'"></owl-date-time>
                                    <span [owlDateTimeTrigger]="fdate" class="dateTimeIcon material-icons">
                                        calendar_today
                                    </span>
                                </mat-form-field>
                                <mat-form-field appearance="fill">
                                    <mat-label>To</mat-label>
                                    <input matInput [owlDateTime]="tdate" formControlName="toDate"
                                        placeholder="MM/DD/YYYY">
                                    <owl-date-time #tdate [pickerType]="'calendar'"></owl-date-time>
                                    <span [owlDateTimeTrigger]="tdate" class="dateTimeIcon material-icons">
                                        calendar_today
                                    </span>
                                </mat-form-field>
                            </div>
                            <div class="right">
                                <label class="label-txt">Order Status:</label>
                                <mat-form-field>
                                    <mat-label>Select</mat-label>
                                    <mat-select formControlName="status" multiple>
                                        <mat-option *ngFor="let oData of orderStatusDetails" [value]="oData.staticDataValue">{{oData.staticDataValue}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="search-bar-options">
                            <button mat-flat-button color="primary" class="button primary" [disabled]="!replenSearchForm.get('accountData').value"
                                (click)="onReplenishmentSearchClick()"> Search
                            </button>
                            <button mat-stroked-button color="primary" class="clear-filter" (click)="onClearSearchResults()">
                                Clear search results
                            </button>
                
                        </div>
                    </div>
                </form>
                <!-- Search Filter -->
                
                <!-- Replenishment Order Data -->
                <div class="mat-elevation-z8">
                    <table mat-table [dataSource]="dataSource" aria-describedby="Replenishment Order View" #t3sort matSort matSortActive="statusWeight" matSortDirection='asc'>

                        <ng-container matColumnDef="dispAccNum">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Account</th>
                            <td mat-cell *matCellDef="let replenRow">
                                {{replenRow.dispAccNum}} </td>
                        </ng-container>

                        <ng-container matColumnDef="accName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Account Name</th>
                            <td mat-cell *matCellDef="let replenRow">
                                {{replenRow.accName}} </td>
                        </ng-container>

                        <ng-container matColumnDef="dateAdded">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Replenishment Order Create Date</th>
                            <td mat-cell *matCellDef="let replenRow">
                                {{replenRow.dateAdded | date:'MM/dd/yyyy HH:mm a'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="rplnshOrderHdrId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Replenishment Order Number</th>
                            <td mat-cell *matCellDef="let replenRow">
                                {{replenRow.rplnshOrderHdrId}} </td>
                        </ng-container>

                        <ng-container matColumnDef="dateSentErp">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Date Sent to ERP</th>
                            <td mat-cell *matCellDef="let replenRow">
                                {{replenRow.dateSentErp | date:'MM/dd/yyyy HH:mm a'}} </td>
                        </ng-container>

                        <ng-container matColumnDef="erpOrderNum">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">ERP Order No</th>
                            <td mat-cell *matCellDef="let replenRow">
                                {{replenRow.erpOrderNum}} </td>
                        </ng-container>

                        <ng-container matColumnDef="dateLastUpdated">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Date Last Updated</th>
                            <td mat-cell *matCellDef="let replenRow">
                                {{replenRow.dateLastUpdated | date:'MM/dd/yyyy HH:mm a'}} </td>
                        </ng-container> 

                        <ng-container matColumnDef="rplnshHdrStatusDisplay">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Status</th>
                            <td mat-cell *matCellDef="let replenRow">
                                {{replenRow.rplnshHdrStatusDisplay}} </td>
                        </ng-container>

                        <ng-container matColumnDef="statusWeight">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col" [hidden]="hideFlag"></th>
                            <td mat-cell *matCellDef="let replenRow" [hidden]="hideFlag">
                                {{replenRow.statusWeight}} </td>
                        </ng-container>
                        
                        <ng-container matColumnDef="itemsOnOrder">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Items On Order</th>
                            <td mat-cell *matCellDef="let replenRow">
                                {{replenRow.itemsOnOrder}} </td>
                        </ng-container>

                        <ng-container matColumnDef="actions">
                            <th mat-header-cell *matHeaderCellDef scope="col"> Action</th>
                            <td mat-cell *matCellDef="let replenRow">
                                <div style="display:flex">
                                    <mat-icon class="grid-icon" (click)="onViewMoreDetails(replenRow, 'replenOrders')"><em class="fas fa-search-plus" style="display: block; margin-top: 20%;"></em></mat-icon>
                                </div>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedReplenishmentColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedReplenishmentColumns;" [ngClass]="{'pending-approval-row': row.rplnshHdrStatus === 'Pending Approval'}"></tr>

                    </table>
                    <!-- Row shown when there is no matching data. -->
                    <div *ngIf="noDataFlag" class="no-records">
                        No records found
                    </div>
                    <!-- Row shown when there is no matching data. -->
                    <mat-paginator #t3paginator [pageSizeOptions]="[5, 10, 25, 100, 200]"></mat-paginator>
                </div>
                <!-- Replenishment Order Data -->
            </div>
        </mat-tab>
    </mat-tab-group>
    </mat-card-content>
</mat-card>