import { AppEnv } from '../../src/app/core/constants/app.constant';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
let configUrl: string;

if (location.hostname.includes('navixrx.cardinalhealth') || location.hostname.includes('glnlookup.cardinalhealth')) {
 configUrl = 'https://api.cardinalhealth.com/wbd/config/api'
}
else if (location.hostname.includes('stg')){
  configUrl = 'https://api.stage.cardinalhealth.com/wbd/config/api'
}
else if (location.hostname.includes('dev')){
  configUrl = 'https://api.dev.cardinalhealth.com/wbd/config/api'
}
else {
  configUrl = 'https://api.dr.cardinalhealth.com/wbd/config/api'
}

export const environment = {
  name: AppEnv.Production,
  configUrl: configUrl,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
