import {
  Directive,
  AfterContentInit,
  HostListener,
  OnInit,
  OnDestroy
} from '@angular/core';

import { AuthService } from 'src/app/core/services/auth.service';

@Directive({
  selector: '[WBDAppIdleTimeout]'
})
export class AppIdleTimeoutDirective
  implements OnInit, AfterContentInit, OnDestroy {
  /***************Properties***************/
  private authenticated = false;
  private idleTimeOut = 15;
  private timer = null;

  private subscription = { authenticated: null, signedOut: null };
  /***************Properties***************/

  /***************Constructor***************/
  constructor(
    private authService: AuthService,
  ) { }
  /***************Constructor***************/

  /***************Implementation***************/
  ngOnInit(): void {
    /* istanbul ignore next */
    if (!location.href.includes('/gln')) {
      this.subscription.authenticated = this.authService.authenticated$.subscribe(
        () => {
          this.onUserAuthenticated();
        }
      );

      this.subscription.signedOut = this.authService.signedOut$.subscribe(() => {
        this.onUserSignedOut();
      });
    }
    /* istanbul ignore next */
    else {
      this.glnLookup();
    }
  }

  ngAfterContentInit(): void {
    this.init();
  }

  ngOnDestroy(): void {
    this.subscription.authenticated.unsubscribe();
    this.subscription.signedOut.unsubscribe();
  }

  /***************Implementation***************/

  /***************Events***************/

  /* istanbul ignore next */
  @HostListener('window:mousemove')
  resetAppIdleTimer() {
    /* istanbul ignore next */
    if (location.href.includes('/gln')) {
      this.glnLookup();
    }
    else if (this.authenticated) {
      this.resetTimer();
    }
    else {
      this.authService.signOut(true);
    }

  }

  /***************Events***************/

  /***************Private Methods***************/
  /* istanbul ignore next */
  private init() {
    if (!location.hostname.includes('/gln')) {
      this.verifyAuthentication();
    }
    else {
      this.glnLookup();
    }
  }

  private verifyAuthentication() {
    this.authService.isAuthenticated().then(authResponse => {
      this.authenticated = authResponse.authenticated.all;
      this.startTimer();
    });
  }

  /* istanbul ignore next */
  private glnLookup() {
    this.idleTimeOut = 1000;
    this.onUserAuthenticated();
  }

  private onUserAuthenticated() {
    this.authenticated = true;
    this.startTimer();
  }

  private onUserSignedOut() {
    this.authenticated = false;
    this.clearTimer();
  }

  private startTimer() {
    if (this.authenticated) {
      this.timer = setTimeout(() => {
        this.authService.signOut(true);
      }, this.idleTimeOut * 60 * 1000);
    }
  }

  /* istanbul ignore next */
  private resetTimer() {
    this.clearTimer();
    this.startTimer();
  }

  private clearTimer() {
    clearTimeout(this.timer);
  }

  /***************Private Methods***************/
}
