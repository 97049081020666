import { Injectable } from '@angular/core';
import { Router, NavigationEnd, Route } from '@angular/router';
import { RouteData } from 'src/app/shared/models/app';
import { filter } from 'rxjs/operators';
// import { LoadedRouterConfig } from '@angular/router/src/config';
import { OktaRoutes } from 'src/app/core/constants/routes.constant';



@Injectable()
export class RouteUtilService {
  /*********************Properties*********************/
  get previousUrl(): string {
    return this.prevUrl;
  }

  get activeUrl(): string {
    return this.currUrl;
  }

  private prevUrl = null;
  private currUrl = null;
  
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(private router: Router) {
    /* istanbul ignore next */
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.prevUrl = this.currUrl;
        this.currUrl = e.url;
      });
  }
  /*********************Constructor*********************/

  /*********************Utility Methods*********************/
/* istanbul ignore next */
  navigate(url) {
    this.router.navigateByUrl(url, { replaceUrl: true });
  }
/* istanbul ignore next */
  redirectToLogin(message: string = null) {
    this.navigate(OktaRoutes.Login.Url);
  }
/* istanbul ignore next */
  addRouteData(url: string, data: Array<RouteData>) {
    const route = this.getRoute(url);

    if (route && route.data) {
      data.forEach(d => {
        route.data[d.key] = d.value;
      });
    }
  }

  /*********************Private Methods*********************/
/* istanbul ignore next */
  private getRoute(url: string) {
    let urlRoute = null;

    if (url) {
      this.router.config.forEach(parent => {
        if (parent.loadChildren && !urlRoute) {
          urlRoute = this.getRouteConfig(parent, url);
        }

        if (parent.children && !urlRoute) {
          parent.children.forEach(route => {
            urlRoute = urlRoute || this.getRouteConfig(route, url);
          });
        }
      });
    }

    return urlRoute;
  }

  /* istanbul ignore next */
  private getRouteConfig(route: Route, url: string) {
    let urlRoute = null;

    if (route.loadChildren && route.loadChildren.length > 0) {
      const routerConfig = (route as any)['_loadedConfig'] as any;

      if (routerConfig) {
        if (url.charAt(0) === '/') {
          url = url.substr(1);
        }

        const routePath = route.path ? route.path + '/' : '';

        if (!urlRoute) {
          routerConfig.routes.forEach(r => {
            urlRoute = urlRoute || this.getUrlRoute(r, routePath + r.path, url);
          });
        }
      }
    }

    return urlRoute;
  }

  /* istanbul ignore next */
  private getUrlRoute(route: Route, parentUrl: string, url: string) {
    let urlRoute = null;
    parentUrl = !parentUrl.endsWith('/') ? parentUrl + '/' : parentUrl;
    urlRoute = (parentUrl + route.path)
      .toLowerCase()
      .startsWith(url.toLowerCase())
      ? route
      : null;

    if (!urlRoute && route.children) {
      route.children.forEach(p => {
        urlRoute = urlRoute || this.getUrlRoute(p, parentUrl + p.path, url);
      });
    }

    return urlRoute;
  }

}

