import { NgModule } from '@angular/core';
import { CacheModule, CacheLoader, CACHE } from '@ngx-cache/core';
import { BrowserCacheModule } from '@ngx-cache/platform-browser';

import { CacheFactory } from './cache.factory';
import { CacheResolverFactory } from './cache-resolver.factory';
import { CacheResolverService } from './cache-resolver.service';

@NgModule({
  imports: [
    CacheModule.forRoot({
      provide: CacheLoader,
      useFactory: CacheFactory
    }),
    BrowserCacheModule.forRoot([
      {
        provide: CACHE,
        useClass: CacheResolverFactory
      }
    ])
  ],
  providers: [CacheResolverService]
})
export class AppCacheModule {}
