
<div class="nav-header" *ngFor="let option of topMenuOption; let currentIndex = index" [title]="option.title">
  <a (click)="onMainMenuClicked(currentIndex)" [ngClass]="{ 'highlighted': option.config.highlight}"  *ngIf="option.isMenuVisible"> 
    <i class="fa {{option.src}} icon"  aria-hidden="true" #navigationItem></i>
  </a>
  
  <hr class="bold-divider hr-left-nav"/>
  </div>
  <div class="nav-bottom">
  <div class="nav-header" *ngFor="let option of bottomMenuOption; let currentIndex = index" [title]="option.title"
  [ngClass]="{ 'highlighted': option.config.highlight}"
  >
  
  <hr class="bold-divider hr-left-nav"/>
  <a (click)="onMainMenuClicked(currentIndex + topMenuOption.length)" *ngIf="option.isMenuVisible">   
    <span *ngIf="option.name === 'notification'">
      <i class="fa {{option.src}} icon"  aria-hidden="true" #navigationItem></i>
    </span>
    <i class="fa {{option.src}} icon"  aria-hidden="true" #navigationItem *ngIf="option.name !== 'notification'"></i>
  </a>
  <!-- <div class="nav-submenu shadow bottom-0" *ngIf="option.subMenuItems.length > 0 && option.config.showSubMenu">
    <ul class="line-start">
      <li>
        {{option.subMenuHeader}}  
        <a> 
          <i class="fa fa-times-circle submenu-close" aria-hidden="true" title="close"
          (click)="closeSubMenu()"></i>
        </a> 
      </li>    
      <hr class="bold-divider"/>
      <li 
        *ngFor="let subMenu of option.subMenuItems; let subMenuCurrentIndex = index" 
        (click)= "onSubMenuClicked(currentIndex + topMenuOption.length, subMenuCurrentIndex)" [title]="subMenu.name">
        {{subMenu.name}}
        <hr class="bold-divider"/>
      </li>  
    </ul>
  </div> -->
  </div>
  </div>
  