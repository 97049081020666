<h2 mat-dialog-title>
    <span *ngIf="isDeleteDialog">Confirm Delete</span>
    <span *ngIf="!isDeleteDialog">Confirm release of Group ID: {{data.releaseOrderDetails.groupID}}</span>
    <mat-icon class="dialog-icon" (click)="onCloseDialog()">close</mat-icon>
</h2>
<form [formGroup]="releaseDeleteForm" name="releaseDeleteForm" novalidate>
    <mat-dialog-content>
        <p *ngIf="!isDeleteDialog">
            <strong>Confirm release of Group ID: {{data.releaseOrderDetails.groupID}}</strong>
            <br /><br />
            Number of orders: {{data.releaseOrderDetails.numofGrpOrders}}
            <br />
            Number of lines: {{data.releaseOrderDetails.numofLinesGrpOrders}}
        </p>
        <div *ngIf="isDeleteDialog">
            <strong>Rx(s):</strong><br />
        <p *ngFor="let rxNum of data.releaseDeleteOrder.rxNum" style="margin-bottom: 5px;">
            {{rxNum}}
        </p>
        <mat-form-field appearance="fill">
            <mat-label>Reason for removing item(s):</mat-label>
            <textarea placeholder="Minimum of 10 characters required. Maximum 50 characters allowed" matInput #reason maxlength="50" formControlName="reason"></textarea>
            <mat-hint align="end">{{reason.value.length}} / 50</mat-hint>
        </mat-form-field>
    </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button cdkFocusInitial mat-stroked-button color="primary" (click)="onCloseDialog()">Cancel</button>
        <button mat-button mat-flat-button color="primary" *ngIf="!isDeleteDialog" class="button primary" (click)="onConfirmRelease()">Confirm
            Release</button>
            <button mat-button mat-flat-button color="primary" *ngIf="isDeleteDialog" class="button primary" (click)="onConfirmDelete()" [disabled]="(!releaseDeleteForm.get('reason').value || (releaseDeleteForm.get('reason').value.length < 10))">Confirm
                Delete</button>
    </mat-dialog-actions>
</form>