import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseService } from './../base.service';
import { AppService } from './../../../app.service';

import { Environment } from './../../../shared/models/environment';
import { environment } from 'src/environments/environment';
import { ApiPrefix, MiscApi } from '../../constants/api.constant';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService extends BaseService {
  /*********************Properties*********************/
  private environment: Environment = Environment.getInstance();
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(protected override http: HttpClient, private appService: AppService) {
    super(http);
  }
  /*********************Constructor*********************/
  /* istanbul ignore next */
  load() {
    return new Promise((resolve, reject) => {
      super
        .fetch(environment.configUrl + MiscApi.Settings)
        .subscribe((response: any) => {
          if (!response.hasError) {
            this.onConfigResponse(response.data);
            this.appService.init();

            this.appService.initOnLoad().then(load => {
              if (load) {
                this.appService.load().then(() => {
                  resolve(true);
                });
              } else {
                resolve(true);
              }
            }).catch((err) => {
              console.log(err)
            });
          }
        });
    });
  }

  /*********************Service Methods*********************/

  /*********************Private Methods*********************/
  // private getBaseUrl() {
  //   return window.location.origin
  //     ? window.location.origin
  //     : window.location.protocol + '//' + window.location.host;
  // }
  /* istanbul ignore next */
  private onConfigResponse(config: any) {
    this.environment.appConfig.appLoaded = true;
    this.environment.appConfig.apiKey = config.apiKey;
    this.environment.appConfig.apiTimeOut = config.apiTimeOut;
    this.environment.appConfig.internalLoginUrl = config.internalLoginUrl;

    this.environment.appConfig.apigeeTokenEndpoint = config.apigeeTokenEndpoint;
    this.environment.appConfig.baseApiUrl = config.baseApiUrl;
    this.environment.appConfig.production = false;
    this.environment.appConfig.wbdInternalUrl = config.wbdInternalUrl;
    this.environment.appConfig.appIdleTimeOut = config.appIdleTimeOut;

    this.environment.apiConfig = {
      mdm: config.mdmUrl + ApiPrefix.Mdm,
      rbcGLNUrl: config.rbcGLNUrl + '/wbd/user' + ApiPrefix.User,
      rbcClientCredentialsUrl: config.rbcClientCredentialsUrl + ApiPrefix.rbcClientCredentialsUrl,
      config: config.configUrl + ApiPrefix.Config,
      auth: config.authUrl + ApiPrefix.Auth,
      wbd: config.wbdUrl + ApiPrefix.Wbd,
      order: config.orderUrl + ApiPrefix.Order,
      user: config.userUrl + ApiPrefix.User,
      apigeeExternalAppBaseUrl: config.apigeeExternalAppBaseUrl + ApiPrefix.User,
      apigeeExternalAppRBCToken: config.apigeeExternalAppRBCToken,
    };

    this.environment.oktaConfig = {
      clientId: config.oktaClientId.trim(),
      issuer: config.oktaIssuer,
      redirectUri: config.oktaRedirectUri,
      logoutUri: config.oktaLogoutUri,
      scope: 'openid email',
    };
    //this.environment.googleTagConfig.id = config.googleTagId;
  }

  /*********************Private Methods*********************/
}


