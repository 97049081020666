import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { DateFormat } from 'src/app/core/constants/app.constant';
import { BillingData, BillingExceptions, DeleteBillObj } from '../../customer-account-management';

import * as _moment from 'moment';
import 'moment-timezone';
// tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment } from 'moment';
import { CustomerAccountManagementService } from '../../customer-account-management.service';
import { ToastService } from 'src/app/shared/utils/toast.service';
import { ValidationDirective } from 'src/app/shared/directives/validations.directive';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
const moment = _moment;

@Component({
  selector: 'app-modal-dialog-manage-data',
  templateUrl: './modal-dialog-manage-data.component.html',
  styleUrls: ['./modal-dialog-manage-data.component.scss']
})
export class ModalDialogManageDataComponent implements OnInit {
  /*********************Properties*********************/
  dialogDetails: any;
  billingData: BillingData;
  exceptionData: BillingExceptions[];
  billingForm: FormGroup;
  billCinDetails: any;
  CINNumber: number;
  reasonText: string;
  buttonText: string;
  today: string = moment().format('yyyy-MM-DD');
  disableCntrl: boolean = false;
  isCurrentDate: boolean = false;
  isFutureDate: boolean = false;
  deleteBillObj: DeleteBillObj = {
    exceptionNumber: null,
    reason: null
  };
  /*********************Properties*********************/
  /*********************Constructor*********************/
  constructor(
    public dialogRef: MatDialogRef<ModalDialogManageDataComponent>,
    private customerAccountManagementService: CustomerAccountManagementService,
    private formBuilder: FormBuilder,
    private toastr: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  /*********************Constructor*********************/
  /*********************Lifecyle Methods*********************/
  ngOnInit(): void {
    this.dialogDetails = this.data;
    this.init();
  }
  /*********************Lifecyle Methods*********************/
  /*********************helper Methods*********************/

  /*********************helper Methods*********************/
  /*********************Component Methods*********************/
  onCloseDialog() {
    this.dialogRef.close();
  }

  onBillCINDescChange(value: string) {
    let selectedCIN = this.billCinDetails.find(item => item.description === value);
    this.CINNumber = selectedCIN.cin;
  }

  getBillingExceptionCINDetail() {
    this.customerAccountManagementService.getBillingExceptionCINDetail().subscribe((response: any) => {
      if (response && !response.hasError) {
        this.billCinDetails = response.data.cinDetails;

        //set CINdetails on edit
        if (this.dialogDetails.action != "Add") {
          let cinVal = this.dialogDetails.data ? this.dialogDetails.data.cinDescription : null;
          this.onBillCINDescChange(cinVal);
        }
      }
    });
  }

  onSaveClick(buttonAction: string) {
    this.billingData = {
      exceptionNumber: null,
      accountID: this.dialogDetails.accountId ? this.dialogDetails.accountId.toString() : null,
      cin: this.CINNumber,
      cinDescription: this.billingForm.value.bill_cin_desc,
      price: parseFloat(this.billingForm.controls['ext_cust_cost'].value),
      startDate: moment(this.billingForm.value.eff_start_date).format(DateFormat.YYYYMMDD),
      endDate: moment(this.billingForm.value.eff_end_date).format(DateFormat.YYYYMMDD),
      reason: this.billingForm.value.cost_change_reason,
    };

    switch (buttonAction) {
      case 'Edit':
        this.billingData.exceptionNumber = this.dialogDetails.data ? this.dialogDetails.data.exceptionNumber : null;
        this.billingData.cinDescription = this.isFutureDate?this.billingData.cinDescription:this.dialogDetails.data ? this.dialogDetails.data.cinDescription : null;
        this.billingData.startDate = this.isCurrentDate?this.dialogDetails.data.startDate:this.billingData ? this.billingData.startDate : moment();
        this.updateBillingException(this.billingData);
        break;
      case 'Delete':
        this.deleteBillObj.exceptionNumber = this.dialogDetails.data ? this.dialogDetails.data.exceptionNumber : null;
        this.deleteBillObj.reason = this.billingData.reason;
        this.deleteBillingException(this.deleteBillObj);
        break;
      default: //default action is Add  
        this.saveBillingException(this.billingData);
    }
  }

  saveBillingException(billingData: BillingData) {
    this.customerAccountManagementService.saveBillingException(billingData).subscribe((response: any) => {
      if (response && !response.hasError) {
        this.dialogRef.close('get data');
        this.toastr.success("Data saved Successfully");
      }
    });
  }

  updateBillingException(billingData: BillingData) {
    this.customerAccountManagementService.updateBillingException(billingData).subscribe((response: any) => {
      if (response && !response.hasError) {
        this.dialogRef.close('get data');
        this.toastr.success("Data updated Successfully");
      }
    });
  }

  deleteBillingException(deleteBillObj: DeleteBillObj) {
    this.customerAccountManagementService.deleteBillingException(deleteBillObj).subscribe((response: any) => {
      if (response && !response.hasError) {
        this.dialogRef.close('get data');
        this.toastr.success("Data updated Successfully");
      }
    });
  }
  /*********************Component Methods*********************/
  /*********************Init/Reset Methods*********************/
  private init() {
    this.getBillingExceptionCINDetail();
    let isDisabled;
    this.isFutureDate, this.isCurrentDate = false;
    switch (this.dialogDetails.action) {
      case 'Edit':
        //set reason text
        this.reasonText = 'Cost Change';
        //set button action
        this.buttonText = 'Save';
        //set disable on delete action
        if (this.dialogDetails.data.endDate < this.today) {
          //past validation
          this.disableCntrl = true;
          isDisabled = true;
        }
        else if (this.dialogDetails.data.startDate > this.today) {
          //future validation
          this.isFutureDate = true;
          this.disableCntrl = false;
          isDisabled = false;
        }
        else if(this.dialogDetails.data.startDate <= this.today && this.dialogDetails.data.endDate >= this.today) {
          //current validation
          this.isCurrentDate = true;
          this.disableCntrl = false;
          isDisabled = true;
        }
        break;

      case 'Delete':
        //set reason text
        this.reasonText = 'Delete';
        //set button action
        this.buttonText = 'Delete';
        //set disable on delete action
        isDisabled = true;
        this.disableCntrl = false;
        break;

      default:
        //set reason text
        this.reasonText = 'Cost Change';
        //set button action
        this.buttonText = 'Save';
        //set disable on delete action
        isDisabled = false;
        this.disableCntrl = false;
        break;
    }
    
    //form initialization
    let cinDescVal = this.dialogDetails.data ? this.dialogDetails.data.cinDescription : null;
    let reasonTxt = this.dialogDetails.data ? this.dialogDetails.data.reason : null;
    
    this.billingForm = this.formBuilder.group({
      bill_cin_desc: [{ value: cinDescVal, disabled: isDisabled }, [Validators.required]],
      ext_cust_cost: [{ value: this.dialogDetails.data ? this.dialogDetails.data.price.toString() : null, disabled: isDisabled }, [Validators.required]],
      eff_start_date: [{ value: this.dialogDetails.data ? moment(this.dialogDetails.data.startDate) : null, disabled: isDisabled }, [Validators.required]],
      eff_end_date: [{ value: this.dialogDetails.data ? moment(this.dialogDetails.data.endDate) : null, disabled: this.dialogDetails.action == 'Delete' ? true : this.disableCntrl }, [Validators.required]],
      cost_change_reason: [{ value: this.dialogDetails.action == 'Delete' ? null : reasonTxt, disabled: this.dialogDetails.action == 'Delete' ? false : this.disableCntrl }, [Validators.required]],
      exception_num: [this.dialogDetails.data ? this.dialogDetails.data.exceptionNumber : null],
      previous_cost: [this.dialogDetails.data ? this.dialogDetails.data.price.toString() : null],
      cin_number: [this.dialogDetails.data ? this.dialogDetails.data.cin : this.CINNumber],
      billing_exceptions: [this.dialogDetails.exceptionData]
    }, { validator: [ValidationDirective.billingDateValidator] });  

    this.billingForm.get("bill_cin_desc").valueChanges.subscribe(cinDesc => {
        let selectedCIN = this.billCinDetails.find(item => item.description === cinDesc);  
        this.billingForm.get("cin_number").setValue(selectedCIN.cin);
    });
  
  }
  /*********************Init/Reset Methods*********************/
}
