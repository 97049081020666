import { Component, OnInit, ViewChild } from '@angular/core';
// import { MatTableDataSource, MatPaginator, MatSort, MatDialog } from '@angular/material';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ModalDialogCustomerAccountManagementComponent } from './modal-dialog-customer-account-management/modal-dialog-customer-account-management.component';
import { CommonControls } from 'src/app/shared/common-control/common-controls';

import { CustomerAccountManagementService } from './customer-account-management.service';

import { CustomerData, SearchCustomerCriteria, SearchAcctConfigCriteria} from './customer-account-management';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-customer-account-management',
  templateUrl: './customer-account-management.component.html',
  styleUrls: ['./customer-account-management.component.scss'],
  providers: []
})

export class CustomerAccountManagementComponent implements OnInit {
  /*********************Properties*********************/
  @ViewChild('customerSearchInput', { static: true }) customerSearchInput: any;
  clearSearchFlag: boolean = false;
  disableSearchFlag: boolean = true;
  customerSearchForm: FormGroup;
  searchCustomerObj: SearchCustomerCriteria = {
    "keyword": null,
    "filterUserNum":"0",
  };
  searchConfig: SearchAcctConfigCriteria = {
    "accountId": null,
  }

  customerData: CustomerData[];
  customerDataTempObj: CustomerData[];

  displayedColumns: string[] = ['sapAccountNumber', 'legacyAccountNumber', 'accountName1', 'address1', 'city', 'state', 'customer_actions']; 
  dataSource: MatTableDataSource<CustomerData>

  noDataFlag: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private customerAccountManagementService: CustomerAccountManagementService,
  ) { }
  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit(): void {
    this.init();

    //Form Initialization
    this.customerSearchForm = this.formBuilder.group({
      'keyword': [null, [Validators.required]]
  });
  }
  /*********************Lifecyle Methods*********************/

  /*********************Component Methods*********************/
  onCopyPaste(e: ClipboardEvent) {//ClipboardEvent to capture cut copy paste
    let val = {
      value: null
    }
    val.value = e.clipboardData.getData('text/plain');
    this.onSearchFilter(val);
  }
  
  onSearchFilter(val) {
    this.clearSearchFlag = true;
    this.customerSearchForm.controls.keyword.setErrors(null);
    if (val.value && val.value.length > 2 && CommonControls.alphaNumericAndSpecial(val.value)) {//enter & search for user (starts with 3 char- alphanumeric)
      this.resetSearchObj();
      this.searchCustomerObj.keyword = val.value;
    }
    else {//default condition
      this.disableSearchFlag = false;
      this.customerSearchForm.controls.keyword.setErrors({ InvalidSearch: true });
    }
  
    //search button validation
    if (val && val.value && val.value.length > 2) {
      this.disableSearchFlag = false;
    }
    else {
      this.disableSearchFlag = true;
    }
  }

  onCustomerSearchClick() {
    if (this.searchCustomerObj) {
      this.customerAccountManagementService.searchCustomerData(this.searchCustomerObj).subscribe((response: any) => {
        this.customerData = !response.hasError ? response.data.customers : [];
        this.refreshGrid(this.customerData);
      });
    }
  }
  
  onClearSearch() {
    this.customerSearchForm.get('keyword').setValue('');
    this.clearSearchFlag = false;
    this.disableSearchFlag = true;
  }
  
  onClearSearchResults() {
    this.resetSearchObj();
    this.onClearSearch();
    this.onCustomerSearchClick();
  }
  /*********************Component Methods*********************/

  /*********************Dialog Methods*********************/
  public openDialog(customerRow: any) {
    const dialogRef = this.dialog.open(ModalDialogCustomerAccountManagementComponent, {
      data: customerRow,
      width: '80%',
      autoFocus: false,
      disableClose: true
    });
  
    dialogRef.afterClosed().subscribe((result: any) => {
      // if (result && result == 'save') {
  
      // }
    });
  /*********************Dialog Methods*********************/
}

/*********************Init/Reset Methods*********************/
private init() {
  this.searchCustomerObj.keyword = '';
  this.onCustomerSearchClick();
  this.noDataFlag = false;
}

private refreshGrid(obj) {
  this.dataSource = new MatTableDataSource(obj);
  this.dataSource.paginator = this.paginator;
  this.dataSource.sort = this.sort;
  if (obj && obj.length > 0) {
    this.noDataFlag = false;
  }
  else {
    this.noDataFlag = true;
  }
}

private resetSearchObj() {
  this.searchCustomerObj = {
    "keyword": null,
    "filterUserNum":"0",
  };
}
/*********************Init/Reset Methods*********************/

}
