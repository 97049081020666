import { Component, OnInit , ViewChild} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ModalDialogUserManagementComponent } from './modal-dialog-user-management/modal-dialog-user-management.component';
import { CommonControls } from 'src/app/shared/common-control/common-controls';

import { UserManagementService } from './user-management.service';

import { UserData, SearchUserCriteria } from './user-management';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  providers: []
})

export class UserManagementComponent implements OnInit{
  /*********************Properties*********************/
  @ViewChild('userSearchInput', { static: true }) userSearchInput: any;
  clearSearchFlag: boolean = false;
  disableSearchFlag: boolean = true;
  searchUserObj: SearchUserCriteria = {
    "keyword": null
  };
  userSearchForm: FormGroup;

  userData: UserData[];
  userDataTempObj: UserData[];

  displayedColumns_user: string[] = ['userNum', 'firstName', 'lastName', 'email', 'active_user', 'user_actions'];
  dataSource: MatTableDataSource<UserData>

  noDataFlag: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /*********************Properties*********************/

/*********************Constructor*********************/
constructor(
  public dialog: MatDialog,
  private formBuilder: FormBuilder,
  private userManagementService: UserManagementService,
) { }
/*********************Constructor*********************/

/*********************Lifecyle Methods*********************/
ngOnInit(): void {
  this.init();

  //Form Initialization
  this.userSearchForm = this.formBuilder.group({
    'keyword': [null, [Validators.required]]
  });
}

/*********************Lifecyle Methods*********************/
/*********************Component Methods*********************/
onCopyPaste(e: ClipboardEvent) {//ClipboardEvent to capture cut copy paste
  let val = {
    value: null
  }
  val.value = e.clipboardData.getData('text/plain');
  this.onSearchFilter(val);
}

onSearchFilter(val) {
  this.clearSearchFlag = true;
  this.userSearchForm.controls.keyword.setErrors(null);
  if (val.value && val.value.length > 2 && CommonControls.alphaNumericAndSpecial(val.value)) {//enter & search for user (starts with 3 char- alphanumeric)
    this.resetSearchObj();
    this.searchUserObj.keyword = val.value;
  }
  else {//default condition
    this.disableSearchFlag = false;
    this.userSearchForm.controls.keyword.setErrors({ InvalidSearch: true });
  }

  //search button validation
  if (val && val.value && val.value.length > 2) {
    this.disableSearchFlag = false;
  }
  else {
    this.disableSearchFlag = true;
  }
}

onUserSearchClick() {
  if (this.searchUserObj) {
    this.userManagementService.searchUserData(this.searchUserObj).subscribe((response: any) => {
      this.userData = !response.hasError ? response.data.users : [];
      this.refreshGrid(this.userData);
    });
  }
}

onClearSearch() {
  this.userSearchForm.get('keyword').setValue('');
  this.clearSearchFlag = false;
  this.disableSearchFlag = true;
}

onClearSearchResults() {
  this.resetSearchObj();
  this.onClearSearch();
  this.onUserSearchClick();
}
/*********************Component Methods*********************/
/*********************Dialog Methods*********************/
public openDialog(userRow: any) {
  const dialogRef = this.dialog.open(ModalDialogUserManagementComponent, {
    data: {userRow, updateRole: (userNum: number, roleNum: number) => { this.updateRole(userNum, roleNum) }},
    width: '80%',
    autoFocus: false,
    disableClose: true,
  });

  dialogRef.afterClosed().subscribe((result: any) => {
    // if (result && result == 'save') {

    // }
  });
}

public updateRole(userNum: number, roleNum: number) {
  let obj = this.userData.find(userInfo => userInfo.userNum == userNum);
  if (obj) {
    obj.userRoleXrefList[0].roleNum = roleNum;
    this.refreshGrid(this.userData);
  }
}
/*********************Dialog Methods*********************/
  
  /*********************Init/Reset Methods*********************/

  private init() {
    this.searchUserObj.keyword = '';
    this.onUserSearchClick();
    this.noDataFlag = false;
  }

  private refreshGrid(obj) {
    this.dataSource = new MatTableDataSource(obj);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (obj && obj.length > 0) {
      this.noDataFlag = false;
    }
    else {
      this.noDataFlag = true;
    }
  }

  private resetSearchObj() {
    this.searchUserObj = {
      "keyword": null
    };
  }
}