import { CacheLoader, CacheStaticLoader } from '@ngx-cache/core';
import { CacheDuration } from '../constants/app.constant';

export function CacheFactory(): CacheLoader {
  return new CacheStaticLoader({
    key: 'cms-Client',
    lifeSpan: {
      TTL: CacheDuration.TwoHour,
      expiry: CacheDuration.TwoHour
    }
  });
}
