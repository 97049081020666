
export const ClientApi = {
  SearchFormularyData: 'formulary/search',
  EditRemoveFormularyData: 'formulary',
  SearchFacilityInventoryData: 'inventory/search',
  SearchCustomerInventoryData: 'customerinventory/search',
  getInventoryAdjustmentData: 'get/inventoryadjustment',
  getReasonCode: 'inventory/adjustment/reasoncode',
  getInvoiceReportData: 'get/invoicereportdata',
  EditInventoryData: 'inventory',
  getReplenishmentOrdersNotReceivedData: 'get/productnotreceived',
  getOrderStatusData: 'compliancePackageOrder/status',
  getOrderStatusDetailData: 'compliancePackageOrder/status/detail',
  SearchUserData: 'user/search',
  getUserAccounts: 'user/getUserAccountsRelation?userNum=',
  getAcctConfigDetails: 'customerconfig/get',
  getAcctBillingDetails: 'customerconfig/getbillingexceptions',
  saveAcctConfigDetails: 'customerconfig/save',
  getBillingExceptionCINDetail: 'customerconfig/getcindetails',
  saveBillingExceptionData: 'customerconfig/savebillingexceptions',
  updateBillingExceptionData: 'customerconfig/updatebillingexception',
  deleteBillingExceptionData: 'customerconfig/deletebillingexception',
  SearchCustomerAccounts: 'customerAccounts/search',
  SearchAssignedCustomerAccounts: 'user/getUserAccountsRelation?userNum=',
  SaveAssignedCustomerAccounts: 'user/setUserAccountsRelation',
  getPMSParsedData: 'compliancePackageOrder/file/status',
  getOrderDetails: 'compliancePackageOrder/details',
  SaveOrderDetails: 'compliancePackageOrder/saveAndValidate',
  CancelOrderDetails: 'compliancePackageOrder/cancel',
  UploadCSV: 'compliancePackageOrder/file/upload',
  getReplenishmentOrders: 'replenishmentorder/search',
  getReplenOrderInvoice: 'replenishmentorder/invoice',
  getReplenOrderDetails: 'replenishmentorder/searchdetail',
  getReplenOrderItem: 'search/item',
  saveReplenOrderDetails: 'replenishmentorder/save',
  getRBCData: 'user/gln',
  jwtEndpoint: 'token/jwttoken?grant_type=client_credentials',
  saveParLevel: 'customerinventory/parLevel',
  saveReplenishmentItem: 'replenishmentorder/save',
  getHeldQueueHeaderData: 'compliancePackageOrder/searchgroup',
  getHeldQueueGroupDetails: 'compliancePackageOrder/searchdetailgroup',
  releaseDeleteOrder: 'compliancePackageOrder/releaseOrDelete',
}

export const ExportApi = {
  ExportCurrentFormularyData: 'export/formulary',
  ExportInvAdjData: 'export/inventoryadjustment',
  ExportInvByCustomerData: 'export/customerinvetory',
  ExportInvFacilityData: 'export/facilityinvetory',
  ExportInvoiceData: 'export/invoicereportdata',
  ExportReplenishmentOrdersNotReceivedData: 'export/productnotreceived',
  ExportOrderStatusData: 'order/status/export',
}

export const ConfigApi = {
  GetStaticData: '/static/data',
  SaveTypeRef: 'typeRef',
  DeleteTypeRef: 'typeRef/',
  SavePreferences: 'preferences',
};

export const ApiPrefix = {
  Auth: 'api/login/',
  Allocation: 'api/',
  Config: '/api',
  Order: '/api/v1/',
  User: '/api/v1/',
  Mdm: '/api/v1/',
  Wbd: '/api/v1/',
  apigeeExternalAppBaseUrl:'/api/v1/',
  apigeeExternalAppRBCToken: '',
  rbcClientCredentialsUrl: '/oauth2/v3/'
};

export const MiscApi = {
  Settings: '/settings',
  Log: '/log',
};

  export const AuthApi = {
  GetDetails: 'user'
};


  export const UserApi = {
    ValidateSession: 'login/validate-session',
    GetDetails: '',
    LogOut:'logout'
  };