import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import { ClientApi } from 'src/app/core/constants/api.constant';
import { BaseService } from 'src/app/core/services/base.service';
import { Environment } from 'src/app/shared/models/environment';

@Injectable({
  providedIn: 'root'
})
export class RbcService extends BaseService {
  /*********************Properties*********************/
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(protected http: HttpClient) {
    super(http);
  }
  /*********************Constructor*********************/

  public getToken() {
    const headers = {
      headers: {
        Authorization: 'Basic ' + this.apiConfig.apigeeExternalAppRBCToken
      }
    }
    const url = this.serviceUrl(this.apiConfig.rbcClientCredentialsUrl, ClientApi.jwtEndpoint);
    return super.post(url, {}, headers).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  public getRBCData(customerAccountNumber: string, zipcode: string, token: string) {
    const payload = {
      "customerAccountNumber": customerAccountNumber,
      "zipcode": zipcode
    }
    const headers = {
      headers: {
        'rbc': 'rbc',
        'Authorization': token,
        'x-api-key': Environment.getInstance().appConfig.apiKey
      }
    };
    const url = this.serviceUrl(this.apiConfig.rbcGLNUrl, ClientApi.getRBCData);
    return super.post(url, payload, headers).pipe(
      map((response: any) => {
        return response;
      })
    );
  }

}
