import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
import 'moment-timezone';
// tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment } from 'moment';

import { ValidationDirective } from 'src/app/shared/directives/validations.directive';
import { CommonControls } from 'src/app/shared/common-control/common-controls';

import { FormularyService } from './../formulary.service';
import { DialogService } from 'src/app/core/dialog/dialog.service';
import { UtilityService } from 'src/app/shared/utils/utility.service';
import { ToastService } from 'src/app/shared/utils/toast.service';
import { UserService } from 'src/app/core/services/user.service';

import { DateFormat } from 'src/app/core/constants/app.constant';
import { updateFormularyData, InventoryAccountData } from '../formulary';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

const moment = _moment;

@Component({
  selector: 'app-modal-dialog-formulary',
  templateUrl: './modal-dialog-formulary.component.html',
  styleUrls: ['./modal-dialog-formulary.component.scss']
})
export class ModalDialogFormularyComponent implements OnInit {

  /*********************Properties*********************/

  title: string;
  addEditFormularyForm: FormGroup;
  formularyRowData: any;
  EffectiveStartDate: any;
  EffectiveEndDate: any;
  disableInput: boolean;
  disableBtn: boolean = false;
  saveObj: updateFormularyData;
  totalOnHand: number = 0;
  medTypeData: any;
  medTypeForm: FormGroup;
  medTypeString: any;
  medTypeCode: any;

  validFStartDate: boolean = false;
  validFEndDate: boolean = false;
  showSDateTime: boolean = false;
  showEDateTime: boolean = false;

  displayedColumns: string[] = ['sapAccountNumber', 'accountName', 'inventoryOnHand', 'inventoryOnOrder', 'quantityReserved'];
  dataSource: MatTableDataSource<InventoryAccountData>;
  noDataFlag: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private formBuilder: FormBuilder,
    private formularyService: FormularyService,
    private dialogRef: MatDialogRef<ModalDialogFormularyComponent>,
    private dialog: DialogService,
    private userService: UserService,
    private utility: UtilityService,
    private toastr: ToastService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }
  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit(): void {
    this.formularyRowData = this.data;

    this.medTypeCode = this.formularyRowData.medType;
    this.medTypeString = this.formularyRowData.medTypeString;
    this.medTypeData = this.userService.getMedTypeDetails();

    // default value for med type
    if (this.medTypeCode == null) {
      this.medTypeCode = 3;
      this.medTypeString = "UOU";
    }

    this.medTypeForm = this.formBuilder.group({
      'medTypeString': [this.medTypeString, [Validators.required]],
      'medTypeCode': [this.medTypeCode, [Validators.required]],
    });

    //Delete after flag added from server side
    if (this.formularyRowData.inventoryAdjustmentList  && this.formularyRowData.inventoryAdjustmentList.length > 0) {
      this.formularyRowData.inventoryAdjustmentList.forEach((value) => {
        value.houseAccFlag = false;
        this.totalOnHand += value.inventoryOnHand;
      });
      this.formularyRowData.inventoryAdjustmentList[0].houseAccFlag = true;
    }
    //Delete after flag added from server side

    this.dataSource = new MatTableDataSource(this.formularyRowData.inventoryAdjustmentList);
    this.formularyRowData.inventoryAdjustmentList && this.formularyRowData.inventoryAdjustmentList.length > 0 ? this.noDataFlag = false : this.noDataFlag = true;
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

    //when user edit/remove formulary, check if start/end date is todays date
    let sDate = new Date(this.formularyRowData.startDate + ' ' + this.formularyRowData.startTime);
    this.checkTodaysDateforSDate(sDate);

    let eDate = new Date(this.formularyRowData.endDate + ' ' + this.formularyRowData.endTime);
    this.checkTodaysDateforEDate(eDate);

    if (this.formularyRowData.delete) {
      this.title = "Remove Formulary";
      this.showEDateTime = true;
      //Form Initialization
      this.addEditFormularyForm = this.formBuilder.group({
        'EffectiveStartDate': [sDate, [Validators.required]],
        'EffectiveEndDate': [new Date(), [Validators.required]]
      });
      this.disableInput = this.formularyRowData.delete;
    }
    else {
      this.title = "Edit Formulary";
      let endDateVal = null;
      if (this.formularyRowData.endDate == null) {
        endDateVal = new Date('12/31/9999');
      }
      else {
        endDateVal = eDate;
      }

      this.addEditFormularyForm = this.formBuilder.group({
        'EffectiveStartDate': [sDate, [Validators.required]],
        'EffectiveEndDate': [endDateVal, [Validators.required]]
      }, { validator: [ValidationDirective.pastDateValidator] });
    }

    //when user change start/end date to todays date
    this.addEditFormularyForm.get("EffectiveStartDate").valueChanges.subscribe(dateVal => {
      this.checkTodaysDateforSDate(dateVal);
    });
    this.addEditFormularyForm.get("EffectiveEndDate").valueChanges.subscribe(dateVal => {
      let d = moment(dateVal);
      let inputVal = d.creationData().input;
      if (typeof inputVal === 'string' && inputVal.length > 9) {
        if (CommonControls.checkTodaysDate(inputVal)) {
          this.showEDateTime = true
        }
        else {
          this.showEDateTime = false;
          this.addEditFormularyForm.controls['EffectiveEndDate'].setValue(moment(inputVal).set({ hour: 23, minute: 59, second: 0 }), { emitEvent: false });
        }

        let checkDate = moment(inputVal).format('HH:mm');
        if (checkDate == '00:00' && this.showEDateTime) {
          this.addEditFormularyForm.controls['EffectiveEndDate'].setValue(moment(inputVal).set({ hour: 23, minute: 59, second: 0 }), { emitEvent: false });
        }
      }
      else if (typeof inputVal === 'object') {
        if (CommonControls.checkTodaysDate(dateVal)) {
          this.showEDateTime = true
        }
        else {
          this.showEDateTime = false;
          this.addEditFormularyForm.controls['EffectiveEndDate'].setValue(moment(dateVal).set({ hour: 23, minute: 59, second: 0 }), { emitEvent: false });
        }

        let checkDate = moment(dateVal).format('HH:mm');
        if (checkDate == '00:00' && this.showEDateTime) {
          this.addEditFormularyForm.controls['EffectiveEndDate'].setValue(moment(dateVal).set({ hour: 23, minute: 59, second: 0 }), { emitEvent: false });
        }
      }
    });
  }


  /*********************Lifecyle Methods*********************/
  /*********************helper Methods*********************/
  public checkTodaysDateforSDate(date: any) {
    if (date && CommonControls.checkTodaysDate(date)) {
      this.showSDateTime = true;
      return true;
    }
    else {
      this.showSDateTime = false;
      return false;
    }
  }

  public checkTodaysDateforEDate(date: any) {
    if (date && CommonControls.checkTodaysDate(date)) {
      this.showEDateTime = true;
      // return set?moment(date).set({hour:23,minute:59,second:0}):date;
      return true;
    }
    else {
      this.showEDateTime = false;
      return false;
      // return moment(date).set({hour:23,minute:59,second:0});
    }
  }
  /*********************helper Methods*********************/
  /*********************Component Methods*********************/
  onSaveClick(buttonState) {
    if (buttonState == 'save') {
      this.utility.confirmSave().subscribe((result: any) => {
        if (result) {
          this.saveData('save');
        }
      });
    }
    else {
      this.utility.confirmDelete().subscribe((result: any) => {
        if (result) {
          this.saveData('delete');
        }
      });
    }
  }

  saveData(userAction: string) {
    // const ndcValue = CommonControls.revertNDC(this.formularyRowData.ndc);
    // pull updated med type string and find corresponding med type code
    this.medTypeString = this.medTypeForm.value.medTypeString;
    this.medTypeData.forEach((number, index) => {
      if (this.medTypeData[index].medType == this.medTypeString) {
        this.medTypeCode = this.medTypeData[index].medTypeCode;
        }
    });

    this.medTypeForm = this.formBuilder.group({
      'medTypeString': [this.medTypeString, [Validators.required]],
      'medTypeCode': [this.medTypeCode, [Validators.required]],
    });

    this.saveObj = {
      "itemFormularyNum": this.formularyRowData.itemFormularyNum,
      "ndc": this.formularyRowData.ndc,
      "cin": this.formularyRowData.cin,
      "startDate": moment.utc(this.addEditFormularyForm.value.EffectiveStartDate).format(DateFormat.YYYYMMDD),
      "endDate": moment.utc(this.addEditFormularyForm.value.EffectiveEndDate).format(DateFormat.YYYYMMDD),
      "startTime": moment.utc(this.addEditFormularyForm.value.EffectiveStartDate).format(DateFormat.HHmmss),
      "endTime": moment.utc(this.addEditFormularyForm.value.EffectiveEndDate).format(DateFormat.HHmmss),
      "userAction": userAction,
      "medType": this.medTypeForm.value.medTypeCode
    }

    this.formularyService.editRemoveFormularyData(this.saveObj).subscribe((response: any) => {
      if (response && response.hasError) {
        this.toastr.error('Error occured while saving the data.');
      }
      else {
        this.toastr.success('Data updated successfully.');
        this.dialogRef.close('save');
      }
    });
  }

  onCloseDialog() {
    this.dialogRef.close();
  }
  /*********************Component Methods*********************/


  /*********************Private Methods*********************/

  /*********************Private Methods*********************/
}