import { ModuleRoutes, OktaRoutes } from './routes.constant';

export const menuOption = [
  /******top navigation********/
  {
    src: "fa-home", title: 'Dashboard', name: 'dashboard', url: ModuleRoutes.Dashboard.Url, isMenuVisible: true,
    config: { showSubMenu: false, highlight: false, displayBottom: false, permissionNum: [], permissionsList: []},
    id: "home-id",
    subMenuItems: [],
    subMenuHeader: '',
    menuIndex: 0
  },
  {
    src: "fa-triangle-exclamation", title: 'Held Queue', name: 'held-queue', url: ModuleRoutes.HeldQueue.Url, isMenuVisible: true,
    config: { showSubMenu: false, highlight: false, displayBottom: false, permissionNum: [21,22], permissionsList: []},
    id: "heldQueue-id",
    subMenuItems: [],
    subMenuHeader: '',
    menuIndex: 1
  },
  {
    src: "fa-dolly-flatbed", title: 'Inventory', name: 'inventory', url: ModuleRoutes.Inventory.Url, isMenuVisible: true,
    config: { showSubMenu: false, highlight: false, displayBottom: false, permissionNum: [23,24,25,26,27,28], permissionsList: []},
    id: "inventory-id",
    subMenuItems: [],
    subMenuHeader: '',
    menuIndex: 2
  },
  {
    src: "fa-cart-plus", title: 'Formulary', name: 'formulary', url: ModuleRoutes.Formulary.Url, isMenuVisible: true,
    config: { showSubMenu: false, highlight: false, displayBottom: false, permissionNum: [5,6], permissionsList: []},
    id: "formulary-id",
    subMenuItems: [],
    subMenuHeader: '',
    menuIndex: 3
  },
  {
    src: "fa-clipboard-check", title: 'Fulfillment Order Status', name: 'order-status', url: ModuleRoutes.OrderStatus.Url, isMenuVisible: true,
    config: { showSubMenu: false, highlight: false, displayBottom: false, permissionNum: [7,8], permissionsList: []},
    id: "fulfillment-order-id",
    subMenuItems: [],
    subMenuHeader: '',
    menuIndex: 4
  },
  {
    src: "fa-chart-bar", title: 'Reports', name: 'reports', url: ModuleRoutes.Report.Url, isMenuVisible: true,
    config: { showSubMenu: false, highlight: false, displayBottom: false, permissionNum: [17,18,19,20], permissionsList: []},
    id: "reports-id",
    subMenuItems: [],
    subMenuHeader: '',
    menuIndex: 5
  },
  {
    src: "fa-pills", title: 'Order Import', name: 'pms', url: ModuleRoutes.Pms.Url, isMenuVisible: true,
    config: { showSubMenu: false, highlight: false, displayBottom: false, permissionNum: [9,10,11,12], permissionsList: []},
    id: "order-import-id",
    subMenuItems: [],
    subMenuHeader: '',
    menuIndex: 6
  },
  /******top navigation********/
  /******bottom navigation********/
  {
    src: "fa-user-circle", title: 'Customer Account Management', name: 'customer-account-management', url: ModuleRoutes.CustomerAccountManagement.Url, isMenuVisible: true,
    config: { showSubMenu: false, highlight: false, displayBottom: true, permissionNum: [15,16], permissionsList: []},
    id: "account-management-id",
    subMenuItems: [],
    subMenuHeader: '',
    menuIndex: 8
  },
  {
    src: "fa-users", title: 'User Management', name: 'user-management', url: ModuleRoutes.UserManagement.Url, isMenuVisible: true,
    config: { showSubMenu: false, highlight: false, displayBottom: true, permissionNum: [13,14], permissionsList: []},
    id: "user-management-id",
    subMenuItems: [],
    subMenuHeader: '',
    menuIndex: 7
  },
  {
    src: "fa-power-off", title: 'Logout', name: 'logout', url: OktaRoutes.Logout.Url, isMenuVisible: true,
    config: { showSubMenu: false, highlight: false, displayBottom: true, permissionNum: [], permissionsList: []},
    id: "logout-id",
    subMenuItems: [],
    subMenuHeader: '',
    menuIndex: 9
  },
  /******bottom navigation********/

];

/******Dashboard navigation********/
export const dashboardMenuOption = [
  {
    src: "fa-triangle-exclamation", title: 'Held Queue', name: 'held-queue', url: ModuleRoutes.HeldQueue.Url, isMenuVisible: true,
    config: { showSubMenu: false, highlight: false, displayBottom: true, permissionNum: [21,22], permissionsList: []},
    id: "heldQueue-id",
    menuIndex: 1
  },
  {
    src: "fa-dolly-flatbed", title: 'Inventory', name: 'inventory', url: ModuleRoutes.Inventory.Url, isMenuVisible: true,
    config: { showSubMenu: false, highlight: false, displayBottom: true, permissionNum: [23,24,25,26,27,28], permissionsList: []},
    id: "inventory-id",
    menuIndex: 2
  },
  {
    src: "fa-cart-plus", title: 'Formulary', name: 'formulary', url: ModuleRoutes.Formulary.Url, isMenuVisible: true,
    config: { showSubMenu: false, highlight: false, displayBottom: true, permissionNum: [5,6], permissionsList: []},
    id: "formulary-id",
    menuIndex: 3
  },
  {
    src: "fa-clipboard-check", title: 'Fulfillment Order Status', name: 'order-status', url: ModuleRoutes.OrderStatus.Url, isMenuVisible: true,
    config: { showSubMenu: false, highlight: false, displayBottom: true, permissionNum: [7,8], permissionsList: []},
    id: "fulfillment-order-id",
    menuIndex: 4
  },
  {
    src: "fa-chart-bar", title: 'Reports', name: 'reports', url: ModuleRoutes.Report.Url, isMenuVisible: true,
    config: { showSubMenu: false, highlight: false, displayBottom: true, permissionNum: [17,18,19,20], permissionsList: []},
    id: "reports-id",
    menuIndex: 5
  },
  {
    src: "fa-pills", title: 'Order Import', name: 'pms', url: ModuleRoutes.Pms.Url, isMenuVisible: true,
    config: { showSubMenu: false, highlight: false, displayBottom: false, permissionNum: [9,10,11,12], permissionsList: []},
    id: "order-import-id",
    menuIndex: 6
  }
]
/******Dashboard navigation********/
