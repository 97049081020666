export class CacheConstant {
  static readonly StaticData = {
    Name: 'static-data',
    AppPreferences: 'appPreferencesList',
    Messages: 'messageRefList',
    State: 'stateList',
    TypeGroups: 'typeGroupRefList',
    TypeRefs: 'typeRefList',
    RolePermissionsList: 'rolePermissionList',
    FoStatCdeList: 'foStatCdeList',
    MedTypeList: 'medTypeList'
  };

  static readonly OktaAuth = 'okta-auth';
  static readonly UserAuth = 'user-auth';
  // static readonly UserRole = 'user-role';
  static readonly UserPreferences = 'user-preferences';
}
