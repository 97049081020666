import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { InventoryService } from '../inventory.service';
import { ToastService } from 'src/app/shared/utils/toast.service';

import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ReplenishmentDetailData, SearchReplenOrderDetailCriteria, saveReplenOrderDetails, replenishmentDetailList } from '../inventory';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ModalDialogConfirmReplenComponent } from './modal-dialog-confirm-replen/modal-dialog-confirm-replen.component';
import { CommonControls } from 'src/app/shared/common-control/common-controls';
import { ModalDialogReplenishmentAdditemComponent } from './modal-dialog-replenishment-additem/modal-dialog-replenishment-additem/modal-dialog-replenishment-additem.component';

@Component({
  selector: 'app-modal-dialog-replenishment',
  templateUrl: './modal-dialog-replenishment.component.html',
  styleUrls: ['./modal-dialog-replenishment.component.scss']
})
export class ModalDialogReplenishmentComponent implements OnInit {

  /*********************Properties*********************/
  replenDialogTitle: string;
  editReplenOrder: FormGroup;
  editPoNum: FormGroup;
  noDataFlag: boolean = false;
  searchReplenOrderDetailsObj: SearchReplenOrderDetailCriteria = {
    "rplnishOrderHdrId": null
  };
  pendingOrderFlag: boolean = false;
  orderQtyErrorFlag: boolean = false;
  editFlag: boolean = false;
  currentOrderQty: string;
  orderQty: string;
  poNumber: string;
  currentPoNumber: string;
  dialogData: any;
  updatedIndices: any = [];
  
  replenOrderDetails: saveReplenOrderDetails;
  replenDetailList: replenishmentDetailList;

  displayedColumns: string[] = ['srcItemNum','cin','ndc','itemDescription', 'packQty','packSize','suggestedOrderQty','orderQty','onHandQty','thirtyDayUsage'];
  replenishmentOrderDetailsData: any;
  dataSource: MatTableDataSource<ReplenishmentDetailData>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private toastr: ToastService,
    private dialogRef: MatDialogRef<ModalDialogReplenishmentComponent>,
    private inventoryService: InventoryService,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }
  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit(): void {
    if(this.data.rplnshHdrStatus == "Pending Approval"){
      this.pendingOrderFlag = true;
    }
    this.editPoNum = this.formBuilder.group({
      "poNumber": [this.data.poNumber]
    });
    this.editReplenOrder = new FormGroup({});
    this.currentPoNumber = this.data.poNumber ?? "";
    this.replenDialogTitle = 'Suggested Replenishment Order: Account ' + this.data.dispAccNum + " - "+ this.data.accName;
    this.loadReplenOrderDetailsData(this.data.rplnshOrderHdrId);
    !this.pendingOrderFlag ? this.editPoNum.disable() : this.editPoNum.enable();
  }
  /*********************Lifecyle Methods*********************/
  /*********************Component Methods*********************/
  loadReplenOrderDetailsData(rplnshOrderHdrId: number) {
    this.searchReplenOrderDetailsObj.rplnishOrderHdrId = rplnshOrderHdrId;
    this.inventoryService.searchReplenOrderDetails(this.searchReplenOrderDetailsObj).subscribe((response: any) => {
      this.replenishmentOrderDetailsData = !response.hasError ? response.data : [];
      if(this.replenishmentOrderDetailsData != null){
        //Form Initialization
        this.replenishmentOrderDetailsData.forEach((replenLine: any, replenIndex: string) => {
          this.editReplenOrder.addControl('orderQty' + replenIndex, new FormControl(replenLine.orderQty, [Validators.required]));
        });
        !this.pendingOrderFlag ? this.editReplenOrder.disable() : this.editReplenOrder.enable();
      }
      this.noDataFlag = this.replenishmentOrderDetailsData.length <= 0;
      this.replenishmentOrderDetailsData.forEach((number: number, index: number) => {
        this.replenishmentOrderDetailsData[index]["currentOrderQty"] = this.replenishmentOrderDetailsData[index].orderQty;
        this.replenishmentOrderDetailsData[index]["index"] = index;
      });
      this.dataSource = new MatTableDataSource(this.replenishmentOrderDetailsData);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    });
  }

  onEditReplen(index: any){
    this.poNumber = this.editPoNum.controls.poNumber.value ?? "";
    /* istanbul ignore if */
    // if orderQty was last edited field
    if(CommonControls.numericOnly(index)){
      this.currentOrderQty = this.replenishmentOrderDetailsData[index].currentOrderQty.toString();
      this.orderQty = this.editReplenOrder.controls["orderQty"+index].value;
      this.orderQtyErrorFlag = false;
      // if both values equal current db values
      if(this.poNumber == this.currentPoNumber && this.orderQty == this.currentOrderQty){
        this.editFlag = false;
      }
      else {
        this.editFlag = true;
        // validate if qty is positive and numeric
        this.validateQty(index);
      }
    }
    // poNum was last edited
    else{
      /* istanbul ignore if */
      // if both values equal current db values
      if(this.poNumber == this.currentPoNumber && this.orderQty == this.currentOrderQty){
        this.editFlag = false;
      }
      else {
        this.editFlag = true;
      }
    }
  }

  validateQty(index: any){
    /* istanbul ignore if */
    // if orderQty is null or contains non-numeric values, set error
    if (!this.orderQty || this.orderQty == "." || !CommonControls.numericOnly(this.orderQty)) {
      this.editReplenOrder.get('orderQty' + index).setErrors({ QtyError: true });
      this.orderQtyErrorFlag = true;
    };
    // if orderQty exists, has been edited, and has no errors
    if(this.orderQty != "" && this.orderQty != this.currentOrderQty && !this.orderQtyErrorFlag){
      this.dataSource.filteredData[index].orderQty = this.orderQty.toNumber();
      // add index to updatedIndices to only update db with edited lines
      if(!this.updatedIndices.includes(index)){
        this.updatedIndices.push(index);
      }
    }
  }

  onApproveOrder(action: string) {
    this.dialogData = this.dataSource.filteredData;
    this.dialogData['accountNumber'] = this.data.dispAccNum;
    this.dialogData['accName'] = this.data.accName;
    this.dialogData['poNumber'] = this.editPoNum.controls.poNumber;
    this.dialogData['rplnshOrderHdrId'] = this.data.rplnshOrderHdrId;
    this.dialogData['updatedIndices'] = this.updatedIndices;
    this.dialogData.action = action;
    this.openConfirmationWindow();
  }

  onSaveOrder(action: string){
    this.replenOrderDetails = {
      "rplHdrId": this.data.rplnshOrderHdrId,
      "poNum": this.editPoNum.controls.poNumber.value,
      "action": action,
      "replenishmentDetailList": []
    };
    this.updatedIndices.forEach((value: any,index: number) => {
      this.replenDetailList = {
        "cin": null,
        "ndc": null,
        "srcItemNum": null,
        "description": null,
        "orderQty": null
      };
      this.replenDetailList.cin = this.dataSource.filteredData[value].cin;
      this.replenDetailList.ndc = this.dataSource.filteredData[value].ndc;
      this.replenDetailList.srcItemNum = this.dataSource.filteredData[value].srcItemNum;
      this.replenDetailList.description = this.dataSource.filteredData[value].itemDescription;
      this.replenDetailList.orderQty = this.dataSource.filteredData[value].orderQty;
      this.replenOrderDetails.replenishmentDetailList.push(this.replenDetailList);
    });
    this.inventoryService.saveReplenOrderDetails(this.replenOrderDetails).subscribe((response: any) => {
      if (response && !response.hasError) {
        this.dialogRef.close('save');
        this.toastr.success("Data saved successfully");
      }
    });
  }

  onCloseDialog() {
    this.dialogRef.close();
  }
  /*********************Component Methods*********************/
  /*********************Dialog Methods*********************/
  public openConfirmationWindow() {
    const dialogRef = this.dialog.open(ModalDialogConfirmReplenComponent, {
      data: this.dialogData,
      width: '35%',
      autoFocus: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if(result && result == 'save'){
        this.dialogRef.close();
      }
    });
  }

  
  /**
   * Method to open add item modal
   */
   addItem(){
    const dialogRef = this.dialog.open(ModalDialogReplenishmentAdditemComponent, {
      data: this.data,
      autoFocus: true,
      disableClose: true
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result == 'save') {
          this.loadReplenOrderDetailsData( this.searchReplenOrderDetailsObj.rplnishOrderHdrId);
      }
    });
  }
  /*********************Dialog Methods*********************/
}
