import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';

import { ClientApi } from 'src/app/core/constants/api.constant';

@Injectable({
  providedIn: 'root'
})
export class OrderStatusService  extends BaseService {
  /*********************Properties*********************/

 /*********************Properties*********************/

 /*********************Constructor*********************/
  constructor(protected http: HttpClient) {
    super(http);
    // this.apiConfig.order = 'http://localhost:7285/api/v1/';
   }
/*********************Constructor*********************/

/*********************Service Methods*********************/

 //Get Order Status service
   getOrderStatusData(searchCriteria) {
    const url = this.serviceUrl(this.apiConfig.order, ClientApi.getOrderStatusData);
    
    return super.post(url, searchCriteria).pipe(
      map((response: any) => {
        // if (!response.hasError) {
        return response;
        // }
      })
    )
  }

  //Get Order Status Details service
  getOrderStatusDetailData(orderNum: number) {
    const url = this.serviceUrl(this.apiConfig.order, ClientApi.getOrderStatusDetailData);
    
    return super.fetch(url+"?orderNum="+orderNum).pipe(
      map((response: any) => {
        // if (!response.hasError) {
        return response;
        // }
      })
    )
  }

/*********************Service Methods*********************/

/*********************Utility Methods*********************/

/*********************Utility Methods*********************/
}
