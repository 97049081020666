import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OktaAuth, Token, Tokens } from '@okta/okta-auth-js';

import { Environment } from 'src/app/shared/models/environment';

import { OktaConstant } from '../constants/okta.constant';
import { OktaRoutes } from '../constants/routes.constant';

@Injectable({
  providedIn: 'root',
})
export class OktaPkceAuthService {
  /*********************Properties*********************/
  readonly auth = { validate: true };
  private oktaAuth: OktaAuth = null;
  private redirectUrls = {
    login: window.location.origin,
    callback: window.location.origin + OktaRoutes.Callback.Url,
  };
  private pkceConfig = {
    url: '',
    issuer: '',
    clientId: '',
    pkce: true,
    grantType: 'authorization_code',
    redirectUri: '',
  };
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private router: Router
  ) { }
  /*********************Constructor*********************/

  /*********************Utility Methods*********************/

  init(): void {
    const config = Environment.getInstance().oktaConfig;
    Object.mapProperties(this.pkceConfig, config);
    this.pkceConfig.url = config.issuer;
    this.oktaAuth = this.getOktaAuth();
  }

  isAuthCallback(): boolean {
    return window.location.href.startsWith(this.redirectUrls.callback);
  }

  async isAuthenticated(): Promise<boolean> {
    // Checks if there is a current idToken in the TokenManger.
    return this.oktaAuth
      ? !!(await (this.oktaAuth.tokenManager.get(OktaConstant.IdToken) &&
        this.oktaAuth.tokenManager.get(OktaConstant.AccessToken)))
      : true;
  }

  async getToken(tokenName: string = OktaConstant.AccessToken): Promise<Token> {
    return await this.oktaAuth.tokenManager.get(tokenName);
  }

  async getTokens(): Promise<Tokens> {
    return await this.oktaAuth.tokenManager.getTokens();
  }

  /*********************Utility Methods*********************/

  /*********************Service Methods*********************/
  /* istanbul ignore next */
  initAuthCode(): Promise<any> {
    return this.oktaAuth.token.getWithRedirect({
      responseType: this.pkceConfig.pkce ? 'code' : ['token', 'id_token'],
      // prompt: 'login',
      scopes: ['openid', 'profile', 'email'],
    }).catch(function (err) {
      console.log(err);
    });
  }

  async redirect(): Promise<any> {
    return this.oktaAuth.token
      .parseFromUrl()
      .then((tokens) => {
        const { idToken } = tokens.tokens;
        const { accessToken } = tokens.tokens;

        const response = {
          idToken: idToken.idToken,
          accessToken: accessToken.accessToken,
          hasError: false,
        };

        this.oktaAuth.tokenManager.add(OktaConstant.IdToken, idToken);
        this.oktaAuth.tokenManager.add(OktaConstant.AccessToken, accessToken);

        response.hasError = response.accessToken ? false : true;
        return Promise.resolve(response);
      })
      .catch((error) => {
        error.hasError = true;
        return Promise.resolve(error);
      });
  }

  async renew(token: any) {
    const tokenConfig:any = {
      idToken: token.idToken.idToken,
      accessToken:token.accessToken.accessToken,
      scopes: ['openid', 'profile', 'email'],
      authorizeUrl: this.pkceConfig.issuer + '/oauth2/v1/authorize',
      issuer: this.pkceConfig.issuer,
      clientId: this.pkceConfig.clientId,
    };

    return this.oktaAuth.token
      .renewTokens(tokenConfig)
      .then((tokens) => {
        const response = this.onTokenResponse(tokens);
        return Promise.resolve(response);
      })
      .catch((error) => {
        console.log(error);
        return Promise.resolve(error);
      });

  }

  

  /* istanbul ignore next */
  async signOut(): Promise<void> {
    await this.isAuthenticated().then((oktaAuthenticated) => {
      if (oktaAuthenticated) {
        this.oktaAuth.closeSession();
      }

      if (this.oktaAuth) {
        this.oktaAuth.tokenManager.clear();
      }

      window.localStorage.clear();
      window.sessionStorage.clear();
    }).catch((err) =>{
      console.log(err)
    });

    if (this.router.url == "/") {
      this.initAuthCode();
    };
  }

  /*********************Service Methods*********************/

  /*********************Private Methods*********************/

  private getOktaAuth(
    redirectUrl: string = this.redirectUrls.callback
  ): OktaAuth {
    /* istanbul ignore if */
    if (!OktaAuth.features.isPKCESupported()) {
      this.pkceConfig.pkce = false;
      this.pkceConfig.grantType = '';
    }

    this.pkceConfig.redirectUri = redirectUrl;
    return new OktaAuth(this.pkceConfig);
  }

  private onTokenResponse(tokens) {
    const response = {
      idToken: null,
      accessToken: null,
      hasError: false,
      expiresAt: null,
    };


    if (tokens.idToken) {
      response.idToken = tokens.idToken.idToken;
      this.oktaAuth.tokenManager.add(OktaConstant.IdToken, tokens.idToken);
      response.expiresAt = tokens.idToken.expiresAt;
    }

    if (tokens.accessToken) {
      response.accessToken = tokens.accessToken.accessToken;
      this.oktaAuth.tokenManager.add(OktaConstant.AccessToken, tokens.accessToken);
      response.expiresAt = tokens.accessToken.expiresAt;
    }

    return response;
  }

  /*********************Private Methods*********************/
}