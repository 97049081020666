<form name="accForm" [formGroup]="accForm" novalidate>
    <!-- Account Selection dropdown  -->
    <mat-form-field class="account-selection">
      <mat-label class="label-txt">Select Account</mat-label>  
      <mat-select formControlName="accSelection" #selectAccount (selectionChange)="onAccChange($event.value)" required>
        <mat-option>
          <ngx-mat-select-search placeholderLabel="Search..." formControlName="accFilterCtrl" noEntriesFoundLabel="No match found"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let accData of filteredAcc | async" [value]="accData">
          {{accData.accNumAndName}}
        </mat-option>
      </mat-select>
    </mat-form-field>
     <!-- Account Selection dropdown -->
  </form>