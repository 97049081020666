/***************Enum Constants***************/

export enum AppEnv {
  Local = 'local',
  Dev = 'dev',
  Stage = 'stage',
  QA = 'qa',
  DR = 'dr',
  Production = 'production'
}

// Replace below code once build shell script for nginx configuration is enabled in nitrodx, need client approval firt
export const AppConfigUrl = { 
  DR: {
    production: true,
    name: AppEnv.Production,
    configUrl: "https://api.dr.cardinalhealth.com/wbd/config/api",
  }
}

export enum HttpHeader {
  ContentType = 'Content-Type',
  ApiKey = 'x-api-key',
  Token = 'access-token',
  Authorization = 'Authorization'
}

export const HttpStatusCode = {
  Timeout: -1,
  NoContent: 204,
  UnAuthorized: 401,
  BadRequest: 400,
  NotFound: 404,
  PreconditionRequired: 428,
  SysErrId: 100
};

export const MessageFormatter = {
  Limit: '{{limit}}',
  Item: '{{item}}',
  Total: '{{total}}',
  Date: '{{date}}',
  Time: '{{time}}',
  name: '{{name}}',
};

export enum ErrorNotificationType {
  Toast = 'TOASTER',
  Page = 'PAGE',
  Modal = 'MODAL'
}

export enum ErrorConfig {
  SysErrId = 'SYS100',
  BadRequestId = 'SYS400',
  NotFoundId = 'SYS404',
  PreconditionRequiredId = 'SYS428',
  TimeoutId = 'UI408'
}

export enum CacheDuration {
  Default = 60 * 60 * 2,
  OneMinute = 60 * 1,
  OneHour = 60 * 60 * 1,
  TwoHour = 60 * 60 * 2,
  OneDay = 24 * 60 * 60 * 1,
}

export enum Configuration {
  API_KEY = 'apiKey',
  UNDER_MAITENANCE = 'appDown',
  INTERNAL_URL = 'configUrl',
  APP_TIMEOUT = 'appIdleTimeout',
  API_TIMEOUT = 'apiTimeout',
  OKTA_ISSUER = 'oktaIssuer',
  OKTA_CLIENT_ID = 'oktaClient',
  OKTA_REDIRECT_URL = 'oktaRedirect',
  OKTA_LOGOUT_URL = 'oktaLogout',
  PRODUCTION_FLAG = 'production',
  APIGEE_ENDPOINT = 'apigeeTokenEndpoint',
  CONFIG_API_ENDPOINT = 'configUrl',
  CONTENT_API_ENDPOINT = 'contentUrl',
  CONTRACT_API_ENDPOINT = 'contractUrl',
  MDM_API_ENDPOINT = 'mdmUrl',
  ORDER_API_ENDPOINT = 'orderUrl',
  USER_API_ENDPOINT = 'userUrl',
}

export enum LogLevel {
  TRACE = 'trace',
  DEBUG = 'debug',
  INFO = 'info',
  LOG = 'log',
  WARN = 'warn',
  ERROR = 'error',
}

export enum EncDecAlgo {
  AES = 'AES',
  DES = 'DES',
  TripleDES = 'TripleDES',
  RC4 = 'RC4',
  RC4Drop = 'RC4Drop',
  Rabbit = 'Rabbit',
  RabbitLegacy = 'RabbitLegacy',
  EvpKDF = 'EvpKDF',
}

export enum ModalReasons {
  Yes = 'YES',
  No = 'No',
  Cancel = 'CANCEL',
  Close = 'CLOSE'
}

export enum SignOutReason {
  Default = 1,
  Timeout = 2,
  SessionExpired = 3,
}

/***************Enum Constants***************/

export const AppMessages = {
  GenericErrorMessage:
    'An error has occurred  while processing your request. Please try again. If problem persist, contact customer service.',
  UnderMaintenance:
    'Site is temporarily unavailable, we are undergoing routine maintenance at this time.',
  ValidationError:
    'Please enter required information',
  SessionExpiration:
    'Session will be expiring within {{time}}. Click on "Yes" button if you want to refresh the current session.',
  SessionRefresh: 'The session has been refreshed succesfully',
  SessionExpired: {
    Title: 'Session Expired',
    Message: 'Session has expired. Please re-login.',
  },
  Confirm: {
    Submit: {
      Title: 'Confirm Submit',
      Message: 'Are you sure you want to submit?',
    },
    Save: {
      Title: 'Confirm Save',
      Message: 'Would you like to save the changes?',
    },
    Cancel: {
      Title: 'Confirm Cancel',
      Message:
        'There are unsaved changes. Are you sure you want to continue?',
    },
    CancelOrder: {
      Title: 'Confirm Cancel Order',
      Message:
        'Are you sure you want to cancel this patient order?',
    },
    Delete: {
      Title: 'Confirm Remove',
      Message: 'Would you like to remove the record from formulary?',
    },
  },
  Dialog: {
    Success: { Title: 'Success', Message: '' },
    Warning: { Title: 'Warning', Message: '' },
    Error: { Title: 'Error', Message: '' },
    Information: { Title: 'Information', Message: '' },
  }
};

export const Regex = {
  RestrictSpecialChars: /[^A-Za-z0-9:;<=>?\^{}|~!'#$%&'()*+,.`_@\-\[\]\/\\ ]/g,
  NumericOnly: /^[0-9]+$/, ///^-?[0-9]+$/,
  NumericOnlyFractional: /^(0|[1-9]\d*)?(\.\d+)?(?<=\d)$/,
  AlphaNumericOnly: /^[a-zA-Z0-9 ]*$/,
  AlphaOnly: /^[a-zA-Z ]*$/,
  AlphaAndNumeric: /^(?=.*[0-9])(?=.*[a-zA-Z])/,
  AlphaNumericAndSpecial: /^[a-zA-Z0-9-!\s+"#\$%\&'\(\)*+,./:;<=>\?@\[\]]*$/,
  Password: /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[-!"#\$%\&'\(\)*+,./:;<=>\?@\[\]])(?=\S+$).{6,}$/,
  Email: /.{1,}@.{1,}\..{1,}/,
  Phone: /[0-9]{10}/,
  Date: /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/\d{4}$/,
  NumbersWithHyphen: /(\d{5})(\d{4})(\d{2})/,
  removehHyphen: /-/g,
  addSpaceBeforeCapLetter: /([A-Z])/g,
  checkForSpace: /\s/
};


export const DateFormat = {
  Default: 'M/DD/YYYY h:mm A',
  MMDDYYYY: 'MM/DD/YYYY',
  YYYYMMDD: 'YYYY-MM-DD',
  MMDDYYYYHHmm: 'MM/DD/YYYY HH:mm',
  YYYYMMDDHHmm: 'YYYY-MM-DD, HH:mm',
  MMDDYYYYHHmmssA: 'MM/DD/YYYY, HH:mm:ss A',
  MMDDYYYYHHmmA: 'MM/DD/YYYY HH:mm A',
  HHmm: 'HH:mm',
  HHMMA: 'HH:mm A',
  HHmmss: 'HH:mm:ss'
};

export const ErrorMessages = {
  InvalidOrderQty: 'Please enter a valid order quantity',
  ItemSelectionRequired: 'Please select an item to continue',
  InvalidShippingInformation: 'Please enter required shipping information',
  SYS100: 'An error has occurred. Please contact your system administrator.',
  SYS400: 'Bad Request. Please contact your system administrator.',
  SYS404: 'Resource does not exist. Please contact your system administrator.',
  SYS428: 'Requested resouce does not exist. Please refresh your view.',
  UI408: 'Request has timed out. Please try again later.',
};

export const RoleTypes =
  [
    {
      title: "<Select>",
      id: 0
    },
    {
      title: "Pharmacist",
      id: 10
    },
    {
      title: "Pharmacy Tech",
      id: 11
    }
  ];


