
String.prototype.toKeyValuePair = function() {
  const array = this.valueOf().split('&');
  const data = {};

  for (let i = 0; i < array.length; i += 1) {
    const value = array[i].split('=');
    data[value[0]] = value[1];
  }

  return data;
};

String.prototype.toNumber = function (): number {
  return parseInt(this, 0);
};
