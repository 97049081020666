import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';

import { ClientApi } from 'src/app/core/constants/api.constant';
import { SearchCustomerCriteria, SearchAcctConfigCriteria, UpdateAcctConfigSettings, BillingData, DeleteBillObj } from './customer-account-management';

@Injectable({
  providedIn: 'root'
})
export class CustomerAccountManagementService extends BaseService {
  /*********************Constructor*********************/
  constructor(
    protected http: HttpClient) {
    super(http);
    // this.apiConfig.order = 'http://10.32.42.195:7285/api/v1/';
  }
  /*********************Constructor*********************/

  /*********************Service Methods*********************/
  searchCustomerData(searchCriteria: SearchCustomerCriteria) {
    const url = this.serviceUrl(this.apiConfig.user, ClientApi.SearchCustomerAccounts);
    return super.post(url, searchCriteria).pipe(
      map((response: any) => {
        // if (!response.hasError) {
        return response;
        // }
      })
    )
  }

  getAccountConfigDetails(searchAcctCriteria: SearchAcctConfigCriteria) {
    const url = this.serviceUrl(this.apiConfig.order, ClientApi.getAcctConfigDetails);
    return super.post(url, searchAcctCriteria).pipe(
      map((response: any) => {
        // if (!response.hasError) {
        return response;
        // }
      })
    )
  }

  getAccountBillingDetails(searchAccCriteria: SearchAcctConfigCriteria) {
    const url = this.serviceUrl(this.apiConfig.order, ClientApi.getAcctBillingDetails);
    return super.post(url, searchAccCriteria).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  saveAccountConfigDetails(saveCustomers: UpdateAcctConfigSettings){
    const url=this.serviceUrl(this.apiConfig.order,ClientApi.saveAcctConfigDetails);
      return super.post(url, saveCustomers).pipe(
        map((response: any) => {
          // if (!response.hasError) {
          return response;
          // }
        })
      )
  }

  getBillingExceptionCINDetail(){
    const url=this.serviceUrl(this.apiConfig.order,ClientApi.getBillingExceptionCINDetail);
      return super.post(url, null).pipe(
        map((response: any) => {
          // if (!response.hasError) {
          return response;
          // }
        })
      )
  }

  saveBillingException(billingData: BillingData){
    const url=this.serviceUrl(this.apiConfig.order,ClientApi.saveBillingExceptionData);
      return super.post(url, billingData).pipe(
        map((response: any) => {
          // if (!response.hasError) {
          return response;
          // }
        })
      )
  }

  updateBillingException(billingData: BillingData){
    const url=this.serviceUrl(this.apiConfig.order,ClientApi.updateBillingExceptionData);
      return super.post(url, billingData).pipe(
        map((response: any) => {
          // if (!response.hasError) {
          return response;
          // }
        })
      )
  }

  deleteBillingException(deleteBillObj: DeleteBillObj){
    const url=this.serviceUrl(this.apiConfig.order,ClientApi.deleteBillingExceptionData);
      return super.post(url, deleteBillObj).pipe(
        map((response: any) => {
          // if (!response.hasError) {
          return response;
          // }
        })
      )
  }
  /*********************Service Methods*********************/
}
