<mat-card>
	<mat-card-header>
		<mat-card-title>User Management</mat-card-title>
	</mat-card-header>
	<mat-card-content>
	    <!-- Search Filter -->
		<form name="userSearchForm" [formGroup]="userSearchForm" novalidate>
			<div class="search-bar user-mangmt-search">
				<div class="mat-form-search-cont">
					<mat-form-field>
						<input matInput type="text" placeholder="Enter your search..." name="keyword"
								formControlName="keyword" (paste)="onCopyPaste($event)" 
								(keyup)="onSearchFilter(userSearchForm.controls.keyword)"
								#searchInput required />
						<a mat-button [hidden]="!clearSearchFlag" matSuffix mat-icon-button
                            aria-label="x" (click)="onClearSearch()">
                            <mat-icon class="close-icon">close</mat-icon>
                        </a>
						<mat-hint align="start">Please enter search criteria. For more information check <i
							class="fa fa-info-circle info-icon" aria-hidden="true"></i> icon. </mat-hint>
						<mat-error *ngIf="userSearchForm.get('keyword').hasError('InvalidSearch')">
							Invalid Entry. Please refer to the selection criteria <i
							class="fa fa-info-circle info-icon" aria-hidden="true"
							style="font-size: 16px"></i>
						</mat-error>
					</mat-form-field>
					<i class="fa fa-info-circle info-icon" aria-hidden="true" matTooltip="Note: &#13;
							Search by First or Last name, or Email ID" matTooltipClass="tool-tip-class"
							aria-label="Button that shows a tooltip"></i>
					<button mat-flat-button color="primary" class="button primary" (click)="onUserSearchClick()"
						[disabled]="disableSearchFlag || userSearchForm.get('keyword').hasError('InvalidSearch')">
						Search
					</button>
				</div>
				<div class="search-bar-options">
                    <button mat-stroked-button color="primary" class="clear-filter condensed-btn" 
					(click)="onClearSearchResults()">
                        Clear search results
                    </button>
                </div>
			</div>
		</form>
	    <!-- Search Filter -->

	    <!-- User Access table -->
		<div class="mat-elevation-z8">
			<table mat-table [dataSource]="dataSource" aria-describedby="user management view" matSort>

				<ng-container matColumnDef="userNum">
					<th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> User ID</th>
					<td mat-cell *matCellDef="let row"> {{row.userNum}} </td>
				</ng-container>

				<ng-container matColumnDef="firstName">
					<th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> First Name</th>
					<td mat-cell *matCellDef="let row"> {{row.firstName}} </td>
				</ng-container>

				<ng-container matColumnDef="lastName">
					<th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Last Name</th>
					<td mat-cell *matCellDef="let row"> {{row.lastName}} </td>
				</ng-container>

				<ng-container matColumnDef="email">
					<th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Email Address </th>
					<td mat-cell *matCellDef="let row"> {{row.email}} </td>
				</ng-container>
							
				<ng-container matColumnDef="active_user">
					<th mat-header-cell *matHeaderCellDef mat-sort-header scope="col"> Active User </th>
					<td mat-cell *matCellDef="let row">
					    <div class="active-user">
							<mat-icon><i class="fa fa-check-circle" aria-hidden="true"></i></mat-icon>
						</div>
					</td>
				</ng-container>

				<ng-container matColumnDef="user_actions">
					<th mat-header-cell *matHeaderCellDef scope="col"> </th>
					<td mat-cell *matCellDef="let row">
					    <div class="user-action">
							<mat-icon class="grid-icon" title="edit" (click)="openDialog(row)">edit</mat-icon>
						</div>
					</td>
				</ng-container>

				<tr mat-header-row *matHeaderRowDef="displayedColumns_user"></tr>
				<tr mat-row *matRowDef="let row; columns: displayedColumns_user;"></tr>
			</table>
			<div *ngIf="noDataFlag" class="no-records">
				No records found
			</div>
			<mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 200]"></mat-paginator>
		</div>
		<!-- User Access table -->
        
	</mat-card-content>
</mat-card>