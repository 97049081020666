import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { ModalDialogInventoryComponent } from './modal-dialog-inventory/modal-dialog-inventory.component';
import { ModalDialogReplenishmentComponent } from './modal-dialog-replenishment/modal-dialog-replenishment.component';
import { ModalDialogCustomerAcctComponent } from './modal-dialog-customer-acct/modal-dialog-customer-acct.component';
import { CommonControls } from 'src/app/shared/common-control/common-controls';

import { InventoryService } from './inventory.service';
import { SearchUserCriteria } from 'src/app/modules/user-management/user-management';
import { InventoryData, searchCustomerAcctCriteria, CustomerAccountData, AccountData, ReplenishmentData, FacilityInventoryRowData, searchCustomerInventoryCriteria, searchInventoryCriteria, searchReplenishmentCriteria, searchReplenInvoiceDownloadCriteria, ExportFacilityInventoryCriteria } from './inventory';
import { Subject } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { UserService } from 'src/app/core/services/user.service';
import * as _moment from 'moment';
import 'moment-timezone';
// tslint:disable-next-line:no-duplicate-imports
// import { default as _rollupMoment } from 'moment';
import { DateFormat } from 'src/app/core/constants/app.constant';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ToastService } from 'src/app/shared/utils/toast.service';

const moment = _moment;

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
  providers: []
})

export class InventoryComponent implements OnInit, OnDestroy {
  /*********************Properties*********************/
  @ViewChild('facilitySearchInput', { static: true }) facilitySearchInput: any;
  @ViewChild('customerSearchInput', { static: true }) customerSearchInput: any;
  @ViewChild('DCSearchInput', { static: true }) DCSearchInput: any;
  @ViewChild('replenSearchInput', { static: true }) replenSearchInput: any;

  clearSearchFlag: boolean = false;
  clearAccountSearchFlag: boolean = false;
  clearDCSearchFlag: boolean = false;
  disableSearchFlag: boolean = false;
  disableCustSearchFlag: boolean = true;
  disableReplenSearchFlag: boolean = true;
  hideFlag: boolean = true;

  allAccountObj: any = {
    accNumAndName: "All Accounts",
    accountName1: "All Accounts",
    accountNumber: "All",
    assignedAccounts: []
  };
  searchUserObj: SearchUserCriteria = {
    "keyword": null
  };
  searchObj: searchInventoryCriteria = {
    "cin": null,
    "ndc": null,
    "srcItemNum": null,
    "genericName": null,
    "tradeName": null,
    "inventory": false
  };
  searchInvListObj: searchInventoryCriteria = {
    "cin": null,
    "ndc": null,
    "srcItemNum": null,
    "genericName": null,
    "tradeName": null,
    "inventory": true
  };
  searchCustAcctObj: searchCustomerAcctCriteria = {
    "assignedAccounts": null,
    "cin": null,
    "dc": null,
    "srcItemNum": null,
    "description": null,
    "genericName": null,
    "tradeName": null,
    "ndc": null,
    "inventory": false
  };
  searchCustInvObj: searchCustomerInventoryCriteria = {
    "accountNumber": null,
    ...this.searchCustAcctObj
  };

  searchReplenListObj: searchReplenishmentCriteria = {
    "invtryAcctId": [null],
    "status": [],
    "fromDate": null,
    "toDate": null
  };
  searchReplenInvoiceDownloadObj: searchReplenInvoiceDownloadCriteria = {
    "invoiceNumber":null,
    "shipToAccountNumber":null
  };
  exportFacilityInventoryObj: ExportFacilityInventoryCriteria = {
    "inventoryLength": null,
    ...this.searchObj
  }
  facilitySearchForm: FormGroup;
  customerAcctSearchForm: FormGroup;
  replenSearchForm: FormGroup;
  tabItem: any = {
    index: null,
    name: '',
    isActive: false
  }

  iPermissionNum: any;
  permissionsData: any;
  userEmail: string;
  inventoryData: InventoryData[];
  facilityInventoryRowData: FacilityInventoryRowData[];
  replenishmentDataObj: ReplenishmentData[];
  replenishmentData: ReplenishmentData[];
  customerAccountData: CustomerAccountData[];
  sapAccountNumber: any[];
  invAcctId: any[];
  invoiceNumbers: string;
  userNum: number;
  internalUser: boolean = false;
  replenFlag: boolean = false;
  customerAcct: boolean = false;
  accountId: number;

  displayedFacilityColumns: string[] = ['ndc', 'sourceItemCode', 'cin', 'description', 'genericName', 'tradeName', 'totalOnHand', 'packSize', 'packQty', 'strength', 'uom', 'itemType'];
  displayedCustomerAccountColumns: string[] = ['ndcText', 'srcItemNum', 'cin', 'description', 'genericName', 'tradeName', 'onHand', 'onOrder', 'allocated', 'packSize', 'packQuantity', 'strength', 'baseUom', 'itemType', 'parLevel', 'actions'];
  displayedReplenishmentColumns: string[] = ['dispAccNum', 'accName', 'dateAdded', 'rplnshOrderHdrId', 'dateSentErp', 'erpOrderNum', 'dateLastUpdated', 'rplnshHdrStatusDisplay', 'statusWeight', 'itemsOnOrder', 'actions'];
  dataSource: MatTableDataSource<InventoryData>;
  replenishmentOrderDetailsData: any;
  replenRow: any;
  customerAccounts: AccountData[];
  tempCustomerAccounts: AccountData[] = [];
  customerAccountsWithAll: AccountData[];
  orderStatusDetails: any[] = [];
  orderStatusList: any;
  noDataFlag: boolean = false;
  exportCustomerInventoryObj: any;
  @ViewChild('t1sort', { read: MatSort }) t1sort: MatSort;
  @ViewChild('t1paginator', { read: MatPaginator }) t1paginator: MatPaginator;

  @ViewChild('t2sort', { read: MatSort }) t2Sort: MatSort;
  @ViewChild('t2paginator', { read: MatPaginator }) t2paginator: MatPaginator;

  @ViewChild('t3sort', { read: MatSort }) t3Sort: MatSort;
  @ViewChild('t3paginator', { read: MatPaginator }) t3paginator: MatPaginator;

  private onDestroy = new Subject<void>();
  private subscription = {
    activeRoute: null,
    accDetails: null,
    roleNum: null,
  };
  accountDetail: any;
  roleNum: any;

  permissionAssignment: any = {
    viewCustomerInventory: false,
    viewFulfillmentFacility: false,
    viewReplenishmentOrders: false
  };
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private appService: AppService,
    private inventoryService: InventoryService,
    private activatedroute: ActivatedRoute,
    private userService: UserService,
    private toastr: ToastService
  ) { }
  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit(): void {
    this.subscription.activeRoute = this.activatedroute.data.subscribe(data => {
      this.iPermissionNum = data.permissionNum;
    });
    this.permissionsData = this.userService.getUserPermissions();
    this.userEmail = this.userService.getUserEmail();
    this.isUserExternal(this.userEmail);
    this.getInventoryAccts();
    this.permissionsData.forEach((number, index) => {
      this.iPermissionNum.forEach((num, i) => {
        if (this.permissionsData[index].permissionNum == this.iPermissionNum[i]){
          if (this.permissionsData[index].permissionNam == "View Customer Account Inventory" || "Update Customer Account Inventory") {
            this.permissionAssignment.viewCustomerInventory = true;
          }
          if (this.permissionsData[index].permissionNam == "View Fulfillment Facility Inventory" || "Update Fulfillment Facility Inventory") {
            this.permissionAssignment.viewFulfillmentFacility = true;
          }
          if (this.permissionsData[index].permissionNam == "View Replenishment Orders" || "Update Replenishment Orders") {
            this.permissionAssignment.viewReplenishmentOrders = true;
          }
        }
      })
    });

    //Form Initialization
    this.facilitySearchForm = this.formBuilder.group({
      'searchText': [null, [Validators.required]]
    });

    //Form Initialization
    this.customerAcctSearchForm = this.formBuilder.group({
      'accountData': [null],
      'searchText': [null],
    });

    //Form Initialization
    this.replenSearchForm = this.formBuilder.group({
      'accountData': [null],
      "invtryAcctId": [null],
      "status": [null],
      "fromDate": [null],
      "toDate": [null]
    });

    this.init();

  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.subscription.activeRoute.unsubscribe();
  }
  /*********************Lifecyle Methods*********************/

  /*********************Helper Methods*********************/
  public tabChanged(e: any) {
    this.tabItem = {
      index: e.index,
      name: e.tab.textLabel,
      isActive: e.tab.isActive
    }
    this.onClearSearchResults();
  }

  sortColumnsByValues(data: ReplenishmentData[]) {
    data.sort((a, b) => a.dateAdded.localeCompare(b.dateAdded));
    for(let i=0; i<data.length;i++){
      data[i].dateWeight = i;
      switch (data[i].rplnshHdrStatus){
        case 'Pending Approval': data[i].statusWeight = 1; break;
        case 'Submitted': data[i].statusWeight = 30; break;
        case 'Canceled': data[i].statusWeight = 70; break;
        case 'APPROVED': data[i].statusWeight = 70; break;
        case 'Failed to Submit': data[i].statusWeight = 70; break;
        default: data[i].statusWeight = 100; break;
      }
      data[i].statusWeight = data[i].statusWeight + data[i].dateWeight;
    }
    return data;
  }
  /*********************Helper Methods*********************/

  /*********************Component Methods*********************/
  
  onCopyPaste(e: ClipboardEvent) {//ClipboardEvent to capture cut copy paste
    let val = {
      value: null
    }
    val.value = e.clipboardData.getData('text/plain');
    (this.tabItem.name === 'Inventory By Customer' || (this.permissionAssignment.viewNavixInventory == false && this.permissionAssignment.viewCustomerInventory == true)) ? this.onSearchFilter(val, 'customer') : this.onSearchFilter(val, 'facility');
  }

  getInventoryAccts(){ //search for user Num with email
    this.searchUserObj.keyword = this.userEmail;
    if(this.searchUserObj){
      this.inventoryService.searchUserData(this.searchUserObj).subscribe((response: any) => {
        this.userNum = response.data.users[0].userNum;
        if(this.userNum){
          this.inventoryService.searchAssignedCustomerData(this.userNum).subscribe((custResponse: any) => {
            this.tempCustomerAccounts = custResponse.userAccountsMappings ? custResponse.userAccountsMappings[0].inventoryAccountsList : '[]';
            this.tempCustomerAccounts.forEach((data, index) => {
              this.tempCustomerAccounts[index].accountNumber = this.tempCustomerAccounts[index].sapAccountNumber ? this.tempCustomerAccounts[index].sapAccountNumber.toString() : this.tempCustomerAccounts[index].legacyAccountNumber;
              this.tempCustomerAccounts[index]["accNumAndName"] = this.tempCustomerAccounts[index].accountNumber.concat(" - ", this.tempCustomerAccounts[index].accountName1);
              this.allAccountObj.assignedAccounts[index] = this.tempCustomerAccounts[index].accountId;
            });
          this.customerAccounts = [...this.tempCustomerAccounts];
          this.customerAccountsWithAll = [...this.tempCustomerAccounts];
          this.customerAccountsWithAll.unshift(this.allAccountObj);
          });
        }
      });
    }
  }

  onSearchFilter(val: any, type: string) {
    this.clearSearchFlag = true;
    if (val && val.value.length === 11 && CommonControls.numericOnly(val.value)) {//enter & search product using NDC code ( exactly 11 digits numeric)
      this.resetSearchObj();
      this.searchObj.ndc = val.value;
    }
    else if (val && CommonControls.numericOnly(val.value)) {//enter & search product using CIN
      this.resetSearchObj();
      this.searchObj.cin = val.value;
      this.searchObj.srcItemNum = val.value;
    }
    else if (val.value && val.value.length > 2 && CommonControls.aplhaNumericOnly(val.value)) {//enter & search product using source item number or generic name
      this.resetSearchObj();
      this.searchObj.srcItemNum = val.value.toUpperCase();
      this.searchObj.genericName = val.value.toUpperCase();
      this.searchObj.tradeName = val.value.toUpperCase();
    }
    else {//default condition
      type === 'facility' ? val.value === '' ? this.disableSearchFlag = false : this.facilitySearchForm.controls.searchText.setErrors({ InvalidSearch: true }) : this.customerAcctSearchForm.controls.searchText.setErrors({ InvalidSearch: true });
    }
    //when search input is empty hide clear icon
    if (val && val.value == '') {
      this.clearSearchFlag = false;
      this.customerAcctSearchForm.controls.searchText.reset();
      this.replenSearchForm.reset();
      //for inv by customer
      this.searchObj = {
        "cin": null,
        "ndc": null,
        "srcItemNum": null,
        "genericName": null,
        "tradeName": null,
        "inventory": false
      };
      this.searchReplenListObj = {
        "invtryAcctId": [null],
        "status": [],
        "fromDate": null,
        "toDate": null
      };
    }
  }

  onFacilitySearchClick() {
    if (this.searchObj) {
      this.inventoryService.searchFacilityInventoryData(this.searchObj).subscribe((response: any) => {
        this.inventoryData = !response.hasError ? response.data.facilityInventoryList : '[]';
        this.refreshGrid(this.inventoryData);
      });
    }
  }

  onCustomerSearchClick() {
    this.clearSearchFlag = true;
    this.searchCustAcctObj.dc = null;
    this.searchCustAcctObj = {
      "assignedAccounts": null,
      "cin": null,
      "dc": null,
      "srcItemNum": null,
      "description": null,
      "genericName": null,
      "tradeName": null,
      "ndc": null,
      "inventory": false
    };
    this.searchCustInvObj.accountNumber = this.customerAcctSearchForm.value.accountData.sapAccountNumber;
    this.accountId = this.customerAcctSearchForm.value.accountData.accountId;
    if(!this.searchCustInvObj.accountNumber){
      const accVal = this.customerAcctSearchForm.value.accountData.legacyAccountNumber ? this.customerAcctSearchForm.value.accountData.legacyAccountNumber.split('-') : [];
      this.searchCustAcctObj.dc = accVal.length > 1 ? accVal[0] : null;
      this.searchCustInvObj.accountNumber = accVal.length > 1 ? accVal[1] : accVal[0];
    }
    const val = this.customerAcctSearchForm.value.searchText;
    if (val && val.length === 11 && CommonControls.numericOnly(val)) {//enter & search product using NDC code ( exactly 11 digits numeric)
      this.searchCustAcctObj.ndc = val;
    }
    else if (val && CommonControls.numericOnly(val)) {//enter & search product using CIN 
      this.searchCustAcctObj.cin = val;
      this.searchCustAcctObj.srcItemNum = val;
    }
    else if (val && val.length > 2 && CommonControls.aplhaNumericOnly(val)) {//enter & search product using source item number OR description
      this.searchCustAcctObj.srcItemNum = val.toUpperCase();
      this.searchCustAcctObj.genericName = val.toUpperCase();
      this.searchCustAcctObj.tradeName = val.toUpperCase();

    }
    this.searchCustInvObj = {
      "accountNumber": this.searchCustInvObj.accountNumber,
      ...this.searchCustAcctObj
    }
    this.inventoryService.searchCustomerInventoryData(this.searchCustInvObj).subscribe((response: any) => {
      this.customerAccountData = !response.hasError ? response.data.inventoryByAccNum : [];
      this.customerAccountData.forEach((data,index) => {
        this.customerAccountData[index].accountId = this.accountId;
        this.customerAccountData[index].accountNumber = this.searchCustInvObj.accountNumber;
      })
      this.refreshGrid(this.customerAccountData);
    });
  }

  onReplenishmentSearchClick() {
    this.clearSearchFlag = true;
    this.searchReplenListObj = {
      "invtryAcctId": this.replenSearchForm.value.accountData.accountNumber == "All" ? this.customerAccountsWithAll[0].assignedAccounts : [this.replenSearchForm.value.accountData.accountId],
      "status": this.replenSearchForm.value.status ? this.replenSearchForm.value.status : [],
      "fromDate": this.replenSearchForm.value.fromDate ? moment(this.replenSearchForm.value.fromDate).format(DateFormat.YYYYMMDD) : null,
      "toDate": this.replenSearchForm.value.toDate ? moment(this.replenSearchForm.value.toDate).format(DateFormat.YYYYMMDD) : null
    };
    this.getReplenishmentOrderData();
  }

  getReplenishmentOrderData(){
    this.inventoryService.searchReplenishmentOrderData(this.searchReplenListObj).subscribe((response: any) => {
      this.replenishmentDataObj = response.data ? response.data.rplnshOrderHdrSearchResponseResult : [];
      this.replenishmentData = this.replenishmentDataObj ? this.sortColumnsByValues(this.replenishmentDataObj) : [];
      this.replenishmentData.forEach((data,index) => {
        this.replenishmentData[index].accountNumber = this.replenSearchForm.value.accountData.accountNumber;
        this.customerAccountsWithAll.forEach((d,i) => {
          if(this.replenishmentData[index].invtryAccId == this.customerAccountsWithAll[i].accountId) {
            this.replenishmentData[index]['dispAccNum'] = this.customerAccountsWithAll[i].accountNumber;
          }
        })
        
        // mask UI Approved status to Approved for UI
        this.replenishmentData[index].rplnshHdrStatusDisplay = this.replenishmentData[index].rplnshHdrStatus == "UI APPROVED" ? "APPROVED" : this.replenishmentData[index].rplnshHdrStatus;
      })
      this.refreshGrid(this.replenishmentData);
    });
  }

  onExportReport(name: string) {
    this.inventoryData = this.inventoryData ? this.inventoryData : [];
    this.exportFacilityInventoryObj = {
      ...this.searchObj,
      inventoryLength: this.inventoryData.length
    }
    this.exportReportDetails(name, name == 'NavixRX Balance on Hand' ? this.exportFacilityInventoryObj : this.exportCustomerInventoryObj ? this.exportCustomerInventoryObj : this.searchCustInvObj);
  }

  onClearSearch() {
    this.facilitySearchForm.get('searchText').setValue('');
    this.customerAcctSearchForm.get('searchText').setValue('');
    this.replenSearchForm.reset();
    this.disableSearchFlag = false;
    this.clearSearchFlag = false;
    //for inv by customer
    this.searchObj = {
      "cin": null,
      "ndc": null,
      "srcItemNum": null,
      "genericName": null,
      "tradeName": null,
      "inventory": false
    };
    this.searchReplenListObj = {
      "invtryAcctId": [null],
      "status": [],
      "fromDate": null,
      "toDate": null
    };
    this.searchCustInvObj = {
      "accountNumber": null,
      ...this.searchCustAcctObj
    }
    this.exportCustomerInventoryObj = this.searchCustInvObj;
  }

  onClearSearchResults() {
    this.resetSearchObj();
    this.onClearSearch();
    this.gridReset();
  }

  onEditInventory(inventoryRow: any, tabName: string) {
    this.replenFlag = false;
    this.customerAcct = false;
    if (tabName === 'facilityInventory') {
      this.searchInvListObj.cin = inventoryRow.cin;
      this.inventoryService.searchFacilityInventoryData(this.searchInvListObj).subscribe((response: any) => {
        this.facilityInventoryRowData = !response.hasError ? response.data.inventoryAdjustmentList : [];
        inventoryRow.inventoryAdjustmentList = this.facilityInventoryRowData;
        inventoryRow.tabName = tabName;
        this.openDialog(inventoryRow);
      });
    }
    else {
      inventoryRow.tabName = tabName;
      this.openDialog(inventoryRow);
    }
  }

  onViewMoreDetails(replenRow: any, tabName: string) {
    if (tabName === 'replenOrders') {
      this.replenFlag = true;
      this.customerAcct = false;
      this.openDialog(replenRow);
    }
  }

  onCustomParLevel(invRow: any, tabName: string) {
    if(tabName === 'customerAccount'){
      this.customerAcct = true;
      this.replenFlag = false;
      invRow["accNumAndName"] = this.customerAcctSearchForm.value.accountData.accNumAndName;
      this.openDialog(invRow);
    }
  }

  onInvoiceDownloadClick(invNum, replenRow) {
    this.searchReplenInvoiceDownloadObj.invoiceNumber = invNum; //for testing in dev "7220817284"
    this.searchReplenInvoiceDownloadObj.shipToAccountNumber = replenRow.sapAccountNumber; //for testing in dev"2052009086"
    if (this.searchReplenInvoiceDownloadObj){
      this.inventoryService.downloadReplenOrderInvoice(this.searchReplenInvoiceDownloadObj).subscribe((response: any) => {
        if (response && !response.hasError) {
          if (response.data != null){
            const blob = new Blob([response.data], {
              type: "application/pdf",
            });
            const url = window.URL.createObjectURL(blob);
            var file = document.createElement("a");
            file.download = 
            'invoice_'+replenRow.invoiceNumber[0]+".pdf";
            file.href = url;
            file.click();
          }
          else {
            this.toastr.error('Invoice unavailable for download at this time.');
          }
        }
        else {
          this.toastr.error('Server error.');
        }
      });
    }
  }

  getOrderStatusOptions() {
    this.orderStatusList = this.userService.getStaticDataDetails();
    Object.keys(this.orderStatusList).forEach((index) => {
      if (this.orderStatusList[index].staticDataCategory == "Replen_Order_Status_For_MM") {
        this.orderStatusDetails.push(this.orderStatusList[index]);
      }
    });
    return this.orderStatusDetails;
  }

  isUserExternal(email) {
    if (email.toLowerCase().includes("@cardinalhealth.com") || email.toLowerCase().includes("@sonexushealth.com") || email.toLowerCase().includes("@dev.npch.int")) {
      this.internalUser = true;
    }
    else {
      this.internalUser = false;
    }
  }

  /*********************Component Methods*********************/

  /*********************Dialog Methods*********************/
  public openDialog(inventoryRow: any) {
    if (this.replenFlag == true) {
      const dialogRef = this.dialog.open(ModalDialogReplenishmentComponent, {
        data: inventoryRow,
        width: '75%',
        autoFocus: false,
        disableClose: true
      });
  
      dialogRef.afterClosed().subscribe((result: any) => {
        this.getReplenishmentOrderData();
      });
    }
    else if (this.customerAcct) {
      const dialogRef = this.dialog.open(ModalDialogCustomerAcctComponent, {
        data: inventoryRow,
        width: '45%',
        autoFocus: false,
        disableClose: true
      });
  
      dialogRef.afterClosed().subscribe((result: any) => {
        // if (result && result == 'save') {
        // }
      });
    }
    else {
      const dialogRef = this.dialog.open(ModalDialogInventoryComponent, {
        data: inventoryRow,
        width: '75%',
        autoFocus: false,
        disableClose: true
      });

      dialogRef.afterClosed().subscribe((result: any) => {
        // if (result && result == 'save') {

        // }
      });
    }
    
  }  
  /*********************Dialog Methods*********************/

  /*********************Init/Reset Methods*********************/
  private gridReset() {
    this.inventoryData = [];
    this.replenishmentData = [];
    this.refreshGrid(this.inventoryData);
    this.refreshGrid(this.replenishmentData);
  }

  private init() {
    //this.facilitySearchInput.nativeElement.focus(); // onload set focus in search input
    this.orderStatusDetails = this.getOrderStatusOptions();
    this.dataSource = new MatTableDataSource(this.inventoryData);
    this.noDataFlag = true;
    this.userNum = null;
  }

  private refreshGrid(obj) {
    this.dataSource = new MatTableDataSource(obj);
    if (this.tabItem.name === 'Customer Account') {
      this.tabItem.name = 'Customer Account';
      this.dataSource.paginator = this.t2paginator;
      this.dataSource.sort = this.t2Sort;
    }
    else if (this.tabItem.name === 'Replenishment Orders') {
      this.dataSource.paginator = this.t3paginator;
      this.dataSource.sort = this.t3Sort;
    }
    else {
      this.dataSource.paginator = this.t1paginator;
      this.dataSource.sort = this.t1sort;
    }

    this.noDataFlag = (obj && obj.length <= 0);
  }

  private resetSearchObj() {
    this.searchObj = {
      "cin": null,
      "ndc": null,
      "srcItemNum": null,
      "genericName": null,
      "tradeName": null,
      "inventory": false
    };

    this.searchCustAcctObj = {
      "assignedAccounts": null,
      "cin": null,
      "dc": null,
      "srcItemNum": null,
      "description": null,
      "genericName": null,
      "tradeName": null,
      "ndc": null,
      "inventory": false
    }

    this.searchCustInvObj = {
      "accountNumber": null,
      ...this.searchCustAcctObj
    };

    this.searchReplenListObj = {
      "invtryAcctId": [null],
      "status": [],
      "fromDate": null,
      "toDate": null
    };
  }

  private exportReportDetails(reportName, reportRequest) {
    this.inventoryService.export(reportName, reportRequest).subscribe((response) => {
      if (response && !response.hasError) {
        const blob = new Blob([response.data], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = window.URL.createObjectURL(blob);
        var file = document.createElement("a");
        file.download =
          // "WBD_" +
          reportName + ' Report' +
          // "_" +
          // new Date() +
          ".xlsx";
        file.href = url;
        file.click();
      }
      else {
        // alert("This menu is under development");
      }
    });
    // console.log(this.dataSource.sort) ;
  }

  /*********************Init/Reset Methods*********************/

}





