import { ErrorNotificationType } from '../../constants/app.constant';
import { AppError } from './app-error';

export class ServerError extends AppError {
  constructor(error: any, status: number) {
    super();

    this.handled = false;
    this.status = status;
    this.type = error && error.businessFault ? 'business' : 'system';
    this.notificationType =
      (error && error.messageDisplay) || ErrorNotificationType.Toast;
    this.errorId = error ? error.spanId : null;
    this.useServerMessage = error ? error.useServerMessage : false;
    this.url = error ? error.url : null;
    this.securityFault = error ? error.securityFault : false;
    this.businessFault = error ? error.businessFault : false;
    this.logoutUser = error ? error.logoutUser : false;
    this.messageId =
      error && error.faultInfos ? error.faultInfos[0].faultCode : '';
    this.message =
      error && error.useServerMessage && error.faultInfos
        ? error.faultInfos[0].faultMessage
        : null;
  }

  status: number;
  messageId: string;
  useServerMessage: boolean;
  securityFault: boolean;
  businessFault: boolean;
  logoutUser: boolean;
  type: 'business' | 'system';
  notificationType: ErrorNotificationType;
  url: string;
  handled: boolean;
}
