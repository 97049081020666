<h2 mat-dialog-title>
    <div>{{dialogDetails.accountName}} NavixRx
        <span *ngIf="dialogDetails.action == 'Add'"> Add Exception</span>
        <span *ngIf="dialogDetails.action == 'Edit'"> Compliance Pkg Fee Cost Edit</span>
        <span *ngIf="dialogDetails.action == 'Delete'"> Compliance Pkg Fee Cost Delete</span>        
    </div>
    <mat-icon class="dialog-icon" (click)="onCloseDialog()">close</mat-icon>
</h2>
<mat-dialog-content>
    <form name="BillingForm" [formGroup]="billingForm" novalidate>
        <div class="row">
            <div class="col-12 block">
                <div class="wrapper">
                    <ul class="non-editable">
                        <li class="col2">
                            <div class="form-wrap">
                                <mat-form-field appearance="fill">
                                    <mat-label>CIN Description</mat-label>
                                    <mat-select formControlName="bill_cin_desc"
                                        (selectionChange)="onBillCINDescChange($event.value)" required>
                                        <mat-option *ngFor="let desc of billCinDetails" [value]="desc.description">{{
                                            desc.description }}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-error *ngIf="billingForm.controls.bill_cin_desc.status == 'INVALID'">
                                    CIN Description is required
                                </mat-error>
                            </div>
                            <div class="form-wrap txt-only">
                                <!-- Autopopulate CIN value -->
                                <label>CIN:</label>
                                <span><strong>{{CINNumber}}</strong></span>
                            </div>
                        </li>
                        <li class="col2">
                            <div class="form-wrap">
                                <mat-form-field appearance="fill">
                                    <mat-label>External Customer Cost</mat-label>
                                    <span matPrefix>$ &nbsp;</span>
                                    <input matInput formControlName="ext_cust_cost" required />
                                </mat-form-field>
                                <mat-error *ngIf="billingForm.controls.ext_cust_cost.status == 'INVALID'">
                                    External Customer Cost is required
                                </mat-error>
                            </div>
                            <div class="form-wrap">
                                <mat-form-field appearance="fill">
                                    <mat-label>Reason for {{reasonText}}</mat-label>
                                    <textarea placeholder="Maximum 250 characters allowed" matInput #message maxlength="250" formControlName="cost_change_reason"                               required></textarea>
                                    <mat-hint align="end">{{message.value.length}} / 250</mat-hint>
                                </mat-form-field>                                
                                <mat-error *ngIf="billingForm.controls.cost_change_reason.status == 'INVALID' && dialogDetails.action != 'Delete'">
                                    Reason for Cost Change is required  
                                </mat-error>
                                <mat-error *ngIf="billingForm.controls.cost_change_reason.status == 'INVALID' && dialogDetails.action == 'Delete'">
                                    Reason for Delete is required  
                                </mat-error>
                            </div>
                        </li>
                        <li class="col2">
                            <div class="form-wrap">
                                <mat-form-field appearance="fill" class="calendar-control">
                                    <mat-label>Effective Start Date</mat-label>
                                    <input matInput [owlDateTime]="effStartDate" placeholder="MM/DD/YYYY"
                                        formControlName="eff_start_date" required>
                                    <owl-date-time #effStartDate [pickerType]="'calendar'"></owl-date-time>
                                    <span [owlDateTimeTrigger]="effStartDate" class="dateTimeIcon material-icons">
                                        calendar_today
                                    </span>
                                </mat-form-field>
                                <mat-error *ngIf="billingForm.controls.eff_start_date.status == 'INVALID'">
                                    {{ billingForm.get('eff_start_date').hasError('PastDateErr') ? "Start Date cannot be greater than End Date" : ""
                                    || billingForm.get('eff_start_date').hasError('RequiredErr') ? "Invalid Start Date selection. Please select valid date" : ""
                                    || billingForm.get('eff_start_date').hasError('NotUnique') ? "Overlapping Start / End Dates" : "" }}
                                </mat-error>
                            </div>
                            <div class="form-wrap">
                                <mat-form-field appearance="fill" class="calendar-control">
                                    <mat-label>Effective End Date</mat-label>
                                    <input matInput [owlDateTime]="effEndDate" placeholder="MM/DD/YYYY"
                                        formControlName="eff_end_date" required>
                                    <owl-date-time #effEndDate [pickerType]="'calendar'"></owl-date-time>
                                    <span [owlDateTimeTrigger]="effEndDate" class="dateTimeIcon material-icons">
                                        calendar_today
                                    </span>
                                </mat-form-field>
                                <mat-error *ngIf="billingForm.controls.eff_end_date.status == 'INVALID'">
                                    {{ billingForm.get('eff_end_date').hasError('PastDateErr') ? "End Date cannot be less than Start Date" : ""
                                    || billingForm.get('eff_end_date').hasError('RequiredErr') ? "Invalid End Date selection. Please select valid date" : ""
                                    || billingForm.get('eff_end_date').hasError('NotUnique') ? "Overlapping Start / End Dates" : "" }}
                                </mat-error>
                            </div>
                        </li>

                    </ul>
                </div>
            </div>
        </div>
    </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-stroked-button color="primary" (click)="onCloseDialog()">Cancel</button>
    <button mat-button cdkFocusInitial mat-flat-button color="primary" class="button primary"
        (click)="onSaveClick(this.dialogDetails.action)"
        [disabled]="billingForm.status == 'INVALID' || disableCntrl">{{buttonText}}</button>       

</mat-dialog-actions>