<h2 mat-dialog-title> {{replenDialogTitle}}
    <mat-icon class="dialog-icon" (click)="onCloseDialog()">close</mat-icon>
  </h2>
  
  <mat-dialog-content class="orderStatus-dialog inventory-dialog">
  <form name="editReplenOrder" [formGroup]="editReplenOrder" novalidate>  
    <!-- Order Status Data -->
    <div class="mat-elevation-z8 dialog-table">
      <table mat-table [dataSource]="dataSource" aria-describedby="Replenishment Order detail view" matSort>
  
        <ng-container matColumnDef="srcItemNum">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Source Item Number</th>
          <td mat-cell *matCellDef="let row">
            {{row.srcItemNum}} </td>
        </ng-container>

        <ng-container matColumnDef="cin">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">CIN</th>
          <td mat-cell *matCellDef="let row">
            {{row.cin}} </td>
        </ng-container>
  
        <ng-container matColumnDef="ndc">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">NDC</th>
          <td mat-cell *matCellDef="let row">
            {{row.ndc}}</td>
        </ng-container>
  
        <ng-container matColumnDef="itemDescription">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Item Description</th>
          <td mat-cell *matCellDef="let row">
            {{row.itemDescription}} </td>
        </ng-container>

        <ng-container matColumnDef="packQty">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Pack Qty</th>
          <td mat-cell *matCellDef="let row">
            {{row.packQty}} </td>
        </ng-container>
  
        <ng-container matColumnDef="packSize">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Pack Size</th>
          <td mat-cell *matCellDef="let row">
            {{row.packSize}} </td>
        </ng-container>
  
        <ng-container matColumnDef="suggestedOrderQty">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Suggested Order Qty</th>
          <td mat-cell *matCellDef="let row">
            {{row.suggestedOrderQty}} </td>
        </ng-container>
  
        <ng-container matColumnDef="orderQty">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Order Qty</th>
          <td mat-cell *matCellDef="let row">
            <input matInput formControlName="orderQty{{row.index}}" [(ngModel)]="row.orderQty" placeholder="Order Qty" class="order-qty" (keyup)="onEditReplen(row.index)">
            <mat-error *ngIf="editReplenOrder.controls['orderQty'+row.index].status ==='INVALID'" id="errors">
              Order qty must be positive and numeric
          </mat-error></td>
        </ng-container>
  
        <ng-container matColumnDef="onHandQty">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Qty on Hand</th>
          <td mat-cell *matCellDef="let row">
            {{row.onHandQty}} </td>
        </ng-container>
  
        <ng-container matColumnDef="thirtyDayUsage">
          <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Past 30 Day Usage</th>
          <td mat-cell *matCellDef="let row">
            {{row.thirtyDayUsage}} </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  
      </table>
      <!-- Row shown when there is no matching data. -->
      <div *ngIf="noDataFlag" class="no-records">
        No records found
      </div>
      <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 200]"></mat-paginator>
    </div>
    </form>
    <!-- Order Status Data -->
  </mat-dialog-content>
  <form name="editPoNum" [formGroup]="editPoNum" novalidate>
  <div class="row save-bar">
    <mat-dialog-actions class="add-line">
      <button mat-button mat-flat-button color="primary" [disabled]="!pendingOrderFlag" class="button primary" (click)="addItem()"><mat-icon class="material-icons-outlined" title="add">add_circle_outline</mat-icon></button>
    </mat-dialog-actions>
    <mat-dialog-actions class="right">
      <mat-form-field class="replen-order">
        <mat-label>Replenishment PO#</mat-label>
        <input matInput formControlName="poNumber" type="text" maxlength="20" placeholder="Enter debtor PO#..." (keyup)="onEditReplen('po')">
      </mat-form-field>
      <button mat-button mat-stroked-button color="primary" (click)="onCloseDialog()">Cancel</button>
      <button mat-button mat-flat-button color="primary" class="button primary" [disabled]="!pendingOrderFlag || orderQtyErrorFlag || !editFlag" (click)="onSaveOrder('save')">Save</button>
      <button mat-button mat-stroked-button color="primary" [disabled]="!pendingOrderFlag || orderQtyErrorFlag" (click)="onApproveOrder('approve')">Approve</button>
    </mat-dialog-actions>
  </div>
  </form>