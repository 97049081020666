<div class="h-100 okta-container">
  <div class="header">
    <div class="logo">
      <img class="mr-3 pt-1" src="assets/images/cardinal-logo.png" alt="image" />
      <span class="title">
        <span class="black pr-2">WBD</span>
      </span>
    </div>
  </div>
  <div class="error-body">
    <div class="error-text">
      {{ config.message }}
    </div>
  </div>
</div>
