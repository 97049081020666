import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { SearchCriteria } from '../order';
import { PmsService } from '../../pms/pms.service';
import { ToastService } from 'src/app/shared/utils/toast.service';

@Component({
  selector: 'app-modal-dialog-cancel-order',
  templateUrl: './modal-dialog-cancel-order.component.html',
  styleUrls: ['./modal-dialog-cancel-order.component.scss']
})
export class ModalDialogCancelOrderComponent implements OnInit {
  /*********************Properties*********************/
  dialogTitle: string;
  orderStatusRowData: any;
  orderNum: any;
  searchObj: SearchCriteria = {
    "orderNo": null,
    "cancelMessage": null
  };

  /*********************Properties*********************/
  constructor(
    private dialogRef: MatDialogRef<ModalDialogCancelOrderComponent>,
    private pmsService: PmsService,
    private toastr: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.orderNum = this.data.orderNumber;
    this.dialogTitle = 'Confirm Cancel Order';
  }

  /*********************Component Methods*********************/

  onCancelOrder(cancelMessage: string) {
    let cancelDataObj = {
      "orderNo": this.orderNum,
      "cancelMessage": cancelMessage,
    }
    this.pmsService.cancelOrderDetails(cancelDataObj).subscribe((response: any) => {
      if (!response.hasError) {
        this.toastr.success('Order is cancelled successfully.');
        this.dialogRef.close('cancel');
      }
    });
  }
  
  onCloseDialog() {
    this.dialogRef.close();
  }
  /*********************Component Methods*********************/

}
