import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RbcService } from './rbc.service';

@Component({
  selector: 'app-rbc',
  templateUrl: './rbc.component.html',
  styleUrls: ['./rbc.component.scss']
})
export class RbcComponent implements OnInit {
  /*********************Properties*********************/
  rbcForm: FormGroup;
  GLNData: any = {
    GLN: null,
    custName: null
  }

  GLN: any;
  custName: any;
  JWTToken: any;
 /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(private formBuilder: FormBuilder,
    private rbcService: RbcService) {
  }
/*********************Constructor*********************/

/*********************Lifecyle Methods*********************/
  ngOnInit(): void {
    //Form Initialization
    this.rbcForm = this.formBuilder.group({
      'sap': [null, [Validators.required]],
      'zip': [null, [Validators.required]]
    });

    this.getToken();
  }
/*********************Lifecyle Methods*********************/
 /*********************Helper Methods*********************/

/*********************Helper Methods*********************/
/*********************Component Methods*********************/
  getToken() {
    this.rbcService.getToken().subscribe((response) => {
      /* istanbul ignore if */
      if (!response.hasError) {
        this.JWTToken = response.data.token_type + ' ' + response.data.access_token;
      }
    });
  }

  onSubmitForm() {
    this.getServiceData();
  }

  getServiceData() {
    this.GLNData = {
      GLN: null,
      custName: null
    }
    this.rbcService.getRBCData(this.rbcForm.controls.sap.value, this.rbcForm.controls.zip.value, this.JWTToken).subscribe((response) => {
      /* istanbul ignore if */
      if (!response.hasError) {
        this.GLNData = {
          GLN: response.data.GLNInfo ? response.data.GLNInfo[0].globalLocationNumber : null,
          custName: response.data.GLNInfo ? response.data.GLNInfo[0].customerName : null,
        };
        this.GLN = this.GLNData.GLN ? this.GLNData.GLN : 'No GLN found for this account and zip code';
        this.custName = this.GLNData.custName ? this.GLNData.custName : 'No customer found for this account and zip code';
      }
      else {
        this.GLN = 'No GLN found for this account and zipcode';
        this.custName = 'No Customer found for this account and zipcode';
      }
    });
  }
/*********************Component Methods*********************/
}
