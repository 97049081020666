<h2 mat-dialog-title>{{title}}
    <mat-icon class="dialog-icon" (click)="onCloseDialog()">close</mat-icon>
    <hr>
    <h6 class="account-details">Account Name: {{accountName}}</h6>
    <h6 class="account-details">Account Number: {{sapAccountNumber}}</h6>
</h2>
<form [formGroup]="editConfigSettingsForm">

<mat-dialog-content>
        <mat-expansion-panel (opened)="panelOpenState1 = true" (closed)="panelOpenState1 = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div>
                        <mat-icon *ngIf="!panelOpenState1">add_circle_outline</mat-icon>
                        <mat-icon *ngIf="panelOpenState1">remove_circle_outline</mat-icon>
                    </div>
                    <div class="expand_header">Billing</div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row billing-exception">
                <mat-checkbox class="col" formControlName="enableBilling">Enable Billing</mat-checkbox>
                <div class="col text-right">
                    <span (click)="onDialogOpen('Add')">
                        <span class="fa fa-solid fa-user-plus billing-pointer"></span>
                        <label class="label-txt billing-label billing-pointer">Add Exception</label>
                    </span>
                </div>
            </div>
            <!-- Billing Data -->
            <div class="mat-elevation-z8 scroll billing-exception">
                <table mat-table [dataSource]="dataSource" aria-describedby="exception view" matSortActive="startDate"
                    matSortDirection="desc" matSort>

                    <ng-container matColumnDef="cin">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">CIN</th>
                        <td mat-cell *matCellDef="let row">
                            {{row.cin}} </td>
                    </ng-container>

                    <ng-container matColumnDef="cinDescription">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">CIN Description</th>
                        <td mat-cell *matCellDef="let row">
                            {{row.cinDescription}} </td>
                    </ng-container>

                    <ng-container matColumnDef="price">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Cost</th>
                        <td mat-cell *matCellDef="let row">
                            <span>{{row.price | currency: 'USD'}}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="startDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Start Date</th>
                        <td mat-cell *matCellDef="let row">
                            {{row.startDate | date:'MM-dd-yyyy'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="endDate">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">End Date</th>
                        <td mat-cell *matCellDef="let row">
                            {{row.endDate | date:'MM-dd-yyyy'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef scope="col"> </th>
                        <td mat-cell *matCellDef="let row">
                            <div style="display:flex; float:right">
                                <mat-icon class="grid-icon" title="edit" (click)="onDialogOpen('Edit', row)">edit
                                </mat-icon>
                                <mat-icon class="grid-icon" [ngClass]="{'disable-btn': !(row.startDate > today)}"
                                    title="Remove" (click)="onDialogOpen('Delete', row)">delete</mat-icon>
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                </table>
                <!-- Row shown when there is no matching data. -->
                <div *ngIf="noDataFlag" class="no-records">
                    No exceptions found
                </div>
                <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 200]"></mat-paginator>
            </div>
            <!-- Billing Data -->
        </mat-expansion-panel>
        <mat-expansion-panel (opened)="panelOpenState2 = true" (closed)="panelOpenState2 = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div>
                        <mat-icon *ngIf="!panelOpenState2">add_circle_outline</mat-icon>
                        <mat-icon *ngIf="panelOpenState2">remove_circle_outline</mat-icon>
                    </div>
                    <div class="expand_header">Par Level</div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-checkbox formControlName="customParLevel">Custom Par Level</mat-checkbox>
        </mat-expansion-panel>
        <mat-expansion-panel (opened)="panelOpenState3 = true" (closed)="panelOpenState3 = false" [hidden]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div>
                        <mat-icon *ngIf="!panelOpenState3">add_circle_outline</mat-icon>
                        <mat-icon *ngIf="panelOpenState3">remove_circle_outline</mat-icon>
                    </div>
                    <div class="expand_header">Shipping</div>

                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>
                <mat-radio-group formControlName="shipDirToPtnt">
                    <mat-radio-button [value]="false">Ship to Pharmacy</mat-radio-button>
                    <mat-radio-button [value]="true">Ship Directly to Patient</mat-radio-button>
                </mat-radio-group>
            </div>
            <mat-checkbox>Override Ship-to Name</mat-checkbox>
            <mat-form-field>
                <input matInput type="text" placeholder="Enter new name...">
            </mat-form-field>
        </mat-expansion-panel>
        <mat-expansion-panel (opened)="panelOpenState4 = true" (closed)="panelOpenState4 = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div>
                        <mat-icon *ngIf="!panelOpenState4">add_circle_outline</mat-icon>
                        <mat-icon *ngIf="panelOpenState4">remove_circle_outline</mat-icon>
                    </div>
                    <div class="expand_header">SLA</div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="label-txt">SLA:</label>
            <mat-form-field class="sla-selection">
                <mat-select formControlName="sla">
                    <mat-option *ngFor="let sData of slaDetails" [value]="sData.staticDataValue">
                        {{sData.staticDataValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            days
        </mat-expansion-panel>
        <mat-expansion-panel (opened)="panelOpenState5 = true" (closed)="panelOpenState5 = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div>
                        <mat-icon *ngIf="!panelOpenState5">add_circle_outline</mat-icon>
                        <mat-icon *ngIf="panelOpenState5">remove_circle_outline</mat-icon>
                    </div>
                    <div class="expand_header">Patient Order</div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-error *ngIf="checkValid()">At least one checkbox must be selected.</mat-error>
            <mat-checkbox formControlName="autoApproved">Auto-Approval</mat-checkbox>
            <mat-checkbox formControlName="allowEditsInNavix">Allow Edits in NavixRx OM</mat-checkbox> 
            <mat-label class="label-type">Label Type:</mat-label> 
            <mat-form-field class="label-selection">
                <mat-select formControlName="hoaPrintType"> 
                    <mat-option *ngFor="let option of labelTypeList"
                    [value]="option.value">{{ option.value }}</mat-option>
                </mat-select>
            </mat-form-field>
        </mat-expansion-panel>
        <mat-expansion-panel (opened)="panelOpenState6 = true" (closed)="panelOpenState6 = false">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div>
                        <mat-icon *ngIf="!panelOpenState6">add_circle_outline</mat-icon>
                        <mat-icon *ngIf="panelOpenState6">remove_circle_outline</mat-icon>
                    </div>
                    <div class="expand_header">Interface Version</div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <label class="label-txt">Interface Version:</label>
            <mat-form-field class="version-selection">
                <mat-select formControlName="interfaceVersion">
                    <mat-option *ngFor="let vData of interfaceVersionDetails" [value]="vData.staticDataValue">
                        {{vData.staticDataValue}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </mat-expansion-panel>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button mat-stroked-button color="primary" (click)="onSaveClick('cancel')">Cancel</button>
    <button mat-button cdkFocusInitial mat-flat-button color="primary" class="button primary"
        (click)="onSaveClick('save')" [disabled]="checkValid()">Save</button>
</mat-dialog-actions>
</form>
