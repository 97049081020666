<h2 mat-dialog-title> {{dialogTitle}}
    <mat-icon class="dialog-icon" (click)="onCloseDialog()">close</mat-icon>
  </h2>
  <mat-dialog-content>
    <div class="info-message">
        <i class="fa fa-info-circle info-icon" aria-hidden="true"></i>
        <span>
          This action cannot be undone. The order will need to be resent.
        </span>
      </div>
    <h5>
        Are you sure you want to cancel Order # {{orderNum}}?
    </h5>
    <div>
      <mat-error *ngIf="message.value.length == 0">
        Reason for Cancellation is required  
    </mat-error>  
        <mat-form-field appearance="fill">
            <mat-label>Reason for cancellation:</mat-label>
            <textarea placeholder="Maximum 256 characters allowed" matInput #message maxlength="256"></textarea>
            <mat-hint align="end">{{message.value.length}} / 256</mat-hint>
        </mat-form-field>                           
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-flat-button color="primary" class="button primary" (click)="onCancelOrder(message.value)" [disabled]="message.value.trim().length < 10">Yes, cancel</button>
    <button mat-button cdkFocusInitial mat-stroked-button color="primary" (click)="onCloseDialog()">No</button>
  </mat-dialog-actions>
