import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';

import { ClientApi } from 'src/app/core/constants/api.constant';

@Injectable({
  providedIn: 'root'
})
export class FormularyService  extends BaseService {
  /*********************Properties*********************/
//  baseUrl ="https://wbd-mdm.apps.cf.np.gc.cahcloud.net";
 /*********************Properties*********************/

 /*********************Constructor*********************/
  constructor(protected http: HttpClient) {
    super(http);
    //this.apiConfig.order = 'http://localhost:7285/api/v1/';
   }
/*********************Constructor*********************/

/*********************Service Methods*********************/

// Formulary search service
searchFormularyData(searchCriteria){
  // const url = this.baseUrl + ClientApi.SearchFormularyData;
  const url = this.serviceUrl(this.apiConfig.mdm, ClientApi.SearchFormularyData);
  return super.post(url, searchCriteria).pipe(
    map((response: any) => {
      // if (!response.hasError) {
        return response;
      // }
    })
  )
}

// Formulary Edit/Remove service
editRemoveFormularyData(saveObj){
  const url = this.serviceUrl(this.apiConfig.mdm, ClientApi.EditRemoveFormularyData);
  return super.post(url, saveObj).pipe(
    map((response: any) => {
      // if (!response.hasError) {
        return response;
      // }
    })
  )
}

// Inventory search service
searchInventoryData(searchCriteria){
  const url = this.serviceUrl(this.apiConfig.order, ClientApi.SearchFacilityInventoryData);
  return super.post(url, searchCriteria).pipe(
    map((response: any) => {
      // if (!response.hasError) {
        return response;
      // }
    })
  )
}

/*********************Service Methods*********************/
}
