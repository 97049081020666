import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CommonControls } from 'src/app/shared/common-control/common-controls';
import { ModalDialogConfirmComponent } from './modal-dialog-confirm/modal-dialog-confirm.component';

@Component({
  selector: 'app-modal-dialog-customer-acct',
  templateUrl: './modal-dialog-customer-acct.component.html',
  styleUrls: ['./modal-dialog-customer-acct.component.scss']
})
export class ModalDialogCustomerAcctComponent implements OnInit {

  /*********************Properties*********************/
  dialogTitle: string;
  saveParLevel: FormGroup;
  invRowData: any;
  errorFlag: boolean = false;

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ModalDialogCustomerAcctComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) { }
  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit(): void {
    this.invRowData = this.data;
    this.dialogTitle = "Edit Par Level"

    //Form Initialization

    this.saveParLevel = this.formBuilder.group({
      'previousParLevel': this.data.parLevel,
      "parLevel": [this.data.parLevel, [Validators.required]]
    });
  }
  /*********************Lifecyle Methods*********************/

  /*********************Component Methods*********************/
  onCloseDialog() {
    this.dialogRef.close();
  }

  onConfirmChanges() {
    this.data.parLevel = this.saveParLevel.value.parLevel;
    this.openConfirmationWindow();
  }

  parLevelValidation(parLevel) {
    const previousParLevel = this.saveParLevel.controls.previousParLevel;
    if (parLevel && parLevel != "." && parLevel <= 9999999) {
      if(!CommonControls.numericOnlyFractional(parLevel)) {
        this.errorFlag = true;
        this.saveParLevel.value.previousParLevel = previousParLevel;
      } else if (parLevel != previousParLevel) {
        // If it passes set the previous par level to current value
        this.saveParLevel.value.parLevel = parLevel;
        this.errorFlag = false;
      }
    } else {
      this.errorFlag = true;
      this.saveParLevel.value.parLevel = previousParLevel;
    };
  }
  /*********************Component Methods*********************/

  /*********************Dialog Methods*********************/
  public openConfirmationWindow() {
    const dialogRef = this.dialog.open(ModalDialogConfirmComponent, {
      data: this.data,
      width: '35%',
      autoFocus: false,
      disableClose: true
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result && result == 'save') {
        this.dialogRef.close();
      }
    });
  }
  /*********************Dialog Methods*********************/
}
