import { NgModule, CUSTOM_ELEMENTS_SCHEMA, APP_INITIALIZER, ModuleWithProviders, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ErrorInterceptor } from './interceptors/error.interceptor';

import { RouteUtilService } from '../shared/utils/route-util.service';
import { ServicesModule } from './services/services.module';
import { InitFactory } from './services/init/app-init.factory';
import { AppConfigService } from './services/init/app-config.service'

import './extensions/array.extensions';
import './extensions/object.extensions';
import './extensions/string.extensions';
// import './extensions/window.extensions';
import './guards/auth.guard';

import { MaterialModule } from '../shared/modules/material.module';
import { AppCacheModule } from './cache/cache.module';
import { ErrorModule } from './error/error.module';
import { DialogModule } from './dialog/dialog.module';
import { EncDecService } from './utils/enc-dec.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MaterialModule,
    AppCacheModule,
    ErrorModule,
    ServicesModule,
    DialogModule
  ],
  providers: [
    RouteUtilService,
    EncDecService,
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: APP_INITIALIZER, useFactory: InitFactory, deps: [AppConfigService], multi: true }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA // Tells Angular we will have custom tags in our templates
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only'
      );
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
    };
  }
}
