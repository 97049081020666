import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';

import { ClientApi } from 'src/app/core/constants/api.constant';
import { SaveAssignedCustomerCriteria, SearchAssignedCustomerCriteria, SearchCustomerCriteria, SearchUserCriteria } from './user-management';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService extends BaseService {
  /*********************Properties*********************/

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    protected http: HttpClient) {
    super(http);
  }
  /*********************Constructor*********************/
  /*********************Service Methods*********************/
  searchUserData(searchCriteria: SearchUserCriteria) {
    const url = this.serviceUrl(this.apiConfig.user, ClientApi.SearchUserData);
    return super.post(url, searchCriteria).pipe(
      map((response: any) => {
        // if (!response.hasError) {
        return response;
        // }
      })
    )
  }

  searchCustomerAccounts(searchCriteria: SearchCustomerCriteria) {
    const url = this.serviceUrl(this.apiConfig.user, ClientApi.SearchCustomerAccounts);
    return super.post(url, searchCriteria).pipe(
      map((response: any) => {
        // if (!response.hasError) {
        return response;
        // }
      })
    )
  }

  searchAssignedCustomerAccounts(searchCriteria: SearchAssignedCustomerCriteria) {
    const url = this.serviceUrl(this.apiConfig.user, ClientApi.SearchAssignedCustomerAccounts,searchCriteria.userNum);
    return super.fetch(url).pipe(
      map((response: any) => {
        // if (!response.hasError) {
        return response;
        // }
      })
    )
  }

  saveAssignedCustomerAccounts(saveCustomers: SaveAssignedCustomerCriteria){
    const url=this.serviceUrl(this.apiConfig.user,ClientApi.SaveAssignedCustomerAccounts);
      return super.post(url, saveCustomers).pipe(
        map((response: any) => {
          // if (!response.hasError) {
          return response;
          // }
        })
      )
  }

/*********************Service Methods*********************/

}
