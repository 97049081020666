import { Injectable, ErrorHandler, Injector } from '@angular/core';

import { LoggerService } from 'src/app/shared/utils/logger.service';
import { ErrorService } from './error.service';
import { ToastService } from 'src/app/shared/utils/toast.service';

import { ClientError } from './model/client-error';
import { ServerError } from './model/server-error';

@Injectable()
export class ErrorsHandler implements ErrorHandler {
  /*********************Properties*********************/
  private logger: LoggerService;
  private errorService: ErrorService;
  private toast: ToastService;
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(private injector: Injector) {
    this.logger = this.injector.get(LoggerService);
    this.errorService = this.injector.get(ErrorService);
  }
  /*********************Constructor*********************/

  /*********************Methods*********************/

  handleError(error: Error | any) {
    if (error instanceof ClientError) {
      this.handleClientError(error);
    } else if (error instanceof ServerError) {
      this.handleServerError(error);
    } else {
      this.logger.error(error);
    }

    console.error(error);
  }

  /*********************Methods*********************/

  /*********************Private Methods*********************/

  private handleClientError(error: ClientError) {
    if (error.log) {
      this.logger.error(error.message);
    } else {
      this.logger.log(error.message);
    }
  }

  private handleServerError(error: ServerError) {
    if (this.errorService.handleServerException(error)) {
      error = this.errorService.resolveServerError(error);
      this.toast.error(error.message);
    }
  }

  /*********************Private Methods*********************/
}
