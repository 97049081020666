import { CacheResolverService } from '../cache/cache-resolver.service';

import { StaticBase } from './static-base';
import { Messages } from './static';

export class State extends StaticBase {
  /*********************Properties*********************/
  private selectorPropertyName = 'messageId';
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private selectorName: string,
    private primaryKey: string,
    private storageCache: CacheResolverService
  ) {
    super(selectorName, primaryKey, storageCache);
  }
  /*********************Constructor*********************/

  /*********************Methods*********************/

  /**
   * Get message object by message id
   */
  get(id: string): Messages {
    return this.getItem(this.selectorPropertyName, id);
  }

  /*********************Methods*********************/
}
