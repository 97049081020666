import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { saveCustomParLevel } from '../../inventory';

import { InventoryService } from '../../inventory.service';
import { ToastService } from 'src/app/shared/utils/toast.service';

@Component({
  selector: 'app-modal-dialog-confirm',
  templateUrl: './modal-dialog-confirm.component.html',
  styleUrls: ['./modal-dialog-confirm.component.scss']
})
export class ModalDialogConfirmComponent implements OnInit {

  /*********************Properties*********************/
  dialogTitle: string;
  ndc: string; 
  description: string;
  parLevel: string;
  accNumAndName: string;
  srcItemNum: string;
  saveCustomParLevel: saveCustomParLevel = {
    "accountId": null,
    "accNum": null,
    "cin": null,
    "parLevel": null,
    "ndc": null,
    "srcItemNum": null,
    "packSize": null,
    "packQty": null,
    "wacPrice": null
  };
  /*********************Properties*********************/
  
  /*********************Constructor*********************/
  constructor(
    public dialogRef: MatDialogRef<ModalDialogConfirmComponent>,
    private inventoryService: InventoryService,
    private toastr: ToastService,
    @Inject(MAT_DIALOG_DATA) public data
    ) { }
  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit(): void {
    this.dialogTitle = "Confirm Par Level"
    this.ndc = this.data.ndcText;
    this.description = this.data.description;
    this.parLevel = this.data.parLevel;
    this.accNumAndName = this.data.accNumAndName;
    this.srcItemNum = this.data.srcItemNum;
  }
  /*********************Lifecyle Methods*********************/

  /*********************Component Methods*********************/
  onConfirm() {
    this.saveCustomParLevel.accountId = this.data.accountId;
    this.saveCustomParLevel.cin = this.data.cin;
    this.saveCustomParLevel.parLevel = this.data.parLevel;
    this.saveCustomParLevel.ndc = this.data.ndc;
    this.saveCustomParLevel.srcItemNum = this.data.srcItemNum;
    this.saveCustomParLevel.packSize = this.data.packSize;
    this.saveCustomParLevel.packQty = this.data.packQuantity;
    this.saveCustomParLevel.accNum = this.data.accountNumber;
    this.saveCustomParLevel.wacPrice = this.data.wacPrice;
    this.saveParLevel(this.saveCustomParLevel);
  }

  saveParLevel(saveCustomParLevel: saveCustomParLevel) {
    this.inventoryService.saveParLevel(saveCustomParLevel).subscribe((response: any) => {
      if (response?.hasError) {
        this.toastr.error('Error occured while saving the data.');
      }
      else {
        this.dialogRef.close('save');
        this.toastr.success("Data saved Successfully");
      }
    });
  }
  /*********************Component Methods*********************/

  /*********************Dialog Methods*********************/
  onCloseDialog() {
    this.dialogRef.close();
  }
  /*********************Dialog Methods*********************/
}
