import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';

import { ClientApi } from 'src/app/core/constants/api.constant';
import { SearchCriteria } from './pms';


@Injectable({
   providedIn: 'root',
})
export class PmsService extends BaseService {
   /*********************Properties*********************/
   /*********************Properties*********************/

   /*********************Constructor*********************/
   constructor(protected http: HttpClient) {
      super(http);
      // this.apiConfig.order = 'http://10.32.42.195:7285/api/v1/';
   }
   /*********************Constructor*********************/

   /*********************Service Methods*********************/
   // Formulary search service
   getPMSData(searchCriteria: SearchCriteria) {
      const url = this.serviceUrl(this.apiConfig.order, ClientApi.getPMSParsedData);
      return super.post(url, searchCriteria).pipe(
         map((response: any) => {
            // if (!response.hasError) {
            return response;
            // }
         })
      )
   }

   uploadCSVFile(file: any, accountNum: string) {
      const url = this.serviceUrl(this.apiConfig.order, ClientApi.UploadCSV);
      return super.post(url+'?accountNum='+accountNum, file).pipe(
         map((response: any) => {
            // if (!response.hasError) {
            return response;
            // }
         })
      )
   }

   getOrderDetails(searchCriteria: any) {
      const url = this.serviceUrl(this.apiConfig.order, ClientApi.getOrderDetails);
      return super.fetch(url+'?foNum='+searchCriteria).pipe(
         map((response: any) => {
            // if (!response.hasError) {
            return response;
            // }
         })
      )
   }

   saveOrderDetails(saveDataObj: any) {
      const url = this.serviceUrl(this.apiConfig.order, ClientApi.SaveOrderDetails);
      return super.post(url, saveDataObj).pipe(
         map((response: any) => {
            // if (!response.hasError) {
            return response;
            // }
         })
      )
   }

   cancelOrderDetails(cancelDataObj: any) {
      const url = this.serviceUrl(this.apiConfig.order, ClientApi.CancelOrderDetails);
      return super.post(url, cancelDataObj).pipe(
         map((response: any) => {
            // if (!response.hasError) {
            return response;
            // }
         })
      )
   }

   /*********************Service Methods*********************/
}
