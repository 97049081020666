import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { UserService } from 'src/app/core/services/user.service';
import { AccountData, SearchCriteria } from 'src/app/shared/models/header';
import { SearchCustomerCriteria } from 'src/app/modules/user-management/user-management';
import { NavigationEnd, Router } from '@angular/router';
import { TokenService } from 'src/app/shared/utils/token.service';
import { OktaPkceAuthService } from 'src/app/core/services/okta-pkce-auth.service';
import { TokenExpiration } from 'src/app/shared/models/app';
import { AuthService } from 'src/app/core/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  /*********************Properties*********************/
  userDetailsObj: any;
  userDetails: any;
  userData: any[] = [];
  userFlag: any;
  roleNum: any;
  searchObj: SearchCriteria = {
    "keyword": null
  };
  searchCustomerObj: SearchCustomerCriteria = {
    "keyword": null,
    "filterUserNum": null
  };
  accountData: AccountData[];
  tempAccDataObj: AccountData[] = [];
  allAccountObj: any = {
    accNumAndName: "All Accounts",
    accountName1: "All Accounts",
    accountNumber: "All",
    assignedAccounts: [],
    assignedAccIds: []
  }
  menuName: string;

  private onDestroy = new Subject<void>();

  readonly session = { expiring: false, warning: true, timeRemaining: null, refreshed: false };
  private subscription = {
    session: { expiring: null, expired: null }, userProfile: null,
    activeRoute: null
  };

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private appService: AppService,
    private userService: UserService,
    private router: Router,
    private token: TokenService,
    private oktaPkceAuth: OktaPkceAuthService,
    private authService: AuthService,
  ) {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.recallAccountAssignment(event);
      }
    });
  }

  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit() {
    /* istanbul ignore next */
    this.subscription.userProfile = this.userService.userDetails$.subscribe(
      userDetails => {
        this.userDetailsObj = userDetails;
        this.userDetails = JSON.parse(this.userDetailsObj.userinfo);
      });

    this.subscription.session.expiring =
      this.token.apigee.tokenExpiring$.subscribe((expiring) =>
        this.onApigeeTokenExpiring(expiring)
      );

    this.subscription.session.expired =
      this.token.apigee.tokenExpired$.subscribe((expired) =>
        this.onApigeeTokenExpired(expired)
      );


    this.init();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    this.subscription.userProfile.unsubscribe();

    this.subscription.session.expiring.unsubscribe();
    this.subscription.session.expired.unsubscribe();
  }
  
  onUserSearchCall() {
    this.appService.searchUserData(this.searchObj).subscribe((response: any) => {
      if (!response.hasError) {
        this.userData = response.data.users ? response.data.users[0].userNum : null;
        this.userFlag = response.data.users ? response.data.users[0].userRoleXrefList[0].showAllCustomer : false;
        this.roleNum = response.data.users ? response.data.users[0].userRoleXrefList[0].roleNum : null;
        this.userService.setUserFlag(this.userFlag);
        this.userService.setUserRole(this.roleNum);
        this.onUserLogin(this.userData, this.userFlag);
      }
    });
  }
  
  private onUserLogin(userId: any, userFlag: boolean) {
    if (userId) {
      if (userFlag) {
        // show all accounts
        this.searchCustomerObj.filterUserNum = "0";
        this.appService.searchCustomerData(this.searchCustomerObj).subscribe((res: any) => {
          this.tempAccDataObj = res.data.customers ? res.data.customers : []
          this.accountData = this.tempAccDataObj;
          this.accountData.forEach((data, index) => {
            this.accountData[index].accountNumber = this.accountData[index].sapAccountNumber ? this.accountData[index].sapAccountNumber.toString() : this.accountData[index].legacyAccountNumber;
            this.accountData[index]["accNumAndName"] = this.accountData[index].accountNumber.concat(" - ", this.accountData[index].accountName1);
            this.allAccountObj.assignedAccounts[index] = this.accountData[index].sapAccountNumber ? this.accountData[index].sapAccountNumber.toString() : this.accountData[index].legacyAccountNumber;
            this.allAccountObj.assignedAccIds[index] = this.accountData[index].accountId;
          });
        });
      } else {
        this.appService.getAccountSelectionDetails(userId).subscribe((accountsResponse) => {
          this.tempAccDataObj = accountsResponse.userAccountsMappings ? accountsResponse.userAccountsMappings[0].customerAccountsList : []
          this.accountData = this.tempAccDataObj;
          this.accountData.forEach((data, index) => {
            this.accountData[index].accountNumber = this.accountData[index].sapAccountNumber ? this.accountData[index].sapAccountNumber.toString() : this.accountData[index].legacyAccountNumber;
            this.accountData[index]["accNumAndName"] = this.accountData[index].accountNumber.concat(" - ", this.accountData[index].accountName1);
            this.allAccountObj.assignedAccounts[index] = this.accountData[index].sapAccountNumber ? this.accountData[index].sapAccountNumber.toString() : this.accountData[index].legacyAccountNumber;
            this.allAccountObj.assignedAccIds[index] = this.accountData[index].accountId;
          });
        });
      }
      this.appService.setAssignedAccount(this.accountData);
    }
  }

  private recallAccountAssignment(e: any) {
    this.menuName = e.url ? e.url : null;

    // Set all accounts and customer option
    if (this.menuName == '/inventory' || this.menuName == '/orderStatus' || this.menuName == '/report' || this.menuName == '/heldQueue') {
      this.accountData = [...this.tempAccDataObj];
      this.allAccountObj.accNumAndName = 'All Accounts';
      this.accountData.unshift(this.allAccountObj); // set all account  option as 1st option
    }
    else {
      this.accountData = this.tempAccDataObj ? [...this.tempAccDataObj] : this.tempAccDataObj;
    }
    this.appService.setAssignedAccount(this.accountData);
  }

  private getUserDetails() {
    this.searchObj.keyword = this.userDetails.email;
  }

  private onApigeeTokenExpiring(tokenExpiration: TokenExpiration) {
    this.session.refreshed = false;
    this.session.expiring = tokenExpiration.expiring;

    /* istanbul ignore if */
    if (this.session.expiring) {
      console.log('onApigeeTokenExpiring');
      if (this.session.warning) {
        this.session.warning = false;
        this.refreshSession();
      }
    } else {
      this.session.timeRemaining = null;
    }
  }

  /* istanbul ignore next */
  private onApigeeTokenExpired(expired: boolean) {
    if (expired) {
      this.authService.signOut();
    }
  }

  /* istanbul ignore next */
  private refreshSession() {
    this.oktaPkceAuth.getTokens().then((response) => {
      if (response) {
        this.oktaPkceAuth.renew(response).then((response) => {
          if (response.hasError) {
            this.signOut();
          } else {
            this.token.validate(response.accessToken, response.idToken).subscribe((tokenResponse: any) => {
              if (!tokenResponse.hasError) {
                this.appService.load().then(() => {
                  setTimeout(() => {
                    this.session.expiring = false;
                    this.session.warning = true;
                    this.session.refreshed = true;
                  }, 3000);
                });
              } else {
                this.signOut();
              }
            });

          }
        });
      }
    }).catch((err)=>{
      console.log(err);
    });
  }

  private signOut() {
    this.authService.signOut();
  }

  private init() {
    this.getUserDetails();
    this.onUserSearchCall();
  }
  /*********************Private Methods*********************/

}