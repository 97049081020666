import { Cache } from '@ngx-cache/core';
import {
  LocalStorageCacheService,
  MemoryCacheService
} from '@ngx-cache/platform-browser';

export function CacheResolverFactory(): Cache {
  return window.localStorage
    ? new LocalStorageCacheService('browser')
    : new MemoryCacheService('browser');
}
