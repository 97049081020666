import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { MasterRoutes, RoutePrefix } from './core/constants/routes.constant';
import { FormularyComponent } from './modules/formulary/formulary.component';
import { InventoryComponent } from './modules/inventory/inventory.component';
import { OrderStatusComponent } from './modules/order-status/order-status.component';
import { ReportsComponent } from './modules/reports/reports.component';
import { AuthGuard } from './core/guards/auth.guard';
import { MainComponent } from './common/main/main.component';
import { AuthorizeGuard } from './core/guards/authorize.guard';
import { PmsComponent } from './modules/pms/pms.component';
import { UserManagementComponent } from './modules/user-management/user-management.component';
import { CustomerAccountManagementComponent } from './modules/customer-account-management/customer-account-management.component';
import { menuOption } from './core/constants/nav-constant';
import { RbcComponent } from './modules/rbc/rbc.component';
import { HeldQueueComponent } from './modules/held-queue/held-queue.component';

const routes: Routes = [
  // {
  //   path:'',
  //   component: DashboardComponent
  //  }
  {
    path: MasterRoutes.Okta,
    loadChildren: () => import('./modules/okta/okta.module').then(m => m.OktaModule)
  },
  {
    path: '',
    component: MainComponent,
    canActivate: [AuthGuard, AuthorizeGuard],
    canActivateChild: [AuthGuard, AuthorizeGuard],
    children: [
      {
        path: RoutePrefix.Dashboard,
        component: DashboardComponent
      },
      {
        path: RoutePrefix.HeldQueue,
        component: HeldQueueComponent,
        loadChildren: () => import('./modules/held-queue/held-queue.module').then(m => m.HeldQueueModule),
        data: { userRolePermission: menuOption[1].config.permissionsList, permissionNum: menuOption[2].config.permissionNum}
      },
      {
        path: RoutePrefix.Inventory,
        component: InventoryComponent,
        loadChildren: () => import('./modules/inventory/inventory.module').then(m => m.InventoryModule),
        data: { userRolePermission: menuOption[2].config.permissionsList, permissionNum: menuOption[2].config.permissionNum}
      },
      {
        path: RoutePrefix.Formulary,
        component: FormularyComponent,
        loadChildren: () => import('./modules/formulary/formulary.module').then(m => m.FormularyModule),
        data: { userRolePermission: menuOption[3].config.permissionsList, permissionNum: menuOption[3].config.permissionNum}
      },
      {
        path: RoutePrefix.OrderStatus,
        component: OrderStatusComponent,
        loadChildren: () => import('./modules/order-status/order-status.module').then(m => m.OrderStatusModule),
        data: { userRolePermission: menuOption[4].config.permissionsList, permissionNum: menuOption[4].config.permissionNum}
      },
      {
        path: RoutePrefix.Report,
        component: ReportsComponent,
        loadChildren: () => import('./modules/reports/reports.module').then(m => m.ReportsModule),
        data: { userRolePermission: menuOption[5].config.permissionsList, permissionNum: menuOption[5].config.permissionNum}
      },
      {
        path: RoutePrefix.Pms,
        component: PmsComponent,
        loadChildren: () => import('./modules/pms/pms.module').then(m => m.PmsModule),
        data: { userRolePermission: menuOption[6].config.permissionsList, permissionNum: menuOption[6].config.permissionNum}
      },
      {
        path: RoutePrefix.UserManagement,
        component: UserManagementComponent,
        loadChildren: () => import('./modules/user-management/user-management.module').then(m => m.UserManagementModule),
        data: { userRolePermission: menuOption[7].config.permissionsList, permissionNum: menuOption[7].config.permissionNum}
      },
      {
        path: RoutePrefix.CustomerAccountManagement,
        component: CustomerAccountManagementComponent,
        loadChildren: () => import('./modules/customer-account-management/customer-account-management.module').then(m => m.CustomerAccountManagementModule),
        data: { userRolePermission: menuOption[8].config.permissionsList, permissionNum: menuOption[8].config.permissionNum}
      }
    ]
  },
  {
    path:'gln',
    component: RbcComponent,
    pathMatch: 'full'
   },
];



@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
