import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
// import { MatDialogRef, MatPaginator, MatSort, MatTableDataSource, MAT_DIALOG_DATA } from '@angular/material';
import { CommonControls } from 'src/app/shared/common-control/common-controls';

import { ComplianceOrderDataDetails } from '../order';

@Component({
  selector: 'app-modal-dialog-order-status',
  templateUrl: './modal-dialog-order-status.component.html',
  styleUrls: ['./modal-dialog-order-status.component.scss']
})
export class ModalDialogOrderStatusComponent implements OnInit {
  /*********************Properties*********************/
  dialogTitle: string;
  orderStatusRowData: any;

  displayedColumns: string[] = ['rxNum', 'foType', 'origNdc', 'tradeName', 'strength', 'phaQuantityDispensed', 'phaDaysSupply', 'dspnsSrtDte', 'status', 'errorMsg'];
  dataSource: MatTableDataSource<ComplianceOrderDataDetails>;
  noDataFlag: boolean = false;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    // private dialog: MatDialogRef<ModalDialogInventoryComponent>,
    private dialogRef: MatDialogRef<ModalDialogOrderStatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
  /*********************Constructor*********************/

  ngOnInit(): void {
    this.orderStatusRowData = this.data;
    this.dialogTitle = 'View Order Details';
    if (this.orderStatusRowData && this.orderStatusRowData.orderDetails.length > 0) {
      this.orderStatusRowData.orderDetails = this.changeDataMethod(this.orderStatusRowData.orderDetails);
      this.dataSource = new MatTableDataSource(this.orderStatusRowData.orderDetails);
      this.noDataFlag = false;
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
    }
    else {
      this.noDataFlag = true
    }

  }
  /*********************Lifecyle Methods*********************/
/*********************Helper Methods*********************/

public changeDataMethod(data) { // Helper method to change NDC number
  data.forEach((value) => {
    value.origNdc = value.origNdc?CommonControls.numbersWithHyphen(value.origNdc):null;
  });
  return data;
}

/*********************Helper Methods*********************/
  /*********************Component Methods*********************/

  onCloseDialog() {
    this.dialogRef.close();
  }
  /*********************Component Methods*********************/


  /*********************Private Methods*********************/

  /*********************Private Methods*********************/
}
