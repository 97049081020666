import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { InventoryService } from '../../../inventory.service';
import { ItemsArray, SaveReplItemRequest } from '../../../inventory';
import { ToastService } from 'src/app/shared/utils/toast.service';
import { CommonControls } from 'src/app/shared/common-control/common-controls';

@Component({
  selector: 'app-modal-dialog-replenishment-additem',
  templateUrl: './modal-dialog-replenishment-additem.component.html',
  styleUrls: ['./modal-dialog-replenishment-additem.component.scss']
})
export class ModalDialogReplenishmentAdditemComponent implements OnInit {

  dialogTitle: string;
  addRepItem: FormGroup;
  sourceItemNumber: string;
  ndcText: string;
  ndc:number;
  cin: number;
  description: string;
  qty: number;
  addReplenOrderItemForm: FormGroup;
  invAccId : number;
  replenishmentHdrId : number;
  searchErrorMessage: string;
  saveButtonDisable:boolean;
  searchIconDisable: boolean;
  srcItemNumErrorFlag:boolean;
  srcItemNumErrorMssg: string;
  qtyErrorFlag:boolean;
  qtyErrorMssg: string;

  searchReplItemRequest: ItemsArray = {
      Items: [
        {
          "srcItemCode":null,
          "invAccId":null}
   ]
  };
  
  searchItemResponse:  any ;

  saveReplItemRequest: SaveReplItemRequest= {
    "rplHdrId": null,
    "action": null,
    replenishmentDetailList: [
      {
        "ndc": null,
        "cin": null,
        "srcItemNum": null,
        "description": null,
        "orderQty": null
      }
    ]
  };

  
  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ModalDialogReplenishmentAdditemComponent>,
    private inventoryService: InventoryService,
    private toastr: ToastService,
    @Inject(MAT_DIALOG_DATA) public data) {

   }

  ngOnInit(): void {
    this.dialogTitle = 'Add Item';
    this.saveButtonDisable=true;
    this.searchIconDisable=true;
    this.srcItemNumErrorFlag=false;
    this.srcItemNumErrorMssg = null;
    this.qtyErrorFlag=false;
    this.qtyErrorMssg=null;
    this.addReplenOrderItemForm = this.formBuilder.group({
      "sourceItemNumber": [{value:null}, [Validators.required]],
      'ndcText':  [{value:null,disabled: true}],
      "description":[{value:null,disabled: true}],
      "qty":  [{value:null}, [Validators.required]]
    });
  }

  /**
   * Method to close add-item modal
   */
  onCloseDialog() {
    this.dialogRef.close();
  }


  /**
   * Method to search item details
   */
  searchItem() {
    this.ndcText=null;
    this.description=null;
    this.cin=null;
    this.ndc=null;
    this.searchReplItemRequest.Items[0].invAccId=this.data.invtryAccId;
    this.searchReplItemRequest.Items[0].srcItemCode = this.sourceItemNumber.toUpperCase();
    this.inventoryService.searchReplItem(this.searchReplItemRequest).subscribe((response: any) => {
      this.searchItemResponse = !response.hasError ? response.data : [];
      if(this.searchItemResponse.Items.length>0){
        this.ndcText=this.searchItemResponse.Items[0].ndcText;
        this.ndc=this.searchItemResponse.Items[0].ndc11;
        console.log("ndc after search : ",this.searchItemResponse.Items[0].ndc11);
        this.description=this.searchItemResponse.Items[0].description;
        this.cin=this.searchItemResponse.Items[0].cin;
        this.qty = 0;
        let decisionFlag= (this.sourceItemNumber!=null && this.ndcText!=null && this.description!=null && this.qty!=null) ;
        this.saveButtonDisable = (decisionFlag==true? false: true);
      }else{
        this.srcItemNumErrorFlag = true;
        this.srcItemNumErrorMssg = 'Invalid source item number';
      }
    });
  }

  /**
   * Method to save replenishment item detail
   */
  onSave(){
    this.saveReplItemRequest.action='save';
    this.saveReplItemRequest.rplHdrId=this.data.rplnshOrderHdrId;
    this.saveReplItemRequest.replenishmentDetailList[0].description=this.description;
    console.log("this.ndc : ",this.ndc);
    this.saveReplItemRequest.replenishmentDetailList[0].ndc=this.ndc;
    this.saveReplItemRequest.replenishmentDetailList[0].srcItemNum=this.sourceItemNumber;
    this.saveReplItemRequest.replenishmentDetailList[0].orderQty=this.qty;
    this.saveReplItemRequest.replenishmentDetailList[0].cin=this.cin;
    this.inventoryService.saveReplItem(this.saveReplItemRequest).subscribe((response: any) => {
      if (!response.hasError) {
        this.toastr.success('Data saved successfully');
        this.dialogRef.close('save');
      }else{
        this.toastr.error('Error ocurred while saving');
        this.dialogRef.close('save');
      }
    });
  }

  
  /**
   * To validate srcItemNumber form control
   * @param srcItemNumber 
   */
  srcItemNumberValidation() {
    let srcItemNumber = this.addReplenOrderItemForm.controls.sourceItemNumber.value;
    this.ndc=null;
    this.ndcText=null;
    this.description=null;
    this.cin=null;
    this.qty=null;
    this.saveButtonDisable=true;
    if(srcItemNumber && srcItemNumber!="."){
      if(!CommonControls.aplhaNumericOnly(srcItemNumber)){
        this.srcItemNumErrorFlag=true;
        this.srcItemNumErrorMssg = 'Source Item Number should be alphanumeric';
        this.searchIconDisable=true;
      }else{
        this.searchIconDisable=false;
        this.srcItemNumErrorFlag=false;
        this.srcItemNumErrorMssg = null;
      }
    }else{
      this.searchIconDisable=true;
      this.srcItemNumErrorFlag=true;
      this.srcItemNumErrorMssg = 'Source Item Number is mandatory';
    }
  }


  /**
   * To validate qty form control
   * @param qty 
   */
  qtyValidation() {
    let qty= this.addReplenOrderItemForm.controls.qty.value;
    if(qty && qty!="."){
      if(!CommonControls.numericOnly(qty)){
        this.qtyErrorFlag=true;
        this.qtyErrorMssg = 'Qty should be whole number';
        this.saveButtonDisable=true;
      }else{
        let decisionFlag= (this.sourceItemNumber!=null && this.ndcText!=null && this.description!=null && qty!=null) ;
        this.saveButtonDisable = (decisionFlag==true? false: true);
        this.qtyErrorFlag=false;
        this.qtyErrorMssg = null;
      }
    }else{
      this.saveButtonDisable=true;
      this.qtyErrorFlag=true;
      this.qtyErrorMssg = 'Qty is mandatory';
    }
  }

}
