<table aria-describedby="print report view">
    <thead><tr><th scope="col">
      <div class="header-space"></div>
    </th></tr></thead>
    <tbody><tr><td>
      <div class="content">
        <div [innerHTML]="printData.data"></div>
      </div>
    </td></tr></tbody>
    <tfoot><tr><td>
      <div class="footer-space"></div>
    </td></tr></tfoot>
  </table>
  
  