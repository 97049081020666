import { Injectable } from '@angular/core';

import { StaticService } from '../static/static.service';

import { MiscApi } from '../constants/api.constant';
import {
  HttpStatusCode,
  ErrorConfig,
  AppMessages,
  ErrorNotificationType,
} from '../constants/app.constant';
import { ServerError } from './model/server-error';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  /*********************Constructor*********************/
  constructor(private staticService: StaticService) {}
  /*********************Constructor*********************/

  /*********************Service Methods*********************/

  handleServerException(error: ServerError) {
    return (
      !error.handled &&
      (this.handleSystemException(error) || this.handleBusinessException(error))
    );
  }

  resolveServerError(error: ServerError) {
    if (!error.message) {
      error.message =
        error.type === 'business'
          ? this.getMessageDesc(error.messageId)
          : this.getSystemExceptionMessage(error.messageId, error.status);
    }

    error.message = this.setErrorId(error.message, error.errorId);
    return error;
  }

  getSystemExceptionMessage(messageId: string, status: number) {
    if (!messageId) {
      switch (status) {
        case HttpStatusCode.BadRequest:
          messageId = ErrorConfig.BadRequestId;
          break;

        case HttpStatusCode.NotFound:
          messageId = ErrorConfig.NotFoundId;
          break;

        case HttpStatusCode.PreconditionRequired:
          messageId = ErrorConfig.PreconditionRequiredId;
          break;

        case HttpStatusCode.Timeout:
          messageId = ErrorConfig.TimeoutId;
          break;

        default:
          messageId = ErrorConfig.SysErrId;
          break;
      }
    }

    return this.getMessageDesc(messageId);
  }

  /*********************Service Methods*********************/

  /*********************Private Methods*********************/

  private handleSystemException(error: ServerError) {
    let handle = false;
    const bypassServices = [MiscApi.Settings, MiscApi.Log];

    if (!error.businessFault && !error.logoutUser) {
      handle =
        !error.notificationType ||
        error.notificationType === ErrorNotificationType.Toast;
    }

    if (
      error.url &&
      bypassServices.find((u) => {
        return error.url.indexOf(u) > -1;
      })
    ) {
      handle = false;
    }

    return handle;
  }

  private handleBusinessException(error: ServerError) {
    return (
      error.businessFault &&
      error.notificationType === ErrorNotificationType.Toast
    );
  }

  private getMessageDesc(
    messageId: string,
    defaultMessage: string = AppMessages.GenericErrorMessage
  ): string {
    let message = null;

    const ref = this.staticService.message.get(messageId);
    message = !ref ? defaultMessage : ref.messageDesc;

    return message;
  }

  private setErrorId(message: string, errorId: any) {
    if (errorId) {
      return message + ' [Error ID: ' + errorId + ']';
    }

    return message;
  }

  /*********************Private Methods*********************/
}
