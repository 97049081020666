import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { BaseService } from 'src/app/core/services/base.service';
import { IResponse } from 'src/app/shared/models/app';

import { ClientApi, ExportApi } from 'src/app/core/constants/api.constant';
import { IReport, SearchCriteria } from './report';

@Injectable({
  providedIn: 'root'
})
export class ReportsService extends BaseService {
  /*********************Properties*********************/
  reportsList: IReport[] = [
    {
      title: 'Current Formulary',
      searchCriteria: { "cin": null, "ndc": null, "searchText": null, "searchFlag": true, "accountNumber": null },
      tableHeaders: ['ndcText', 'cin', 'description', 'tradeName', 'genericName', 'drugClass', 'form', 'gcn', 'packSize', 'baseUom', 'startDate', 'endDate', 'modifiedDate', 'modifiedBy'],
      unwantedValues: ["ndcText", "formularyFlag", "itemFormularyNum", "packQuantity", "strength", "endTime", "startTime", "deaScheduleClassName"],
      hasSearchFilter: false,
      searchFilters: []
    },
  ];

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(protected http: HttpClient) {
    super(http);
    // this.apiConfig.order = 'http://10.32.32.115:7285/api/v1/'; //uncomment for local testing only
    //this.apiConfig.mdm = 'http://localhost:7284/api/v1/';
  }
  /*********************Constructor*********************/

  /*********************Service Methods*********************/
   // Formulary search service
  searchFormularyData(searchCriteria) {
    const url = this.serviceUrl(this.apiConfig.mdm, ClientApi.SearchFormularyData);
    return super.post(url, searchCriteria).pipe(
      map((response: any) => {
        // if (!response.hasError) {
        return response;
        // }
      })
    )
  }
  

  // Export data to xls service
  export(reportName: string, reportRequest: SearchCriteria) {
    let url = '';
    
        url = this.serviceUrl(
          this.apiConfig.mdm,
          ExportApi.ExportCurrentFormularyData
        );
        
    
    return super.post(url, reportRequest, { responseType: "blob" }).pipe(
      map((response: IResponse) => {
        // if (!response.hasError) {
        return response;
        // }
      })
    );
  }

  /*********************Service Methods*********************/
}
