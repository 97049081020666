<div mat-dialog-title>
  <h4 class="dialog-title">{{ confirm?.title }}</h4>
</div>
<mat-dialog-content
   [innerHTML]="confirm?.message"
></mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="!confirm?.okFlag">
  <button
  mat-button mat-stroked-button color="primary" 
    type="button"
    class="{{ confirm?.action.no.theme }}"
    (click)="onNoClick()"
  >
    {{ confirm?.action.no.text }}
  </button>
  <button
  mat-button mat-flat-button
    color="primary" 
    type="button"
    class="button primary {{ confirm?.action.yes.theme }}"
    (click)="onYesClick()"
  >
    {{ confirm?.action.yes.text }}
  </button>
  <button
  mat-button mat-stroked-button color="primary" 
    type="button"
    class="{{ confirm?.action.cancel.theme }}"
    (click)="onCancelClick()"
    *ngIf="confirm?.actions === actions.YesNoCancel"
  >
    {{ confirm?.action.cancel.text }}
  </button> 

</mat-dialog-actions>
<mat-dialog-actions *ngIf="confirm?.okFlag" align="end">
<button
  mat-button mat-stroked-button color="primary" 
    type="button"
    class="{{ confirm?.action.ok.theme }}"
    (click)="onNoClick()"     
  >
    {{ confirm?.action.ok.text }}
  </button>
</mat-dialog-actions>
