<h2 mat-dialog-title>Order Review &amp; Edit
    <mat-icon class="dialog-icon" (click)="onCloseDialog()">close</mat-icon>
</h2>
<mat-dialog-content class="pms-dialog">
    <div class="col-12 block" style="padding: 0;">
        <div class="wrapper">
            <ul class="non-editable">
                <li class="col3">
                    <div class="detail-field">
                        <label>Order Number:</label>
                        <span>{{pmsOrderDetails.orderNo}}</span>
                    </div>
                    <div class="detail-field">
                        <label>Order Status:</label>
                        <span>{{pmsOrderDetails.orderStatus}}</span>
                    </div>
                    <div class="detail-field">
                        <label>Use Safety Cap:</label>
                        <span>{{pmsOrderDetails.patient.useSafetyCap == true ? 'YES' : (pmsOrderDetails.patient.useSafetyCap == false ? 'NO' : '')}}</span>
                    </div>
                </li>
                <li class="col3">
                    <div class="detail-field">
                        <label>Imported Date:</label>
                        <span>{{pmsOrderDetails.importedDate | date:"yyyy-MM-dd"}}</span>
                    </div>
                    <div class="detail-field">
                        <label>Imported By:</label>
                        <span>{{pmsOrderDetails.importedBy}}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <form name="PMSForm" [formGroup]="pmsForm" novalidate>
        <!-- Patient Information -->
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="expand_header">Patient Information</div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-12 block">
                    <div class="wrapper">
                        <h4>Basic Information</h4>
                        <ul class="non-editable">
                            <li class="col3">
                                <div class="detail-field">
                                    <label>First Name:</label>
                                    <span>{{pmsOrderDetails.patient.firstName}}</span>
                                </div>
                                <div class="detail-field">
                                    <label>Middle Name:</label>
                                    <span>{{pmsOrderDetails.patient.middleName}}</span>
                                </div>
                                <div class="detail-field">
                                    <label>Last Name:</label>
                                    <span>{{pmsOrderDetails.patient.lastName}}</span>
                                </div>
                                <!-- <mat-form-field appearance="fill">
                                    <mat-label>First Name <span class="red">*</span></mat-label>
                                    <input matInput formControlName="patient_firstname">
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>Middle Name</mat-label>
                                    <input matInput formControlName="patient_middlename">
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>Last Name <span class="red">*</span></mat-label>
                                    <input matInput formControlName="patient_lastname">
                                </mat-form-field> -->
                            </li>
                            <li class="col3">
                                <div class="detail-field">
                                    <label>Patient ID:</label>
                                    <span>{{pmsOrderDetails.patient.patientGivenId}}</span>
                                </div>
                                <div class="detail-field">
                                    <label>Patient DOB:</label>
                                    <span>{{pmsOrderDetails.patient.dateOfBirth | date:'yyyy-MM-dd'}}</span>
                                </div>
                                <div class="detail-field">
                                    <label>Phone No.:</label>
                                    <span>{{pmsOrderDetails.patient.phone}}</span>
                                </div>
                                <!-- <mat-form-field appearance="fill">
                                    <mat-label>Patient ID <span class="red">*</span></mat-label>
                                    <input matInput formControlName="patient_id">
                                </mat-form-field>

                                <mat-form-field appearance="fill" class="calendar-control">
                                    <mat-label>Patient DOB</mat-label>
                                    <input matInput [owlDateTime]="dob" formControlName="dob">
                                    <owl-date-time [pickerType]="'calendar'" #dob></owl-date-time>
                                    <span [owlDateTimeTrigger]="dob" class="dateTimeIcon material-icons">
                                        calendar_today
                                    </span>
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>Phone No.</mat-label>
                                    <input matInput formControlName="patient_phone">
                                </mat-form-field> -->
                            </li>
                        </ul>

                        <h4>Address Information</h4>
                        <ul>
                            <li class="col2">
                                <mat-form-field appearance="fill">
                                    <mat-label>Address Line 1</mat-label>
                                    <input matInput formControlName="patient_address">
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>Address Line 2</mat-label>
                                    <input matInput formControlName="patient_address2">
                                </mat-form-field>
                            </li>
                            <li class="col3">
                                <mat-form-field appearance="fill">
                                    <mat-label>City</mat-label>
                                    <input matInput formControlName="patient_city">
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>State</mat-label>
                                    <input matInput formControlName="patient_state">
                                </mat-form-field>

                                <mat-form-field appearance="fill">
                                    <mat-label>ZIP</mat-label>
                                    <input matInput formControlName="patient_zip">
                                </mat-form-field>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <!-- Patient Information -->

        <!-- Prescription -->
        <mat-expansion-panel *ngFor="let prescription of pmsOrderDetails.prescription; let i = index; first as isFirst"
            [expanded]="isFirst">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="expand_header">
                        <span>Prescription {{i+1}}</span>
                        <span><label>Rx Number:</label>
                            {{prescription.rxNumber}}</span>
                        <span>
                            <label>Medication:</label>
                            {{prescription.medication}}
                        </span>
                    </div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- <div class="row">
               
                <div class="col-12 block"> -->
            <!-- Line Details -->
            <!-- <mat-accordion *ngFor="let prescription of pmsOrderDetails.prescription; let i = index" [expanded]="true">
                        <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false"> -->
            <!-- <mat-expansion-panel-header>
                                <mat-panel-title>
                                    Prescription {{i+1}}
                                </mat-panel-title>
                            </mat-expansion-panel-header> -->
            <div class="line-details">
                <div class="row">
                    <div class="col-12 block">
                        <div class="wrapper">
                            <h4>Prescriber Details</h4>
                            <ul class="non-editable">
                                <li class="col5">
                                    <div class="detail-field">
                                        <label>Prefix:</label>
                                        <span>{{prescription.prescriber.prefix}}</span>
                                    </div>
                                    <div class="detail-field">
                                        <label>Suffix:</label>
                                        <span>{{prescription.prescriber.suffix}}</span>
                                    </div>
                                    <div class="detail-field">
                                        <label>First Name:</label>
                                        <span>{{prescription.prescriber.firstName}}</span>
                                    </div>
                                    <div class="detail-field">
                                        <label>Middle Name:</label>
                                        <span>{{prescription.prescriber.middleName}}</span>
                                    </div>
                                    <div class="detail-field">
                                        <label>Last Name:</label>
                                        <span>{{prescription.prescriber.lastName}}</span>
                                    </div>
                                </li>
                                <li class="col2">
                                    <div class="detail-field">
                                        <label>Address:</label>
                                        <span>{{prescription.prescriber.address.includes("null") ? prescription.prescriber.address.replace('null','') : prescription.prescriber.address}}</span>
                                    </div>
                                    <div class="detail-field">
                                        <label>Phone No.:</label>
                                        <span>{{prescription.prescriber.phoneNumber}}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-12 block">
                        <div class="wrapper">
                            <ul class="non-editable">
                                <li class="col3">
                                    <!-- <div class="detail-field">
                                        <label>RX Number:</label>
                                        <span>{{prescription.rxNumber}}</span>
                                    </div> -->
                                    <div class="detail-field">
                                        <label>NDC:</label>
                                        <span>{{prescription.ndc}}</span>
                                    </div>
                                    <!-- <div class="detail-field">
                                        <label>Medication:</label>
                                        <span>{{prescription.medication}}</span>
                                    </div> -->
                                    <div class="detail-field">
                                        <label>Sig:</label>
                                        <span>{{prescription.sig}}</span>
                                    </div>
                                    <div class="detail-field">
                                        <label>Total Quantity:</label>
                                        <span>{{prescription.totalQuantity}}</span>
                                    </div>
                                </li>
                                <li class="col3">
                                    <div class="detail-field">
                                        <label>Days of Supply:</label>
                                        <span>{{prescription.daysSupply}}</span>
                                    </div>
                                    <div class="detail-field">
                                        <label>Refills Remaining:</label>
                                        <span>{{prescription.refillsRemaining}}</span>
                                    </div>
                                    <div class="detail-field">
                                        <label>Fill Number:</label>
                                        <span>{{prescription.fllNumber}}</span>
                                    </div>
                                </li>
                                <li class="col3">
                                    <div class="detail-field">
                                        <label>RX Written Date:</label>
                                        <span>{{prescription.rxWrittenDate | date:'yyyy-MM-dd'}}</span>
                                    </div>
                                    <div class="detail-field">
                                        <label>RX Expiration Date:</label>
                                        <span>{{prescription.rxExpirationDate | date:'yyyy-MM-dd'}}</span>
                                    </div>
                                    <div class="detail-field">
                                        <!-- <label>Administration Start Date:</label>
                                        <span>{{prescription.rxAdministrationStartDate | date:'yyyy-MM-dd'}}</span> -->
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <!-- Editable Details -->
                    <div class="col-12 block">
                        <div class="wrapper">
                            <ul>
                                <li class="col3">
                                    <div class="col-row">
                                    <mat-form-field appearance="fill" class="calendar-control" [ngClass]="{'has-error': pmsForm.controls['admin_start_date'+i].status ==='INVALID'}">
                                        <mat-label>Administration Start Date</mat-label>
                                        <input matInput [min]="pmsForm.controls['date_needed_by'+i].value"  [owlDateTime]="adminStartDate" placeholder="MM/DD/YYYY"
                                            formControlName="admin_start_date{{i}}">
                                        <owl-date-time #adminStartDate [pickerType]="'calendar'"></owl-date-time>
                                        <span [owlDateTimeTrigger]="adminStartDate" class="dateTimeIcon material-icons">
                                            calendar_today
                                        </span>                                        
                                    </mat-form-field>
                                    <mat-error *ngIf="pmsForm.controls['admin_start_date'+i].status ==='INVALID'">
                                        Administration Start Date should be > or = to Date Needed By.
                                    </mat-error>
                                </div>
                                <div class="col-row">
                                    <mat-form-field appearance="fill" class="calendar-control" [ngClass]="{'has-error': pmsForm.controls['date_needed_by'+i].status ==='INVALID'}">
                                        <mat-label>Date Needed By</mat-label>
                                        <input matInput [min]="tdateNeedBy" [owlDateTime]="dateNeededBy" placeholder="MM/DD/YYYY"
                                            formControlName="date_needed_by{{i}}">
                                        <owl-date-time #dateNeededBy [pickerType]="'calendar'"></owl-date-time>
                                        <span [owlDateTimeTrigger]="dateNeededBy" class="dateTimeIcon material-icons">
                                            calendar_today
                                        </span>
                                    </mat-form-field>
                                    <mat-error *ngIf="pmsForm.controls['date_needed_by'+i].status ==='INVALID'">
                                        Date Needed By should be > or = to {{this.sla}} days from current date.
                                    </mat-error>
                                </div>
                                <div class="col-row">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Compliance package</mat-label>
                                        <mat-select formControlName="compliance_pkg_type{{i}}" (selectionChange)="onCompliancePkgTypeChange($event.value, i)">
                                            <mat-option *ngFor="let option of compliancePkgOptions"
                                                [value]="option.value">{{ option.name }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                </li>
                            </ul>
                            <!-- Dosage-->
                            <div class="dosage-details table-bg">
                                <table border="1" aria-describedby="line details view">
                                    <tr>
                                        <th scope="col">Dose</th>
                                        <th scope="col">Administration Time</th>
                                        <th scope="col">Quantity</th>
                                        <th scope="col">Unit of Measure</th>
                                        <th scope="col">Administration Days</th>
                                        <th scope="col">
                                            <a class="material-icons add-btn" [ngClass]="{'disable-btn': !prescription.enableForNonCompliancePkg}" (click)="onAddDose(i)"
                                                matTooltip="Add new dose" matTooltipClass="tool-tip-class"
                                                aria-label="Button that shows a tooltip">
                                                add_circle_outline
                                            </a>
                                        </th>
                                    </tr>
                                    <tr *ngFor="let dose of prescription.foDosageDetails; let index = index">
                                        <td>{{index+1}}</td>
                                        <td class="time-control">
                                            <mat-form-field appearance="fill">
                                                <input matInput [owlDateTimeTrigger]="dt2" [owlDateTime]="dt2"
                                                    formControlName="admin_time{{i}}{{index}}">
                                                <owl-date-time [pickerType]="'timer'" #dt2>
                                                </owl-date-time>
                                                <span [owlDateTimeTrigger]="dt2" class="dateTimeIcon material-icons">
                                                    schedule
                                                </span>
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            <mat-form-field appearance="fill">
                                                <input matInput formControlName="quantity{{i}}{{index}}">
                                            </mat-form-field>
                                        </td>
                                        <td>
                                            <mat-form-field appearance="fill">
                                                <mat-select formControlName="uom{{i}}{{index}}">
                                                    <mat-option *ngFor="let option of doseOptions"
                                                        [value]="option.name">{{ option.value }}</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </td>
                                        <td [ngClass]="{'has-error': dose.hasError}">
                                            <ul>
                                                <li>
                                                    <mat-checkbox matInput formControlName="allDays{{index}}{{i}}"
                                                        [(ngModal)]="dose.selectAllDays"
                                                        (change)="onUpdateAllDays(dose,i, index, $event.checked)">
                                                        DAILY
                                                    </mat-checkbox>
                                                </li>
                                                <li *ngFor="let day of dose.dayOfWeek; let dayIndex = index">
                                                    <!-- <li> -->
                                                    <mat-checkbox matInput
                                                        formControlName="days{{day.name}}{{index}}{{i}}"
                                                        (change)="onUpdateDay(dose, day, i, index, $event.checked)">
                                                        {{day.name}}
                                                    </mat-checkbox>
                                                    <!-- <mat-checkbox matInput
                                                        formControlName="daysMON{{index}}{{i}}"
                                                        (change)="onUpdateDay($event.checked, dose.dayOfWeek, index, 0)">
                                                        Mon
                                                    </mat-checkbox>
                                                    <mat-checkbox matInput
                                                        formControlName="daysTUE{{index}}{{i}}"
                                                        (change)="onUpdateDay($event.checked, dose.dayOfWeek, index, 1)">
                                                        Tue
                                                    </mat-checkbox>
                                                    <mat-checkbox matInput
                                                        formControlName="daysWED{{index}}{{i}}"
                                                        (change)="onUpdateDay($event.checked, dose.dayOfWeek, index, 2)">
                                                        Wed
                                                    </mat-checkbox>
                                                    <mat-checkbox matInput
                                                        formControlName="daysTHU{{index}}{{i}}"
                                                        (change)="onUpdateDay($event.checked, dose.dayOfWeek, index, 3)">
                                                        Thu
                                                    </mat-checkbox>
                                                    <mat-checkbox matInput
                                                        formControlName="daysFRI{{index}}{{i}}"
                                                        (change)="onUpdateDay($event.checked, dose.dayOfWeek, index, 4)">
                                                        Fri
                                                    </mat-checkbox>
                                                    <mat-checkbox matInput
                                                        formControlName="daysSAT{{index}}{{i}}"
                                                        (change)="onUpdateDay($event.checked, dose.dayOfWeek, index, 5)">
                                                        Sat
                                                    </mat-checkbox>
                                                    <mat-checkbox matInput
                                                        formControlName="daysSUN{{index}}{{i}}"
                                                        (change)="onUpdateDay($event.checked, dose.dayOfWeek, index, 6)">
                                                        SUN
                                                    </mat-checkbox> -->
                                                </li>
                                            </ul>
                                        </td>
                                        <td>
                                            <a class="material-icons remove-btn" (click)="onRemoveDose(i, index)" [ngClass]="{'disable-btn': !prescription.enableForNonCompliancePkg}"
                                                matTooltip="Remove dose" matTooltipClass="tool-tip-class"
                                                aria-label="Button that shows a tooltip"
                                                *ngIf="prescription.foDosageDetails.length > 1">
                                                highlight_off
                                            </a>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                            <!-- Dosage-->

                        </div>
                    </div>
                    <!-- Editable Details -->
                </div>
            </div>
            <!-- </mat-expansion-panel>
                    </mat-accordion> -->
            <!-- Line Details -->
            <!-- </div>
            </div> -->
        </mat-expansion-panel>
        <!-- Prescription -->
        <!-- Pharmacy  Information -->
        <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <div class="expand_header">Pharmacy Information</div>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="row">
                <div class="col-12 block">
                    <div class="wrapper">
                        <h4>Pharmacy Details</h4>
                        <ul class="non-editable">
                            <li class="col2">
                                <div class="detail-field">
                                    <label>NCPDP Number:</label>
                                    <span>{{pmsOrderDetails.pharmacy.ncpdp}}</span>
                                </div>
                                <!-- <mat-form-field appearance="fill">
                                    <mat-label>NCPDP Number</mat-label>
                                    <input matInput formControlName="ncpdp">
                                </mat-form-field> -->
                            </li>
                        </ul>
                        <h4>Pharmacist Details</h4>
                        <ul>
                            <li class="col4">
                                <div class="detail-field">
                                    <label>Initials:</label>
                                    <span>{{pmsOrderDetails.pharmacy.initials}}</span>
                                </div>
                                <div class="detail-field">
                                    <label>First Name:</label>
                                    <span>{{pmsOrderDetails.pharmacy.pharmacistFirstName}}</span>
                                </div>
                                <div class="detail-field">
                                    <label>Middle Name:</label>
                                    <span>{{pmsOrderDetails.pharmacy.middleName}}</span>
                                </div>
                                <div class="detail-field">
                                    <label>Last Name:</label>
                                    <span>{{pmsOrderDetails.pharmacy.pharmacistLastName}}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </mat-expansion-panel>
        <!-- Pharmacy  Information -->
    </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" (click)="onSubmit()" [hidden]="permissionsData.permissionAssignment.submitBtn">Submit</button>
    <button mat-raised-button color="primary" (click)="onSubmitReview()" [hidden]="permissionsData.permissionAssignment.submitReviewBtn">Submit for Review</button>
    <button mat-raised-button color="accent" (click)="onSave()">Save</button>
    <button mat-raised-button color="warn" (click)="onCancelOrder()"
        [hidden]="permissionsData.permissionAssignment.cancelBtn">Cancel Order</button>
    <button mat-button mat-stroked-button color="primary" (click)="onCloseDialog()">Back</button>
</mat-dialog-actions>