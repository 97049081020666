import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { CacheResolverService } from '../cache/cache-resolver.service';
import { BaseService } from '../services/base.service';

import { CacheConstant } from '../cache/cache.constant';
import { ConfigApi } from '../constants/api.constant';
import { IResponse } from 'src/app/shared/models/app';
import { AppPreferenceConstant } from './static.constant';
import { Environment } from 'src/app/shared/models/environment';
import { CacheDuration } from '../constants/app.constant';

import { TypeRef } from './type-ref';
import { TypeGroup } from './type-group';
import { AppPreference } from './app-preference';
import { Message } from './message';
import { State } from './state';
import { TypeRefs } from './static';
import { Subject } from 'rxjs';

enum StaticPrimaryKeys {
  AppPreference = 'preferenceId',
  MessageRef = 'messageId',
  TypeGroup = 'typeGroupCode',
  TypeRef = 'typeCode',
  State = 'stateCode',
}

@Injectable({
  providedIn: 'root',
})
export class StaticService extends BaseService {
  /*********************Properties*********************/
  readonly appPreference: AppPreference;
  readonly message: Message;
  readonly state: State;
  readonly typeRef: TypeRef;
  readonly typeGroup: TypeGroup;

  readonly loaded$: Subject<boolean> = new Subject<boolean>();
  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(protected override http: HttpClient, private cache: CacheResolverService) {
    super(http);

    this.appPreference = new AppPreference(
      CacheConstant.StaticData.AppPreferences,
      StaticPrimaryKeys.AppPreference,
      this.cache
    );
    this.message = new Message(
      CacheConstant.StaticData.Messages,
      StaticPrimaryKeys.MessageRef,
      this.cache
    );
    this.state = new State(
      CacheConstant.StaticData.State,
      StaticPrimaryKeys.State,
      this.cache
    );
    this.typeRef = new TypeRef(
      CacheConstant.StaticData.TypeRefs,
      StaticPrimaryKeys.TypeRef,
      this.cache
    );
    this.typeGroup = new TypeGroup(
      CacheConstant.StaticData.TypeGroups,
      StaticPrimaryKeys.TypeGroup,
      this.cache
    );
  }
  /*********************Constructor*********************/

  /*********************Service Methods*********************/

  load() {
    const url = super.serviceUrl(
      this.apiConfig.config,
      ConfigApi.GetStaticData
    );

    return super.fetch(url).pipe(
      map((response: any) => {
        if (!response.hasError) {
          this.onStaticDataResponse(response);
        }

        return response;
      })
    );
  }

  saveTypeRef(typeRef: TypeRefs) {
    const url = super.serviceUrl(
      this.apiConfig.config,
      ConfigApi.SaveTypeRef
    );

    return super.post(url, typeRef);
  }

  deleteTypeRef(typeCode: number) {
    const url = super.serviceUrl(
      this.apiConfig.config,
      ConfigApi.DeleteTypeRef,
      typeCode
    );

    return super.delete(url);
  }

  /*********************Service Methods*********************/

  /*********************Private Methods*********************/

  private onStaticDataResponse(response: IResponse) {
    this.cache.set(
      CacheConstant.StaticData.Name,
      response.data,
      CacheDuration.OneDay
    );
    const logPreference = this.appPreference.get(
      AppPreferenceConstant.SEND_UI_CONSOLE_LOGS
    );

    Environment.getInstance().appConfig.logger.serverLog =
      logPreference.preferenceValue &&
      logPreference.preferenceValue.toString().toBoolean()
        ? true
        : false;

    this.loaded$.next(true);
  }

  /*********************Private Methods*********************/
}
