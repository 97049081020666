<h2 *ngIf="formularyRowData.typeOfData == 'FormularyInfo'" mat-dialog-title>{{title}}
  <mat-icon class="dialog-icon" (click)="onCloseDialog()">close</mat-icon>
</h2>
<mat-dialog-content *ngIf="formularyRowData.typeOfData == 'FormularyInfo'">
  <form name="addEditFormularyForm" [formGroup]="addEditFormularyForm" novalidate>
    <ul>
      <li>
        <strong>NDC:</strong> {{formularyRowData.ndcText}}
      </li>
      <li>
        <strong>CIN:</strong> {{formularyRowData.cin}}
      </li>
      <li>
        <strong>Description:</strong> {{formularyRowData.description}}
      </li>

      <!-- Med Type dropdown  -->
      <form name="medTypeForm" [formGroup]="medTypeForm" novalidate>
        <mat-form-field>
          <mat-label> Med Type</mat-label>
          <mat-select formControlName="medTypeString" required>
            <mat-option *ngFor="let medType of medTypeData" [value]="medType.medType">
              {{medType.medType}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </form>
    <!-- Med Type dropdown -->

      <li *ngIf="!this.formularyRowData.delete">
        <div class="field-wrap">
         
          <mat-form-field appearance="fill" *ngIf="showSDateTime">
            <mat-label>Effective Start Date</mat-label>
            <input matInput [owlDateTime]="esd" formControlName="EffectiveStartDate" required>
            <owl-date-time #esd></owl-date-time>
          <span [owlDateTimeTrigger]="esd" class="dateTimeIcon material-icons">
            calendar_today
            </span>
          </mat-form-field>

          <mat-form-field appearance="fill" *ngIf="!showSDateTime">
            <mat-label>Effective Start Date</mat-label>
            <input matInput [owlDateTime]="esd" formControlName="EffectiveStartDate" required>
            <owl-date-time #esd [pickerType]="'calendar'"></owl-date-time>
          <span [owlDateTimeTrigger]="esd" class="dateTimeIcon material-icons">
            calendar_today
            </span>
          </mat-form-field>

        </div>
        <div class="field-wrap">

          <mat-form-field appearance="fill" *ngIf="showEDateTime">
            <mat-label>Effective End Date</mat-label>
            <input matInput [owlDateTime]="eed" formControlName="EffectiveEndDate" required>
            <owl-date-time #eed></owl-date-time>
            <span [owlDateTimeTrigger]="eed" class="dateTimeIcon material-icons">
              calendar_today
              </span>
          </mat-form-field>

          <mat-form-field appearance="fill" *ngIf="!showEDateTime">
            <mat-label>Effective End Date</mat-label>
            <input matInput [owlDateTime]="eed" formControlName="EffectiveEndDate" required>
            <owl-date-time #eed [pickerType]="'calendar'"></owl-date-time>
            <span [owlDateTimeTrigger]="eed" class="dateTimeIcon material-icons">
              calendar_today
              </span>
          </mat-form-field>

          <mat-error *ngIf="addEditFormularyForm.get('EffectiveEndDate').hasError('PastDateErr')">
            Invalid Date selection - Please select a valid Date/Time
          </mat-error>
        </div>
      </li>
      <li *ngIf="this.formularyRowData.delete">

        <div class="field-wrap">        
          <mat-form-field appearance="fill">
            <mat-label>Effective Start Date</mat-label>
            <input matInput [owlDateTime]="esd" formControlName="EffectiveStartDate" [readonly]="disableInput" required>
            <owl-date-time #esd [pickerType]="'calendar'" [disabled]="disableInput"></owl-date-time>
          <span [owlDateTimeTrigger]="esd" class="dateTimeIcon material-icons" [disabled]="disableInput">
            calendar_today
            </span>
          </mat-form-field>
        </div>

        <div class="field-wrap">
          <mat-form-field appearance="fill" *ngIf="showEDateTime">
            <mat-label>Effective End Date</mat-label>
            <input matInput [owlDateTime]="eed" formControlName="EffectiveEndDate" required>
            <owl-date-time #eed></owl-date-time>
            <span [owlDateTimeTrigger]="eed" class="dateTimeIcon material-icons">
              calendar_today
              </span>
          </mat-form-field>

          <mat-form-field appearance="fill" *ngIf="!showEDateTime">
            <mat-label>Effective End Date</mat-label>
            <input matInput [owlDateTime]="eed" formControlName="EffectiveEndDate" required>
            <owl-date-time #eed [pickerType]="'calendar'"></owl-date-time>
            <span [owlDateTimeTrigger]="eed" class="dateTimeIcon material-icons">
              calendar_today
              </span>
          </mat-form-field>

          <mat-error *ngIf="addEditFormularyForm.get('EffectiveEndDate').hasError('PastDateErr')">
            Invalid Date selection - Please select a valid Date/Time
          </mat-error>
        </div>
      </li>
    </ul>

  </form>
  <div class="info-message" *ngIf="formularyRowData.delete">
    <i class="fa fa-info-circle info-icon" aria-hidden="true"></i>
    <span>
      The changes will be effective after 11:59 PM (CST)
    </span>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="formularyRowData.typeOfData == 'FormularyInfo'">
  <button mat-button mat-stroked-button color="primary" (click)="onCloseDialog()">Cancel</button>
  <button mat-button *ngIf="!formularyRowData.delete" cdkFocusInitial mat-flat-button color="primary"
    class="button primary" (click)="onSaveClick('save')"
    [disabled]="addEditFormularyForm.controls.EffectiveStartDate.status == 'INVALID' || addEditFormularyForm.controls.EffectiveEndDate.status == 'INVALID'">Save</button>
  <button mat-button *ngIf="formularyRowData.delete" cdkFocusInitial mat-flat-button color="primary"
    class="button primary" (click)="onSaveClick('remove')"
    [disabled]="addEditFormularyForm.controls.EffectiveStartDate.status == 'INVALID' || addEditFormularyForm.controls.EffectiveEndDate.status == 'INVALID'">Remove</button>
</mat-dialog-actions>

<h2 [hidden]="!(formularyRowData.typeOfData == 'AccountInfo')" mat-dialog-title>View Inventory
  <mat-icon class="dialog-icon" (click)="onCloseDialog()">close</mat-icon>
</h2>
<mat-dialog-content [hidden]="!(formularyRowData.typeOfData == 'AccountInfo')" class="inventory-dialog">
  <ul>
    <li>
      <strong>NDC:</strong>{{formularyRowData.ndcText}}
    </li>
    <li>
      <strong>CIN:</strong>{{formularyRowData.cin}}
    </li>
    <li style="display: block;">
      <strong>Total Virtual Inventory:</strong>{{noDataFlag?'0':totalOnHand}}
    </li>
  </ul>


<!-- Inventory Facility Data -->
<div class="mat-elevation-z8 dialog-table">
  <table mat-table [dataSource]="dataSource" aria-describedby="formulary row data view" matSort>

    <ng-container matColumnDef="sapAccountNumber">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Account Number</th>
      <td mat-cell *matCellDef="let formularyRow">
        {{formularyRow.sapAccountNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="accountName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Account Name</th>
      <td mat-cell *matCellDef="let formularyRow">
        {{formularyRow.accountName}} </td>
    </ng-container>


    <ng-container matColumnDef="inventoryOnHand">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Virtual Inventory</th>
      <td mat-cell *matCellDef="let formularyRow">
        {{formularyRow.inventoryOnHand}} </td>
    </ng-container>

    <ng-container matColumnDef="inventoryOnOrder">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Quantity pending replenishment" matTooltipClass="table-tooltip" scope="col">On Order</th>
      <td mat-cell *matCellDef="let formularyRow">
        {{formularyRow.inventoryOnOrder}} </td>
    </ng-container>

    <ng-container matColumnDef="quantityReserved">
      <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="Quantity allocated for fulfillment" matTooltipClass="table-tooltip" scope="col">Allocated</th>
      <td mat-cell *matCellDef="let formularyRow">
        {{formularyRow.quantityReserved}}
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let formularyRow; columns: displayedColumns;" [ngClass]="{'houseAcc-row': formularyRow.houseAccFlag === true}"></tr>

  </table>
  <!-- Row shown when there is no matching data. -->
  <div *ngIf="noDataFlag" class="no-records">
    No records found
  </div>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 200]"></mat-paginator>
</div>
<!-- Inventory Facility Data -->
</mat-dialog-content>
<mat-dialog-actions align="end" *ngIf="formularyRowData.typeOfData == 'AccountInfo'">
  <button mat-button mat-stroked-button color="primary" (click)="onCloseDialog()">Close</button>
  </mat-dialog-actions>