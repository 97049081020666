<mat-card>
  <mat-card-header>
    <mat-card-title>Reports</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <form name="reportFilterSearchForm" [formGroup]="reportFilterSearchForm" novalidate>
      <div class="row">
        <div class="col-lg-7 col-md-7" id="report-selection">
          <div class="col-50">
            <label class="label-text">Report Name:</label>
            <mat-form-field class="report-select">
              <mat-label>Select...</mat-label>
              <mat-select formControlName="reportType" required>
                <mat-option *ngFor="let nameData of reportNameDetails" [value]="nameData.staticDataValue">
                  {{nameData.staticDataValue}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="col-50" *ngIf="(reportFilterSearchForm.get('reportType').value) == 'Formulary by Customer'">
            <mat-form-field class="account-select">
              <mat-label>Select Account</mat-label>
              <mat-select formControlName="accountSelect" required>
                <mat-option *ngFor="let account of assignedAccountList" [value]="account">
                  {{account.accNumAndName}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <button mat-flat-button color="primary" class="button primary" (click)="onReportClick()" [disabled]= "disableSearchFlag">
            Run Report
          </button>

        </div>
        <div class="col-lg-5 col-md-5 text-right" id="output-buttons">
          <button mat-stroked-button color="primary" class="print-btn" title="Print" aria-label="Print Report"
            printSectionId="print-section" (click)="onPrintReport()" [disabled]= "!enableFlag" ngxPrint>
            <mat-icon>print</mat-icon>
          </button>
          <button mat-stroked-button color="primary" title="Download Excel" aria-label="Export to Excel"
            class="export-btn" (click)="onExportReport((reportFilterSearchForm.get('reportType').value) == 'Formulary by Customer' ? 'Formulary by Customer' : 'Current Formulary')" [disabled]= "!enableFlag">
            <span style="font-size: 20px;" class="fas fa-download">
            </span>
          </button>
          <button mat-stroked-button color="primary" class="clear-filter" title="Clear Search"
            aria-label="Clear Report Data" (click)="onClearSearchResults()" [disabled]= "!reportFilterSearchForm.get('reportType').value">
            <mat-icon>refresh</mat-icon>
          </button>
        </div>

      </div>
      <div id="print-section" #printSection>
        <!-- Current Formulary -->
        <div class="report-table scroll">
          <table mat-table class="mat-elevation-z8" [dataSource]="dataSource"
            aria-describedby="Current Formulary Report table" matSort>

            <ng-container matColumnDef="ndcText">
              <th mat-header-cell *matHeaderCellDef scope="col">NDC</th>
              <td mat-cell *matCellDef="let formularyRow">
                {{formularyRow.ndcText}} </td>
            </ng-container>

            <ng-container matColumnDef="cin">
              <th mat-header-cell *matHeaderCellDef scope="col">CIN</th>
              <td mat-cell *matCellDef="let formularyRow">
                {{formularyRow.cin}} </td>
            </ng-container>

            <ng-container matColumnDef="description">
              <th mat-header-cell *matHeaderCellDef scope="col">Description</th>
              <td mat-cell *matCellDef="let formularyRow">
                {{formularyRow.description}} </td>
            </ng-container>

            <ng-container matColumnDef="tradeName">
              <th mat-header-cell *matHeaderCellDef scope="col">Trade Name</th>
              <td mat-cell *matCellDef="let formularyRow">
                <span>{{formularyRow.tradeName}} </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="genericName">
              <th mat-header-cell *matHeaderCellDef scope="col">Generic Name</th>
              <td mat-cell *matCellDef="let formularyRow">
                <span>{{formularyRow.genericName}}</span>
              </td>
            </ng-container>

            <ng-container matColumnDef="drugClass">
              <th mat-header-cell *matHeaderCellDef scope="col">Drug Class</th>
              <td mat-cell *matCellDef="let formularyRow">
                {{formularyRow.drugClass}} </td>
            </ng-container>

            <ng-container matColumnDef="form">
              <th mat-header-cell *matHeaderCellDef scope="col">Form</th>
              <td mat-cell *matCellDef="let formularyRow">
                {{formularyRow.form}} </td>
            </ng-container>

            <ng-container matColumnDef="gcn">
              <th mat-header-cell *matHeaderCellDef scope="col">GCN</th>
              <td mat-cell *matCellDef="let formularyRow">
                {{formularyRow.gcn}} </td>
            </ng-container>

            <ng-container matColumnDef="packSize">
              <th mat-header-cell *matHeaderCellDef scope="col">Pack Size</th>
              <td mat-cell *matCellDef="let formularyRow">
                {{formularyRow.packSize}} </td>
            </ng-container>

            <ng-container matColumnDef="baseUom">
              <th mat-header-cell *matHeaderCellDef scope="col">Base UOM</th>
              <td mat-cell *matCellDef="let formularyRow">
                {{formularyRow.baseUom}} </td>
            </ng-container>

            <ng-container matColumnDef="startDate">
              <th mat-header-cell *matHeaderCellDef scope="col">Effective Start Date</th>
              <td mat-cell *matCellDef="let formularyRow">
                {{formularyRow.startDate | date:'yyyy-MM-dd'}} </td>
            </ng-container>

            <ng-container matColumnDef="endDate">
              <th mat-header-cell *matHeaderCellDef scope="col">Effective End Date</th>
              <td mat-cell *matCellDef="let formularyRow">
                {{formularyRow.endDate | date:'yyyy-MM-dd'}} </td>
            </ng-container>

            <ng-container matColumnDef="modifiedDate">
              <th mat-header-cell *matHeaderCellDef scope="col">Modified Date</th>
              <td mat-cell *matCellDef="let formularyRow">
                {{formularyRow.modifiedDate | date:'yyyy-MM-dd'}}
              </td>
            </ng-container>

            <ng-container matColumnDef="modifiedBy">
              <th mat-header-cell *matHeaderCellDef scope="col">Modified By</th>
              <td mat-cell *matCellDef="let formularyRow">
                <span>{{formularyRow.modifiedBy}}</span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="tableHeaders"></tr>
            <tr mat-row *matRowDef="let formularyRow; columns: tableHeaders;"></tr>
          </table>

          <div *ngIf="noDataFlag" class="no-records">
            No records found
          </div>
        </div>
        <!-- Current Formulary -->
      </div>

    </form>
  </mat-card-content>
</mat-card>