import { AfterViewInit, Component, OnDestroy, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { Subject, ReplaySubject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { AppService } from 'src/app/app.service';
import { AccountData } from '../../models/header';

@Component({
  selector: 'app-account-select',
  templateUrl: './account-select.component.html',
  styleUrls: ['./account-select.component.scss']
})
export class AccountSelectComponent implements OnInit, OnDestroy, AfterViewInit {
  /*********************Properties*********************/
  userDetailsObj: any;
  userDetails: any;
  userData: any[] = [];
  accountData: AccountData[] = [];
  allAccountOption: boolean = false;
  
  private onDestroy = new Subject<void>();
  private subscription = {
    accDetails: null,
    assignedAccountList: null,
  };

  accForm: FormGroup;

  /** list of Accounts filtered by search keyword */
  public filteredAcc: ReplaySubject<AccountData[]> = new ReplaySubject<AccountData[]>(1);
  /** Subject that emits when the component has been destroyed. */
  @ViewChild('selectAccount', { static: true }) selectAccount: MatSelect;

  /*********************Properties*********************/

  /*********************Constructor*********************/
  constructor(
    private appService: AppService,
    private formBuilder: FormBuilder,
  ) { }

  /*********************Constructor*********************/

  /*********************Lifecyle Methods*********************/
  ngOnInit() {

    //Form Initialization
    this.accForm = this.formBuilder.group({
      'accSelection': [null, [Validators.required]],
      'accFilterCtrl': [null],
      'searchText': [null],
    });


    this.subscription.assignedAccountList = this.appService.assignedAccountList$.subscribe(
      accList => {
        this.assignAccounts(accList);
      });

    // listen for search field value changes
    this.accForm.controls['accFilterCtrl'].valueChanges
      .pipe(takeUntil(this.onDestroy))
      .subscribe(() => {
        this.filterAccounts();
      });
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
    
    if (this.subscription.accDetails !== null) {
      this.subscription.accDetails.unsubscribe();
    }
    this.subscription.assignedAccountList.unsubscribe();
  }
  /*********************Lifecyle Methods*********************/

  /*********************Component Methods*********************/
  onAccChange(accIdVal: any) {
    let accObj = this.accountData.filter(acc => accIdVal.accountNumber === acc.accountNumber)[0];

    if (accObj) {
      this.appService.setAccount(accObj);
    }
  }

  /*********************Component Methods*********************/

  /*********************Private Methods*********************/
  private assignAccounts(assignedAccountList: any) {
    this.accountData = assignedAccountList;

    if (this.accountData.length === 0) {
      this.accForm.controls['accSelection'].setValue(this.accountData[0]);
      this.onAccChange(this.accountData[0]);
    }
    else {
      let selectedAccVal: any;
      this.subscription.accDetails = this.appService.accDetails$.subscribe(
        accDetail => {
          selectedAccVal = accDetail ? accDetail : null;
          this.accForm.controls['accSelection'].setValue(selectedAccVal);
        }
      );
    }

    // load the initial accountData
    this.filteredAcc.next(this.accountData.slice());
  }


  /**
   * Sets the initial value after the filteredAccount are loaded initially
   */
  protected setInitialValue() {
    this.filteredAcc
      .pipe(take(1), takeUntil(this.onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredAccount are loaded initially
        // and after the mat-option elements are available
        /* istanbul ignore next */
        this.selectAccount.compareWith = (a: AccountData, b: AccountData) => a && b && a.accountNumber === b.accountNumber;
      });
  }

  
  protected filterAccounts() {
    if (!this.accountData) {
      return;
    }
    // get the search keyword
    let search = this.accForm.controls['accFilterCtrl'].value;
    if (!search) {
      this.filteredAcc.next(this.accountData.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the Account
    this.filteredAcc.next(
      this.accountData.filter(acc => acc.accNumAndName.toLowerCase().indexOf(search) > -1)
    );
  }


  /*********************Private Methods*********************/

}
