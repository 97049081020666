<h2 mat-dialog-title> {{dialogTitle}}
  <mat-icon class="dialog-icon" (click)="onCloseDialog()">close</mat-icon>
</h2>
<mat-dialog-content class="inventory-dialog">
  <form name="addEditInventoryForm" [formGroup]="addEditInventoryForm" novalidate>
    <ul>
      <li>
        <strong>NDC:</strong>{{inventoryRowData.ndcText}} 
      </li>
      <li>
        <strong>CIN:</strong>{{inventoryRowData.cin}}
      </li>
      <li>
        <strong>Source Item Number:</strong>{{inventoryRowData.srcItemCde}}
      </li>
      <li>
        <strong>Description:</strong>{{inventoryRowData.description}}
      </li>
      <li>
        <strong>Total Virtual Inventory:</strong>{{noDataFlag?'0':totalOnHand}}
      </li>
    </ul>

  
  <!-- Inventory Facility Data -->
  <div class="mat-elevation-z8 dialog-table">

    <table mat-table [dataSource]="dataSource" aria-describedby="Inventory data view" matSort *ngIf="inventoryRowData.tabName == 'facilityInventory'">
      
      <ng-container matColumnDef="inventoryAccountNumber">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Inventory Account Number</th>
        <td mat-cell *matCellDef="let facInvRow">
            {{facInvRow.accountNo}} </td>
      </ng-container>
      
      <ng-container matColumnDef="inventoryAccountName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Inventory Account Name</th>
        <td mat-cell *matCellDef="let facInvRow">
            {{facInvRow.accountName}} </td>
      </ng-container>
      
      <ng-container matColumnDef="inventoryOnHand">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Inventory On Hand</th>
      <td mat-cell *matCellDef="let facInvRow">
          {{facInvRow.inventoryOnHand}} </td>
      </ng-container>
      
      <ng-container matColumnDef="inventoryOnOrder">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Inventory On Order</th>
      <td mat-cell *matCellDef="let facInvRow">
          {{facInvRow.inventoryOnOrder}} </td>
      </ng-container>
      
      <ng-container matColumnDef="quantityReserved">
      <th mat-header-cell *matHeaderCellDef mat-sort-header scope="col">Quantity Reserved</th>
      <td mat-cell *matCellDef="let facInvRow">
        {{facInvRow.quantityReserved}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'houseAcc-row': row.houseAccFlag === true}"></tr>

    </table>

    <table mat-table [dataSource]="dataSource" aria-describedby="Inventory data view" matSort *ngIf="inventoryRowData.tabName != 'facilityInventory'">
      
      <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns; let heading of displayedColumns">
        <th mat-header-cell *matHeaderCellDef  mat-sort-header scope="col"> {{heading | formatName}} </th>
        <td mat-cell *matCellDef="let invRow"> {{invRow[column]}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'houseAcc-row': row.houseAccFlag === true}"></tr>

    </table>
    <!-- Row shown when there is no matching data. -->
    <div *ngIf="noDataFlag" class="no-records">
      No records found
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100, 200]"></mat-paginator>
  </div>
  <!-- Inventory Facility Data -->
</form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-stroked-button color="primary" (click)="onCloseDialog()">Close</button>
</mat-dialog-actions>